import { useState, useMemo } from 'react';
import * as Icons from '@revel-transit/revel-icons';
import {
  SpotlightProvider,
  SpotlightAction,
  DriverAvatar,
} from '@revel-transit/revel-components-internal';
import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { useSpotlightDriverSearchQuery } from 'generated/page';
import { useSession } from 'next-auth/client';
import { triggerApolloErrorNotification } from 'services/utils';
import { useSelectedOperation } from 'utils/operations/OperationContext';

gql`
  query SpotlightDriverSearch($filters: DriverFilters) {
    drivers: getDrivers(filters: $filters) {
      results {
        id
        avatar
        firstName
        lastName
        phoneNumber
        email
        tlcLicenseNumber
      }
    }
  }
`;

export function Spotlight() {
  const router = useRouter();
  const [query, setQuery] = useState('');
  const [session] = useSession();
  const { system } = useSelectedOperation();

  const { data, loading } = useSpotlightDriverSearchQuery({
    variables: {
      filters: {
        search: query,
        systemId: system?.id,
      },
    },
    skip: !session || !query,
    onError: (err) => {
      triggerApolloErrorNotification(err, 'Error loading drivers');
    },
  });

  const isLoadingData = !data || loading;

  const actions: SpotlightAction[] = useMemo(() => {
    if (!query || isLoadingData) return [];
    return data.drivers.results.map((driver) => ({
      id: driver.id,
      title: `${driver.firstName} ${driver.lastName}`,
      description: `${driver.phoneNumber} | ${driver.email} | TLC: ${
        driver.tlcLicenseNumber ?? '--'
      }`,
      icon: (
        <DriverAvatar
          size={32}
          src={driver.avatar}
          firstName={driver.firstName}
          lastName={driver.lastName}
        />
      ),
      onTrigger: () => {
        router.push(`/${router.query.operationId}/drivers/${driver.id}`);
      },
    }));
  }, [data]);

  return (
    <SpotlightProvider
      query={query}
      onQueryChange={setQuery}
      actions={actions}
      searchIcon={<Icons.MagnifyingGlass />}
      searchPlaceholder="Search drivers..."
      nothingFoundMessage="Nothing found..."
      filter={() => actions}
    />
  );
}
