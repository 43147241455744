import { useEffect, useState, useRef } from 'react';

/**
 * This is used by a clock component to update the time every minute.
 *
 * @returns The current minute of the hour
 */
export function useMinute() {
  const [minute, setMinute] = useState(new Date().getMinutes());
  const initialTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Calculate milliseconds until next minute
    const now = new Date();
    const delay = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

    // Initial timeout to sync with minute change
    initialTimeoutRef.current = setTimeout(() => {
      setMinute(new Date().getMinutes());

      // Setup interval for subsequent minutes
      intervalIdRef.current = setInterval(() => {
        setMinute(new Date().getMinutes());
      }, 60000); // 60000ms = 1 minute
    }, delay);

    // Cleanup timeout and interval on unmount
    return () => {
      if (initialTimeoutRef.current) {
        clearTimeout(initialTimeoutRef.current);
      }
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  return minute;
}
