import { Button, Group, Menu, Text } from '@revel-transit/revel-components-internal';
import * as Icons from '@revel-transit/revel-icons';
import { dayjsRoot } from 'services/dayjs';
import { useMinute } from 'hooks/useMinute';
import Link from 'next/link';
import { OperationId, OPERATIONS } from 'utils/operations/constants';
import { useSelectedOperation } from 'utils/operations/OperationContext';

export const AppOperationSwitcher = () => {
  const { selectedOperation, system } = useSelectedOperation();

  // trigger a re-render every minute to update the time
  useMinute();

  return (
    <Menu position="bottom-end">
      <Menu.Target aria-label="Open operation picker">
        <Button
          loading={!selectedOperation || !system}
          variant="default"
          size="xs"
          leftIcon={<Icons.City size={14} />}
        >
          <Group spacing={4}>
            {selectedOperation.name}
            {system?.timeZone && (
              <Text size="xs" color="dimmed">
                {dayjsRoot().tz(system?.timeZone).format('h:mma z')}
              </Text>
            )}
          </Group>
        </Button>
      </Menu.Target>

      {process.env.NEXT_PUBLIC_FLEET_OPERATION_ENABLED && (
        <Menu.Dropdown>
          {Object.keys(OPERATIONS).map((operationId: OperationId) => (
            <Link key={operationId} href={OPERATIONS[operationId].homePage} passHref>
              <Menu.Item
                icon={<Icons.City size="1rem" />}
                bg={selectedOperation.id === operationId ? 'gray.1' : undefined}
              >
                <Group spacing="sm" position="apart">
                  <Text>{OPERATIONS[operationId].name}</Text>
                  <Text size="xs" color="dimmed">
                    {dayjsRoot().tz(OPERATIONS[operationId].timezone).format('h:mma z')}
                  </Text>
                </Group>
              </Menu.Item>
            </Link>
          ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
};
