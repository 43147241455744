import { ApolloError } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { NotificationProps, openErrorNotification } from '@revel-transit/revel-components-internal';
import { DriverAddress } from 'generated/types';
import { Dayjs, dayjs, dayjsRoot } from 'services/dayjs';

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function snakeCaseToTitleCase(str: string) {
  if (!str) return null;

  return str.split('_').map(capitalizeFirstLetter).join(' ');
}

export const convertMetersToMiles = (meters?: number | null) => {
  if (!meters) {
    return 0;
  }

  return meters * 0.00062137;
};

export const cropCloudinaryUrl = (
  url: string,
  options: {
    size?: number | null;
  } = {
    size: 100,
  },
) => {
  /**
   * Cloudinary api: https://cloudinary.com/documentation/transformation_reference
   *
   * One off implmentation
   */
  const cloudinaryTransformation = `ar_1:1,c_crop,g_face:center,q_100/w_${options.size}`;
  const cropUrl = url.replace('upload/', `upload/${cloudinaryTransformation}/`);

  return cropUrl;
};

export const differenceInDays = (
  startTimestamp?: string | number | Dayjs | Date,
  endTimestamp?: string | number | Dayjs | Date,
): number | null => {
  if (!startTimestamp || !endTimestamp) return null;

  const startDate = dayjs(startTimestamp).startOf('day');
  const endDate = dayjs(endTimestamp).startOf('day');

  return endDate.diff(startDate, 'day');
};

export const shiftWeekdays = (day: number, differenceInDays: number) => {
  if (day < 1 || day > 7) {
    throw new Error('Day value must be between 1 and 7!');
  }

  // day + differenceInDays normalized to 1-7
  const shiftedDay = ((day + differenceInDays - 1) % 7) + 1;

  return shiftedDay;
};

export const durationBetweenTimes = (
  startTimestamp?: string | number | Dayjs | Date,
  endTimestamp?: string | number | Dayjs | Date,
  withSeconds = false,
): string | null => {
  if (!startTimestamp || !endTimestamp) return '--';

  const startDate = dayjs(startTimestamp);
  const endDate = dayjs(endTimestamp);

  const duration = dayjsRoot.duration(endDate.diff(startDate));

  if (!duration.asMilliseconds() && !withSeconds) return `0m`;
  if (!duration.asMilliseconds()) return `0s`;

  const durationString = `${duration.days() ? `${duration.days()}d` : ''}${
    duration.hours() ? `${duration.hours()}h ` : ''
  }${duration.minutes() ? `${duration.minutes()}m` : ''}`;

  if (withSeconds) {
    return `${durationString}${duration.seconds() ? ` ${duration.seconds()}s` : ''}`;
  }

  return durationString;
};

export const makeWithinDay = (toAdjust: Dayjs, base: Dayjs) => {
  if (toAdjust.isBefore(base)) {
    return toAdjust.add(1, 'd');
  }

  const toAdjustDayEarlier = toAdjust.subtract(1, 'd');
  if (toAdjustDayEarlier.isAfter(base)) {
    return toAdjustDayEarlier;
  }

  return toAdjust;
};

export const triggerApolloErrorNotification = (
  err: ApolloError,
  defaultMessage = 'Unable to process request',
  props?: NotificationProps,
) => {
  let message = defaultMessage;

  if (err.graphQLErrors?.length) {
    const userMessages = err.graphQLErrors
      .map((err) => err.extensions?.response?.body?.user_message)
      .filter((message) => message);
    if (userMessages.length) {
      message = `${message}: ${userMessages.join(',')}`;
    }
  }

  datadogRum.addError(err);

  openErrorNotification({
    message,
    ...props,
  });
};

export const formatDriverAddress = ({
  address1,
  address2,
  city,
  state,
  postalCode,
}: Partial<DriverAddress>) => {
  const addressLine1 = address2 ? `${address1}, ${address2}` : address1;
  const addressLine2 = `${city}, ${state} ${postalCode}`;
  return `${addressLine1}\n${addressLine2}`;
};
