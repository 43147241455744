import { signOut, useSession } from 'next-auth/client';
import Link from 'next/link';
import {
  Text,
  Box,
  Group,
  NavLink,
  NavTabs,
  Divider,
  Button,
  ActionIcon,
  Tooltip,
  Burger,
  Drawer,
  createStyles,
  spotlight,
  Title,
  useSmallerThan,
  ActionIconProps,
  Stack,
} from '@revel-transit/revel-components-internal';
import * as Icons from '@revel-transit/revel-icons';

import { useRouter } from 'next/router';
import { useState, useMemo } from 'react';
import { useSelectedOperation } from 'utils/operations/OperationContext';
import { OPERATION_PAGES } from 'utils/operations/constants';
import { AppOperationSwitcher } from './AppOperationSwitcher';

function SearchButton({ size = 'sm' }: { size?: ActionIconProps['size'] }) {
  return (
    <Tooltip label="Driver Search (⌘K)" withArrow>
      <ActionIcon
        variant="subtle"
        color="gray"
        size={size}
        onClick={() => {
          spotlight.open();
        }}
        aria-label="Universal Search"
        sx={(theme) => ({
          [theme.fn.smallerThan('md')]: {
            height: '100%',
            borderRadius: 0,
          },
        })}
      >
        <Icons.MagnifyingGlass />
      </ActionIcon>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  headerWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.other.headerHeight,
    flexShrink: 0,
    zIndex: 100,
    borderBottom: `2px solid ${theme.fn.themeColor('gray', 2)}`,
    [theme.fn.smallerThan('md')]: {
      borderBottomWidth: '1px',
    },
  },
  headerContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: `0 ${theme.spacing.md}`,
    [theme.fn.smallerThan('md')]: {
      paddingRight: 0,
    },
  },
}));

export function AppHeader() {
  const [session] = useSession();
  const { selectedOperation } = useSelectedOperation();

  const router = useRouter();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const isHeaderCollapsed = useSmallerThan('md');

  const { classes } = useStyles();

  const pages = useMemo(
    () =>
      OPERATION_PAGES[selectedOperation.type].map((page) => ({
        ...page,
        href: `/${selectedOperation.id}${page.href}`,
      })),
    [selectedOperation],
  );
  const currentPage = pages.find((page) => router.asPath.startsWith(page.href));

  if (!session) return null;

  return (
    <Box component="header" className={classes.headerWrap}>
      <Box className={classes.headerContent}>
        {!isHeaderCollapsed && (
          <>
            <Stack spacing={4} align="center">
              <Box sx={(theme) => ({ color: theme.fn.themeColor('brand', 4) })}>
                <Icons.RevelLogoText width={72} />
              </Box>
              <Text fw="600" size="xs" sx={{ textTransform: 'uppercase', lineHeight: 1 }}>
                Operations
              </Text>
            </Stack>
            <Box mx="md">
              <AppOperationSwitcher />
            </Box>
          </>
        )}
        {isHeaderCollapsed && (
          <>
            {currentPage && (
              <Group spacing="sm" noWrap>
                <Title size={16}>{currentPage.label}</Title>
                <AppOperationSwitcher />
              </Group>
            )}
            <Group
              ml="auto"
              noWrap
              spacing={0}
              sx={(theme) => ({
                [theme.fn.smallerThan('md')]: {
                  height: '100%',
                },
              })}
            >
              <SearchButton size="md" />
              <Burger
                opened={drawerOpen}
                onClick={() => setDrawerOpen(!drawerOpen)}
                sx={(theme) => ({
                  padding: theme.spacing.md,
                  height: '100%',
                  width: 'min-content',
                  borderRadius: 0,
                })}
              />
            </Group>

            <Drawer
              opened={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              position="top"
              size="auto"
              withCloseButton={false}
              overlayProps={{
                opacity: 0.3,
              }}
              transitionProps={{
                transition: 'scale-y',
              }}
              styles={(theme) => ({
                root: {
                  top: theme.other.headerHeight,
                  left: 0,
                  right: 0,
                },
                inner: {
                  top: theme.other.headerHeight,
                  left: 0,
                  right: 0,
                },
                overlay: {
                  top: theme.other.headerHeight,
                },
              })}
            >
              {pages.map((page) => (
                <NavLink
                  key={page.href}
                  active={router.asPath.startsWith(page.href)}
                  component="a"
                  href={page.href}
                  label={page.label}
                  icon={page.icon}
                  sx={(theme) => ({
                    height: '48px',
                    fontWeight: 600,
                    '&[data-active]': {
                      color: theme.fn.themeColor('blue', 6),
                      backgroundColor: theme.fn.themeColor('blue', 0),
                    },
                  })}
                />
              ))}
              <Divider color="gray.1" />
              <NavLink
                sx={{
                  height: '48px',
                  fontWeight: 600,
                }}
                label="Logout"
                icon={<Icons.Logout />}
                onClick={() => signOut()}
              />
            </Drawer>
          </>
        )}
        {!isHeaderCollapsed && (
          <>
            <NavTabs
              sx={{
                position: 'absolute',
                top: '0',
                left: '50%',
                transform: 'translateX(-50%)',
                justifyContent: 'center',
              }}
            >
              {pages.map((page) => (
                <Link href={page.href} key={page.href} passHref>
                  <NavTabs.Tab
                    leftIcon={page.icon}
                    active={router.asPath.startsWith(page.href)}
                    sx={(theme) => ({
                      flexShrink: 0,
                      height: theme.other.headerHeight,
                      [theme.fn.smallerThan('xl')]: {
                        fontSize: theme.fontSizes.xs,
                        padding: theme.spacing.xs,

                        '& svg': {
                          width: '20px',
                          height: '20px',
                        },
                      },
                    })}
                  >
                    {page.label}
                  </NavTabs.Tab>
                </Link>
              ))}
            </NavTabs>

            <Group spacing="sm" ml="auto">
              <SearchButton />

              <Button variant="subtle" color="gray" size="sm" onClick={() => signOut()} px={8}>
                Logout
              </Button>
            </Group>
          </>
        )}
      </Box>
    </Box>
  );
}
