/* eslint-disable */
/* @ts-nocheck */
// THIS FILE IS GENERATED AUTOMATICALLY FOR driver-ops, DO NOT EDIT DIRECTLY
// This file contains Apollo hooks and ssr functions
import { Date } from '../../../../customScalars';
import { Time } from '../../../../customScalars';
import * as Operations from './types';
import { NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router';
import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient } from '../../lib/apolloClient';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
export async function getServerPageGetDriverVehicleSelectorVehicles(
  options: Omit<
    Apollo.QueryOptions<Operations.GetDriverVehicleSelectorVehiclesQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverVehicleSelectorVehiclesQuery>({
    ...options,
    query: Operations.GetDriverVehicleSelectorVehicles,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverVehicleSelectorVehicles = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverVehicleSelectorVehiclesQuery,
    Operations.GetDriverVehicleSelectorVehiclesQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverVehicleSelectorVehicles, options);
};
export type PageGetDriverVehicleSelectorVehiclesComp = React.FC<{
  data?: Operations.GetDriverVehicleSelectorVehiclesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverVehicleSelectorVehicles = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverVehicleSelectorVehiclesQuery,
    Operations.GetDriverVehicleSelectorVehiclesQueryVariables
  >,
) => (WrappedComponent: PageGetDriverVehicleSelectorVehiclesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverVehicleSelectorVehicles, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverVehicleSelectorVehicles = {
  getServerPage: getServerPageGetDriverVehicleSelectorVehicles,
  withPage: withPageGetDriverVehicleSelectorVehicles,
  usePage: useGetDriverVehicleSelectorVehicles,
};
export async function getServerPageGetDepotSelectorDepots(
  options: Omit<Apollo.QueryOptions<Operations.GetDepotSelectorDepotsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDepotSelectorDepotsQuery>({
    ...options,
    query: Operations.GetDepotSelectorDepots,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDepotSelectorDepots = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDepotSelectorDepotsQuery,
    Operations.GetDepotSelectorDepotsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDepotSelectorDepots, options);
};
export type PageGetDepotSelectorDepotsComp = React.FC<{
  data?: Operations.GetDepotSelectorDepotsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDepotSelectorDepots = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDepotSelectorDepotsQuery,
    Operations.GetDepotSelectorDepotsQueryVariables
  >,
) => (WrappedComponent: PageGetDepotSelectorDepotsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDepotSelectorDepots, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDepotSelectorDepots = {
  getServerPage: getServerPageGetDepotSelectorDepots,
  withPage: withPageGetDepotSelectorDepots,
  usePage: useGetDepotSelectorDepots,
};
export async function getServerPageGetInvoiceDetailsSidebar(
  options: Omit<Apollo.QueryOptions<Operations.GetInvoiceDetailsSidebarQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetInvoiceDetailsSidebarQuery>({
    ...options,
    query: Operations.GetInvoiceDetailsSidebar,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetInvoiceDetailsSidebar = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInvoiceDetailsSidebarQuery,
    Operations.GetInvoiceDetailsSidebarQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetInvoiceDetailsSidebar, options);
};
export type PageGetInvoiceDetailsSidebarComp = React.FC<{
  data?: Operations.GetInvoiceDetailsSidebarQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetInvoiceDetailsSidebar = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInvoiceDetailsSidebarQuery,
    Operations.GetInvoiceDetailsSidebarQueryVariables
  >,
) => (WrappedComponent: PageGetInvoiceDetailsSidebarComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetInvoiceDetailsSidebar, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetInvoiceDetailsSidebar = {
  getServerPage: getServerPageGetInvoiceDetailsSidebar,
  withPage: withPageGetInvoiceDetailsSidebar,
  usePage: useGetInvoiceDetailsSidebar,
};
export async function getServerPageGetSystemsDepots(
  options: Omit<Apollo.QueryOptions<Operations.GetSystemsDepotsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetSystemsDepotsQuery>({
    ...options,
    query: Operations.GetSystemsDepots,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetSystemsDepots = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetSystemsDepotsQuery,
    Operations.GetSystemsDepotsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSystemsDepots, options);
};
export type PageGetSystemsDepotsComp = React.FC<{
  data?: Operations.GetSystemsDepotsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetSystemsDepots = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetSystemsDepotsQuery,
    Operations.GetSystemsDepotsQueryVariables
  >,
) => (WrappedComponent: PageGetSystemsDepotsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetSystemsDepots, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetSystemsDepots = {
  getServerPage: getServerPageGetSystemsDepots,
  withPage: withPageGetSystemsDepots,
  usePage: useGetSystemsDepots,
};
export async function getServerPageGetDriverSystemZones(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverSystemZonesQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverSystemZonesQuery>({
    ...options,
    query: Operations.GetDriverSystemZones,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverSystemZones = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverSystemZonesQuery,
    Operations.GetDriverSystemZonesQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverSystemZones, options);
};
export type PageGetDriverSystemZonesComp = React.FC<{
  data?: Operations.GetDriverSystemZonesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverSystemZones = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverSystemZonesQuery,
    Operations.GetDriverSystemZonesQueryVariables
  >,
) => (WrappedComponent: PageGetDriverSystemZonesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverSystemZones, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverSystemZones = {
  getServerPage: getServerPageGetDriverSystemZones,
  withPage: withPageGetDriverSystemZones,
  usePage: useGetDriverSystemZones,
};
export async function getServerPageListNotesReservationActionShortcuts(
  options: Omit<
    Apollo.QueryOptions<Operations.ListNotesReservationActionShortcutsQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.ListNotesReservationActionShortcutsQuery>({
    ...options,
    query: Operations.ListNotesReservationActionShortcuts,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useListNotesReservationActionShortcuts = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.ListNotesReservationActionShortcutsQuery,
    Operations.ListNotesReservationActionShortcutsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ListNotesReservationActionShortcuts, options);
};
export type PageListNotesReservationActionShortcutsComp = React.FC<{
  data?: Operations.ListNotesReservationActionShortcutsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageListNotesReservationActionShortcuts = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.ListNotesReservationActionShortcutsQuery,
    Operations.ListNotesReservationActionShortcutsQueryVariables
  >,
) => (WrappedComponent: PageListNotesReservationActionShortcutsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.ListNotesReservationActionShortcuts, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrListNotesReservationActionShortcuts = {
  getServerPage: getServerPageListNotesReservationActionShortcuts,
  withPage: withPageListNotesReservationActionShortcuts,
  usePage: useListNotesReservationActionShortcuts,
};
export async function getServerPageGetReservationDetailsSidebar(
  options: Omit<
    Apollo.QueryOptions<Operations.GetReservationDetailsSidebarQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetReservationDetailsSidebarQuery>({
    ...options,
    query: Operations.GetReservationDetailsSidebar,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetReservationDetailsSidebar = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationDetailsSidebarQuery,
    Operations.GetReservationDetailsSidebarQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetReservationDetailsSidebar, options);
};
export type PageGetReservationDetailsSidebarComp = React.FC<{
  data?: Operations.GetReservationDetailsSidebarQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetReservationDetailsSidebar = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationDetailsSidebarQuery,
    Operations.GetReservationDetailsSidebarQueryVariables
  >,
) => (WrappedComponent: PageGetReservationDetailsSidebarComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetReservationDetailsSidebar, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetReservationDetailsSidebar = {
  getServerPage: getServerPageGetReservationDetailsSidebar,
  withPage: withPageGetReservationDetailsSidebar,
  usePage: useGetReservationDetailsSidebar,
};
export async function getServerPageGetReservationFilterCount(
  options: Omit<Apollo.QueryOptions<Operations.GetReservationFilterCountQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetReservationFilterCountQuery>({
    ...options,
    query: Operations.GetReservationFilterCount,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetReservationFilterCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationFilterCountQuery,
    Operations.GetReservationFilterCountQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetReservationFilterCount, options);
};
export type PageGetReservationFilterCountComp = React.FC<{
  data?: Operations.GetReservationFilterCountQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetReservationFilterCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationFilterCountQuery,
    Operations.GetReservationFilterCountQueryVariables
  >,
) => (WrappedComponent: PageGetReservationFilterCountComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetReservationFilterCount, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetReservationFilterCount = {
  getServerPage: getServerPageGetReservationFilterCount,
  withPage: withPageGetReservationFilterCount,
  usePage: useGetReservationFilterCount,
};
export async function getServerPageGetScheduledShiftDetailsSidebar(
  options: Omit<
    Apollo.QueryOptions<Operations.GetScheduledShiftDetailsSidebarQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetScheduledShiftDetailsSidebarQuery>({
    ...options,
    query: Operations.GetScheduledShiftDetailsSidebar,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetScheduledShiftDetailsSidebar = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetScheduledShiftDetailsSidebarQuery,
    Operations.GetScheduledShiftDetailsSidebarQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetScheduledShiftDetailsSidebar, options);
};
export type PageGetScheduledShiftDetailsSidebarComp = React.FC<{
  data?: Operations.GetScheduledShiftDetailsSidebarQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetScheduledShiftDetailsSidebar = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetScheduledShiftDetailsSidebarQuery,
    Operations.GetScheduledShiftDetailsSidebarQueryVariables
  >,
) => (WrappedComponent: PageGetScheduledShiftDetailsSidebarComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetScheduledShiftDetailsSidebar, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetScheduledShiftDetailsSidebar = {
  getServerPage: getServerPageGetScheduledShiftDetailsSidebar,
  withPage: withPageGetScheduledShiftDetailsSidebar,
  usePage: useGetScheduledShiftDetailsSidebar,
};
export async function getServerPageGetShiftInfo(
  options: Omit<Apollo.QueryOptions<Operations.GetShiftInfoQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetShiftInfoQuery>({
    ...options,
    query: Operations.GetShiftInfo,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetShiftInfo = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetShiftInfoQuery, Operations.GetShiftInfoQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetShiftInfo, options);
};
export type PageGetShiftInfoComp = React.FC<{
  data?: Operations.GetShiftInfoQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetShiftInfo = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetShiftInfoQuery, Operations.GetShiftInfoQueryVariables>,
) => (WrappedComponent: PageGetShiftInfoComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetShiftInfo, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetShiftInfo = {
  getServerPage: getServerPageGetShiftInfo,
  withPage: withPageGetShiftInfo,
  usePage: useGetShiftInfo,
};
export async function getServerPageGetShiftFilterCount(
  options: Omit<Apollo.QueryOptions<Operations.GetShiftFilterCountQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetShiftFilterCountQuery>({
    ...options,
    query: Operations.GetShiftFilterCount,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetShiftFilterCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftFilterCountQuery,
    Operations.GetShiftFilterCountQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetShiftFilterCount, options);
};
export type PageGetShiftFilterCountComp = React.FC<{
  data?: Operations.GetShiftFilterCountQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetShiftFilterCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftFilterCountQuery,
    Operations.GetShiftFilterCountQueryVariables
  >,
) => (WrappedComponent: PageGetShiftFilterCountComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetShiftFilterCount, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetShiftFilterCount = {
  getServerPage: getServerPageGetShiftFilterCount,
  withPage: withPageGetShiftFilterCount,
  usePage: useGetShiftFilterCount,
};
export async function getServerPageGetShiftMapMarkerInfo(
  options: Omit<Apollo.QueryOptions<Operations.GetShiftMapMarkerInfoQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetShiftMapMarkerInfoQuery>({
    ...options,
    query: Operations.GetShiftMapMarkerInfo,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetShiftMapMarkerInfo = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftMapMarkerInfoQuery,
    Operations.GetShiftMapMarkerInfoQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetShiftMapMarkerInfo, options);
};
export type PageGetShiftMapMarkerInfoComp = React.FC<{
  data?: Operations.GetShiftMapMarkerInfoQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetShiftMapMarkerInfo = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftMapMarkerInfoQuery,
    Operations.GetShiftMapMarkerInfoQueryVariables
  >,
) => (WrappedComponent: PageGetShiftMapMarkerInfoComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetShiftMapMarkerInfo, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetShiftMapMarkerInfo = {
  getServerPage: getServerPageGetShiftMapMarkerInfo,
  withPage: withPageGetShiftMapMarkerInfo,
  usePage: useGetShiftMapMarkerInfo,
};
export async function getServerPageSpotlightDriverSearch(
  options: Omit<Apollo.QueryOptions<Operations.SpotlightDriverSearchQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.SpotlightDriverSearchQuery>({
    ...options,
    query: Operations.SpotlightDriverSearch,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useSpotlightDriverSearch = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.SpotlightDriverSearchQuery,
    Operations.SpotlightDriverSearchQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.SpotlightDriverSearch, options);
};
export type PageSpotlightDriverSearchComp = React.FC<{
  data?: Operations.SpotlightDriverSearchQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageSpotlightDriverSearch = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.SpotlightDriverSearchQuery,
    Operations.SpotlightDriverSearchQueryVariables
  >,
) => (WrappedComponent: PageSpotlightDriverSearchComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.SpotlightDriverSearch, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrSpotlightDriverSearch = {
  getServerPage: getServerPageSpotlightDriverSearch,
  withPage: withPageSpotlightDriverSearch,
  usePage: useSpotlightDriverSearch,
};

export async function getServerPageGetVehicleFacilities(
  options: Omit<Apollo.QueryOptions<Operations.GetVehicleFacilitiesQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetVehicleFacilitiesQuery>({
    ...options,
    query: Operations.GetVehicleFacilities,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetVehicleFacilities = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetVehicleFacilitiesQuery,
    Operations.GetVehicleFacilitiesQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetVehicleFacilities, options);
};
export type PageGetVehicleFacilitiesComp = React.FC<{
  data?: Operations.GetVehicleFacilitiesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetVehicleFacilities = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetVehicleFacilitiesQuery,
    Operations.GetVehicleFacilitiesQueryVariables
  >,
) => (WrappedComponent: PageGetVehicleFacilitiesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetVehicleFacilities, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetVehicleFacilities = {
  getServerPage: getServerPageGetVehicleFacilities,
  withPage: withPageGetVehicleFacilities,
  usePage: useGetVehicleFacilities,
};
export async function getServerPageGetVehicleFilterCount(
  options: Omit<Apollo.QueryOptions<Operations.GetVehicleFilterCountQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetVehicleFilterCountQuery>({
    ...options,
    query: Operations.GetVehicleFilterCount,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetVehicleFilterCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetVehicleFilterCountQuery,
    Operations.GetVehicleFilterCountQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetVehicleFilterCount, options);
};
export type PageGetVehicleFilterCountComp = React.FC<{
  data?: Operations.GetVehicleFilterCountQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetVehicleFilterCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetVehicleFilterCountQuery,
    Operations.GetVehicleFilterCountQueryVariables
  >,
) => (WrappedComponent: PageGetVehicleFilterCountComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetVehicleFilterCount, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetVehicleFilterCount = {
  getServerPage: getServerPageGetVehicleFilterCount,
  withPage: withPageGetVehicleFilterCount,
  usePage: useGetVehicleFilterCount,
};
export async function getServerPageGetVehicleSelectorVehicles(
  options: Omit<Apollo.QueryOptions<Operations.GetVehicleSelectorVehiclesQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetVehicleSelectorVehiclesQuery>({
    ...options,
    query: Operations.GetVehicleSelectorVehicles,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetVehicleSelectorVehicles = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetVehicleSelectorVehiclesQuery,
    Operations.GetVehicleSelectorVehiclesQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetVehicleSelectorVehicles, options);
};
export type PageGetVehicleSelectorVehiclesComp = React.FC<{
  data?: Operations.GetVehicleSelectorVehiclesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetVehicleSelectorVehicles = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetVehicleSelectorVehiclesQuery,
    Operations.GetVehicleSelectorVehiclesQueryVariables
  >,
) => (WrappedComponent: PageGetVehicleSelectorVehiclesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetVehicleSelectorVehicles, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetVehicleSelectorVehicles = {
  getServerPage: getServerPageGetVehicleSelectorVehicles,
  withPage: withPageGetVehicleSelectorVehicles,
  usePage: useGetVehicleSelectorVehicles,
};

export async function getServerPageCalculateDriverVehicleCreditAmount(
  options: Omit<
    Apollo.QueryOptions<Operations.CalculateDriverVehicleCreditAmountQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.CalculateDriverVehicleCreditAmountQuery>({
    ...options,
    query: Operations.CalculateDriverVehicleCreditAmount,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useCalculateDriverVehicleCreditAmount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.CalculateDriverVehicleCreditAmountQuery,
    Operations.CalculateDriverVehicleCreditAmountQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.CalculateDriverVehicleCreditAmount, options);
};
export type PageCalculateDriverVehicleCreditAmountComp = React.FC<{
  data?: Operations.CalculateDriverVehicleCreditAmountQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageCalculateDriverVehicleCreditAmount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.CalculateDriverVehicleCreditAmountQuery,
    Operations.CalculateDriverVehicleCreditAmountQueryVariables
  >,
) => (WrappedComponent: PageCalculateDriverVehicleCreditAmountComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.CalculateDriverVehicleCreditAmount, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrCalculateDriverVehicleCreditAmount = {
  getServerPage: getServerPageCalculateDriverVehicleCreditAmount,
  withPage: withPageCalculateDriverVehicleCreditAmount,
  usePage: useCalculateDriverVehicleCreditAmount,
};
export async function getServerPageGetDriverAvailableCredit(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverAvailableCreditQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverAvailableCreditQuery>({
    ...options,
    query: Operations.GetDriverAvailableCredit,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverAvailableCredit = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverAvailableCreditQuery,
    Operations.GetDriverAvailableCreditQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverAvailableCredit, options);
};
export type PageGetDriverAvailableCreditComp = React.FC<{
  data?: Operations.GetDriverAvailableCreditQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverAvailableCredit = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverAvailableCreditQuery,
    Operations.GetDriverAvailableCreditQueryVariables
  >,
) => (WrappedComponent: PageGetDriverAvailableCreditComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverAvailableCredit, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverAvailableCredit = {
  getServerPage: getServerPageGetDriverAvailableCredit,
  withPage: withPageGetDriverAvailableCredit,
  usePage: useGetDriverAvailableCredit,
};
export async function getServerPageGetAssignVehicleToReservationReservation(
  options: Omit<
    Apollo.QueryOptions<Operations.GetAssignVehicleToReservationReservationQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetAssignVehicleToReservationReservationQuery>({
    ...options,
    query: Operations.GetAssignVehicleToReservationReservation,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAssignVehicleToReservationReservation = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetAssignVehicleToReservationReservationQuery,
    Operations.GetAssignVehicleToReservationReservationQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAssignVehicleToReservationReservation, options);
};
export type PageGetAssignVehicleToReservationReservationComp = React.FC<{
  data?: Operations.GetAssignVehicleToReservationReservationQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAssignVehicleToReservationReservation = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetAssignVehicleToReservationReservationQuery,
    Operations.GetAssignVehicleToReservationReservationQueryVariables
  >,
) => (WrappedComponent: PageGetAssignVehicleToReservationReservationComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetAssignVehicleToReservationReservation, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetAssignVehicleToReservationReservation = {
  getServerPage: getServerPageGetAssignVehicleToReservationReservation,
  withPage: withPageGetAssignVehicleToReservationReservation,
  usePage: useGetAssignVehicleToReservationReservation,
};
export async function getServerPageGetAssignVehicleToReservationDriverDetails(
  options: Omit<
    Apollo.QueryOptions<Operations.GetAssignVehicleToReservationDriverDetailsQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetAssignVehicleToReservationDriverDetailsQuery>(
    { ...options, query: Operations.GetAssignVehicleToReservationDriverDetails },
  );

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAssignVehicleToReservationDriverDetails = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetAssignVehicleToReservationDriverDetailsQuery,
    Operations.GetAssignVehicleToReservationDriverDetailsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAssignVehicleToReservationDriverDetails, options);
};
export type PageGetAssignVehicleToReservationDriverDetailsComp = React.FC<{
  data?: Operations.GetAssignVehicleToReservationDriverDetailsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAssignVehicleToReservationDriverDetails = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetAssignVehicleToReservationDriverDetailsQuery,
    Operations.GetAssignVehicleToReservationDriverDetailsQueryVariables
  >,
) => (WrappedComponent: PageGetAssignVehicleToReservationDriverDetailsComp): NextPage => (
  props,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetAssignVehicleToReservationDriverDetails, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetAssignVehicleToReservationDriverDetails = {
  getServerPage: getServerPageGetAssignVehicleToReservationDriverDetails,
  withPage: withPageGetAssignVehicleToReservationDriverDetails,
  usePage: useGetAssignVehicleToReservationDriverDetails,
};

export async function getServerPageGetAssignVehicleToShiftDriverDetails(
  options: Omit<
    Apollo.QueryOptions<Operations.GetAssignVehicleToShiftDriverDetailsQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetAssignVehicleToShiftDriverDetailsQuery>({
    ...options,
    query: Operations.GetAssignVehicleToShiftDriverDetails,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAssignVehicleToShiftDriverDetails = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetAssignVehicleToShiftDriverDetailsQuery,
    Operations.GetAssignVehicleToShiftDriverDetailsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAssignVehicleToShiftDriverDetails, options);
};
export type PageGetAssignVehicleToShiftDriverDetailsComp = React.FC<{
  data?: Operations.GetAssignVehicleToShiftDriverDetailsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAssignVehicleToShiftDriverDetails = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetAssignVehicleToShiftDriverDetailsQuery,
    Operations.GetAssignVehicleToShiftDriverDetailsQueryVariables
  >,
) => (WrappedComponent: PageGetAssignVehicleToShiftDriverDetailsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetAssignVehicleToShiftDriverDetails, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetAssignVehicleToShiftDriverDetails = {
  getServerPage: getServerPageGetAssignVehicleToShiftDriverDetails,
  withPage: withPageGetAssignVehicleToShiftDriverDetails,
  usePage: useGetAssignVehicleToShiftDriverDetails,
};
export async function getServerPageGetCancelReservationModal(
  options: Omit<Apollo.QueryOptions<Operations.GetCancelReservationModalQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetCancelReservationModalQuery>({
    ...options,
    query: Operations.GetCancelReservationModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetCancelReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetCancelReservationModalQuery,
    Operations.GetCancelReservationModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCancelReservationModal, options);
};
export type PageGetCancelReservationModalComp = React.FC<{
  data?: Operations.GetCancelReservationModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetCancelReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetCancelReservationModalQuery,
    Operations.GetCancelReservationModalQueryVariables
  >,
) => (WrappedComponent: PageGetCancelReservationModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetCancelReservationModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetCancelReservationModal = {
  getServerPage: getServerPageGetCancelReservationModal,
  withPage: withPageGetCancelReservationModal,
  usePage: useGetCancelReservationModal,
};

export async function getServerPageGetCancelScheduledShiftModal(
  options: Omit<
    Apollo.QueryOptions<Operations.GetCancelScheduledShiftModalQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetCancelScheduledShiftModalQuery>({
    ...options,
    query: Operations.GetCancelScheduledShiftModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetCancelScheduledShiftModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetCancelScheduledShiftModalQuery,
    Operations.GetCancelScheduledShiftModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCancelScheduledShiftModal, options);
};
export type PageGetCancelScheduledShiftModalComp = React.FC<{
  data?: Operations.GetCancelScheduledShiftModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetCancelScheduledShiftModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetCancelScheduledShiftModalQuery,
    Operations.GetCancelScheduledShiftModalQueryVariables
  >,
) => (WrappedComponent: PageGetCancelScheduledShiftModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetCancelScheduledShiftModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetCancelScheduledShiftModal = {
  getServerPage: getServerPageGetCancelScheduledShiftModal,
  withPage: withPageGetCancelScheduledShiftModal,
  usePage: useGetCancelScheduledShiftModal,
};

export async function getServerPageGetShiftCancellationReasons(
  options: Omit<Apollo.QueryOptions<Operations.GetShiftCancellationReasonsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetShiftCancellationReasonsQuery>({
    ...options,
    query: Operations.GetShiftCancellationReasons,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetShiftCancellationReasons = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftCancellationReasonsQuery,
    Operations.GetShiftCancellationReasonsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetShiftCancellationReasons, options);
};
export type PageGetShiftCancellationReasonsComp = React.FC<{
  data?: Operations.GetShiftCancellationReasonsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetShiftCancellationReasons = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftCancellationReasonsQuery,
    Operations.GetShiftCancellationReasonsQueryVariables
  >,
) => (WrappedComponent: PageGetShiftCancellationReasonsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetShiftCancellationReasons, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetShiftCancellationReasons = {
  getServerPage: getServerPageGetShiftCancellationReasons,
  withPage: withPageGetShiftCancellationReasons,
  usePage: useGetShiftCancellationReasons,
};

export async function getServerPageGetCheckInReservationModal(
  options: Omit<Apollo.QueryOptions<Operations.GetCheckInReservationModalQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetCheckInReservationModalQuery>({
    ...options,
    query: Operations.GetCheckInReservationModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetCheckInReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetCheckInReservationModalQuery,
    Operations.GetCheckInReservationModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetCheckInReservationModal, options);
};
export type PageGetCheckInReservationModalComp = React.FC<{
  data?: Operations.GetCheckInReservationModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetCheckInReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetCheckInReservationModalQuery,
    Operations.GetCheckInReservationModalQueryVariables
  >,
) => (WrappedComponent: PageGetCheckInReservationModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetCheckInReservationModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetCheckInReservationModal = {
  getServerPage: getServerPageGetCheckInReservationModal,
  withPage: withPageGetCheckInReservationModal,
  usePage: useGetCheckInReservationModal,
};

export async function getServerPageGetDriverEmploymentType(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverEmploymentTypeQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverEmploymentTypeQuery>({
    ...options,
    query: Operations.GetDriverEmploymentType,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverEmploymentType = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverEmploymentTypeQuery,
    Operations.GetDriverEmploymentTypeQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverEmploymentType, options);
};
export type PageGetDriverEmploymentTypeComp = React.FC<{
  data?: Operations.GetDriverEmploymentTypeQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverEmploymentType = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverEmploymentTypeQuery,
    Operations.GetDriverEmploymentTypeQueryVariables
  >,
) => (WrappedComponent: PageGetDriverEmploymentTypeComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverEmploymentType, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverEmploymentType = {
  getServerPage: getServerPageGetDriverEmploymentType,
  withPage: withPageGetDriverEmploymentType,
  usePage: useGetDriverEmploymentType,
};

export async function getServerPageGetInsuranceProviders(
  options: Omit<Apollo.QueryOptions<Operations.GetInsuranceProvidersQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetInsuranceProvidersQuery>({
    ...options,
    query: Operations.GetInsuranceProviders,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetInsuranceProviders = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInsuranceProvidersQuery,
    Operations.GetInsuranceProvidersQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetInsuranceProviders, options);
};
export type PageGetInsuranceProvidersComp = React.FC<{
  data?: Operations.GetInsuranceProvidersQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetInsuranceProviders = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInsuranceProvidersQuery,
    Operations.GetInsuranceProvidersQueryVariables
  >,
) => (WrappedComponent: PageGetInsuranceProvidersComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetInsuranceProviders, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetInsuranceProviders = {
  getServerPage: getServerPageGetInsuranceProviders,
  withPage: withPageGetInsuranceProviders,
  usePage: useGetInsuranceProviders,
};
export async function getServerPageGetAdicRegistrations(
  options: Omit<Apollo.QueryOptions<Operations.GetAdicRegistrationsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetAdicRegistrationsQuery>({
    ...options,
    query: Operations.GetAdicRegistrations,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetAdicRegistrations = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetAdicRegistrationsQuery,
    Operations.GetAdicRegistrationsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetAdicRegistrations, options);
};
export type PageGetAdicRegistrationsComp = React.FC<{
  data?: Operations.GetAdicRegistrationsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetAdicRegistrations = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetAdicRegistrationsQuery,
    Operations.GetAdicRegistrationsQueryVariables
  >,
) => (WrappedComponent: PageGetAdicRegistrationsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetAdicRegistrations, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetAdicRegistrations = {
  getServerPage: getServerPageGetAdicRegistrations,
  withPage: withPageGetAdicRegistrations,
  usePage: useGetAdicRegistrations,
};

export async function getServerPageGetDriverFleetResourceDetails(
  options: Omit<
    Apollo.QueryOptions<Operations.GetDriverFleetResourceDetailsQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverFleetResourceDetailsQuery>({
    ...options,
    query: Operations.GetDriverFleetResourceDetails,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverFleetResourceDetails = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverFleetResourceDetailsQuery,
    Operations.GetDriverFleetResourceDetailsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverFleetResourceDetails, options);
};
export type PageGetDriverFleetResourceDetailsComp = React.FC<{
  data?: Operations.GetDriverFleetResourceDetailsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverFleetResourceDetails = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverFleetResourceDetailsQuery,
    Operations.GetDriverFleetResourceDetailsQueryVariables
  >,
) => (WrappedComponent: PageGetDriverFleetResourceDetailsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverFleetResourceDetails, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverFleetResourceDetails = {
  getServerPage: getServerPageGetDriverFleetResourceDetails,
  withPage: withPageGetDriverFleetResourceDetails,
  usePage: useGetDriverFleetResourceDetails,
};

export async function getServerPageGetDriverVehiclePreference(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverVehiclePreferenceQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverVehiclePreferenceQuery>({
    ...options,
    query: Operations.GetDriverVehiclePreference,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverVehiclePreference = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverVehiclePreferenceQuery,
    Operations.GetDriverVehiclePreferenceQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverVehiclePreference, options);
};
export type PageGetDriverVehiclePreferenceComp = React.FC<{
  data?: Operations.GetDriverVehiclePreferenceQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverVehiclePreference = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverVehiclePreferenceQuery,
    Operations.GetDriverVehiclePreferenceQueryVariables
  >,
) => (WrappedComponent: PageGetDriverVehiclePreferenceComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverVehiclePreference, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverVehiclePreference = {
  getServerPage: getServerPageGetDriverVehiclePreference,
  withPage: withPageGetDriverVehiclePreference,
  usePage: useGetDriverVehiclePreference,
};

export async function getServerPageGetDriverAvailableSecurityDepositLedger(
  options: Omit<
    Apollo.QueryOptions<Operations.GetDriverAvailableSecurityDepositLedgerQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverAvailableSecurityDepositLedgerQuery>({
    ...options,
    query: Operations.GetDriverAvailableSecurityDepositLedger,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverAvailableSecurityDepositLedger = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverAvailableSecurityDepositLedgerQuery,
    Operations.GetDriverAvailableSecurityDepositLedgerQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverAvailableSecurityDepositLedger, options);
};
export type PageGetDriverAvailableSecurityDepositLedgerComp = React.FC<{
  data?: Operations.GetDriverAvailableSecurityDepositLedgerQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverAvailableSecurityDepositLedger = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverAvailableSecurityDepositLedgerQuery,
    Operations.GetDriverAvailableSecurityDepositLedgerQueryVariables
  >,
) => (WrappedComponent: PageGetDriverAvailableSecurityDepositLedgerComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverAvailableSecurityDepositLedger, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverAvailableSecurityDepositLedger = {
  getServerPage: getServerPageGetDriverAvailableSecurityDepositLedger,
  withPage: withPageGetDriverAvailableSecurityDepositLedger,
  usePage: useGetDriverAvailableSecurityDepositLedger,
};

export async function getServerPageGetReservationBillingSettings(
  options: Omit<
    Apollo.QueryOptions<Operations.GetReservationBillingSettingsQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetReservationBillingSettingsQuery>({
    ...options,
    query: Operations.GetReservationBillingSettings,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetReservationBillingSettings = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationBillingSettingsQuery,
    Operations.GetReservationBillingSettingsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetReservationBillingSettings, options);
};
export type PageGetReservationBillingSettingsComp = React.FC<{
  data?: Operations.GetReservationBillingSettingsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetReservationBillingSettings = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationBillingSettingsQuery,
    Operations.GetReservationBillingSettingsQueryVariables
  >,
) => (WrappedComponent: PageGetReservationBillingSettingsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetReservationBillingSettings, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetReservationBillingSettings = {
  getServerPage: getServerPageGetReservationBillingSettings,
  withPage: withPageGetReservationBillingSettings,
  usePage: useGetReservationBillingSettings,
};

export async function getServerPageGetEditReservationModal(
  options: Omit<Apollo.QueryOptions<Operations.GetEditReservationModalQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetEditReservationModalQuery>({
    ...options,
    query: Operations.GetEditReservationModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetEditReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetEditReservationModalQuery,
    Operations.GetEditReservationModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEditReservationModal, options);
};
export type PageGetEditReservationModalComp = React.FC<{
  data?: Operations.GetEditReservationModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetEditReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetEditReservationModalQuery,
    Operations.GetEditReservationModalQueryVariables
  >,
) => (WrappedComponent: PageGetEditReservationModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetEditReservationModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetEditReservationModal = {
  getServerPage: getServerPageGetEditReservationModal,
  withPage: withPageGetEditReservationModal,
  usePage: useGetEditReservationModal,
};

export async function getServerPageGetEndReservationModal(
  options: Omit<Apollo.QueryOptions<Operations.GetEndReservationModalQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetEndReservationModalQuery>({
    ...options,
    query: Operations.GetEndReservationModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetEndReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetEndReservationModalQuery,
    Operations.GetEndReservationModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEndReservationModal, options);
};
export type PageGetEndReservationModalComp = React.FC<{
  data?: Operations.GetEndReservationModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetEndReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetEndReservationModalQuery,
    Operations.GetEndReservationModalQueryVariables
  >,
) => (WrappedComponent: PageGetEndReservationModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetEndReservationModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetEndReservationModal = {
  getServerPage: getServerPageGetEndReservationModal,
  withPage: withPageGetEndReservationModal,
  usePage: useGetEndReservationModal,
};

export async function getServerPageGetEndScheduledShiftModal(
  options: Omit<Apollo.QueryOptions<Operations.GetEndScheduledShiftModalQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetEndScheduledShiftModalQuery>({
    ...options,
    query: Operations.GetEndScheduledShiftModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetEndScheduledShiftModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetEndScheduledShiftModalQuery,
    Operations.GetEndScheduledShiftModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetEndScheduledShiftModal, options);
};
export type PageGetEndScheduledShiftModalComp = React.FC<{
  data?: Operations.GetEndScheduledShiftModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetEndScheduledShiftModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetEndScheduledShiftModalQuery,
    Operations.GetEndScheduledShiftModalQueryVariables
  >,
) => (WrappedComponent: PageGetEndScheduledShiftModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetEndScheduledShiftModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetEndScheduledShiftModal = {
  getServerPage: getServerPageGetEndScheduledShiftModal,
  withPage: withPageGetEndScheduledShiftModal,
  usePage: useGetEndScheduledShiftModal,
};

export async function getServerPageListIncidents(
  options: Omit<Apollo.QueryOptions<Operations.ListIncidentsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.ListIncidentsQuery>({
    ...options,
    query: Operations.ListIncidents,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useListIncidents = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.ListIncidentsQuery, Operations.ListIncidentsQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ListIncidents, options);
};
export type PageListIncidentsComp = React.FC<{
  data?: Operations.ListIncidentsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageListIncidents = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.ListIncidentsQuery, Operations.ListIncidentsQueryVariables>,
) => (WrappedComponent: PageListIncidentsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.ListIncidents, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrListIncidents = {
  getServerPage: getServerPageListIncidents,
  withPage: withPageListIncidents,
  usePage: useListIncidents,
};

export async function getServerPageGetIssueDetailsModalIssue(
  options: Omit<Apollo.QueryOptions<Operations.GetIssueDetailsModalIssueQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetIssueDetailsModalIssueQuery>({
    ...options,
    query: Operations.GetIssueDetailsModalIssue,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetIssueDetailsModalIssue = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetIssueDetailsModalIssueQuery,
    Operations.GetIssueDetailsModalIssueQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetIssueDetailsModalIssue, options);
};
export type PageGetIssueDetailsModalIssueComp = React.FC<{
  data?: Operations.GetIssueDetailsModalIssueQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetIssueDetailsModalIssue = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetIssueDetailsModalIssueQuery,
    Operations.GetIssueDetailsModalIssueQueryVariables
  >,
) => (WrappedComponent: PageGetIssueDetailsModalIssueComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetIssueDetailsModalIssue, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetIssueDetailsModalIssue = {
  getServerPage: getServerPageGetIssueDetailsModalIssue,
  withPage: withPageGetIssueDetailsModalIssue,
  usePage: useGetIssueDetailsModalIssue,
};

export async function getServerPageGetDriverIncidentNoteInfo(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverIncidentNoteInfoQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverIncidentNoteInfoQuery>({
    ...options,
    query: Operations.GetDriverIncidentNoteInfo,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverIncidentNoteInfo = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentNoteInfoQuery,
    Operations.GetDriverIncidentNoteInfoQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverIncidentNoteInfo, options);
};
export type PageGetDriverIncidentNoteInfoComp = React.FC<{
  data?: Operations.GetDriverIncidentNoteInfoQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverIncidentNoteInfo = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentNoteInfoQuery,
    Operations.GetDriverIncidentNoteInfoQueryVariables
  >,
) => (WrappedComponent: PageGetDriverIncidentNoteInfoComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverIncidentNoteInfo, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverIncidentNoteInfo = {
  getServerPage: getServerPageGetDriverIncidentNoteInfo,
  withPage: withPageGetDriverIncidentNoteInfo,
  usePage: useGetDriverIncidentNoteInfo,
};
export async function getServerPageGetReservationNoteModal(
  options: Omit<Apollo.QueryOptions<Operations.GetReservationNoteModalQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetReservationNoteModalQuery>({
    ...options,
    query: Operations.GetReservationNoteModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetReservationNoteModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationNoteModalQuery,
    Operations.GetReservationNoteModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetReservationNoteModal, options);
};
export type PageGetReservationNoteModalComp = React.FC<{
  data?: Operations.GetReservationNoteModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetReservationNoteModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationNoteModalQuery,
    Operations.GetReservationNoteModalQueryVariables
  >,
) => (WrappedComponent: PageGetReservationNoteModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetReservationNoteModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetReservationNoteModal = {
  getServerPage: getServerPageGetReservationNoteModal,
  withPage: withPageGetReservationNoteModal,
  usePage: useGetReservationNoteModal,
};
export async function getServerPageGetScheduledShiftNoteModal(
  options: Omit<Apollo.QueryOptions<Operations.GetScheduledShiftNoteModalQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetScheduledShiftNoteModalQuery>({
    ...options,
    query: Operations.GetScheduledShiftNoteModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetScheduledShiftNoteModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetScheduledShiftNoteModalQuery,
    Operations.GetScheduledShiftNoteModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetScheduledShiftNoteModal, options);
};
export type PageGetScheduledShiftNoteModalComp = React.FC<{
  data?: Operations.GetScheduledShiftNoteModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetScheduledShiftNoteModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetScheduledShiftNoteModalQuery,
    Operations.GetScheduledShiftNoteModalQueryVariables
  >,
) => (WrappedComponent: PageGetScheduledShiftNoteModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetScheduledShiftNoteModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetScheduledShiftNoteModal = {
  getServerPage: getServerPageGetScheduledShiftNoteModal,
  withPage: withPageGetScheduledShiftNoteModal,
  usePage: useGetScheduledShiftNoteModal,
};

export async function getServerPageGetNotesCount(
  options: Omit<Apollo.QueryOptions<Operations.GetNotesCountQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetNotesCountQuery>({
    ...options,
    query: Operations.GetNotesCount,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetNotesCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetNotesCountQuery, Operations.GetNotesCountQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetNotesCount, options);
};
export type PageGetNotesCountComp = React.FC<{
  data?: Operations.GetNotesCountQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetNotesCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetNotesCountQuery, Operations.GetNotesCountQueryVariables>,
) => (WrappedComponent: PageGetNotesCountComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetNotesCount, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetNotesCount = {
  getServerPage: getServerPageGetNotesCount,
  withPage: withPageGetNotesCount,
  usePage: useGetNotesCount,
};
export async function getServerPageGetNotes(
  options: Omit<Apollo.QueryOptions<Operations.GetNotesQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetNotesQuery>({
    ...options,
    query: Operations.GetNotes,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetNotes = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetNotesQuery, Operations.GetNotesQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetNotes, options);
};
export type PageGetNotesComp = React.FC<{
  data?: Operations.GetNotesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetNotes = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetNotesQuery, Operations.GetNotesQueryVariables>,
) => (WrappedComponent: PageGetNotesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetNotes, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetNotes = {
  getServerPage: getServerPageGetNotes,
  withPage: withPageGetNotes,
  usePage: useGetNotes,
};

export async function getServerPageGetDriverIncidentReviewDispute(
  options: Omit<
    Apollo.QueryOptions<Operations.GetDriverIncidentReviewDisputeQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverIncidentReviewDisputeQuery>({
    ...options,
    query: Operations.GetDriverIncidentReviewDispute,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverIncidentReviewDispute = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentReviewDisputeQuery,
    Operations.GetDriverIncidentReviewDisputeQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverIncidentReviewDispute, options);
};
export type PageGetDriverIncidentReviewDisputeComp = React.FC<{
  data?: Operations.GetDriverIncidentReviewDisputeQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverIncidentReviewDispute = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentReviewDisputeQuery,
    Operations.GetDriverIncidentReviewDisputeQueryVariables
  >,
) => (WrappedComponent: PageGetDriverIncidentReviewDisputeComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverIncidentReviewDispute, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverIncidentReviewDispute = {
  getServerPage: getServerPageGetDriverIncidentReviewDispute,
  withPage: withPageGetDriverIncidentReviewDispute,
  usePage: useGetDriverIncidentReviewDispute,
};

export async function getServerPageGetStandbyReservationModal(
  options: Omit<Apollo.QueryOptions<Operations.GetStandbyReservationModalQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetStandbyReservationModalQuery>({
    ...options,
    query: Operations.GetStandbyReservationModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetStandbyReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetStandbyReservationModalQuery,
    Operations.GetStandbyReservationModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetStandbyReservationModal, options);
};
export type PageGetStandbyReservationModalComp = React.FC<{
  data?: Operations.GetStandbyReservationModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetStandbyReservationModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetStandbyReservationModalQuery,
    Operations.GetStandbyReservationModalQueryVariables
  >,
) => (WrappedComponent: PageGetStandbyReservationModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetStandbyReservationModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetStandbyReservationModal = {
  getServerPage: getServerPageGetStandbyReservationModal,
  withPage: withPageGetStandbyReservationModal,
  usePage: useGetStandbyReservationModal,
};

export async function getServerPageGetDriverVoidInvoiceModal(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverVoidInvoiceModalQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverVoidInvoiceModalQuery>({
    ...options,
    query: Operations.GetDriverVoidInvoiceModal,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverVoidInvoiceModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverVoidInvoiceModalQuery,
    Operations.GetDriverVoidInvoiceModalQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverVoidInvoiceModal, options);
};
export type PageGetDriverVoidInvoiceModalComp = React.FC<{
  data?: Operations.GetDriverVoidInvoiceModalQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverVoidInvoiceModal = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverVoidInvoiceModalQuery,
    Operations.GetDriverVoidInvoiceModalQueryVariables
  >,
) => (WrappedComponent: PageGetDriverVoidInvoiceModalComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverVoidInvoiceModal, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverVoidInvoiceModal = {
  getServerPage: getServerPageGetDriverVoidInvoiceModal,
  withPage: withPageGetDriverVoidInvoiceModal,
  usePage: useGetDriverVoidInvoiceModal,
};
export async function getServerPageGetInvoiceVoidedReasons(
  options: Omit<Apollo.QueryOptions<Operations.GetInvoiceVoidedReasonsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetInvoiceVoidedReasonsQuery>({
    ...options,
    query: Operations.GetInvoiceVoidedReasons,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetInvoiceVoidedReasons = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInvoiceVoidedReasonsQuery,
    Operations.GetInvoiceVoidedReasonsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetInvoiceVoidedReasons, options);
};
export type PageGetInvoiceVoidedReasonsComp = React.FC<{
  data?: Operations.GetInvoiceVoidedReasonsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetInvoiceVoidedReasons = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInvoiceVoidedReasonsQuery,
    Operations.GetInvoiceVoidedReasonsQueryVariables
  >,
) => (WrappedComponent: PageGetInvoiceVoidedReasonsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetInvoiceVoidedReasons, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetInvoiceVoidedReasons = {
  getServerPage: getServerPageGetInvoiceVoidedReasons,
  withPage: withPageGetInvoiceVoidedReasons,
  usePage: useGetInvoiceVoidedReasons,
};

export async function getServerPageGetDepositDetailsSidebar(
  options: Omit<Apollo.QueryOptions<Operations.GetDepositDetailsSidebarQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDepositDetailsSidebarQuery>({
    ...options,
    query: Operations.GetDepositDetailsSidebar,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDepositDetailsSidebar = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDepositDetailsSidebarQuery,
    Operations.GetDepositDetailsSidebarQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDepositDetailsSidebar, options);
};
export type PageGetDepositDetailsSidebarComp = React.FC<{
  data?: Operations.GetDepositDetailsSidebarQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDepositDetailsSidebar = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDepositDetailsSidebarQuery,
    Operations.GetDepositDetailsSidebarQueryVariables
  >,
) => (WrappedComponent: PageGetDepositDetailsSidebarComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDepositDetailsSidebar, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDepositDetailsSidebar = {
  getServerPage: getServerPageGetDepositDetailsSidebar,
  withPage: withPageGetDepositDetailsSidebar,
  usePage: useGetDepositDetailsSidebar,
};
export async function getServerPageGetDriverSecurityDepositLedgerRecords(
  options: Omit<
    Apollo.QueryOptions<Operations.GetDriverSecurityDepositLedgerRecordsQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverSecurityDepositLedgerRecordsQuery>({
    ...options,
    query: Operations.GetDriverSecurityDepositLedgerRecords,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverSecurityDepositLedgerRecords = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverSecurityDepositLedgerRecordsQuery,
    Operations.GetDriverSecurityDepositLedgerRecordsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverSecurityDepositLedgerRecords, options);
};
export type PageGetDriverSecurityDepositLedgerRecordsComp = React.FC<{
  data?: Operations.GetDriverSecurityDepositLedgerRecordsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverSecurityDepositLedgerRecords = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverSecurityDepositLedgerRecordsQuery,
    Operations.GetDriverSecurityDepositLedgerRecordsQueryVariables
  >,
) => (WrappedComponent: PageGetDriverSecurityDepositLedgerRecordsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverSecurityDepositLedgerRecords, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverSecurityDepositLedgerRecords = {
  getServerPage: getServerPageGetDriverSecurityDepositLedgerRecords,
  withPage: withPageGetDriverSecurityDepositLedgerRecords,
  usePage: useGetDriverSecurityDepositLedgerRecords,
};

export async function getServerPageGetDriverRestrictionsContent(
  options: Omit<
    Apollo.QueryOptions<Operations.GetDriverRestrictionsContentQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverRestrictionsContentQuery>({
    ...options,
    query: Operations.GetDriverRestrictionsContent,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverRestrictionsContent = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverRestrictionsContentQuery,
    Operations.GetDriverRestrictionsContentQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverRestrictionsContent, options);
};
export type PageGetDriverRestrictionsContentComp = React.FC<{
  data?: Operations.GetDriverRestrictionsContentQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverRestrictionsContent = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverRestrictionsContentQuery,
    Operations.GetDriverRestrictionsContentQueryVariables
  >,
) => (WrappedComponent: PageGetDriverRestrictionsContentComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverRestrictionsContent, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverRestrictionsContent = {
  getServerPage: getServerPageGetDriverRestrictionsContent,
  withPage: withPageGetDriverRestrictionsContent,
  usePage: useGetDriverRestrictionsContent,
};
export async function getServerPageGetTracks(
  options: Omit<Apollo.QueryOptions<Operations.GetTracksQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetTracksQuery>({
    ...options,
    query: Operations.GetTracks,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetTracks = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetTracksQuery, Operations.GetTracksQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetTracks, options);
};
export type PageGetTracksComp = React.FC<{
  data?: Operations.GetTracksQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetTracks = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetTracksQuery, Operations.GetTracksQueryVariables>,
) => (WrappedComponent: PageGetTracksComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetTracks, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetTracks = {
  getServerPage: getServerPageGetTracks,
  withPage: withPageGetTracks,
  usePage: useGetTracks,
};
export async function getServerPageGetDriverIncidents(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverIncidentsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverIncidentsQuery>({
    ...options,
    query: Operations.GetDriverIncidents,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverIncidents = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentsQuery,
    Operations.GetDriverIncidentsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverIncidents, options);
};
export type PageGetDriverIncidentsComp = React.FC<{
  data?: Operations.GetDriverIncidentsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverIncidents = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentsQuery,
    Operations.GetDriverIncidentsQueryVariables
  >,
) => (WrappedComponent: PageGetDriverIncidentsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverIncidents, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverIncidents = {
  getServerPage: getServerPageGetDriverIncidents,
  withPage: withPageGetDriverIncidents,
  usePage: useGetDriverIncidents,
};
export async function getServerPageGetDriverIncident(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverIncidentQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverIncidentQuery>({
    ...options,
    query: Operations.GetDriverIncident,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverIncident = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentQuery,
    Operations.GetDriverIncidentQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverIncident, options);
};
export type PageGetDriverIncidentComp = React.FC<{
  data?: Operations.GetDriverIncidentQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverIncident = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentQuery,
    Operations.GetDriverIncidentQueryVariables
  >,
) => (WrappedComponent: PageGetDriverIncidentComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverIncident, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverIncident = {
  getServerPage: getServerPageGetDriverIncident,
  withPage: withPageGetDriverIncident,
  usePage: useGetDriverIncident,
};
export async function getServerPageGetInvoiceTabInvoices(
  options: Omit<Apollo.QueryOptions<Operations.GetInvoiceTabInvoicesQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetInvoiceTabInvoicesQuery>({
    ...options,
    query: Operations.GetInvoiceTabInvoices,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetInvoiceTabInvoices = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInvoiceTabInvoicesQuery,
    Operations.GetInvoiceTabInvoicesQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetInvoiceTabInvoices, options);
};
export type PageGetInvoiceTabInvoicesComp = React.FC<{
  data?: Operations.GetInvoiceTabInvoicesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetInvoiceTabInvoices = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInvoiceTabInvoicesQuery,
    Operations.GetInvoiceTabInvoicesQueryVariables
  >,
) => (WrappedComponent: PageGetInvoiceTabInvoicesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetInvoiceTabInvoices, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetInvoiceTabInvoices = {
  getServerPage: getServerPageGetInvoiceTabInvoices,
  withPage: withPageGetInvoiceTabInvoices,
  usePage: useGetInvoiceTabInvoices,
};
export async function getServerPageGetReservationsTab(
  options: Omit<Apollo.QueryOptions<Operations.GetReservationsTabQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetReservationsTabQuery>({
    ...options,
    query: Operations.GetReservationsTab,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetReservationsTab = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationsTabQuery,
    Operations.GetReservationsTabQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetReservationsTab, options);
};
export type PageGetReservationsTabComp = React.FC<{
  data?: Operations.GetReservationsTabQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetReservationsTab = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationsTabQuery,
    Operations.GetReservationsTabQueryVariables
  >,
) => (WrappedComponent: PageGetReservationsTabComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetReservationsTab, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetReservationsTab = {
  getServerPage: getServerPageGetReservationsTab,
  withPage: withPageGetReservationsTab,
  usePage: useGetReservationsTab,
};
export async function getServerPageGetRidesTabRides(
  options: Omit<Apollo.QueryOptions<Operations.GetRidesTabRidesQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetRidesTabRidesQuery>({
    ...options,
    query: Operations.GetRidesTabRides,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetRidesTabRides = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetRidesTabRidesQuery,
    Operations.GetRidesTabRidesQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetRidesTabRides, options);
};
export type PageGetRidesTabRidesComp = React.FC<{
  data?: Operations.GetRidesTabRidesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetRidesTabRides = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetRidesTabRidesQuery,
    Operations.GetRidesTabRidesQueryVariables
  >,
) => (WrappedComponent: PageGetRidesTabRidesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetRidesTabRides, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetRidesTabRides = {
  getServerPage: getServerPageGetRidesTabRides,
  withPage: withPageGetRidesTabRides,
  usePage: useGetRidesTabRides,
};
export async function getServerPageListRecurringShifts(
  options: Omit<Apollo.QueryOptions<Operations.ListRecurringShiftsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.ListRecurringShiftsQuery>({
    ...options,
    query: Operations.ListRecurringShifts,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useListRecurringShifts = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.ListRecurringShiftsQuery,
    Operations.ListRecurringShiftsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ListRecurringShifts, options);
};
export type PageListRecurringShiftsComp = React.FC<{
  data?: Operations.ListRecurringShiftsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageListRecurringShifts = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.ListRecurringShiftsQuery,
    Operations.ListRecurringShiftsQueryVariables
  >,
) => (WrappedComponent: PageListRecurringShiftsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.ListRecurringShifts, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrListRecurringShifts = {
  getServerPage: getServerPageListRecurringShifts,
  withPage: withPageListRecurringShifts,
  usePage: useListRecurringShifts,
};
export async function getServerPageGetRecurringShift(
  options: Omit<Apollo.QueryOptions<Operations.GetRecurringShiftQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetRecurringShiftQuery>({
    ...options,
    query: Operations.GetRecurringShift,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetRecurringShift = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetRecurringShiftQuery,
    Operations.GetRecurringShiftQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetRecurringShift, options);
};
export type PageGetRecurringShiftComp = React.FC<{
  data?: Operations.GetRecurringShiftQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetRecurringShift = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetRecurringShiftQuery,
    Operations.GetRecurringShiftQueryVariables
  >,
) => (WrappedComponent: PageGetRecurringShiftComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetRecurringShift, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetRecurringShift = {
  getServerPage: getServerPageGetRecurringShift,
  withPage: withPageGetRecurringShift,
  usePage: useGetRecurringShift,
};
export async function getServerPageGetShiftsTabShifts(
  options: Omit<Apollo.QueryOptions<Operations.GetShiftsTabShiftsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetShiftsTabShiftsQuery>({
    ...options,
    query: Operations.GetShiftsTabShifts,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetShiftsTabShifts = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftsTabShiftsQuery,
    Operations.GetShiftsTabShiftsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetShiftsTabShifts, options);
};
export type PageGetShiftsTabShiftsComp = React.FC<{
  data?: Operations.GetShiftsTabShiftsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetShiftsTabShifts = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftsTabShiftsQuery,
    Operations.GetShiftsTabShiftsQueryVariables
  >,
) => (WrappedComponent: PageGetShiftsTabShiftsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetShiftsTabShifts, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetShiftsTabShifts = {
  getServerPage: getServerPageGetShiftsTabShifts,
  withPage: withPageGetShiftsTabShifts,
  usePage: useGetShiftsTabShifts,
};
export async function getServerPageListTrafficViolationTickets(
  options: Omit<Apollo.QueryOptions<Operations.ListTrafficViolationTicketsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.ListTrafficViolationTicketsQuery>({
    ...options,
    query: Operations.ListTrafficViolationTickets,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useListTrafficViolationTickets = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.ListTrafficViolationTicketsQuery,
    Operations.ListTrafficViolationTicketsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ListTrafficViolationTickets, options);
};
export type PageListTrafficViolationTicketsComp = React.FC<{
  data?: Operations.ListTrafficViolationTicketsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageListTrafficViolationTickets = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.ListTrafficViolationTicketsQuery,
    Operations.ListTrafficViolationTicketsQueryVariables
  >,
) => (WrappedComponent: PageListTrafficViolationTicketsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.ListTrafficViolationTickets, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrListTrafficViolationTickets = {
  getServerPage: getServerPageListTrafficViolationTickets,
  withPage: withPageListTrafficViolationTickets,
  usePage: useListTrafficViolationTickets,
};
export async function getServerPageGetDriversScreen(
  options: Omit<Apollo.QueryOptions<Operations.GetDriversScreenQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriversScreenQuery>({
    ...options,
    query: Operations.GetDriversScreen,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriversScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriversScreenQuery,
    Operations.GetDriversScreenQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriversScreen, options);
};
export type PageGetDriversScreenComp = React.FC<{
  data?: Operations.GetDriversScreenQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriversScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriversScreenQuery,
    Operations.GetDriversScreenQueryVariables
  >,
) => (WrappedComponent: PageGetDriversScreenComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriversScreen, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriversScreen = {
  getServerPage: getServerPageGetDriversScreen,
  withPage: withPageGetDriversScreen,
  usePage: useGetDriversScreen,
};
export async function getServerPageGetDriver(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverQuery>({
    ...options,
    query: Operations.GetDriver,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriver = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetDriverQuery, Operations.GetDriverQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriver, options);
};
export type PageGetDriverComp = React.FC<{
  data?: Operations.GetDriverQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriver = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetDriverQuery, Operations.GetDriverQueryVariables>,
) => (WrappedComponent: PageGetDriverComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriver, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriver = {
  getServerPage: getServerPageGetDriver,
  withPage: withPageGetDriver,
  usePage: useGetDriver,
};
export async function getServerPageGetDriverAddress(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverAddressQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverAddressQuery>({
    ...options,
    query: Operations.GetDriverAddress,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverAddress = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverAddressQuery,
    Operations.GetDriverAddressQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverAddress, options);
};
export type PageGetDriverAddressComp = React.FC<{
  data?: Operations.GetDriverAddressQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverAddress = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverAddressQuery,
    Operations.GetDriverAddressQueryVariables
  >,
) => (WrappedComponent: PageGetDriverAddressComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverAddress, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverAddress = {
  getServerPage: getServerPageGetDriverAddress,
  withPage: withPageGetDriverAddress,
  usePage: useGetDriverAddress,
};
export async function getServerPageGetDriverLicenses(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverLicensesQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverLicensesQuery>({
    ...options,
    query: Operations.GetDriverLicenses,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverLicenses = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverLicensesQuery,
    Operations.GetDriverLicensesQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverLicenses, options);
};
export type PageGetDriverLicensesComp = React.FC<{
  data?: Operations.GetDriverLicensesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverLicenses = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverLicensesQuery,
    Operations.GetDriverLicensesQueryVariables
  >,
) => (WrappedComponent: PageGetDriverLicensesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverLicenses, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverLicenses = {
  getServerPage: getServerPageGetDriverLicenses,
  withPage: withPageGetDriverLicenses,
  usePage: useGetDriverLicenses,
};
export async function getServerPageGetDriverTracks(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverTracksQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverTracksQuery>({
    ...options,
    query: Operations.GetDriverTracks,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverTracks = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetDriverTracksQuery, Operations.GetDriverTracksQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverTracks, options);
};
export type PageGetDriverTracksComp = React.FC<{
  data?: Operations.GetDriverTracksQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverTracks = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetDriverTracksQuery, Operations.GetDriverTracksQueryVariables>,
) => (WrappedComponent: PageGetDriverTracksComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverTracks, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverTracks = {
  getServerPage: getServerPageGetDriverTracks,
  withPage: withPageGetDriverTracks,
  usePage: useGetDriverTracks,
};
export async function getServerPageGetDriverShift(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverShiftQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverShiftQuery>({
    ...options,
    query: Operations.GetDriverShift,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverShift = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetDriverShiftQuery, Operations.GetDriverShiftQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverShift, options);
};
export type PageGetDriverShiftComp = React.FC<{
  data?: Operations.GetDriverShiftQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverShift = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetDriverShiftQuery, Operations.GetDriverShiftQueryVariables>,
) => (WrappedComponent: PageGetDriverShiftComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverShift, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverShift = {
  getServerPage: getServerPageGetDriverShift,
  withPage: withPageGetDriverShift,
  usePage: useGetDriverShift,
};
export async function getServerPageGetDriverOtpValidationCode(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverOtpValidationCodeQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverOtpValidationCodeQuery>({
    ...options,
    query: Operations.GetDriverOtpValidationCode,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverOtpValidationCode = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverOtpValidationCodeQuery,
    Operations.GetDriverOtpValidationCodeQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverOtpValidationCode, options);
};
export type PageGetDriverOtpValidationCodeComp = React.FC<{
  data?: Operations.GetDriverOtpValidationCodeQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverOtpValidationCode = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverOtpValidationCodeQuery,
    Operations.GetDriverOtpValidationCodeQueryVariables
  >,
) => (WrappedComponent: PageGetDriverOtpValidationCodeComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverOtpValidationCode, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverOtpValidationCode = {
  getServerPage: getServerPageGetDriverOtpValidationCode,
  withPage: withPageGetDriverOtpValidationCode,
  usePage: useGetDriverOtpValidationCode,
};

export async function getServerPageGetInvoicesScreen(
  options: Omit<Apollo.QueryOptions<Operations.GetInvoicesScreenQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetInvoicesScreenQuery>({
    ...options,
    query: Operations.GetInvoicesScreen,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetInvoicesScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInvoicesScreenQuery,
    Operations.GetInvoicesScreenQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetInvoicesScreen, options);
};
export type PageGetInvoicesScreenComp = React.FC<{
  data?: Operations.GetInvoicesScreenQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetInvoicesScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetInvoicesScreenQuery,
    Operations.GetInvoicesScreenQueryVariables
  >,
) => (WrappedComponent: PageGetInvoicesScreenComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetInvoicesScreen, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetInvoicesScreen = {
  getServerPage: getServerPageGetInvoicesScreen,
  withPage: withPageGetInvoicesScreen,
  usePage: useGetInvoicesScreen,
};

export async function getServerPageGetSelectedShiftMapLocation(
  options: Omit<Apollo.QueryOptions<Operations.GetSelectedShiftMapLocationQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetSelectedShiftMapLocationQuery>({
    ...options,
    query: Operations.GetSelectedShiftMapLocation,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetSelectedShiftMapLocation = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetSelectedShiftMapLocationQuery,
    Operations.GetSelectedShiftMapLocationQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSelectedShiftMapLocation, options);
};
export type PageGetSelectedShiftMapLocationComp = React.FC<{
  data?: Operations.GetSelectedShiftMapLocationQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetSelectedShiftMapLocation = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetSelectedShiftMapLocationQuery,
    Operations.GetSelectedShiftMapLocationQueryVariables
  >,
) => (WrappedComponent: PageGetSelectedShiftMapLocationComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetSelectedShiftMapLocation, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetSelectedShiftMapLocation = {
  getServerPage: getServerPageGetSelectedShiftMapLocation,
  withPage: withPageGetSelectedShiftMapLocation,
  usePage: useGetSelectedShiftMapLocation,
};
export async function getServerPageGetBalancerMapConfig(
  options: Omit<Apollo.QueryOptions<Operations.GetBalancerMapConfigQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetBalancerMapConfigQuery>({
    ...options,
    query: Operations.GetBalancerMapConfig,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetBalancerMapConfig = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetBalancerMapConfigQuery,
    Operations.GetBalancerMapConfigQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetBalancerMapConfig, options);
};
export type PageGetBalancerMapConfigComp = React.FC<{
  data?: Operations.GetBalancerMapConfigQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetBalancerMapConfig = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetBalancerMapConfigQuery,
    Operations.GetBalancerMapConfigQueryVariables
  >,
) => (WrappedComponent: PageGetBalancerMapConfigComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetBalancerMapConfig, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetBalancerMapConfig = {
  getServerPage: getServerPageGetBalancerMapConfig,
  withPage: withPageGetBalancerMapConfig,
  usePage: useGetBalancerMapConfig,
};
export async function getServerPageGetBalancerMap(
  options: Omit<Apollo.QueryOptions<Operations.GetBalancerMapQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetBalancerMapQuery>({
    ...options,
    query: Operations.GetBalancerMap,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetBalancerMap = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetBalancerMapQuery, Operations.GetBalancerMapQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetBalancerMap, options);
};
export type PageGetBalancerMapComp = React.FC<{
  data?: Operations.GetBalancerMapQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetBalancerMap = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetBalancerMapQuery, Operations.GetBalancerMapQueryVariables>,
) => (WrappedComponent: PageGetBalancerMapComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetBalancerMap, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetBalancerMap = {
  getServerPage: getServerPageGetBalancerMap,
  withPage: withPageGetBalancerMap,
  usePage: useGetBalancerMap,
};
export async function getServerPageGetSelectedShiftMapRoute(
  options: Omit<Apollo.QueryOptions<Operations.GetSelectedShiftMapRouteQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetSelectedShiftMapRouteQuery>({
    ...options,
    query: Operations.GetSelectedShiftMapRoute,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetSelectedShiftMapRoute = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetSelectedShiftMapRouteQuery,
    Operations.GetSelectedShiftMapRouteQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSelectedShiftMapRoute, options);
};
export type PageGetSelectedShiftMapRouteComp = React.FC<{
  data?: Operations.GetSelectedShiftMapRouteQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetSelectedShiftMapRoute = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetSelectedShiftMapRouteQuery,
    Operations.GetSelectedShiftMapRouteQueryVariables
  >,
) => (WrappedComponent: PageGetSelectedShiftMapRouteComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetSelectedShiftMapRoute, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetSelectedShiftMapRoute = {
  getServerPage: getServerPageGetSelectedShiftMapRoute,
  withPage: withPageGetSelectedShiftMapRoute,
  usePage: useGetSelectedShiftMapRoute,
};
export async function getServerPageGetDriverMapsScreen(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverMapsScreenQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverMapsScreenQuery>({
    ...options,
    query: Operations.GetDriverMapsScreen,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverMapsScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverMapsScreenQuery,
    Operations.GetDriverMapsScreenQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverMapsScreen, options);
};
export type PageGetDriverMapsScreenComp = React.FC<{
  data?: Operations.GetDriverMapsScreenQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverMapsScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverMapsScreenQuery,
    Operations.GetDriverMapsScreenQueryVariables
  >,
) => (WrappedComponent: PageGetDriverMapsScreenComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverMapsScreen, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverMapsScreen = {
  getServerPage: getServerPageGetDriverMapsScreen,
  withPage: withPageGetDriverMapsScreen,
  usePage: useGetDriverMapsScreen,
};
export async function getServerPageGetReservationsScreen(
  options: Omit<Apollo.QueryOptions<Operations.GetReservationsScreenQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetReservationsScreenQuery>({
    ...options,
    query: Operations.GetReservationsScreen,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetReservationsScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationsScreenQuery,
    Operations.GetReservationsScreenQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetReservationsScreen, options);
};
export type PageGetReservationsScreenComp = React.FC<{
  data?: Operations.GetReservationsScreenQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetReservationsScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetReservationsScreenQuery,
    Operations.GetReservationsScreenQueryVariables
  >,
) => (WrappedComponent: PageGetReservationsScreenComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetReservationsScreen, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetReservationsScreen = {
  getServerPage: getServerPageGetReservationsScreen,
  withPage: withPageGetReservationsScreen,
  usePage: useGetReservationsScreen,
};
export async function getServerPageGetDriverIncidentDisputes(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverIncidentDisputesQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverIncidentDisputesQuery>({
    ...options,
    query: Operations.GetDriverIncidentDisputes,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverIncidentDisputes = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentDisputesQuery,
    Operations.GetDriverIncidentDisputesQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverIncidentDisputes, options);
};
export type PageGetDriverIncidentDisputesComp = React.FC<{
  data?: Operations.GetDriverIncidentDisputesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverIncidentDisputes = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverIncidentDisputesQuery,
    Operations.GetDriverIncidentDisputesQueryVariables
  >,
) => (WrappedComponent: PageGetDriverIncidentDisputesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverIncidentDisputes, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverIncidentDisputes = {
  getServerPage: getServerPageGetDriverIncidentDisputes,
  withPage: withPageGetDriverIncidentDisputes,
  usePage: useGetDriverIncidentDisputes,
};
export async function getServerPageGetOffboardingScreenList(
  options: Omit<Apollo.QueryOptions<Operations.GetOffboardingScreenListQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetOffboardingScreenListQuery>({
    ...options,
    query: Operations.GetOffboardingScreenList,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetOffboardingScreenList = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetOffboardingScreenListQuery,
    Operations.GetOffboardingScreenListQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetOffboardingScreenList, options);
};
export type PageGetOffboardingScreenListComp = React.FC<{
  data?: Operations.GetOffboardingScreenListQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetOffboardingScreenList = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetOffboardingScreenListQuery,
    Operations.GetOffboardingScreenListQueryVariables
  >,
) => (WrappedComponent: PageGetOffboardingScreenListComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetOffboardingScreenList, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetOffboardingScreenList = {
  getServerPage: getServerPageGetOffboardingScreenList,
  withPage: withPageGetOffboardingScreenList,
  usePage: useGetOffboardingScreenList,
};

export async function getServerPageRideHail(
  options: Omit<Apollo.QueryOptions<Operations.RideHailQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.RideHailQuery>({
    ...options,
    query: Operations.RideHail,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useRideHail = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.RideHailQuery, Operations.RideHailQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.RideHail, options);
};
export type PageRideHailComp = React.FC<{
  data?: Operations.RideHailQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageRideHail = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.RideHailQuery, Operations.RideHailQueryVariables>,
) => (WrappedComponent: PageRideHailComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.RideHail, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrRideHail = {
  getServerPage: getServerPageRideHail,
  withPage: withPageRideHail,
  usePage: useRideHail,
};
export async function getServerPageGetScheduledShiftFilterCount(
  options: Omit<
    Apollo.QueryOptions<Operations.GetScheduledShiftFilterCountQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetScheduledShiftFilterCountQuery>({
    ...options,
    query: Operations.GetScheduledShiftFilterCount,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetScheduledShiftFilterCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetScheduledShiftFilterCountQuery,
    Operations.GetScheduledShiftFilterCountQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetScheduledShiftFilterCount, options);
};
export type PageGetScheduledShiftFilterCountComp = React.FC<{
  data?: Operations.GetScheduledShiftFilterCountQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetScheduledShiftFilterCount = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetScheduledShiftFilterCountQuery,
    Operations.GetScheduledShiftFilterCountQueryVariables
  >,
) => (WrappedComponent: PageGetScheduledShiftFilterCountComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetScheduledShiftFilterCount, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetScheduledShiftFilterCount = {
  getServerPage: getServerPageGetScheduledShiftFilterCount,
  withPage: withPageGetScheduledShiftFilterCount,
  usePage: useGetScheduledShiftFilterCount,
};
export async function getServerPageGetDriverScheduledShiftsScreen(
  options: Omit<
    Apollo.QueryOptions<Operations.GetDriverScheduledShiftsScreenQueryVariables>,
    'query'
  >,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverScheduledShiftsScreenQuery>({
    ...options,
    query: Operations.GetDriverScheduledShiftsScreen,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverScheduledShiftsScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverScheduledShiftsScreenQuery,
    Operations.GetDriverScheduledShiftsScreenQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverScheduledShiftsScreen, options);
};
export type PageGetDriverScheduledShiftsScreenComp = React.FC<{
  data?: Operations.GetDriverScheduledShiftsScreenQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverScheduledShiftsScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverScheduledShiftsScreenQuery,
    Operations.GetDriverScheduledShiftsScreenQueryVariables
  >,
) => (WrappedComponent: PageGetDriverScheduledShiftsScreenComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverScheduledShiftsScreen, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverScheduledShiftsScreen = {
  getServerPage: getServerPageGetDriverScheduledShiftsScreen,
  withPage: withPageGetDriverScheduledShiftsScreen,
  usePage: useGetDriverScheduledShiftsScreen,
};

export async function getServerPageGetShiftStopPoints(
  options: Omit<Apollo.QueryOptions<Operations.GetShiftStopPointsQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetShiftStopPointsQuery>({
    ...options,
    query: Operations.GetShiftStopPoints,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetShiftStopPoints = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftStopPointsQuery,
    Operations.GetShiftStopPointsQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetShiftStopPoints, options);
};
export type PageGetShiftStopPointsComp = React.FC<{
  data?: Operations.GetShiftStopPointsQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetShiftStopPoints = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftStopPointsQuery,
    Operations.GetShiftStopPointsQueryVariables
  >,
) => (WrappedComponent: PageGetShiftStopPointsComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetShiftStopPoints, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetShiftStopPoints = {
  getServerPage: getServerPageGetShiftStopPoints,
  withPage: withPageGetShiftStopPoints,
  usePage: useGetShiftStopPoints,
};
export async function getServerPageGetDriverShiftsScreen(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverShiftsScreenQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverShiftsScreenQuery>({
    ...options,
    query: Operations.GetDriverShiftsScreen,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverShiftsScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverShiftsScreenQuery,
    Operations.GetDriverShiftsScreenQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverShiftsScreen, options);
};
export type PageGetDriverShiftsScreenComp = React.FC<{
  data?: Operations.GetDriverShiftsScreenQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverShiftsScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverShiftsScreenQuery,
    Operations.GetDriverShiftsScreenQueryVariables
  >,
) => (WrappedComponent: PageGetDriverShiftsScreenComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverShiftsScreen, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverShiftsScreen = {
  getServerPage: getServerPageGetDriverShiftsScreen,
  withPage: withPageGetDriverShiftsScreen,
  usePage: useGetDriverShiftsScreen,
};
export async function getServerPageGetShiftResourceMetadata(
  options: Omit<Apollo.QueryOptions<Operations.GetShiftResourceMetadataQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetShiftResourceMetadataQuery>({
    ...options,
    query: Operations.GetShiftResourceMetadata,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetShiftResourceMetadata = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftResourceMetadataQuery,
    Operations.GetShiftResourceMetadataQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetShiftResourceMetadata, options);
};
export type PageGetShiftResourceMetadataComp = React.FC<{
  data?: Operations.GetShiftResourceMetadataQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetShiftResourceMetadata = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetShiftResourceMetadataQuery,
    Operations.GetShiftResourceMetadataQueryVariables
  >,
) => (WrappedComponent: PageGetShiftResourceMetadataComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetShiftResourceMetadata, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetShiftResourceMetadata = {
  getServerPage: getServerPageGetShiftResourceMetadata,
  withPage: withPageGetShiftResourceMetadata,
  usePage: useGetShiftResourceMetadata,
};
export async function getServerPageGetDriverFleetResource(
  options: Omit<Apollo.QueryOptions<Operations.GetDriverFleetResourceQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetDriverFleetResourceQuery>({
    ...options,
    query: Operations.GetDriverFleetResource,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetDriverFleetResource = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverFleetResourceQuery,
    Operations.GetDriverFleetResourceQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetDriverFleetResource, options);
};
export type PageGetDriverFleetResourceComp = React.FC<{
  data?: Operations.GetDriverFleetResourceQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetDriverFleetResource = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetDriverFleetResourceQuery,
    Operations.GetDriverFleetResourceQueryVariables
  >,
) => (WrappedComponent: PageGetDriverFleetResourceComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetDriverFleetResource, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetDriverFleetResource = {
  getServerPage: getServerPageGetDriverFleetResource,
  withPage: withPageGetDriverFleetResource,
  usePage: useGetDriverFleetResource,
};
export async function getServerPageGetVehiclesScreen(
  options: Omit<Apollo.QueryOptions<Operations.GetVehiclesScreenQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetVehiclesScreenQuery>({
    ...options,
    query: Operations.GetVehiclesScreen,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetVehiclesScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetVehiclesScreenQuery,
    Operations.GetVehiclesScreenQueryVariables
  >,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetVehiclesScreen, options);
};
export type PageGetVehiclesScreenComp = React.FC<{
  data?: Operations.GetVehiclesScreenQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetVehiclesScreen = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<
    Operations.GetVehiclesScreenQuery,
    Operations.GetVehiclesScreenQueryVariables
  >,
) => (WrappedComponent: PageGetVehiclesScreenComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetVehiclesScreen, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetVehiclesScreen = {
  getServerPage: getServerPageGetVehiclesScreen,
  withPage: withPageGetVehiclesScreen,
  usePage: useGetVehiclesScreen,
};
export async function getServerPageZoneZones(
  options: Omit<Apollo.QueryOptions<Operations.ZonePageZonesQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.ZonePageZonesQuery>({
    ...options,
    query: Operations.ZonePageZones,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useZoneZones = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.ZonePageZonesQuery, Operations.ZonePageZonesQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.ZonePageZones, options);
};
export type PageZoneZonesComp = React.FC<{
  data?: Operations.ZonePageZonesQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageZoneZones = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.ZonePageZonesQuery, Operations.ZonePageZonesQueryVariables>,
) => (WrappedComponent: PageZoneZonesComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.ZonePageZones, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrZoneZones = {
  getServerPage: getServerPageZoneZones,
  withPage: withPageZoneZones,
  usePage: useZoneZones,
};
export async function getServerPageGetSystem(
  options: Omit<Apollo.QueryOptions<Operations.GetSystemQueryQueryVariables>, 'query'>,
  ctx?: any,
) {
  const apolloClient = getApolloClient(ctx);

  const data = await apolloClient.query<Operations.GetSystemQueryQuery>({
    ...options,
    query: Operations.GetSystemQuery,
  });

  const apolloState = apolloClient.cache.extract();

  return {
    props: {
      ssrApolloCache: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  };
}
export const useGetSystem = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetSystemQueryQuery, Operations.GetSystemQueryQueryVariables>,
) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetSystemQuery, options);
};
export type PageGetSystemComp = React.FC<{
  data?: Operations.GetSystemQueryQuery;
  error?: Apollo.ApolloError;
}>;
export const withPageGetSystem = (
  optionsFunc?: (
    router: NextRouter,
  ) => QueryHookOptions<Operations.GetSystemQueryQuery, Operations.GetSystemQueryQueryVariables>,
) => (WrappedComponent: PageGetSystemComp): NextPage => (props) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  const { data, error } = useQuery(Operations.GetSystemQuery, options);
  return <WrappedComponent {...props} data={data} error={error} />;
};
export const ssrGetSystem = {
  getServerPage: getServerPageGetSystem,
  withPage: withPageGetSystem,
  usePage: useGetSystem,
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Coordinates: { input: any; output: any };
  CurrentSafetyExamJson: { input: any; output: any };
  Date: { input: Date; output: Date };
  DateTime: { input: any; output: any };
  Geojson: { input: any; output: any };
  JSON: { input: any; output: any };
  SegmentData: { input: any; output: any };
  SignedUrls: { input: any; output: any };
  Time: { input: Time; output: Time };
};

export type AboundToken = {
  __typename?: 'AboundToken';
  aboundToken: Scalars['String']['output'];
};

export type ActiveCXRentalsList = {
  __typename?: 'ActiveCXRentalsList';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<CXRental>>;
  systemCounts?: Maybe<Array<ActiveRentalCountBySystem>>;
  totalCount: Scalars['Int']['output'];
};

export type ActiveRentalCountBySystem = {
  __typename?: 'ActiveRentalCountBySystem';
  count: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
};

export type ActiveRentalsFilters = {
  ordering?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddRentalIdToCommunityReportInput = {
  communityReportId: Scalars['ID']['input'];
  rentalId: Scalars['ID']['input'];
};

export type AddRenterViolationImagesInput = {
  hasParkingCameraViolation?: InputMaybe<Scalars['Boolean']['input']>;
  hasParkingTicket?: InputMaybe<Scalars['Boolean']['input']>;
  hasTowTicket?: InputMaybe<Scalars['Boolean']['input']>;
  renterId: Scalars['ID']['input'];
  renterViolationId: Scalars['ID']['input'];
};

export type AddRenterViolationInput = {
  action: ViolationActions;
  cxAgentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  rentalId?: InputMaybe<Scalars['ID']['input']>;
  renterId: Scalars['ID']['input'];
  rideHailId?: InputMaybe<Scalars['ID']['input']>;
  summonsImageUrl?: InputMaybe<Scalars['String']['input']>;
  summonsNumber?: InputMaybe<Scalars['String']['input']>;
  suspensionDays?: InputMaybe<Scalars['Int']['input']>;
  violationName: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  countryName?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  location: Point;
  name?: Maybe<Scalars['String']['output']>;
  pointOfInterest?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  sourceIdentifier: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type AdicRegistration = {
  __typename?: 'AdicRegistration';
  adicIdentifier?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  driverId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mvr_status?: Maybe<RegistrationStatuses>;
  response: Scalars['JSON']['output'];
  status?: Maybe<RegistrationStatuses>;
  submission?: Maybe<Scalars['JSON']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AdicRegistrationFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  ordering?: InputMaybe<AdicRegistrationOrdering>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum AdicRegistrationOrdering {
  createdAtAscending = 'createdAtAscending',
  createdAtDescending = 'createdAtDescending',
}

export type AdicRegistrationResponse = {
  __typename?: 'AdicRegistrationResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<AdicRegistration>>;
};

export type AgreeToReservationContractInput = {
  legalDocumentId: Scalars['ID']['input'];
  reservationId: Scalars['ID']['input'];
};

export type Amenity = {
  __typename?: 'Amenity';
  amenityType: AmenityTypes;
  id: Scalars['ID']['output'];
  location: Point;
  metadata?: Maybe<AmenityMetadata>;
  source?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['String']['output']>;
};

export type AmenityFilters = {
  amenityType?: InputMaybe<AmenityTypes>;
  location?: InputMaybe<PointInput>;
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type AmenityMetadata = {
  __typename?: 'AmenityMetadata';
  numberOfSpaces?: Maybe<Scalars['Int']['output']>;
};

export type AmenityResponse = {
  __typename?: 'AmenityResponse';
  count: Scalars['Int']['output'];
  results: Array<Amenity>;
};

export enum AmenityTypes {
  fhv_spot = 'fhv_spot',
}

export type Assignment = {
  __typename?: 'Assignment';
  assignmentType: Scalars['String']['output'];
  averageDriveTime?: Maybe<Scalars['Int']['output']>;
  averageWorkOrderDuration?: Maybe<Scalars['Int']['output']>;
  batteryLevelThreshold?: Maybe<Scalars['Int']['output']>;
  batterySkipCount: Scalars['Int']['output'];
  batterySwapCount: Scalars['Int']['output'];
  completedWorkOrderCount: Scalars['Int']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Identity>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  dispatchNotes?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastLocation?: Maybe<Point>;
  manualSkippedWorkOrderCount?: Maybe<Scalars['Int']['output']>;
  operator?: Maybe<Operator>;
  remainingWorkOrderCount?: Maybe<Scalars['Int']['output']>;
  resourceTasks?: Maybe<Array<Task>>;
  skippedWorkOrderCount: Scalars['Int']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
  startLocation?: Maybe<Point>;
  status?: Maybe<AssignmentStatus>;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  systemSkippedWorkOrderCount?: Maybe<Scalars['Int']['output']>;
  taskTypeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  taskTypes?: Maybe<Array<TaskType>>;
  totalWorkOrderCount: Scalars['Int']['output'];
};

export type AssignmentresourceTasksArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentFilters = {
  assignmentId?: InputMaybe<Scalars['String']['input']>;
  assignmentType?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  includeDev?: InputMaybe<Scalars['Boolean']['input']>;
  operatorId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type AssignmentList = {
  __typename?: 'AssignmentList';
  count: Scalars['Int']['output'];
  results: Array<Assignment>;
};

export type AssignmentOperatorLocation = {
  __typename?: 'AssignmentOperatorLocation';
  id: Scalars['ID']['output'];
  location: Point;
};

export enum AssignmentStatus {
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  DELETED = 'DELETED',
  DELETING = 'DELETING',
  DRAFT = 'DRAFT',
  PROCESSING = 'PROCESSING',
  UPCOMING = 'UPCOMING',
}

export type BalancerMapConfig = {
  __typename?: 'BalancerMapConfig';
  calculateRelativeBalance: Scalars['Boolean']['output'];
  deficitColorDark: Scalars['String']['output'];
  deficitColorLight: Scalars['String']['output'];
  maxDeficit: Scalars['Float']['output'];
  maxOpacity: Scalars['Float']['output'];
  maxSurplus: Scalars['Float']['output'];
  minDeficit: Scalars['Float']['output'];
  minOpacity: Scalars['Float']['output'];
  minSurplus: Scalars['Float']['output'];
  showDeficit: Scalars['Boolean']['output'];
  showSurplus: Scalars['Boolean']['output'];
  surplusColorDark: Scalars['String']['output'];
  surplusColorLight: Scalars['String']['output'];
};

export type CXAgent = {
  __typename?: 'CXAgent';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Resolver fetched fields */
  identity?: Maybe<Identity>;
  lastName?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<RoleMember>>>;
};

export type CXAgentFilters = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CXBillingFrequency {
  annual = 'annual',
  monthly = 'monthly',
  non_recurring = 'non_recurring',
  quarterly = 'quarterly',
  weekly = 'weekly',
}

export type CXCreditBalance = {
  __typename?: 'CXCreditBalance';
  created: Scalars['DateTime']['output'];
  creditDefinition: CXCreditDefinition;
  /** Resolver fetched fields */
  cxAgent?: Maybe<CXAgent>;
  cxAgentId?: Maybe<Scalars['String']['output']>;
  cxReason?: Maybe<Scalars['String']['output']>;
  cxReasonDescription?: Maybe<Scalars['String']['output']>;
  expiredByIdentity?: Maybe<Identity>;
  expiredByIdentityAt?: Maybe<Scalars['DateTime']['output']>;
  expiredByIdentityId?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  remainingBalance: Scalars['Int']['output'];
  startingBalance: Scalars['Int']['output'];
};

export type CXCreditDefinition = {
  __typename?: 'CXCreditDefinition';
  code?: Maybe<Scalars['String']['output']>;
  creditType: Scalars['String']['output'];
  internalDescription?: Maybe<Scalars['String']['output']>;
  internalName?: Maybe<Scalars['String']['output']>;
};

export type CXCurrentDiscountRates = {
  __typename?: 'CXCurrentDiscountRates';
  rental?: Maybe<CXRenterDiscountRate>;
  rideHail?: Maybe<CXRenterDiscountRate>;
};

export type CXDriver = {
  __typename?: 'CXDriver';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  stats?: Maybe<CXDriverStats>;
  tlcLicenseNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum CXDriverShiftEvaluatedStates {
  active_shifts = 'active_shifts',
  inactive_shifts = 'inactive_shifts',
  shifts_eligible_to_start = 'shifts_eligible_to_start',
  shifts_with_vehicle = 'shifts_with_vehicle',
}

export type CXDriverShiftFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  evaluatedState?: InputMaybe<CXDriverShiftEvaluatedStates>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  resourceId?: InputMaybe<Scalars['ID']['input']>;
  states?: InputMaybe<Array<CXDriverShiftStates>>;
};

export enum CXDriverShiftStates {
  activating = 'activating',
  active = 'active',
  canceled = 'canceled',
  complete = 'complete',
  draft = 'draft',
  driver_on_the_way = 'driver_on_the_way',
  no_show = 'no_show',
  scheduled = 'scheduled',
  scheduled_break = 'scheduled_break',
  stand_by = 'stand_by',
  unscheduled_break = 'unscheduled_break',
}

export type CXDriverStats = {
  __typename?: 'CXDriverStats';
  canceledRideCount?: Maybe<Scalars['Int']['output']>;
  completedRideCount?: Maybe<Scalars['Int']['output']>;
  noShowRideCount?: Maybe<Scalars['Int']['output']>;
};

export type CXIdVerification = {
  __typename?: 'CXIdVerification';
  cxAgentId?: Maybe<Scalars['ID']['output']>;
  faceImages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  idBackImage?: Maybe<Scalars['String']['output']>;
  idCountry?: Maybe<Scalars['String']['output']>;
  idDob?: Maybe<Scalars['Date']['output']>;
  idExpiry?: Maybe<Scalars['Date']['output']>;
  idFirstName?: Maybe<Scalars['String']['output']>;
  idFrontImage?: Maybe<Scalars['String']['output']>;
  idIssueDate?: Maybe<Scalars['Date']['output']>;
  idLastName?: Maybe<Scalars['String']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  idUsState?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use jumioIdentityVerificationSimilarity */
  identityVerificationSimilarity?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use jumioIdentityVerificationValidity */
  identityVerificationValidity?: Maybe<Scalars['Boolean']['output']>;
  jumioIdScanReference?: Maybe<Scalars['String']['output']>;
  jumioIdentityVerificationSimilarity?: Maybe<Scalars['String']['output']>;
  jumioIdentityVerificationValidity?: Maybe<Scalars['Boolean']['output']>;
  lastEdited?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver fetched fields */
  lastEditedBy?: Maybe<Identity>;
  personaInquiryReferenceId?: Maybe<Scalars['String']['output']>;
  renterId: Scalars['String']['output'];
  transactionDate?: Maybe<Scalars['DateTime']['output']>;
  verificationStatus: Scalars['String']['output'];
};

export type CXIdVerificationLink = {
  __typename?: 'CXIdVerificationLink';
  link?: Maybe<Scalars['String']['output']>;
};

export type CXIneligibilityReason = {
  __typename?: 'CXIneligibilityReason';
  reasonName: CXIneligibiltyReasonName;
  state: CXIneligibilityState;
};

export enum CXIneligibilityState {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  INACTIVE = 'INACTIVE',
  INCOMPLETE = 'INCOMPLETE',
  SUSPENDED = 'SUSPENDED',
  UNAPPROVED = 'UNAPPROVED',
}

export enum CXIneligibiltyReasonName {
  android_terms_of_use_privacy_policy_reaccept_required = 'android_terms_of_use_privacy_policy_reaccept_required',
  background_check_consider = 'background_check_consider',
  background_check_failed = 'background_check_failed',
  background_check_pending = 'background_check_pending',
  background_check_suspended = 'background_check_suspended',
  deletion_requested = 'deletion_requested',
  drivers_license_agreement_not_accepted = 'drivers_license_agreement_not_accepted',
  duplicate_id = 'duplicate_id',
  ebike_manual_suspension = 'ebike_manual_suspension',
  ef_rejected = 'ef_rejected',
  face_mismatch = 'face_mismatch',
  fee_agreement_not_accepted = 'fee_agreement_not_accepted',
  fraud = 'fraud',
  id_expired = 'id_expired',
  id_verification_fraud = 'id_verification_fraud',
  id_verification_not_started = 'id_verification_not_started',
  id_verification_pending = 'id_verification_pending',
  id_verification_unexpected_response = 'id_verification_unexpected_response',
  invalid_default_payment_method = 'invalid_default_payment_method',
  learners_permit = 'learners_permit',
  manual_ban = 'manual_ban',
  manual_suspension = 'manual_suspension',
  need_to_improve_selfie = 'need_to_improve_selfie',
  no_payment_method = 'no_payment_method',
  payment_not_received = 'payment_not_received',
  privacy_policy_not_accepted = 'privacy_policy_not_accepted',
  rental_agreement_not_accepted = 'rental_agreement_not_accepted',
  ride_hail_agreement_not_accepted = 'ride_hail_agreement_not_accepted',
  ride_hail_manual_suspension = 'ride_hail_manual_suspension',
  ride_hail_manual_temporary_suspension = 'ride_hail_manual_temporary_suspension',
  safety_exam_not_completed = 'safety_exam_not_completed',
  safety_exam_refresh_required = 'safety_exam_refresh_required',
  safety_exam_retake_for_violation = 'safety_exam_retake_for_violation',
  second_idv_failure_contact_cx_required = 'second_idv_failure_contact_cx_required',
  temporary_suspension = 'temporary_suspension',
  terms_and_conditions_not_accepted = 'terms_and_conditions_not_accepted',
  under_18_or_not_licensed_less_than_year = 'under_18_or_not_licensed_less_than_year',
  under_21 = 'under_21',
  unreadable_id_scan = 'unreadable_id_scan',
  unsupported_id_country = 'unsupported_id_country',
  unsupported_id_type = 'unsupported_id_type',
  user_education_agreement_not_accepted = 'user_education_agreement_not_accepted',
}

export type CXInvoice = {
  __typename?: 'CXInvoice';
  appliedCreditTotal: Scalars['Int']['output'];
  /** @deprecated Use CXInvoiceItems.description = 'billable_duration_reduction' */
  billableDurationReduction?: Maybe<Scalars['Int']['output']>;
  category: CXInvoiceCategoryEnum;
  created: Scalars['DateTime']['output'];
  createdByIdentityId?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  cxInvoiceReason?: Maybe<Scalars['String']['output']>;
  cxInvoiceReasonFk?: Maybe<CXInvoiceReason>;
  description?: Maybe<Scalars['String']['output']>;
  discountRateReduction?: Maybe<Scalars['Int']['output']>;
  experiment?: Maybe<Scalars['String']['output']>;
  experimentGroup?: Maybe<Scalars['String']['output']>;
  experimentalPricingReduction?: Maybe<Scalars['Int']['output']>;
  fromEf?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  invoiceItems?: Maybe<Array<CXInvoiceItems>>;
  mergedPaymentIntents?: Maybe<Array<PaymentIntent>>;
  /** @deprecated Use CXInvoiceItems.description = 'minimum_stripe_charge_reduction' */
  minimumStripeChargeReduction?: Maybe<Scalars['Int']['output']>;
  paymentIntents?: Maybe<Array<PaymentIntent>>;
  refundedByIdentity?: Maybe<Identity>;
  refundedByIdentityId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use CXInvoiceItems.description = 'rental_pass_reduction' */
  rentalPassReduction?: Maybe<Scalars['Int']['output']>;
  renterRentalPass?: Maybe<CXRenterRentalPass>;
  /** @deprecated Use CXInvoiceItems.description = 'renter_unable_to_ride_reduction' */
  renterUnableToRideReduction?: Maybe<Scalars['Int']['output']>;
  /** Resolver fetched fields */
  ridehail?: Maybe<CXRideHail>;
  ridehailId?: Maybe<Scalars['ID']['output']>;
  rideshareResourceMetadata?: Maybe<CXRideshareInvoiceResourceMetadata>;
  status: CXInvoiceStatus;
  subtotal: Scalars['Int']['output'];
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tax: Scalars['Int']['output'];
  taxPercentage: Scalars['Float']['output'];
  tipPaymentIntent?: Maybe<PaymentIntent>;
  totalAmount: Scalars['Int']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
  voidedByIdentity?: Maybe<Identity>;
  voidedByIdentityId?: Maybe<Scalars['ID']['output']>;
};

export enum CXInvoiceCategoryEnum {
  CUSTOMER_EXPERIENCE = 'CUSTOMER_EXPERIENCE',
  EBIKE = 'EBIKE',
  RENTAL = 'RENTAL',
  RENTAL_PASS = 'RENTAL_PASS',
  RIDE_HAIL = 'RIDE_HAIL',
  SIGN_UP_FEE = 'SIGN_UP_FEE',
}

export enum CXInvoiceCreatedReason {
  DAMAGED_STOLEN_LOST = 'DAMAGED_STOLEN_LOST',
  DAMAGES = 'DAMAGES',
  DISPUTE_FEE = 'DISPUTE_FEE',
  EBIKE_USER_CHARGEBACK = 'EBIKE_USER_CHARGEBACK',
  IMPROPER_PARKING = 'IMPROPER_PARKING',
  METER = 'METER',
  MOVING_VIOLATION = 'MOVING_VIOLATION',
  NO_PARKING = 'NO_PARKING',
  OTHER_FEE = 'OTHER_FEE',
  TICKET = 'TICKET',
  TOW = 'TOW',
  VIOLATION_FEE = 'VIOLATION_FEE',
  WAV_RIDE = 'WAV_RIDE',
}

export type CXInvoiceItems = {
  __typename?: 'CXInvoiceItems';
  amount: Scalars['Int']['output'];
  coupon?: Maybe<Coupon>;
  couponId?: Maybe<Scalars['ID']['output']>;
  description: Scalars['String']['output'];
  discountRate?: Maybe<DiscountRate>;
  dwellTimeUnit?: Maybe<Scalars['String']['output']>;
  dwellTimeValue?: Maybe<Scalars['Int']['output']>;
  dynamicRateDiscount?: Maybe<DynamicRateDiscount>;
  dynamicRateDiscountId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  stopPointType?: Maybe<CXStopPointTypes>;
  territoryFee?: Maybe<TerritoryFee>;
  tollFee?: Maybe<TollFee>;
};

export type CXInvoiceReason = {
  __typename?: 'CXInvoiceReason';
  id: Scalars['ID']['output'];
  internalName: Scalars['String']['output'];
  product: Scalars['String']['output'];
  taxable?: Maybe<Scalars['Boolean']['output']>;
};

export type CXInvoiceReasonsFilter = {
  product?: InputMaybe<Scalars['String']['input']>;
};

export enum CXInvoiceStatus {
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  EF_OPEN = 'EF_OPEN',
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  VOIDED = 'VOIDED',
}

export type CXInvoicesList = {
  __typename?: 'CXInvoicesList';
  count: Scalars['Int']['output'];
  results: Array<CXInvoice>;
};

export type CXMobileVersions = {
  __typename?: 'CXMobileVersions';
  android?: Maybe<CXMobileVersionsSinglePlatform>;
  ios?: Maybe<CXMobileVersionsSinglePlatform>;
};

export type CXMobileVersionsSinglePlatform = {
  __typename?: 'CXMobileVersionsSinglePlatform';
  force?: Maybe<Scalars['String']['output']>;
  latestLive?: Maybe<Scalars['String']['output']>;
  warning?: Maybe<Scalars['String']['output']>;
};

export type CXOrder = {
  __typename?: 'CXOrder';
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
  addressRegion?: Maybe<Scalars['String']['output']>;
  addressStreet?: Maybe<Scalars['String']['output']>;
  addressStreet2?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledReason?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  curencyCode?: Maybe<Scalars['String']['output']>;
  deliveryInstructions?: Maybe<Scalars['String']['output']>;
  expectedDeliveryEnd?: Maybe<Scalars['DateTime']['output']>;
  expectedDeliveryStart?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fulfilmentStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lineItems?: Maybe<Array<CXOrderLineItem>>;
  middleName?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<CXOrderPaymentStatus>;
  phone?: Maybe<Scalars['String']['output']>;
  renterId?: Maybe<Scalars['ID']['output']>;
  subtotal?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<Scalars['Int']['output']>;
  totalAmount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CXOrderLineItem = {
  __typename?: 'CXOrderLineItem';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  frequency?: Maybe<CXBillingFrequency>;
  id: Scalars['ID']['output'];
  orderId: Scalars['ID']['output'];
  productOffer: CXProductOffer;
  productOfferId?: Maybe<Scalars['ID']['output']>;
  quantity: Scalars['Int']['output'];
  subscriptionId?: Maybe<Scalars['ID']['output']>;
  subtotal: Scalars['Int']['output'];
  taxable: Scalars['Boolean']['output'];
  unitCost: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum CXOrderPaymentStatus {
  canceled = 'canceled',
  denied = 'denied',
  paid_in_full = 'paid_in_full',
  pending = 'pending',
  processing = 'processing',
}

export type CXOrderSubscriptionCancellation = {
  __typename?: 'CXOrderSubscriptionCancellation';
  addressCity: Scalars['String']['output'];
  addressCountry: Scalars['String']['output'];
  addressPostalCode: Scalars['String']['output'];
  addressRegion: Scalars['String']['output'];
  addressStreet: Scalars['String']['output'];
  addressStreet2?: Maybe<Scalars['String']['output']>;
  canceledByIdentityId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  pickupInstructions?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  requestedPickupEnd?: Maybe<Scalars['DateTime']['output']>;
  requestedPickupStart?: Maybe<Scalars['DateTime']['output']>;
  subscriptionId: Scalars['ID']['output'];
};

export type CXOrderSubscriptionCancellationInput = {
  addressCity: Scalars['String']['input'];
  addressCountry: Scalars['String']['input'];
  addressPostalCode: Scalars['String']['input'];
  addressRegion: Scalars['String']['input'];
  addressStreet: Scalars['String']['input'];
  addressStreet2?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  pickupInstructions?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  requestedPickupEnd?: InputMaybe<Scalars['DateTime']['input']>;
  requestedPickupStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CXOrderSubscriptions = {
  __typename?: 'CXOrderSubscriptions';
  cancellation?: Maybe<CXOrderSubscriptionCancellation>;
  createdAt: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  frequency?: Maybe<CXBillingFrequency>;
  id: Scalars['ID']['output'];
  lastPaymentStatus?: Maybe<Scalars['String']['output']>;
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>;
  nextBillingSubtotal?: Maybe<Scalars['Int']['output']>;
  nextBillingTax?: Maybe<Scalars['Int']['output']>;
  nextBillingTotal?: Maybe<Scalars['Int']['output']>;
  order: CXOrder;
  orderLineItemId?: Maybe<Scalars['ID']['output']>;
  recurringCost?: Maybe<Scalars['Int']['output']>;
  renterId?: Maybe<Scalars['ID']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  subscriptionNumber?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['ID']['output']>;
  taxable?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CXOrderUpdateInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  addressRegion?: InputMaybe<Scalars['String']['input']>;
  addressStreet?: InputMaybe<Scalars['String']['input']>;
  addressStreet2?: InputMaybe<Scalars['String']['input']>;
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  expectedDeliveryEnd?: InputMaybe<Scalars['DateTime']['input']>;
  expectedDeliveryStart?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CXProduct = {
  __typename?: 'CXProduct';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  manufacturer: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CXProductOffer = {
  __typename?: 'CXProductOffer';
  availability: CXProductOfferStock;
  currencyCode: Scalars['String']['output'];
  frequency: CXBillingFrequency;
  id: Scalars['ID']['output'];
  nonRecurringCost: Scalars['Int']['output'];
  productVariant: CXProductVariant;
  recurringCost: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
  taxable: Scalars['Boolean']['output'];
};

export enum CXProductOfferStock {
  in_stock = 'in_stock',
  out_of_stock = 'out_of_stock',
}

export type CXProductVariant = {
  __typename?: 'CXProductVariant';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  product: CXProduct;
  updatedAt: Scalars['DateTime']['output'];
};

export type CXRental = {
  __typename?: 'CXRental';
  created: Scalars['DateTime']['output'];
  currentRentalStatus: CXRentalStatus;
  distanceTraveled?: Maybe<Scalars['Int']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  endAddress?: Maybe<Scalars['String']['output']>;
  fleetResource: FleetResource;
  fleetResourceId: Scalars['ID']['output'];
  fromEf?: Maybe<Scalars['Boolean']['output']>;
  gisViolationGeometries: Array<MultiLineStringType>;
  /** @deprecated in favor of gisViolationGeometries */
  gisViolationRoutes: Array<LineString>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<CXInvoice>;
  invoiceTotal?: Maybe<InvoiceLineItemDeprecated>;
  isBusinessProfile: Scalars['Boolean']['output'];
  numPassengers?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<CXRentalRating>;
  renter?: Maybe<CXRenter>;
  renterId?: Maybe<Scalars['ID']['output']>;
  renterRentalPass?: Maybe<CXRenterRentalPass>;
  route?: Maybe<LineString>;
  selfie?: Maybe<CXRentalSelfie>;
  start?: Maybe<Scalars['DateTime']['output']>;
  startAddress?: Maybe<Scalars['String']['output']>;
  streetCleaning?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  /** Resolver fetched fields */
  timeline?: Maybe<Array<RentalTimelineStatus>>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  userCreatedLocation: Point;
  userEndLocation?: Maybe<Point>;
  userStartLocation?: Maybe<Point>;
  userUpdatedLocation: Point;
  vehicleEndLocation?: Maybe<Point>;
  vehicleStartLocation?: Maybe<Point>;
};

export type CXRentalPass = {
  __typename?: 'CXRentalPass';
  hours: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  nameKey: Scalars['String']['output'];
  passType: CXRentalPassType;
  price: Scalars['Int']['output'];
  systemId: Scalars['String']['output'];
};

export enum CXRentalPassType {
  day = 'day',
  month = 'month',
}

export type CXRentalRating = {
  __typename?: 'CXRentalRating';
  category?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Int']['output'];
};

export type CXRentalSelfie = {
  __typename?: 'CXRentalSelfie';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  rider1HasHelmet?: Maybe<Scalars['Boolean']['output']>;
  rider1HasPerson?: Maybe<Scalars['Boolean']['output']>;
  rider1ImageUrl?: Maybe<Scalars['String']['output']>;
  rider1Valid?: Maybe<Scalars['Boolean']['output']>;
  rider2HasHelmet?: Maybe<Scalars['Boolean']['output']>;
  rider2HasPerson?: Maybe<Scalars['Boolean']['output']>;
  rider2ImageUrl?: Maybe<Scalars['String']['output']>;
  rider2Valid?: Maybe<Scalars['Boolean']['output']>;
  ridersValid?: Maybe<Scalars['Boolean']['output']>;
  state: RentalSelfieState;
  updatedAt: Scalars['DateTime']['output'];
};

export type CXRentalStatus = {
  __typename?: 'CXRentalStatus';
  actor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  start: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  userStartLocation: Point;
  vehicleAddress?: Maybe<Scalars['String']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type CXRentalsFilters = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  renterId?: InputMaybe<Scalars['ID']['input']>;
};

export type CXRenter = {
  __typename?: 'CXRenter';
  abTestVariant?: Maybe<Scalars['String']['output']>;
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  activeIneligibilities?: Maybe<Array<Ineligibility>>;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
  addressRegion?: Maybe<Scalars['String']['output']>;
  addressStreet?: Maybe<Scalars['String']['output']>;
  appVersion?: Maybe<Scalars['String']['output']>;
  completedRideHailCount?: Maybe<Scalars['Int']['output']>;
  convertedAt?: Maybe<Scalars['DateTime']['output']>;
  coupons?: Maybe<CouponList>;
  created: Scalars['DateTime']['output'];
  credit?: Maybe<Scalars['Int']['output']>;
  currentDiscountRates?: Maybe<CXCurrentDiscountRates>;
  currentState?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** @deprecated no longer using default system */
  defaultSystemId?: Maybe<Scalars['ID']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedByIdentityId?: Maybe<Scalars['ID']['output']>;
  deletedReason?: Maybe<Scalars['String']['output']>;
  deletionType?: Maybe<RenterDeletionTypes>;
  ebikeCurrentState?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  experimentGroup?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fromEf?: Maybe<Scalars['Boolean']['output']>;
  hasPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  idVerificationApproved?: Maybe<Scalars['DateTime']['output']>;
  isUnder21?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  mostRecentRentalSystem?: Maybe<System>;
  mostRecentRentalSystemId?: Maybe<Scalars['ID']['output']>;
  osName?: Maybe<OSName>;
  osVersion?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  problemReportCount?: Maybe<Scalars['Int']['output']>;
  profilePhoto?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  rentalCount?: Maybe<Scalars['Int']['output']>;
  rentalDistanceMeters?: Maybe<Scalars['Int']['output']>;
  renterAgreements: CXRenterAgreement;
  renterIneligibilities?: Maybe<Array<Maybe<CXRenterIneligibility>>>;
  renterStats: CXRenterStats;
  reportVerificationApproved?: Maybe<Scalars['DateTime']['output']>;
  reservationCount?: Maybe<Scalars['Int']['output']>;
  rideHailCurrentState?: Maybe<Scalars['String']['output']>;
  signUpSystem?: Maybe<System>;
  signUpSystemId?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['ID']['output']>;
  /**
   * Resolver fetched fields
   * @deprecated no longer using default system
   */
  system?: Maybe<System>;
  updated: Scalars['DateTime']['output'];
  waitlists?: Maybe<Array<CXWaitlist>>;
  zendeskId?: Maybe<Scalars['String']['output']>;
};

export type CXRenterAgreement = {
  __typename?: 'CXRenterAgreement';
  certificationOfDisabilitySignedAt?: Maybe<Scalars['DateTime']['output']>;
  checkrMotorVehicleReportRequestIp?: Maybe<Scalars['String']['output']>;
  checkrMotorVehicleReportSignature?: Maybe<Scalars['String']['output']>;
  checkrMotorVehicleReportSignedAt?: Maybe<Scalars['DateTime']['output']>;
  driversLicenseAgreementRequestIp?: Maybe<Scalars['String']['output']>;
  driversLicenseAgreementSignedAt?: Maybe<Scalars['DateTime']['output']>;
  feeAcceptanceRequestIp?: Maybe<Scalars['String']['output']>;
  feeAcceptanceSignedAt?: Maybe<Scalars['DateTime']['output']>;
  privacyPolicyRequestIp?: Maybe<Scalars['String']['output']>;
  privacyPolicySignedAt?: Maybe<Scalars['DateTime']['output']>;
  rentalAgreementRequestIp?: Maybe<Scalars['String']['output']>;
  rentalAgreementSignedAt?: Maybe<Scalars['DateTime']['output']>;
  smsMessageAgreementRequestIp?: Maybe<Scalars['String']['output']>;
  smsMessageAgreementSignedAt?: Maybe<Scalars['DateTime']['output']>;
  termsAndConditionsRequestIp?: Maybe<Scalars['String']['output']>;
  termsAndConditionsSignedAt?: Maybe<Scalars['DateTime']['output']>;
  userEducationAgreementRequestIp?: Maybe<Scalars['String']['output']>;
  userEducationAgreementSignedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CXRenterDiscountRate = {
  __typename?: 'CXRenterDiscountRate';
  created?: Maybe<Scalars['DateTime']['output']>;
  discountRate?: Maybe<DiscountRate>;
  ended?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  product?: Maybe<Scalars['String']['output']>;
  renterId: Scalars['ID']['output'];
  started?: Maybe<Scalars['DateTime']['output']>;
  startedByAgentId?: Maybe<Scalars['ID']['output']>;
  startedByIdentity?: Maybe<Identity>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type CXRenterIneligibility = {
  __typename?: 'CXRenterIneligibility';
  ineligibilityReason: CXIneligibilityReason;
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type CXRenterRentalPass = {
  __typename?: 'CXRenterRentalPass';
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  rentalPass?: Maybe<CXRentalPass>;
};

export type CXRenterStats = {
  __typename?: 'CXRenterStats';
  rentalCount: Scalars['Int']['output'];
  rentalTotalDistance: Scalars['Int']['output'];
  rideHailCount: Scalars['Int']['output'];
  totalDistance: Scalars['Int']['output'];
  totalRides: Scalars['Int']['output'];
};

export type CXRentersEmail = {
  __typename?: 'CXRentersEmail';
  created: Scalars['DateTime']['output'];
  /** Resolver fetched fields */
  cxAgent?: Maybe<Identity>;
  cxAgentId?: Maybe<Scalars['ID']['output']>;
  email: Scalars['String']['output'];
};

export type CXRentersFilters = {
  email?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceFilters?: InputMaybe<InvoiceFilters>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CXRideHail = {
  __typename?: 'CXRideHail';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  acceptedOffers?: Maybe<Array<CXRideHailOffer>>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentStopPoint: CXStopPoint;
  dispatchedRoute?: Maybe<LineString>;
  driver?: Maybe<CXDriver>;
  driverId?: Maybe<Scalars['ID']['output']>;
  driverRideHailId?: Maybe<Scalars['ID']['output']>;
  dropOffAddress: Scalars['String']['output'];
  dropOffLocation: Point;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<CXInvoice>;
  invoiceTotal?: Maybe<Scalars['Int']['output']>;
  isBusinessProfile: Scalars['Boolean']['output'];
  pickUpAddress: Scalars['String']['output'];
  pickUpLocation: Point;
  rating?: Maybe<CXRideHailRating>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  renter?: Maybe<CXRenter>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  rideHailOffer?: Maybe<CXRideHailOffer>;
  rideHailTimeline?: Maybe<Array<Maybe<RideHailTimeline>>>;
  route?: Maybe<LineString>;
  state: CXRideHailStates;
  stopPoints?: Maybe<Array<CXStopPoint>>;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tip?: Maybe<Tip>;
  vehicle?: Maybe<CXVehicle>;
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
  violations: Array<RenterViolation>;
};

export type CXRideHailFilters = {
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  renterId?: InputMaybe<Scalars['ID']['input']>;
  resourceId?: InputMaybe<Scalars['ID']['input']>;
};

export type CXRideHailList = {
  __typename?: 'CXRideHailList';
  count: Scalars['Int']['output'];
  results: Array<CXRideHail>;
};

export type CXRideHailOffer = {
  __typename?: 'CXRideHailOffer';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  amount: Scalars['Int']['output'];
  baseFeeAmount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  designatedDropOffRideLocation?: Maybe<Scalars['String']['output']>;
  designatedPickUpRideLocation?: Maybe<Scalars['String']['output']>;
  /** @deprecated use priceModifications */
  discountRateReduction?: Maybe<Scalars['Int']['output']>;
  driverRideHailId?: Maybe<Scalars['String']['output']>;
  dropOffAddress: Scalars['String']['output'];
  dropOffLocation: Point;
  dropOffZoneId?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  estimatedArrivalTime: Scalars['DateTime']['output'];
  estimatedArrivalTimeRange?: Maybe<Scalars['DateTime']['output']>;
  estimatedDropoffTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedRemainingTripDurationMinutes?: Maybe<Scalars['Int']['output']>;
  estimatedTripDurationMinutes: Scalars['Int']['output'];
  estimatedTripMeters?: Maybe<Scalars['Int']['output']>;
  fees: Array<TerritoryFee>;
  id: Scalars['ID']['output'];
  isWaveOffer?: Maybe<Scalars['Boolean']['output']>;
  minimumBaseFareDifference?: Maybe<Scalars['Int']['output']>;
  offerStops?: Maybe<Array<Maybe<CXRideHailOfferStop>>>;
  originalOffer?: Maybe<Scalars['String']['output']>;
  perMileAmount?: Maybe<Scalars['Int']['output']>;
  perMinuteAmount?: Maybe<Scalars['Int']['output']>;
  pickUpAddress: Scalars['String']['output'];
  pickUpLocation: Point;
  pickUpZoneId?: Maybe<Scalars['String']['output']>;
  previousOffer?: Maybe<Scalars['String']['output']>;
  priceModifications?: Maybe<Array<PriceModification>>;
  priceMultiplier?: Maybe<Scalars['String']['output']>;
  priceMultiplierDifference?: Maybe<Scalars['Int']['output']>;
  pricing: CXRideHailPricing;
  renterDiscount?: Maybe<Scalars['String']['output']>;
  renterId?: Maybe<Scalars['String']['output']>;
  rideHail?: Maybe<Scalars['String']['output']>;
  rideType?: Maybe<Scalars['String']['output']>;
  route: LineString;
  strikePrice?: Maybe<Scalars['Int']['output']>;
  subtotal: Scalars['Int']['output'];
  systemId?: Maybe<Scalars['String']['output']>;
  tax: Scalars['Int']['output'];
  tolls: Array<RideHailOfferTollFee>;
  userLocation?: Maybe<Point>;
  zonePricing?: Maybe<Scalars['String']['output']>;
};

export type CXRideHailOfferStop = {
  __typename?: 'CXRideHailOfferStop';
  address: Scalars['String']['output'];
  addressObject: Address;
  location: Point;
  order: Scalars['Int']['output'];
  requestedArrivalAt?: Maybe<Scalars['DateTime']['output']>;
  stopType: CXStopPointTypes;
};

export type CXRideHailPricing = {
  __typename?: 'CXRideHailPricing';
  baseFeeAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  minimumBaseFare: Scalars['Int']['output'];
  perMileAmount: Scalars['Int']['output'];
  perMinuteAmount: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
  taxPercentage: Scalars['Float']['output'];
};

export type CXRideHailRating = {
  __typename?: 'CXRideHailRating';
  category?: Maybe<CXRideHailRatingCategory>;
  details?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Int']['output'];
  ratingReasons: Array<Scalars['String']['output']>;
};

export enum CXRideHailRatingCategory {
  better_than_alternatives = 'better_than_alternatives',
  clean_and_safe = 'clean_and_safe',
  cleanliness = 'cleanliness',
  conversation = 'conversation',
  driving = 'driving',
  friendly_driver = 'friendly_driver',
  great_driving = 'great_driving',
  navigation = 'navigation',
  other_issue = 'other_issue',
  pickup_dropoff = 'pickup_dropoff',
  price = 'price',
  professionalism = 'professionalism',
  zero_emissions = 'zero_emissions',
}

export enum CXRideHailStates {
  active = 'active',
  arrived = 'arrived',
  canceled = 'canceled',
  completed = 'completed',
  destination_changed = 'destination_changed',
  dispatched = 'dispatched',
  driver_canceled = 'driver_canceled',
  driver_rejected = 'driver_rejected',
  failed = 'failed',
  matching = 'matching',
  offer = 'offer',
  offer_rejected = 'offer_rejected',
  pending = 'pending',
  pick_up_changed = 'pick_up_changed',
  rejected = 'rejected',
  resumed = 'resumed',
  scheduled = 'scheduled',
  stops_changed = 'stops_changed',
  system_rejected = 'system_rejected',
}

export type CXRideshareInvoiceResourceMetadata = {
  __typename?: 'CXRideshareInvoiceResourceMetadata';
  color?: Maybe<Scalars['String']['output']>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['ID']['output']>;
};

export type CXShift = {
  __typename?: 'CXShift';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  driver?: Maybe<CXDriver>;
  id: Scalars['ID']['output'];
  issueType?: Maybe<CXShiftIssueTypes>;
  resourceId?: Maybe<Scalars['String']['output']>;
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  state: CXDriverShiftStates;
  updatedAt: Scalars['DateTime']['output'];
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export enum CXShiftIssueTypes {
  accident = 'accident',
  end_shift_early = 'end_shift_early',
  failed_to_acknowledge_ride = 'failed_to_acknowledge_ride',
  failed_to_start_shift = 'failed_to_start_shift',
  issue_with_vehicle = 'issue_with_vehicle',
  lost_and_found = 'lost_and_found',
  low_battery = 'low_battery',
  needs_cleaning = 'needs_cleaning',
  non_vehicle_issue = 'non_vehicle_issue',
  special_assignment = 'special_assignment',
  unknown = 'unknown',
}

export type CXStopPoint = {
  __typename?: 'CXStopPoint';
  actualAddress?: Maybe<Scalars['String']['output']>;
  actualArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  driverStopPointId: Scalars['ID']['output'];
  estimatedArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedArrivalTimePostMatch?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  location: Point;
  requestedArrivalAt?: Maybe<Scalars['DateTime']['output']>;
  rideHail?: Maybe<Scalars['ID']['output']>;
  state: CXStopPointStates;
  stopType: CXStopPointTypes;
  userLocation?: Maybe<Point>;
  waitTimeEndsAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum CXStopPointStates {
  arrived = 'arrived',
  assigned = 'assigned',
  canceled = 'canceled',
  completed = 'completed',
  failed = 'failed',
  matching = 'matching',
  offer = 'offer',
  offer_rejected = 'offer_rejected',
  pending = 'pending',
  removed = 'removed',
}

export enum CXStopPointTypes {
  dropoff = 'dropoff',
  intermediate = 'intermediate',
  pickup = 'pickup',
}

export type CXVehicle = {
  __typename?: 'CXVehicle';
  driverMetadata?: Maybe<CXVehicleDriverMetadata>;
  id: Scalars['ID']['output'];
  licensePlate: Scalars['String']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  passengerCapacity: Scalars['Int']['output'];
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['String']['output']>;
  totalMileage?: Maybe<Scalars['Float']['output']>;
  vehicleColor?: Maybe<Scalars['String']['output']>;
  wheelchairAccessible: Scalars['Boolean']['output'];
};

export type CXVehicleDriverMetadata = {
  __typename?: 'CXVehicleDriverMetadata';
  dispatchingTlcBase: CXVehicleMetadataTLCBase;
  fhvLicenseNumber: Scalars['String']['output'];
  fleetResourceId: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  tlcBase: CXVehicleMetadataTLCBase;
};

export type CXVehicleMetadataTLCBase = {
  __typename?: 'CXVehicleMetadataTLCBase';
  name: Scalars['String']['output'];
  tlcBaseId: Scalars['String']['output'];
};

export type CXWaitlist = {
  __typename?: 'CXWaitlist';
  createdAt: Scalars['DateTime']['output'];
  dequeuedAt?: Maybe<Scalars['DateTime']['output']>;
  dequeuedReason?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  productId: Scalars['ID']['output'];
  renterId?: Maybe<Scalars['ID']['output']>;
  waitlistProduct: WaitlistProduct;
};

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type CalendlyUrls = {
  __typename?: 'CalendlyUrls';
  onboardingMeeting?: Maybe<Scalars['String']['output']>;
};

export type CancelDriverOpsDriverShiftInput = {
  note: Scalars['String']['input'];
  shiftCancellationReasonId: Scalars['String']['input'];
  shiftId: Scalars['ID']['input'];
};

export type CancelDriverReservationInput = {
  isBillable?: InputMaybe<Scalars['Boolean']['input']>;
  reservationId: Scalars['ID']['input'];
};

export type CancelDriverScheduledShiftInput = {
  cancellationReason?: InputMaybe<DriverScheduledShiftCancelationReasons>;
  deleteRecurringShift?: InputMaybe<Scalars['Boolean']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type CancelDriverShiftInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  ptoRequested: Scalars['Boolean']['input'];
  shiftId: Scalars['ID']['input'];
  shortNote: Scalars['String']['input'];
};

export type CheckInReservationInput = {
  location?: InputMaybe<PointInput>;
  locationAccuracy?: InputMaybe<Scalars['Float']['input']>;
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  reservationId: Scalars['ID']['input'];
};

export type CheckInShiftInput = {
  location: PointInput;
  locationAccuracy: Scalars['Float']['input'];
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type CheckInShiftOverrideInput = {
  shiftId: Scalars['ID']['input'];
};

export type ClaimOpenReservationInput = {
  openShiftId: Scalars['ID']['input'];
};

export type ClaimOpenShiftTemplatesInput = {
  beginGeneratingAt?: InputMaybe<Scalars['DateTime']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  openShiftTemplateIds: Array<Scalars['ID']['input']>;
};

export type ClimateSensors = {
  __typename?: 'ClimateSensors';
  batteryHeater?: Maybe<Scalars['Boolean']['output']>;
  climateKeeperMode?: Maybe<TeslaSensorOnOff>;
  defrostMode?: Maybe<Scalars['Int']['output']>;
  driverTempSetting?: Maybe<Scalars['Float']['output']>;
  fanStatus?: Maybe<Scalars['Int']['output']>;
  insideTemp?: Maybe<Scalars['Float']['output']>;
  isAutoConditioningOn?: Maybe<Scalars['Boolean']['output']>;
  isClimateOn?: Maybe<Scalars['Boolean']['output']>;
  isFrontDefroster_on?: Maybe<Scalars['Boolean']['output']>;
  isPreconditioning?: Maybe<Scalars['Boolean']['output']>;
  isRearDefrosterOn?: Maybe<Scalars['Boolean']['output']>;
  leftTempDirection?: Maybe<Scalars['Int']['output']>;
  maxAvailTemp?: Maybe<Scalars['Float']['output']>;
  minAvailTemp?: Maybe<Scalars['Float']['output']>;
  outsideTemp?: Maybe<Scalars['Float']['output']>;
  passengerTempSetting?: Maybe<Scalars['Float']['output']>;
  remoteHeaterControlEnabled?: Maybe<Scalars['Boolean']['output']>;
  rightTempDirection?: Maybe<Scalars['Int']['output']>;
  seatHeaterLeft?: Maybe<Scalars['Int']['output']>;
  seatHeaterRearCenter?: Maybe<Scalars['Int']['output']>;
  seatHeaterRearLeft?: Maybe<Scalars['Int']['output']>;
  seatHeaterRearRight?: Maybe<Scalars['Int']['output']>;
  seatHeaterRight?: Maybe<Scalars['Int']['output']>;
  sideMirrorHeaters?: Maybe<Scalars['Boolean']['output']>;
  wiperBladeHeater?: Maybe<Scalars['Boolean']['output']>;
};

export type CloseIssueInput = {
  closeReason: Scalars['String']['input'];
  closeReasonDescription?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type CommandResourceInput = {
  command: ResourceCommand;
};

export type CommandResourceResponse = {
  __typename?: 'CommandResourceResponse';
  actionType: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  sensors?: Maybe<Sensors>;
};

export type CommandShiftFleetResourceInput = {
  command: ResourceCommand;
};

export type CommunityReport = {
  __typename?: 'CommunityReport';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifiedRental?: Maybe<CXRental>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  locationAddress: Scalars['String']['output'];
  locationPoint: Point;
  photos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Resolver fetched fields */
  rentalSuggestions: Array<CommunityReportRentalSuggestion>;
  reporterEmail?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Maybe<TagCX>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  violationAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CommunityReportrentalSuggestionsArgs = {
  filters?: InputMaybe<CommunityReportRentalSuggestionFilters>;
};

export type CommunityReportRentalSuggestion = {
  __typename?: 'CommunityReportRentalSuggestion';
  distance: Scalars['Int']['output'];
  location: Point;
  rental: CXRental;
  timestamp: Scalars['DateTime']['output'];
};

export type CommunityReportRentalSuggestionFilters = {
  distanceBuffer?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<PointInput>;
  resultsLimit?: InputMaybe<Scalars['Int']['input']>;
  timeBuffer?: InputMaybe<Scalars['Int']['input']>;
  violationAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompleteShiftReviewInput = {
  reviewersNote: Scalars['String']['input'];
  shiftModifications?: InputMaybe<ShiftModificationsInput>;
  shiftReviewIssueIds: Array<Scalars['ID']['input']>;
  tasksModifications?: InputMaybe<Array<TaskModificationsInput>>;
};

export type Coupon = {
  __typename?: 'Coupon';
  couponDefinition: CouponDefinition;
  couponDefinitionId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  remainingUses?: Maybe<Scalars['Int']['output']>;
  usedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponDefinition = {
  __typename?: 'CouponDefinition';
  id: Scalars['ID']['output'];
  incentiveAmount?: Maybe<Scalars['Int']['output']>;
  incentiveRate?: Maybe<Scalars['Float']['output']>;
  internalName?: Maybe<Scalars['String']['output']>;
  maxIncentiveAmount?: Maybe<Scalars['Int']['output']>;
  minOfferSubtotal?: Maybe<Scalars['Int']['output']>;
  numCouponsIssued: Scalars['Int']['output'];
  product?: Maybe<CouponDefinitionProductsEnum>;
  reusableCoupon?: Maybe<Scalars['Boolean']['output']>;
  rideTypeDisplayKey?: Maybe<Scalars['String']['output']>;
};

export enum CouponDefinitionProductsEnum {
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export type CouponList = {
  __typename?: 'CouponList';
  count: Scalars['Int']['output'];
  results: Array<Coupon>;
};

export type CreateAdhocShiftReviewInput = {
  reviewersNote: Scalars['String']['input'];
  shiftId: Scalars['ID']['input'];
  shiftModifications?: InputMaybe<ShiftModificationsInput>;
  tasksModifications?: InputMaybe<Array<TaskModificationsInput>>;
};

export type CreateAdicRegistrationInput = {
  driverId: Scalars['ID']['input'];
};

export type CreateAssignmentInput = {
  assignmentType?: InputMaybe<Scalars['String']['input']>;
  batteryLevelThreshold?: InputMaybe<Scalars['Int']['input']>;
  operatorId?: InputMaybe<Scalars['ID']['input']>;
  startLocation?: InputMaybe<PointInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDriverContractConversionFormInput = {
  convertAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
};

export type CreateDriverIncidentDisputeInput = {
  driverDescription: Scalars['String']['input'];
  driverIncidentId: Scalars['ID']['input'];
  photoUris?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateDriverIncidentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  driverId: Scalars['ID']['input'];
  incidentId: Scalars['ID']['input'];
  occurredAt?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDriverIssueInput = {
  issueType: DriverIssueTypes;
  reportComment?: InputMaybe<Scalars['String']['input']>;
  reportCommentDetail?: InputMaybe<Scalars['String']['input']>;
  reportPhotoUris?: InputMaybe<Array<Scalars['String']['input']>>;
  shiftId: Scalars['ID']['input'];
  startedAtLocation?: InputMaybe<PointInput>;
  startedAtLocationAccuracy?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateDriverReservationInput = {
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId: Scalars['ID']['input'];
  scheduledEndAt: Scalars['DateTime']['input'];
  scheduledStartAt: Scalars['DateTime']['input'];
  systemId: Scalars['ID']['input'];
};

export type CreateDriverScheduledShiftInput = {
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId: Scalars['ID']['input'];
  durationMinutes: Scalars['Int']['input'];
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  startAt: Scalars['String']['input'];
  systemId: Scalars['ID']['input'];
};

export type CreateDriverShiftInput = {
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId: Scalars['ID']['input'];
  scheduledEndAt: Scalars['String']['input'];
  scheduledStartAt: Scalars['String']['input'];
  settings?: InputMaybe<ShiftSettingsInput>;
  systemId: Scalars['ID']['input'];
};

export type CreateDriverTaskInput = {
  durationSeconds?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  shiftId: Scalars['ID']['input'];
  taskDefinition: DriverTaskDefinitionInput;
  windowEndAt?: InputMaybe<Scalars['DateTime']['input']>;
  windowStartAt: Scalars['DateTime']['input'];
};

export type CreateFlagInput = {
  active: Scalars['Boolean']['input'];
  address: Scalars['String']['input'];
  expirationDate: Scalars['DateTime']['input'];
  flagTypeId: Scalars['ID']['input'];
  internalTitle: Scalars['String']['input'];
  location: PointInput;
  startDate: Scalars['DateTime']['input'];
  systemId: Scalars['ID']['input'];
};

export type CreateInvoiceInput = {
  amount: Scalars['Int']['input'];
  applyTax: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  reason?: InputMaybe<CXInvoiceCreatedReason>;
  reasonId?: InputMaybe<Scalars['ID']['input']>;
  renterId: Scalars['ID']['input'];
  renterViolationId?: InputMaybe<Scalars['ID']['input']>;
  systemId: Scalars['ID']['input'];
};

export type CreateLicenseInput = {
  driverId: Scalars['ID']['input'];
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licenseType: LicenseTypes;
  metadata?: InputMaybe<LicenseMetadataInput>;
  photoBackUri?: InputMaybe<Scalars['String']['input']>;
  photoFrontUri?: InputMaybe<Scalars['String']['input']>;
};

export type CreateManualInvoiceInput = {
  amount: Scalars['Int']['input'];
  displayDetailText?: InputMaybe<Scalars['String']['input']>;
  driverId: Scalars['ID']['input'];
  invoiceItemType: DriverInvoiceItemDescriptor;
};

export type CreateNoteInput = {
  note: Scalars['String']['input'];
  objectId: Scalars['ID']['input'];
  objectType: NoteObjectType;
};

export type CreateOperatorInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreateRecurringReservationsInput = {
  depotId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  scheduleTimes: Array<RecurringReservationScheduleTimesInput>;
};

export type CreateRecurringShiftInput = {
  beginGeneratingAt?: InputMaybe<Scalars['DateTime']['input']>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId: Scalars['ID']['input'];
  endTime: Scalars['String']['input'];
  endWeekday: Scalars['Int']['input'];
  settings?: InputMaybe<ShiftSettingsInput>;
  startTime: Scalars['String']['input'];
  startWeekday: Scalars['Int']['input'];
  systemId: Scalars['ID']['input'];
};

export type CreateRepairInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  completedById: Scalars['ID']['input'];
  fleetResourceId: Scalars['ID']['input'];
  repairTypeId: Scalars['ID']['input'];
  reservationId?: InputMaybe<Scalars['ID']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSecurityDepositInput = {
  splitSecurityDeposit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateServiceLogInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTaskInput = {
  description: Scalars['String']['input'];
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  status: Scalars['String']['input'];
  taskTypeId: Scalars['ID']['input'];
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreditInput = {
  context: Scalars['String']['input'];
  issueId?: InputMaybe<Scalars['ID']['input']>;
  reason: Scalars['String']['input'];
  reasonDescription?: InputMaybe<Scalars['String']['input']>;
  renterId: Scalars['ID']['input'];
  value: Scalars['Int']['input'];
};

export type CreditMutationResponse = {
  __typename?: 'CreditMutationResponse';
  applied?: Maybe<Scalars['Int']['output']>;
  creditType?: Maybe<Scalars['String']['output']>;
  renterCreditBalanceId?: Maybe<Scalars['ID']['output']>;
};

export type Depot = {
  __typename?: 'Depot';
  address: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  location: Point;
  name: Scalars['String']['output'];
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  vehicleFacility?: Maybe<VehicleFacility>;
  vehicleFacilityId?: Maybe<Scalars['ID']['output']>;
};

export type DevelopmentCommandDriverScheduleInput = {
  command: DriverScheduleDevelopmentCommand;
  driverId: Scalars['ID']['input'];
};

export type DevelopmentCommandDriverScheduledShiftInput = {
  command: DriverScheduledShiftDevelopmentCommand;
  shiftId: Scalars['ID']['input'];
};

export enum DeviceTypes {
  android = 'android',
  ios = 'ios',
}

export type DiscountRate = {
  __typename?: 'DiscountRate';
  created?: Maybe<Scalars['DateTime']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  product?: Maybe<DiscountRateProductType>;
  rate?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export enum DiscountRateProductType {
  ebike = 'ebike',
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export type DismissDriverTaskInput = {
  location: PointInput;
  locationAccuracy: Scalars['Float']['input'];
};

export enum Divisions {
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export type Driver = {
  __typename?: 'Driver';
  addresses?: Maybe<Array<DriverAddress>>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dayforceXrefCode?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  depot?: Maybe<Depot>;
  driverLicenseIssuer?: Maybe<Scalars['String']['output']>;
  driverLicenseNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employmentStatus?: Maybe<DriverEmploymentStatus>;
  employmentType?: Maybe<EmploymentTypes>;
  firstName: Scalars['String']['output'];
  firstShiftCompleted?: Maybe<Scalars['Boolean']['output']>;
  fleetResource?: Maybe<FleetResource>;
  fleetResourceId?: Maybe<Scalars['String']['output']>;
  hasRestrictions: Scalars['Boolean']['output'];
  hcmClient?: Maybe<Scalars['String']['output']>;
  hcmClientId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  licenses?: Maybe<Array<License>>;
  nextShiftFleetType?: Maybe<DriverShiftFleetType>;
  onboardedAt?: Maybe<Scalars['DateTime']['output']>;
  otp?: Maybe<DriverOTP>;
  phoneNumber: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  recurringShifts: Array<RecurringShift>;
  shifts: Array<DriverShift>;
  sizedAvatar?: Maybe<ImgSrc>;
  stats?: Maybe<DriverStats>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['String']['output']>;
  terminationDate?: Maybe<Scalars['DateTime']['output']>;
  timeAway: Array<DriverTimeAway>;
  tlcLicenseExpires?: Maybe<Scalars['Date']['output']>;
  tlcLicenseNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DriveraddressesArgs = {
  filters?: InputMaybe<DriverAddressFilters>;
};

export type DriverhasRestrictionsArgs = {
  platform?: InputMaybe<RestrictionReasonPlatforms>;
};

export type DriverlicensesArgs = {
  filters?: InputMaybe<LicenseFilters>;
};

export type DriverrecurringShiftsArgs = {
  filters?: InputMaybe<ListRecurringShiftsFilters>;
};

export type DrivershiftsArgs = {
  filters?: InputMaybe<DriverShiftFilters>;
};

export type DriversizedAvatarArgs = {
  height: Scalars['Int']['input'];
  scale?: InputMaybe<Scalars['Int']['input']>;
  width: Scalars['Int']['input'];
};

export type DrivertimeAwayArgs = {
  filters?: InputMaybe<DriverTimeAwayFilters>;
};

export type DriverAddress = {
  __typename?: 'DriverAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  addressType: DriverAddressTypes;
  city: Scalars['String']['output'];
  driverId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type DriverAddressFilters = {
  addressType?: InputMaybe<DriverAddressTypes>;
  driverId: Scalars['ID']['input'];
};

export type DriverAddressResponse = {
  __typename?: 'DriverAddressResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverAddress>>;
};

export enum DriverAddressTypes {
  home = 'home',
}

export type DriverAppConfig = {
  __typename?: 'DriverAppConfig';
  balancerMap: BalancerMapConfig;
  calendlyUrls: CalendlyUrls;
  driverEditShiftWindowMinutes: Scalars['Int']['output'];
  driverSupportNumber: Scalars['String']['output'];
  featureFlags: FeatureFlags;
  minimumAppVersions: MinimumAppVersions;
  newHireDepots?: Maybe<Array<HireDepotConfig>>;
  timers: Timers;
};

export type DriverAssetPresignedPost = {
  __typename?: 'DriverAssetPresignedPost';
  fields: Scalars['JSON']['output'];
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DriverAttestationInput = {
  answer: DriverScheduledShiftAttestationAnswer;
  reason?: InputMaybe<DriverScheduledShiftAttestationReason>;
};

export type DriverContractConversionForm = {
  __typename?: 'DriverContractConversionForm';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  convertAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DriverCredit = {
  __typename?: 'DriverCredit';
  additionalDetails?: Maybe<Scalars['String']['output']>;
  amountAvailable: Scalars['Int']['output'];
  amountIssued: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  driverId: Scalars['ID']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reasonGranted: DriverCreditGrantingReasons;
};

export type DriverCreditAmountResponse = {
  __typename?: 'DriverCreditAmountResponse';
  creditAmount: Scalars['Int']['output'];
};

export enum DriverCreditGrantingReasons {
  ended_reservation_early = 'ended_reservation_early',
  forgot_to_end_reservation = 'forgot_to_end_reservation',
  in_person_onboarding = 'in_person_onboarding',
  mechanical_issue = 'mechanical_issue',
  other = 'other',
  promo_model_y = 'promo_model_y',
  software_issue = 'software_issue',
  tolls = 'tolls',
  vehicle_lacked_charge = 'vehicle_lacked_charge',
  vehicle_not_available = 'vehicle_not_available',
  vehicle_required_cleaning = 'vehicle_required_cleaning',
}

export type DriverDepot = {
  __typename?: 'DriverDepot';
  depot: Depot;
};

export type DriverDepotFilter = {
  driverId: Scalars['ID']['input'];
};

export type DriverDepotPagedResult = PagedResult & {
  __typename?: 'DriverDepotPagedResult';
  count: Scalars['Int']['output'];
  results: Array<DriverDepot>;
};

export enum DriverEmploymentStatus {
  ACTIVE = 'ACTIVE',
  FURLOUGHED = 'FURLOUGHED',
  INACTIVE = 'INACTIVE',
  LOA = 'LOA',
  PRESTART = 'PRESTART',
  REVOKED = 'REVOKED',
  TERMINATED = 'TERMINATED',
}

export enum DriverFilteredStopPointTypes {
  dropoff = 'dropoff',
  generic = 'generic',
  intermediate = 'intermediate',
  issue = 'issue',
  none = 'none',
  pickup = 'pickup',
  rebalance = 'rebalance',
  recall = 'recall',
  scheduled_break = 'scheduled_break',
  shift_end = 'shift_end',
  unscheduled_break = 'unscheduled_break',
  vehicle_check = 'vehicle_check',
}

export type DriverFilters = {
  driverEmploymentStatus__in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  driverTrackPointThreshold?: InputMaybe<Scalars['Int']['input']>;
  driverTrackType?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<EmploymentTypes>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  ordering?: InputMaybe<Array<DriverOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DriverFleetResource = {
  __typename?: 'DriverFleetResource';
  activeReservation?: Maybe<RentalReservation>;
  activeShift?: Maybe<DriverShift>;
  assignedDriver?: Maybe<Driver>;
  batteryLevel?: Maybe<Scalars['Float']['output']>;
  color: Scalars['String']['output'];
  detail: DriverFleetResourceDetail;
  fleetResourceMetadata?: Maybe<FleetResourceMetadata>;
  id: Scalars['ID']['output'];
  licensePlate: Scalars['String']['output'];
  location?: Maybe<Point>;
  qrCode?: Maybe<Scalars['String']['output']>;
  speedLimitPin?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  systemId: Scalars['ID']['output'];
  telematicsId: Scalars['String']['output'];
  telematicsProvider: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  vin: Scalars['String']['output'];
};

export enum DriverFleetResourceAssignmentValues {
  all = 'all',
  assigned = 'assigned',
  driver = 'driver',
  reservation = 'reservation',
  shift = 'shift',
  unassigned = 'unassigned',
}

export type DriverFleetResourceCountFilters = {
  assignment?: InputMaybe<DriverFleetResourceAssignmentValues>;
  excludeModels?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeResourceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeResourceStatuses?: InputMaybe<Array<FleetResourceStatusValues>>;
  insuranceProviderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  makes?: InputMaybe<Array<Scalars['String']['input']>>;
  maxBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  minBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<Array<Scalars['String']['input']>>;
  notAtFacility?: InputMaybe<Scalars['Boolean']['input']>;
  resourceStatuses?: InputMaybe<Array<FleetResourceStatusValues>>;
  search?: InputMaybe<Scalars['String']['input']>;
  vehicleFacilityId?: InputMaybe<Scalars['ID']['input']>;
};

export type DriverFleetResourceDetail = {
  __typename?: 'DriverFleetResourceDetail';
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  passengerCapacity?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type DriverFleetResourceDetailFilters = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type DriverFleetResourceDetailsResponse = {
  __typename?: 'DriverFleetResourceDetailsResponse';
  count: Scalars['Int']['output'];
  results: Array<Maybe<DriverFleetResourceDetail>>;
};

export type DriverFleetResourceFilters = {
  assignment: DriverFleetResourceAssignmentValues;
  excludeModels?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeResourceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeResourceStatuses?: InputMaybe<Array<FleetResourceStatusValues>>;
  insuranceProviderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  makes?: InputMaybe<Array<Scalars['String']['input']>>;
  maxBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  minBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<Array<Scalars['String']['input']>>;
  notAtFacility?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<Array<DriverFleetResourceOrderingValues>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  resourceStatuses?: InputMaybe<Array<FleetResourceStatusValues>>;
  search?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
  vehicleFacilityId?: InputMaybe<Scalars['ID']['input']>;
  vehicleTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum DriverFleetResourceOrderingValues {
  batteryLevelAscending = 'batteryLevelAscending',
  batteryLevelDescending = 'batteryLevelDescending',
  updatedAscending = 'updatedAscending',
  updatedDescending = 'updatedDescending',
}

export type DriverFleetResourcesResponse = {
  __typename?: 'DriverFleetResourcesResponse';
  count: Scalars['Int']['output'];
  results: Array<Maybe<DriverFleetResource>>;
};

export type DriverIncident = {
  __typename?: 'DriverIncident';
  /** @deprecated TODO: remove in https://gorevel.atlassian.net/browse/TT-204 */
  appealApprovedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByIdentityId?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  driver?: Maybe<Driver>;
  driverId: Scalars['ID']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  forgivenAt?: Maybe<Scalars['DateTime']['output']>;
  forgivenByIdentityId?: Maybe<Scalars['String']['output']>;
  forgivenessReason?: Maybe<IncidentForgivenessReason>;
  id: Scalars['ID']['output'];
  incident?: Maybe<Incident>;
  incidentDisputes?: Maybe<Array<Maybe<DriverIncidentDispute>>>;
  incidentId: Scalars['ID']['output'];
  isDisputable?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Array<Note>>;
  occurredAt?: Maybe<Scalars['DateTime']['output']>;
  uberDriverAction?: Maybe<UberDriverAction>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DriverIncidentDispute = {
  __typename?: 'DriverIncidentDispute';
  createdAt: Scalars['DateTime']['output'];
  disputeType: DriverIncidentDisputeTypes;
  driverDescription?: Maybe<Scalars['String']['output']>;
  driverIncident?: Maybe<DriverIncident>;
  driverIncidentId: Scalars['ID']['output'];
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  finalizedByIdentity?: Maybe<Identity>;
  finalizedByIdentityId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  photoUrls?: Maybe<Array<Scalars['String']['output']>>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  status: DriverIncidentDisputeStatuses;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum DriverIncidentDisputeOrderingField {
  createdAtAscending = 'createdAtAscending',
  createdAtDescending = 'createdAtDescending',
}

export enum DriverIncidentDisputeStatuses {
  accepted = 'accepted',
  denied = 'denied',
  submitted = 'submitted',
}

export enum DriverIncidentDisputeTypes {
  appeal = 'appeal',
  dispute = 'dispute',
}

export type DriverIncidentDisputesFilters = {
  disputeType__in?: InputMaybe<Array<DriverIncidentDisputeTypes>>;
  driverIncidentId?: InputMaybe<Scalars['ID']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriverIncidentDisputeOrderingField>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  points__in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status__in?: InputMaybe<Array<DriverIncidentDisputeStatuses>>;
  trackType__in?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DriverIncidentDisputesResponse = {
  __typename?: 'DriverIncidentDisputesResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverIncidentDispute>>;
};

export type DriverIncidentsFilters = {
  createdAtGte?: InputMaybe<Scalars['String']['input']>;
  createdAtLte?: InputMaybe<Scalars['String']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  trackType?: InputMaybe<Scalars['String']['input']>;
};

export type DriverIncidentsResponse = {
  __typename?: 'DriverIncidentsResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverIncident>>;
};

export type DriverInsuranceApproval = {
  __typename?: 'DriverInsuranceApproval';
  id: Scalars['ID']['output'];
  insuranceProvider: InsuranceProvider;
};

export type DriverInsuranceApprovalFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
};

export type DriverInsuranceApprovalResponse = {
  __typename?: 'DriverInsuranceApprovalResponse';
  count: Scalars['Int']['output'];
  results: Array<Maybe<DriverInsuranceApproval>>;
};

export type DriverInvoice = {
  __typename?: 'DriverInvoice';
  appliedCredit?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByIdentityId?: Maybe<Scalars['ID']['output']>;
  descriptor?: Maybe<DriverInvoiceDescriptors>;
  driver?: Maybe<Driver>;
  driverId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoiceItems?: Maybe<Array<DriverInvoiceItem>>;
  isOverdue?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<InvoiceMetaData>;
  paymentIntents?: Maybe<Array<DriverPaymentIntent>>;
  processAt?: Maybe<Scalars['DateTime']['output']>;
  status: DriverInvoiceStatuses;
  subtotal: Scalars['Int']['output'];
  tax: Scalars['Int']['output'];
  taxPercentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
  voidedAt?: Maybe<Scalars['DateTime']['output']>;
  voidedReason?: Maybe<Scalars['String']['output']>;
};

export enum DriverInvoiceDescriptors {
  charging = 'charging',
  cleaning_fee = 'cleaning_fee',
  external_charging_session = 'external_charging_session',
  ez_pass_charges = 'ez_pass_charges',
  late_weekly_rental_return_fee = 'late_weekly_rental_return_fee',
  tire_replacement = 'tire_replacement',
  tlc_summons = 'tlc_summons',
  toll = 'toll',
  towing_charges = 'towing_charges',
  vehicle_damage = 'vehicle_damage',
  vehicle_rental = 'vehicle_rental',
  vehicle_rental_weekly = 'vehicle_rental_weekly',
  vehicle_security_deposit = 'vehicle_security_deposit',
  vehicle_traffic_violation = 'vehicle_traffic_violation',
  vehicle_upgrade = 'vehicle_upgrade',
}

export type DriverInvoiceFilters = {
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  descriptors?: InputMaybe<Array<InputMaybe<DriverInvoiceDescriptors>>>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  reservationId?: InputMaybe<Scalars['ID']['input']>;
  statuses?: InputMaybe<Array<DriverInvoiceStatuses>>;
};

export type DriverInvoiceItem = {
  __typename?: 'DriverInvoiceItem';
  amount?: Maybe<Scalars['Int']['output']>;
  descriptor?: Maybe<DriverInvoiceItemDescriptor>;
  displayDetailText?: Maybe<Scalars['String']['output']>;
  displayTitleText?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  itemType?: Maybe<DriverInvoiceItemDescriptor>;
  order?: Maybe<Scalars['Int']['output']>;
};

export enum DriverInvoiceItemDescriptor {
  charging_fee = 'charging_fee',
  cleaning_fee = 'cleaning_fee',
  credit = 'credit',
  external_charging_session = 'external_charging_session',
  ez_pass_charges = 'ez_pass_charges',
  ez_pass_rental = 'ez_pass_rental',
  failed_invoice_fee = 'failed_invoice_fee',
  insurance_premium = 'insurance_premium',
  late_vehicle_return = 'late_vehicle_return',
  late_weekly_rental_return_fee = 'late_weekly_rental_return_fee',
  security_deposit = 'security_deposit',
  tax = 'tax',
  ticket = 'ticket',
  ticket_processing_fee = 'ticket_processing_fee',
  tire_replacement = 'tire_replacement',
  tlc_summons = 'tlc_summons',
  toll = 'toll',
  toll_processing_fee = 'toll_processing_fee',
  towing_charges = 'towing_charges',
  vehicle_damage = 'vehicle_damage',
  vehicle_rental = 'vehicle_rental',
  vehicle_upgrade = 'vehicle_upgrade',
  withheld_security_deposit = 'withheld_security_deposit',
}

export enum DriverInvoiceStatuses {
  canceled = 'canceled',
  failed = 'failed',
  paid = 'paid',
  pending = 'pending',
  ready_for_payment = 'ready_for_payment',
  refund_failed = 'refund_failed',
  refunded = 'refunded',
  requires_manual_intervention = 'requires_manual_intervention',
  scheduled = 'scheduled',
  sms_warning_sent = 'sms_warning_sent',
  voided = 'voided',
}

export type DriverInvoiceVoidedReason = {
  __typename?: 'DriverInvoiceVoidedReason';
  displayText: Scalars['String']['output'];
  reason: VoidedInvoiceReasonsEnum;
};

export type DriverInvoicesResponse = {
  __typename?: 'DriverInvoicesResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverInvoice>>;
};

export type DriverIssue = {
  __typename?: 'DriverIssue';
  driverId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  issueType: DriverIssueTypes;
  reportComment?: Maybe<Scalars['String']['output']>;
  reportCommentDetail?: Maybe<Scalars['String']['output']>;
  reportPhotoUrls?: Maybe<Array<Scalars['String']['output']>>;
  shiftId: Scalars['ID']['output'];
  tasks: DriverTaskResponse;
};

export type DriverIssueFilter = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  issueTypeIn?: InputMaybe<Array<DriverIssueTypes>>;
  shiftId?: InputMaybe<Scalars['ID']['input']>;
};

export type DriverIssueResponse = {
  __typename?: 'DriverIssueResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverIssue>>;
};

export enum DriverIssueTypes {
  accident = 'accident',
  lost_and_found = 'lost_and_found',
  special_assignment = 'special_assignment',
  vehicle_issue = 'vehicle_issue',
}

export type DriverOTP = {
  __typename?: 'DriverOTP';
  expiresAt: Scalars['DateTime']['output'];
  verificationCode?: Maybe<Scalars['String']['output']>;
};

export type DriverOpsDriverAndDetails = {
  __typename?: 'DriverOpsDriverAndDetails';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dayforceXrefCode?: Maybe<Scalars['String']['output']>;
  employmentStatus?: Maybe<DriverEmploymentStatus>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  terminationDate?: Maybe<Scalars['DateTime']['output']>;
  tlcLicenseNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DriverOpsDriverListResponse = {
  __typename?: 'DriverOpsDriverListResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverOpsDriverAndDetails>>;
};

export enum DriverOpsDriverOrderingField {
  createdAtAscending = 'createdAtAscending',
  createdAtDescending = 'createdAtDescending',
  updatedAtAscending = 'updatedAtAscending',
  updatedAtDescending = 'updatedAtDescending',
}

export type DriverOpsDriverResponse = {
  __typename?: 'DriverOpsDriverResponse';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dayforceXrefCode?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  employmentStatus?: Maybe<DriverEmploymentStatus>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['DateTime']['output']>;
  tlcLicenseNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DriverOpsDriverShift = {
  __typename?: 'DriverOpsDriverShift';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  breaks?: Maybe<Array<ShiftBreak>>;
  cancelationReason?: Maybe<Scalars['String']['output']>;
  driver: DriverOpsDriverAndDetails;
  id: Scalars['ID']['output'];
  issueType?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledRecallAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  state: DriverOpsShiftStates;
  vehicleLocation?: Maybe<Point>;
};

export type DriverOpsDriverShiftsResponse = {
  __typename?: 'DriverOpsDriverShiftsResponse';
  count: Scalars['Int']['output'];
  results: Array<DriverOpsDriverShift>;
};

export type DriverOpsDriverTimeAway = {
  __typename?: 'DriverOpsDriverTimeAway';
  createdAt: Scalars['DateTime']['output'];
  employeeMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  managerMessage?: Maybe<Scalars['String']['output']>;
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  status: DriverOpsDriverTimeAwayStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type DriverOpsDriverTimeAwayFilters = {
  scheduledEndAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledEndAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  statusIn?: InputMaybe<Array<DriverOpsDriverTimeAwayStatus>>;
};

export type DriverOpsDriverTimeAwayResponse = {
  __typename?: 'DriverOpsDriverTimeAwayResponse';
  count: Scalars['Int']['output'];
  results: Array<DriverOpsDriverTimeAway>;
};

export enum DriverOpsDriverTimeAwayStatus {
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  CANCELPENDING = 'CANCELPENDING',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
}

export type DriverOpsDriversFilters = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  ordering?: InputMaybe<Array<DriverOpsDriverOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum DriverOpsEvaluatedState {
  active_shifts = 'active_shifts',
  inactive_shifts = 'inactive_shifts',
  shifts_eligible_to_start = 'shifts_eligible_to_start',
  shifts_with_vehicle = 'shifts_with_vehicle',
}

export type DriverOpsShift = {
  __typename?: 'DriverOpsShift';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  driver: DriverOpsDriverAndDetails;
  id: Scalars['ID']['output'];
  issueType?: Maybe<Scalars['String']['output']>;
  liveRouteUrl?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledRecallAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  state: DriverOpsShiftStates;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type DriverOpsShiftFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  evaluatedState?: InputMaybe<DriverOpsEvaluatedState>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  ordering?: InputMaybe<Array<DriverOpsShiftOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state__in?: InputMaybe<Array<DriverOpsShiftStates>>;
  state__nin?: InputMaybe<Array<DriverOpsShiftStates>>;
};

export enum DriverOpsShiftOrderingField {
  scheduledRecallAtAscending = 'scheduledRecallAtAscending',
  scheduledRecallAtDescending = 'scheduledRecallAtDescending',
  scheduledStartAtAscending = 'scheduledStartAtAscending',
  scheduledStartAtDescending = 'scheduledStartAtDescending',
}

export enum DriverOpsShiftStates {
  activating = 'activating',
  active = 'active',
  canceled = 'canceled',
  complete = 'complete',
  driver_on_the_way = 'driver_on_the_way',
  no_show = 'no_show',
  scheduled = 'scheduled',
  scheduled_break = 'scheduled_break',
  stand_by = 'stand_by',
  unscheduled_break = 'unscheduled_break',
}

export enum DriverOrderingField {
  createdAtAscending = 'createdAtAscending',
  createdAtDescending = 'createdAtDescending',
  updatedAtAscending = 'updatedAtAscending',
  updatedAtDescending = 'updatedAtDescending',
}

export type DriverPayment = {
  __typename?: 'DriverPayment';
  baseFeeAmount?: Maybe<Scalars['Int']['output']>;
  consumerTotalAmount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  driverId: Scalars['ID']['output'];
  driverTakeRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  offerEstimatedDurationSeconds?: Maybe<Scalars['Int']['output']>;
  offerEstimatedTripMeters?: Maybe<Scalars['Int']['output']>;
  offerPricingPerMileAmount?: Maybe<Scalars['Int']['output']>;
  offerPricingPerMinuteAmount?: Maybe<Scalars['Int']['output']>;
  paymentItems?: Maybe<Array<DriverPaymentItem>>;
  paymentType: DriverPaymentTypes;
  perMileAmount?: Maybe<Scalars['Int']['output']>;
  perMinuteAmount?: Maybe<Scalars['Int']['output']>;
  receiptItems?: Maybe<Array<UnformattedDriverReceiptItem>>;
  referenceExternalId: Scalars['ID']['output'];
  shiftId: Scalars['ID']['output'];
  status: DriverPaymentStatuses;
  totalAmount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DriverPaymentFilters = {
  referenceExternalId?: InputMaybe<Scalars['ID']['input']>;
};

export type DriverPaymentIntent = {
  __typename?: 'DriverPaymentIntent';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  declineReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paymentMethod?: Maybe<DriverPaymentMethod>;
  paymentMethodInfo?: Maybe<Scalars['JSON']['output']>;
  status?: Maybe<DriverPaymentIntentStatuses>;
  stripeExternalId?: Maybe<Scalars['String']['output']>;
};

export enum DriverPaymentIntentStatuses {
  canceled = 'canceled',
  created = 'created',
  processing = 'processing',
  requires_action = 'requires_action',
  requires_capture = 'requires_capture',
  requires_confirmation = 'requires_confirmation',
  requires_payment_method = 'requires_payment_method',
  succeeded = 'succeeded',
}

export type DriverPaymentItem = {
  __typename?: 'DriverPaymentItem';
  amount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  itemType?: Maybe<DriverPaymentItemType>;
};

export enum DriverPaymentItemType {
  base_fare = 'base_fare',
  cancellation_fee = 'cancellation_fee',
  dwell_time_fee = 'dwell_time_fee',
  no_show_fee = 'no_show_fee',
  territory_fee = 'territory_fee',
  tip = 'tip',
  toll_fee = 'toll_fee',
}

export type DriverPaymentMethod = {
  __typename?: 'DriverPaymentMethod';
  brand?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  lastFour?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DriverPaymentMethodTypes>;
};

export type DriverPaymentMethodFilters = {
  driverId: Scalars['ID']['input'];
  isSelected?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum DriverPaymentMethodTypes {
  card = 'card',
  link = 'link',
}

export enum DriverPaymentStatuses {
  revel_manual_payout = 'revel_manual_payout',
}

export enum DriverPaymentTypes {
  ride_hail = 'ride_hail',
  ride_hail_tip = 'ride_hail_tip',
  ride_hail_toll = 'ride_hail_toll',
}

export type DriverPhoneMaskingSession = {
  __typename?: 'DriverPhoneMaskingSession';
  maskedConsumerPhoneNumber: Scalars['String']['output'];
};

export type DriverPunchAuthURL = {
  __typename?: 'DriverPunchAuthURL';
  url: Scalars['String']['output'];
};

export type DriverReceiptItem = {
  __typename?: 'DriverReceiptItem';
  amount: Scalars['Int']['output'];
  itemKey: Scalars['String']['output'];
  label: Scalars['String']['output'];
  subLabel?: Maybe<Scalars['String']['output']>;
};

export type DriverReceiptItemGroup = {
  __typename?: 'DriverReceiptItemGroup';
  amount: Scalars['Int']['output'];
  itemKey: Scalars['String']['output'];
  items: Array<DriverReceiptItem>;
  label: Scalars['String']['output'];
};

export enum DriverReceiptItemType {
  header_item = 'header_item',
  sub_item = 'sub_item',
  text = 'text',
}

export type DriverRenter = {
  __typename?: 'DriverRenter';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  profilePhoto?: Maybe<Scalars['String']['output']>;
};

export type DriverRequestPhoneLoginResponse = {
  __typename?: 'DriverRequestPhoneLoginResponse';
  phoneLastFour: Scalars['String']['output'];
  twoFactorToken: Scalars['String']['output'];
};

export type DriverReservationEditableScheduleTimeRange = {
  __typename?: 'DriverReservationEditableScheduleTimeRange';
  reservationId: Scalars['ID']['output'];
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
};

export type DriverRestrictionItem = {
  __typename?: 'DriverRestrictionItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: RestrictionReasonNameKeys;
  label: Scalars['String']['output'];
  link?: Maybe<DriverRestrictionItemLink>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type DriverRestrictionItemLink = {
  __typename?: 'DriverRestrictionItemLink';
  href: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type DriverRestrictionReason = {
  __typename?: 'DriverRestrictionReason';
  driverId: Scalars['ID']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  restrictionReason: RestrictionReason;
  startedAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DriverRestrictionReasonFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  endedAt__isnull?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  platform?: InputMaybe<RestrictionReasonPlatforms>;
  reasons?: InputMaybe<Array<RestrictionReasonNameKeys>>;
};

export type DriverRestrictionReasonResponse = {
  __typename?: 'DriverRestrictionReasonResponse';
  count: Scalars['Int']['output'];
  results: Array<DriverRestrictionReason>;
};

export type DriverRestrictionStatusContentFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  platform?: InputMaybe<RestrictionReasonPlatforms>;
};

export type DriverRestrictionStatusGroup = {
  __typename?: 'DriverRestrictionStatusGroup';
  description?: Maybe<Scalars['String']['output']>;
  extendedDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<DriverRestrictionItem>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DriverRestrictionsStatusContent = {
  __typename?: 'DriverRestrictionsStatusContent';
  groups: Array<DriverRestrictionStatusGroup>;
};

export type DriverRideHail = {
  __typename?: 'DriverRideHail';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  consumerMetadata?: Maybe<Scalars['JSON']['output']>;
  consumerRideHailId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentStopPoint: DriverStopPoint;
  dispatchedRoute?: Maybe<LineString>;
  driverId?: Maybe<Scalars['ID']['output']>;
  driverRenter?: Maybe<DriverRenter>;
  dropOffAddress: Scalars['String']['output'];
  dropOffLocation: Point;
  estimatedTripMeters?: Maybe<Scalars['Int']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  fleetResourceMetadata?: Maybe<FleetResourceMetadata>;
  id: Scalars['ID']['output'];
  invoiceTotal?: Maybe<Scalars['Int']['output']>;
  payments?: Maybe<Array<DriverPayment>>;
  pickUpAddress: Scalars['String']['output'];
  pickUpLocation: Point;
  receipt?: Maybe<DriverRideReceipt>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  renterId?: Maybe<Scalars['ID']['output']>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  route?: Maybe<LineString>;
  shift?: Maybe<DriverShift>;
  shiftId?: Maybe<Scalars['ID']['output']>;
  state: DriverRideHailStates;
  stopPoints?: Maybe<Array<DriverStopPoint>>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tip?: Maybe<Tip>;
  tripDurationSeconds?: Maybe<Scalars['Int']['output']>;
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type DriverRideHailFilters = {
  assignedAtEnd?: InputMaybe<Scalars['String']['input']>;
  assignedAtStart?: InputMaybe<Scalars['String']['input']>;
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  finalizedAtEnd?: InputMaybe<Scalars['String']['input']>;
  finalizedAtStart?: InputMaybe<Scalars['String']['input']>;
  finalizedIsNull?: InputMaybe<Scalars['Boolean']['input']>;
  hasPayment?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriverRideHailOrderingField>>>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  states?: InputMaybe<Array<DriverRideHailStates>>;
};

export enum DriverRideHailOrderingField {
  finalizedAtAscending = 'finalizedAtAscending',
  finalizedAtDescending = 'finalizedAtDescending',
}

export enum DriverRideHailStates {
  active = 'active',
  arrived = 'arrived',
  canceled = 'canceled',
  completed = 'completed',
  destination_changed = 'destination_changed',
  dispatched = 'dispatched',
  failed = 'failed',
  matching = 'matching',
  offer = 'offer',
  offer_rejected = 'offer_rejected',
  pending = 'pending',
  pick_up_changed = 'pick_up_changed',
  rejected = 'rejected',
  resumed = 'resumed',
  scheduled = 'scheduled',
  stops_changed = 'stops_changed',
}

export type DriverRideReceipt = {
  __typename?: 'DriverRideReceipt';
  amount: Scalars['Int']['output'];
  items: Array<DriverReceiptItemGroup>;
  notes?: Maybe<Array<Scalars['String']['output']>>;
};

export enum DriverScheduleDevelopmentCommand {
  clear_performance_tracks = 'clear_performance_tracks',
  clear_schedule = 'clear_schedule',
}

export type DriverScheduledShift = {
  __typename?: 'DriverScheduledShift';
  attestation?: Maybe<DriverScheduledShiftAttestation>;
  cancelationReason?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  depot?: Maybe<Depot>;
  depotId?: Maybe<Scalars['ID']['output']>;
  driver: Driver;
  driverId: Scalars['ID']['output'];
  evaluation?: Maybe<DriverScheduledShiftEvaluation>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lateCancellationStartAt: Scalars['DateTime']['output'];
  pay: ShiftPay;
  recurringShiftId?: Maybe<Scalars['ID']['output']>;
  reservation?: Maybe<RentalReservation>;
  reservationId?: Maybe<Scalars['ID']['output']>;
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledEndAvailableAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  scheduledStartAvailableAt: Scalars['DateTime']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  state: DriverScheduledShiftState;
  system: System;
  systemId: Scalars['ID']['output'];
  tasks: DriverTaskResponse;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DriverScheduledShifttasksArgs = {
  filters: DriverScheduledShiftTaskFilter;
};

export type DriverScheduledShiftAttestation = {
  __typename?: 'DriverScheduledShiftAttestation';
  answer: DriverScheduledShiftAttestationAnswer;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<DriverScheduledShiftAttestationReason>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum DriverScheduledShiftAttestationAnswer {
  no = 'no',
  yes = 'yes',
}

export enum DriverScheduledShiftAttestationReason {
  breaks_taken_but_not_recorded = 'breaks_taken_but_not_recorded',
  other = 'other',
  work_related_obligations = 'work_related_obligations',
}

export enum DriverScheduledShiftCancelationReasons {
  late_callout = 'late_callout',
  sick_callout = 'sick_callout',
}

export enum DriverScheduledShiftDevelopmentCommand {
  end_now = 'end_now',
  start_now = 'start_now',
  task_duration_shortened = 'task_duration_shortened',
  task_window_start_now = 'task_window_start_now',
  time_offset_10_minutes = 'time_offset_10_minutes',
}

export type DriverScheduledShiftEvaluation = {
  __typename?: 'DriverScheduledShiftEvaluation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  issues: Array<Maybe<Scalars['String']['output']>>;
};

export type DriverScheduledShiftEvent = {
  __typename?: 'DriverScheduledShiftEvent';
  eventType: DriverScheduledShiftEventType;
  shift: DriverScheduledShift;
  shiftId: Scalars['ID']['output'];
  task?: Maybe<DriverTask>;
  taskId?: Maybe<Scalars['ID']['output']>;
};

export type DriverScheduledShiftEventFilters = {
  driverId: Scalars['ID']['input'];
  eventTypeIn?: InputMaybe<Array<DriverScheduledShiftEventType>>;
  shiftId?: InputMaybe<Scalars['ID']['input']>;
};

export enum DriverScheduledShiftEventType {
  created = 'created',
  state_changed = 'state_changed',
  task_changed = 'task_changed',
}

export type DriverScheduledShiftFilter = {
  activeTaskDefinitionNameIn?: InputMaybe<Array<DriverTaskTypes>>;
  attestationIsNull?: InputMaybe<Scalars['Boolean']['input']>;
  depotIdIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledStartAtGte?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAtLte?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  stateIn?: InputMaybe<Array<DriverScheduledShiftState>>;
  stateNotIn?: InputMaybe<Array<DriverScheduledShiftState>>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export enum DriverScheduledShiftOrderingField {
  finalizedAtAscending = 'finalizedAtAscending',
  finalizedAtDescending = 'finalizedAtDescending',
  scheduledEndAtAscending = 'scheduledEndAtAscending',
  scheduledEndAtDescending = 'scheduledEndAtDescending',
  scheduledStartAtAscending = 'scheduledStartAtAscending',
  scheduledStartAtDescending = 'scheduledStartAtDescending',
  startedAtAscending = 'startedAtAscending',
  startedAtDescending = 'startedAtDescending',
}

export enum DriverScheduledShiftState {
  active = 'active',
  canceled = 'canceled',
  completed = 'completed',
  scheduled = 'scheduled',
}

export type DriverScheduledShiftTaskFilter = {
  mustFinalizeBeforeShiftCompletion?: InputMaybe<Scalars['Boolean']['input']>;
  nameIn?: InputMaybe<Array<DriverTaskTypes>>;
  stateIn?: InputMaybe<Array<DriverTaskState>>;
  stateNotIn?: InputMaybe<Array<DriverTaskState>>;
};

export type DriverScheduledShiftsPagedResult = PagedResult & {
  __typename?: 'DriverScheduledShiftsPagedResult';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverScheduledShift>>;
};

export type DriverSetupIntent = {
  __typename?: 'DriverSetupIntent';
  clientSecret?: Maybe<Scalars['String']['output']>;
  stripeExternalId?: Maybe<Scalars['String']['output']>;
};

export type DriverShift = {
  __typename?: 'DriverShift';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  breaks?: Maybe<Array<DriverShiftBreak>>;
  clockIn?: Maybe<Scalars['DateTime']['output']>;
  clockOut?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentIssue?: Maybe<ShiftIssue>;
  currentIssueId?: Maybe<Scalars['ID']['output']>;
  currentStopPoint?: Maybe<DriverStopPoint>;
  depot?: Maybe<Depot>;
  depotId?: Maybe<Scalars['ID']['output']>;
  driver: Driver;
  driverArrivedForShiftAt?: Maybe<Scalars['DateTime']['output']>;
  estimatedPay?: Maybe<Scalars['Int']['output']>;
  fleetResourceMetadata?: Maybe<FleetResourceMetadata>;
  hasNote: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  issueType?: Maybe<DriverShiftIssueTypes>;
  liveRouteUrl?: Maybe<Scalars['String']['output']>;
  logisticsManager?: Maybe<LogisticsManagers>;
  note?: Maybe<ShiftNote>;
  remainingUnscheduledBreaks?: Maybe<RemainingUnscheduledBreaks>;
  reservationBillingSettings?: Maybe<ReservationBillingSettings>;
  reservationContract?: Maybe<ReservationContract>;
  reservationId?: Maybe<Scalars['ID']['output']>;
  reservationType?: Maybe<ReservationTypes>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledRecallAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt: Scalars['DateTime']['output'];
  settings?: Maybe<ShiftSettings>;
  shiftCancellation?: Maybe<ShiftCancellation>;
  state: DriverShiftStates;
  stats?: Maybe<ShiftStats>;
  systemId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
  vehicleLocationUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DriverShiftBreak = {
  __typename?: 'DriverShiftBreak';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<DriverShiftBreakTypes>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum DriverShiftBreakTypes {
  scheduled = 'scheduled',
  unscheduled = 'unscheduled',
}

export enum DriverShiftCancelationReasons {
  call_out_within_two_hours = 'call_out_within_two_hours',
  call_out_within_two_hours_pto = 'call_out_within_two_hours_pto',
  canceled_shift_paid = 'canceled_shift_paid',
  driver_ops_override = 'driver_ops_override',
  driver_revoked = 'driver_revoked',
  driver_sick = 'driver_sick',
  driver_termination = 'driver_termination',
  inactive_driver = 'inactive_driver',
  leave_of_absence = 'leave_of_absence',
  low_demand_rebalance = 'low_demand_rebalance',
  no_call_no_show = 'no_call_no_show',
  other = 'other',
  pto = 'pto',
  recurring_shift_update = 'recurring_shift_update',
  same_day_call_out = 'same_day_call_out',
  tardy = 'tardy',
  unpaid_time = 'unpaid_time',
  vehicle_issue = 'vehicle_issue',
  weather = 'weather',
}

export type DriverShiftDevice = {
  __typename?: 'DriverShiftDevice';
  appVersion?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type DriverShiftFilters = {
  currentStopPointType__in?: InputMaybe<Array<DriverFilteredStopPointTypes>>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  depotId__in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  evaluatedState?: InputMaybe<EvaluatedState>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  hasNote?: InputMaybe<Scalars['Boolean']['input']>;
  hasVehicle?: InputMaybe<Scalars['Boolean']['input']>;
  isInIssue?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<Array<DriverShiftOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  reservationType?: InputMaybe<ReservationTypes>;
  scheduledEndAtEnd?: InputMaybe<Scalars['String']['input']>;
  scheduledEndAtStart?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state__in?: InputMaybe<Array<DriverShiftStates>>;
  state__nin?: InputMaybe<Array<DriverShiftStates>>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export enum DriverShiftFleetType {
  centralized_fleet = 'centralized_fleet',
  distributed_fleet = 'distributed_fleet',
  none = 'none',
}

export enum DriverShiftIssueTypes {
  accident = 'accident',
  end_shift_early = 'end_shift_early',
  failed_to_acknowledge_ride = 'failed_to_acknowledge_ride',
  failed_to_start_shift = 'failed_to_start_shift',
  issue_with_vehicle = 'issue_with_vehicle',
  lost_and_found = 'lost_and_found',
  low_battery = 'low_battery',
  needs_cleaning = 'needs_cleaning',
  non_vehicle_issue = 'non_vehicle_issue',
  special_assignment = 'special_assignment',
  stale_location = 'stale_location',
  unknown = 'unknown',
}

export enum DriverShiftOrderingField {
  clockInAscending = 'clockInAscending',
  clockInDescending = 'clockInDescending',
  clockOutAscending = 'clockOutAscending',
  clockOutDescending = 'clockOutDescending',
  idAscending = 'idAscending',
  idDescending = 'idDescending',
  inIssueFirst = 'inIssueFirst',
  inIssueLast = 'inIssueLast',
  scheduledEndAtAscending = 'scheduledEndAtAscending',
  scheduledEndAtDescending = 'scheduledEndAtDescending',
  scheduledRecallAtAscending = 'scheduledRecallAtAscending',
  scheduledRecallAtDescending = 'scheduledRecallAtDescending',
  scheduledStartAtAscending = 'scheduledStartAtAscending',
  scheduledStartAtDescending = 'scheduledStartAtDescending',
  stateAscending = 'stateAscending',
  stateDescending = 'stateDescending',
}

export enum DriverShiftStates {
  activating = 'activating',
  active = 'active',
  canceled = 'canceled',
  complete = 'complete',
  driver_on_the_way = 'driver_on_the_way',
  no_show = 'no_show',
  scheduled = 'scheduled',
  scheduled_break = 'scheduled_break',
  stand_by = 'stand_by',
  unscheduled_break = 'unscheduled_break',
}

export type DriverShiftsResponse = {
  __typename?: 'DriverShiftsResponse';
  count: Scalars['Int']['output'];
  results: Array<DriverShift>;
};

export type DriverSignUpInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneVerificationKey: Scalars['String']['input'];
};

export type DriverSignUpResponse = {
  __typename?: 'DriverSignUpResponse';
  driver: Driver;
  identity: Identity;
  token: Token;
};

export type DriverStartShiftInput = {
  accuracy?: InputMaybe<Scalars['Float']['input']>;
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  deviceName?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<DeviceTypes>;
  expoPushToken?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<PointInput>;
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  pushTokenType?: InputMaybe<PushTokenTypes>;
  shiftId: Scalars['ID']['input'];
};

export type DriverStats = {
  __typename?: 'DriverStats';
  averageNumberOfSecondsToAckRides?: Maybe<Scalars['Int']['output']>;
  criticalAlertsPer100Miles?: Maybe<Scalars['Int']['output']>;
  overallDriverRating?: Maybe<Scalars['Float']['output']>;
  percentageOfAvailability?: Maybe<Scalars['Float']['output']>;
  percentageOfRidesUnder3Stars?: Maybe<Scalars['Float']['output']>;
  performanceStatus?: Maybe<Scalars['Float']['output']>;
  performanceStatusThresholds?: Maybe<Scalars['JSON']['output']>;
  totalNumberOfCompletedRides?: Maybe<Scalars['Int']['output']>;
};

export type DriverStopPoint = {
  __typename?: 'DriverStopPoint';
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use arrivedAt */
  actualArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  address: Scalars['String']['output'];
  arrivedAt?: Maybe<Scalars['DateTime']['output']>;
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  consumerRideHailId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentRoute?: Maybe<LineString>;
  currentRouteGeojsonString?: Maybe<Scalars['String']['output']>;
  driverRenter?: Maybe<DriverRenter>;
  estimatedArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedArrivalTimeAtOffer?: Maybe<Scalars['DateTime']['output']>;
  estimatedCompletionTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedCompletionTimeAtOffer?: Maybe<Scalars['DateTime']['output']>;
  estimatedTripMeters?: Maybe<Scalars['Int']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use parentId */
  internalParentId?: Maybe<Scalars['ID']['output']>;
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  location: Point;
  metadata?: Maybe<StoppointMeta>;
  order?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  priority: Scalars['Int']['output'];
  renterId?: Maybe<Scalars['ID']['output']>;
  requestedArrivalAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  state: DriverStopPointStates;
  stopType: DriverStopPointTypes;
  systemId?: Maybe<Scalars['ID']['output']>;
};

export enum DriverStopPointStates {
  arrived = 'arrived',
  assigned = 'assigned',
  canceled = 'canceled',
  completed = 'completed',
  failed = 'failed',
  matching = 'matching',
  offer = 'offer',
  offer_rejected = 'offer_rejected',
  pending = 'pending',
  removed = 'removed',
}

export enum DriverStopPointTypes {
  dropoff = 'dropoff',
  generic = 'generic',
  intermediate = 'intermediate',
  issue = 'issue',
  pickup = 'pickup',
  rebalance = 'rebalance',
  recall = 'recall',
  scheduled_break = 'scheduled_break',
  shift_end = 'shift_end',
  unscheduled_break = 'unscheduled_break',
  vehicle_check = 'vehicle_check',
}

export type DriverStopPointsResponse = {
  __typename?: 'DriverStopPointsResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<Maybe<DriverStopPoint>>>;
};

export type DriverSubmitPhoneLoginResponse = {
  __typename?: 'DriverSubmitPhoneLoginResponse';
  driver: Driver;
  identity: Identity;
  token: Token;
};

export type DriverSubmitTwoFactorLoginInput = {
  twoFactorToken: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type DriverSystemZone = {
  __typename?: 'DriverSystemZone';
  areaGeojsonString: Scalars['String']['output'];
  areaInvertedGeojsonString?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  markerLocation?: Maybe<Point>;
  nameKey?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  systemId: Scalars['ID']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
  zoneType: Scalars['String']['output'];
};

export type DriverTask = {
  __typename?: 'DriverTask';
  durationSeconds?: Maybe<Scalars['Int']['output']>;
  evaluation?: Maybe<DriverTaskEvaluation>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  finalizedAtLocationAccuracy?: Maybe<Scalars['Float']['output']>;
  finalizedLocation?: Maybe<Point>;
  id: Scalars['ID']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  startedAtLocation?: Maybe<Point>;
  startedAtLocationAccuracy?: Maybe<Scalars['Float']['output']>;
  state: DriverTaskState;
  stateReason?: Maybe<DriverTaskStateReasons>;
  taskDefinition: DriverTaskDefinition;
  windowEndAt?: Maybe<Scalars['DateTime']['output']>;
  windowStartAt: Scalars['DateTime']['output'];
};

export type DriverTaskDefinition = {
  __typename?: 'DriverTaskDefinition';
  autoEnd?: Maybe<Scalars['Boolean']['output']>;
  autoStart?: Maybe<Scalars['Boolean']['output']>;
  dismissible?: Maybe<Scalars['Boolean']['output']>;
  endBeforeDuration?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  mustFinalizeBeforeShiftCompletion?: Maybe<Scalars['Boolean']['output']>;
  name: DriverTaskTypes;
};

export type DriverTaskDefinitionInput = {
  name: DriverTaskTypes;
};

export type DriverTaskEvaluation = {
  __typename?: 'DriverTaskEvaluation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  issues: Array<Maybe<Scalars['String']['output']>>;
};

export type DriverTaskFilter = {
  mustFinalizeBeforeShiftCompletion?: InputMaybe<Scalars['Boolean']['input']>;
  nameIn?: InputMaybe<Array<DriverTaskTypes>>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  shiftId: Scalars['ID']['input'];
  stateIn?: InputMaybe<Array<DriverTaskState>>;
  stateNotIn?: InputMaybe<Array<DriverTaskState>>;
};

export type DriverTaskResponse = {
  __typename?: 'DriverTaskResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverTask>>;
};

export enum DriverTaskState {
  active = 'active',
  canceled = 'canceled',
  completed = 'completed',
  failed = 'failed',
  pending = 'pending',
}

export enum DriverTaskStateReasons {
  canceled = 'canceled',
  completed = 'completed',
  dismissed = 'dismissed',
  missed_window = 'missed_window',
  shift_completed = 'shift_completed',
}

export enum DriverTaskTypes {
  accident = 'accident',
  lost_and_found = 'lost_and_found',
  none = 'none',
  paid_break = 'paid_break',
  performance_management_review = 'performance_management_review',
  special_assignment = 'special_assignment',
  unpaid_break = 'unpaid_break',
  vehicle_issue = 'vehicle_issue',
  waiting_for_vehicle = 'waiting_for_vehicle',
}

export type DriverTaxInfo = {
  __typename?: 'DriverTaxInfo';
  aboundTinFingerprint?: Maybe<Scalars['String']['output']>;
  aboundUserId?: Maybe<Scalars['String']['output']>;
  tinStatus?: Maybe<TinStatus>;
  tinType?: Maybe<Scalars['String']['output']>;
};

export type DriverTimeAway = {
  __typename?: 'DriverTimeAway';
  employeeMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  managerMessage?: Maybe<Scalars['String']['output']>;
  netHours: Scalars['String']['output'];
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  status: DriverTimeAwayStatus;
};

export type DriverTimeAwayFilters = {
  scheduledEndAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledEndAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusIn?: InputMaybe<Array<DriverTimeAwayStatus>>;
};

export enum DriverTimeAwayStatus {
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  CANCELPENDING = 'CANCELPENDING',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
}

export type DriverTrackLevel = {
  __typename?: 'DriverTrackLevel';
  driverId?: Maybe<Scalars['ID']['output']>;
  driverTrackLevelAcknowledgementId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  level?: Maybe<TrackLevel>;
  levelId?: Maybe<Scalars['ID']['output']>;
  warningMessage?: Maybe<Scalars['String']['output']>;
  warningState?: Maybe<PerformanceWarningState>;
};

export type DriverTrackLevelFilters = {
  needsAcknowledgement?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<Array<DriverTrackLevelOrderingField>>;
  pointThreshold?: InputMaybe<Scalars['Int']['input']>;
};

export enum DriverTrackLevelOrderingField {
  pointThresholdAscending = 'pointThresholdAscending',
  pointThresholdDescending = 'pointThresholdDescending',
}

export type DriverTrackLevelResponse = {
  __typename?: 'DriverTrackLevelResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverTrackLevel>>;
};

export enum DriverUpdateEmploymentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REVOKED = 'REVOKED',
}

export type DriverVehicleExclusionMakeAndModel = {
  __typename?: 'DriverVehicleExclusionMakeAndModel';
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
};

export type DriverVehiclePreferenceResponse = {
  __typename?: 'DriverVehiclePreferenceResponse';
  preferredVehicleType: DriverVehiclePreferenceType;
};

export enum DriverVehiclePreferenceType {
  premium = 'premium',
  standard = 'standard',
}

export type DriversResponse = {
  __typename?: 'DriversResponse';
  count: Scalars['Int']['output'];
  results: Array<Driver>;
};

export type DynamicRateDiscount = {
  __typename?: 'DynamicRateDiscount';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expireAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  maxRate: Scalars['Float']['output'];
  minRate: Scalars['Float']['output'];
};

export enum EmploymentTypes {
  W2 = 'W2',
  contractor = 'contractor',
}

export type EmptyResponse = {
  __typename?: 'EmptyResponse';
  id?: Maybe<Scalars['ID']['output']>;
};

export type EndDriverScheduledShiftInput = {
  attestation?: InputMaybe<DriverAttestationInput>;
  location?: InputMaybe<PointInput>;
  locationAccuracy?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type EndDriverTaskInput = {
  location: PointInput;
  locationAccuracy: Scalars['Float']['input'];
};

export type EndReservationInput = {
  location?: InputMaybe<PointInput>;
  locationAccuracy?: InputMaybe<Scalars['Float']['input']>;
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  reservationId: Scalars['ID']['input'];
};

export enum EvaluatedState {
  active_shifts = 'active_shifts',
  inactive_shifts = 'inactive_shifts',
  shifts_eligible_to_start = 'shifts_eligible_to_start',
  shifts_with_vehicle = 'shifts_with_vehicle',
}

export enum ExpoPlatforms {
  android = 'android',
  ios = 'ios',
  macos = 'macos',
  web = 'web',
  windows = 'windows',
}

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  /** @deprecated Never used. Remove soon™️ */
  isCarControlsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isDeferredBreakEnabled?: Maybe<Scalars['Boolean']['output']>;
  isDevAllowed: Scalars['Boolean']['output'];
  isDriverInvoicesEnabled?: Maybe<Scalars['Boolean']['output']>;
  isDriverPaymentMethodsEnabled?: Maybe<Scalars['Boolean']['output']>;
  isDriverSignUpEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isPerformanceDriverStandingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isRentalAppEnabled?: Maybe<Scalars['Boolean']['output']>;
  isRenterOffboardingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isReservationAgreementEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isScheduledBreakExtendedActionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  isServicesAgreementEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isShiftActivityPremiumEnabled?: Maybe<Scalars['Boolean']['output']>;
  isTaxDocumentsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isUnscheduledBreakCapEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isUnscheduledBreakUrgentEnabled?: Maybe<Scalars['Boolean']['output']>;
  isVariableLeaseRatesEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isWeeklyScheduleEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type Flag = {
  __typename?: 'Flag';
  active: Scalars['Boolean']['output'];
  address: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  descriptionDisplayKey?: Maybe<Scalars['String']['output']>;
  expirationDate: Scalars['DateTime']['output'];
  flagStatus: FlagStatus;
  flagType: FlagType;
  id: Scalars['ID']['output'];
  internalTitle: Scalars['String']['output'];
  location: Point;
  startDate: Scalars['DateTime']['output'];
  system: System;
  titleDisplayKey?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
};

export type FlagFilters = {
  excludeExpired?: InputMaybe<Scalars['Boolean']['input']>;
  flagTypeId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
  systemName?: InputMaybe<Scalars['String']['input']>;
};

export enum FlagStatus {
  ENDED = 'ENDED',
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING',
}

export type FlagType = {
  __typename?: 'FlagType';
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type FleetResource = {
  __typename?: 'FleetResource';
  batteryLevel: Scalars['Float']['output'];
  color?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  detail?: Maybe<FleetResourceDetail>;
  division?: Maybe<Divisions>;
  estimateMileage?: Maybe<Scalars['Int']['output']>;
  googleDirections?: Maybe<GoogleDirections>;
  hasBlockingTask?: Maybe<Scalars['Boolean']['output']>;
  hubId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isReservable?: Maybe<Scalars['Boolean']['output']>;
  lastServicedAt?: Maybe<Scalars['DateTime']['output']>;
  latestServiceLog?: Maybe<ServiceLog>;
  licensePlate: Scalars['String']['output'];
  location: Point;
  locationGroup?: Maybe<LocationGroup>;
  matchesFilters?: Maybe<Scalars['Boolean']['output']>;
  model: Scalars['String']['output'];
  passengerAccount?: Maybe<PassengerAppAccount>;
  range?: Maybe<Scalars['Int']['output']>;
  reservationStatus?: Maybe<FleetResourceReservationStatus>;
  sensors?: Maybe<Sensors>;
  session?: Maybe<FleetResourceSession>;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  tasks?: Maybe<Array<Task>>;
  telematicsId: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  vin: Scalars['String']['output'];
  workOrder?: Maybe<WorkOrder>;
  workOrderId?: Maybe<Scalars['ID']['output']>;
  workOrderType?: Maybe<WorkOrderType>;
};

export type FleetResourcetasksArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type FleetResourceDetail = {
  __typename?: 'FleetResourceDetail';
  id: Scalars['ID']['output'];
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  passengerCapacity?: Maybe<Scalars['Int']['output']>;
  wheelchair_accessible?: Maybe<Scalars['Boolean']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type FleetResourceEvent = {
  __typename?: 'FleetResourceEvent';
  eventType?: Maybe<FleetResourceEventType>;
  resource?: Maybe<FleetResource>;
};

export enum FleetResourceEventType {
  AVAILABLE = 'AVAILABLE',
  SENSOR_UPDATE = 'SENSOR_UPDATE',
  TAG_ADDED = 'TAG_ADDED',
  TAG_REMOVED = 'TAG_REMOVED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export type FleetResourceMetadata = {
  __typename?: 'FleetResourceMetadata';
  dispatchingTlcBase?: Maybe<ResourceMetadataTLCBase>;
  fhvLicenseNumber: Scalars['String']['output'];
  fleetResourceId: Scalars['ID']['output'];
  insuranceProvider?: Maybe<InsuranceProvider>;
  isControllable?: Maybe<Scalars['Boolean']['output']>;
  tlcBase?: Maybe<ResourceMetadataTLCBase>;
  vehicle?: Maybe<FleetResource>;
};

export type FleetResourceMetadataResponse = {
  __typename?: 'FleetResourceMetadataResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<Maybe<FleetResourceMetadata>>>;
};

export enum FleetResourceReservationStatus {
  AVAILABLE = 'AVAILABLE',
  TAKEN_IN_ELECTRICFEEL = 'TAKEN_IN_ELECTRICFEEL',
  WITH_CURRENT_OPERATOR_IN_RESERVATION = 'WITH_CURRENT_OPERATOR_IN_RESERVATION',
  WITH_CURRENT_OPERATOR_IN_WORKORDER = 'WITH_CURRENT_OPERATOR_IN_WORKORDER',
  WITH_OPERATOR_IN_ELECTRICFEEL = 'WITH_OPERATOR_IN_ELECTRICFEEL',
  WITH_OPERATOR_IN_RESERVATION = 'WITH_OPERATOR_IN_RESERVATION',
  WITH_OPERATOR_IN_WORKORDER = 'WITH_OPERATOR_IN_WORKORDER',
  WITH_RENTER = 'WITH_RENTER',
}

export type FleetResourceSession = {
  __typename?: 'FleetResourceSession';
  efOperatorId?: Maybe<Scalars['Int']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  fleetResourceId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  identity?: Maybe<Identity>;
  identityExternalId?: Maybe<Scalars['String']['output']>;
  identityType?: Maybe<Scalars['String']['output']>;
  serviceContext?: Maybe<ServiceContext>;
  serviceContextId?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
};

export type FleetResourceSessionFilters = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  serviceContext?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export enum FleetResourceStatusValues {
  active = 'active',
  at_bodyshop = 'at_bodyshop',
  at_dealer = 'at_dealer',
  compliance = 'compliance',
  in_shop = 'in_shop',
  isolation = 'isolation',
  new_delivered_and_inspected = 'new_delivered_and_inspected',
  out_of_service = 'out_of_service',
  project_vehicles = 'project_vehicles',
  ready_for_pick_up = 'ready_for_pick_up',
  unknown = 'unknown',
  vehicle_prep = 'vehicle_prep',
  weekly = 'weekly',
}

export type FleetResourcesStats = {
  __typename?: 'FleetResourcesStats';
  availableFleetResourcesCount: Scalars['Int']['output'];
  batteryDischargedFleetResourcesCount: Scalars['Int']['output'];
  blockedCount: Scalars['Int']['output'];
  dischargedSwappableCount: Scalars['Int']['output'];
  dischargedTotalCount: Scalars['Int']['output'];
  inAssignmentSessionFleetResourcesCount: Scalars['Int']['output'];
  inConsumerSessionFleetResourcesCount: Scalars['Int']['output'];
  inReservationSessionFleetResourcesCount: Scalars['Int']['output'];
  rentalsTodayCount: Scalars['Int']['output'];
  selectedResourcesAverageBatteryLevel: Scalars['Float']['output'];
  specialOpsCount: Scalars['Int']['output'];
  taskBlockedFleetResourcesCount: Scalars['Int']['output'];
  towCount: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
};

export type GetDepotsFilters = {
  id__in?: InputMaybe<Array<Scalars['ID']['input']>>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetDriverOpsDriverAndDetailsFilters = {
  driverFilters?: InputMaybe<DriverOpsDriversFilters>;
  paginationFilters?: InputMaybe<PaginationFilter>;
  shiftFilters?: InputMaybe<DriverOpsShiftFilters>;
};

export type GetDriverOpsDriverAndShiftFilters = {
  evaluatedState?: InputMaybe<DriverOpsEvaluatedState>;
  ordering?: InputMaybe<Array<DriverOpsShiftOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['String']['input']>;
  state__in?: InputMaybe<Array<DriverOpsShiftStates>>;
};

export type GetDriverSystemZonesFilters = {
  zoneType?: InputMaybe<OpsSystemZoneType>;
};

export type GoogleDirections = {
  __typename?: 'GoogleDirections';
  distance?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
};

export type GoogleLoginCXAgentInput = {
  googleIdToken: Scalars['String']['input'];
};

export type GoogleLoginOperatorInput = {
  googleIdToken: Scalars['String']['input'];
};

export type HireDepotConfig = {
  __typename?: 'HireDepotConfig';
  id: Scalars['ID']['output'];
};

export type Hub = {
  __typename?: 'Hub';
  addressDisplayKey: Scalars['String']['output'];
  availableResourceCount: Scalars['Int']['output'];
  capacity: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  descriptionDisplayKey: Scalars['String']['output'];
  entrance: Point;
  id: Scalars['ID']['output'];
  images: Array<Scalars['String']['output']>;
  internalName?: Maybe<Scalars['String']['output']>;
  isLive: Scalars['Boolean']['output'];
  nameDisplayKey: Scalars['String']['output'];
  perimeter: Scalars['String']['output'];
  resourceCount: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
  unusedCapacity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type HubEvent = {
  __typename?: 'HubEvent';
  eventType?: Maybe<HubEventType>;
  hub?: Maybe<Hub>;
};

export enum HubEventType {
  CHANGE = 'CHANGE',
}

export type HubResource = {
  __typename?: 'HubResource';
  enteredAt: Scalars['DateTime']['output'];
  enteredByService: Scalars['String']['output'];
  enteredByServiceContext: Scalars['String']['output'];
  enteredByServiceContextId: Scalars['String']['output'];
  hub: Hub;
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  removedByService?: Maybe<Scalars['String']['output']>;
  removedByServiceContext?: Maybe<Scalars['String']['output']>;
  removedByServiceContextId?: Maybe<Scalars['String']['output']>;
  resource: FleetResource;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Identity = {
  __typename?: 'Identity';
  context: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
};

export type ImgSrc = {
  __typename?: 'ImgSrc';
  src?: Maybe<Scalars['String']['output']>;
  srcset?: Maybe<Scalars['String']['output']>;
};

export type Incident = {
  __typename?: 'Incident';
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  expirationDays?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  points: Scalars['Int']['output'];
  track?: Maybe<Track>;
  trackId: Scalars['ID']['output'];
};

export enum IncidentForgivenessReason {
  appeal_review = 'appeal_review',
  dispute_review = 'dispute_review',
  employment_review = 'employment_review',
  grace_period = 'grace_period',
  hr_review = 'hr_review',
}

export type IncidentsResponse = {
  __typename?: 'IncidentsResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<Incident>>;
};

export type Ineligibility = {
  __typename?: 'Ineligibility';
  end?: Maybe<Scalars['DateTime']['output']>;
  endDescription?: Maybe<Scalars['String']['output']>;
  endServiceContext?: Maybe<IneligibilityServiceContext>;
  endedByIdentity?: Maybe<Identity>;
  endedByIdentityId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  ineligibilityReason?: Maybe<CXIneligibilityReason>;
  renterViolation?: Maybe<RenterViolation>;
  start: Scalars['DateTime']['output'];
  startDescription?: Maybe<Scalars['String']['output']>;
  startServiceContext?: Maybe<IneligibilityServiceContext>;
  /** Resolver fetched fields */
  startedByIdentity?: Maybe<Identity>;
  startedByIdentityId?: Maybe<Scalars['ID']['output']>;
};

export type IneligibilityFilters = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum IneligibilityServiceContext {
  CONSUMER = 'CONSUMER',
  CX = 'CX',
  MIGRATION = 'MIGRATION',
}

export enum InsurancePremiumTiers {
  tier_one = 'tier_one',
  tier_three = 'tier_three',
  tier_two = 'tier_two',
}

export enum InsurancePremiumTiersDaily {
  tier_one = 'tier_one',
  tier_two = 'tier_two',
}

export type InsuranceProvider = {
  __typename?: 'InsuranceProvider';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type InsuranceProviderResponse = {
  __typename?: 'InsuranceProviderResponse';
  count: Scalars['Int']['output'];
  results: Array<InsuranceProvider>;
};

export enum InvoiceFilterCategoryOptions {
  ebike = 'ebike',
  other = 'other',
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export type InvoiceFilters = {
  category?: InputMaybe<InvoiceFilterCategoryOptions>;
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  renterId: Scalars['ID']['input'];
};

export type InvoiceLineItemDeprecated = {
  __typename?: 'InvoiceLineItemDeprecated';
  category: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  itemValue: Scalars['String']['output'];
};

export type InvoiceMetaData = {
  __typename?: 'InvoiceMetaData';
  voidedReasonContext?: Maybe<Scalars['String']['output']>;
};

export type IsInCar = {
  __typename?: 'IsInCar';
  inCar: Scalars['Boolean']['output'];
};

export type Issue = {
  __typename?: 'Issue';
  assignedTo?: Maybe<CXAgent>;
  closeReason?: Maybe<Scalars['String']['output']>;
  closeReasonDescription?: Maybe<Scalars['String']['output']>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  closedBy?: Maybe<CXAgent>;
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fleetResourceId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  issueData?: Maybe<ProblemReport>;
  /** Resolver fetched fields */
  issueDetail?: Maybe<IssueDetail>;
  issueType: IssueType;
  objectId: Scalars['ID']['output'];
  objectTime: Scalars['DateTime']['output'];
  rentalRating?: Maybe<Scalars['Int']['output']>;
  renterId?: Maybe<Scalars['ID']['output']>;
  status: IssueStatus;
  system?: Maybe<System>;
  systemId: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
};

export type IssueDetail = {
  __typename?: 'IssueDetail';
  communityReport?: Maybe<CommunityReport>;
  idVerification?: Maybe<CXIdVerification>;
  problemReport?: Maybe<ProblemReport>;
};

export type IssueDriverCreditInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Int']['input'];
  driverId: Scalars['ID']['input'];
  reasonGranted: DriverCreditGrantingReasons;
};

export type IssueFilters = {
  assignmentState?: InputMaybe<Scalars['String']['input']>;
  cxAgentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  issueType?: InputMaybe<IssueType>;
  ordering?: InputMaybe<IssueOrdering>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rentalRatings?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  renterId?: InputMaybe<Scalars['ID']['input']>;
  showAutomated?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IssueStatus>;
  systemIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type IssueImageUploadTarget = {
  __typename?: 'IssueImageUploadTarget';
  s3Path: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type IssueList = {
  __typename?: 'IssueList';
  count: Scalars['Int']['output'];
  results: Array<Issue>;
};

export enum IssueOrdering {
  asc = 'asc',
  desc = 'desc',
}

export type IssueResponse = {
  __typename?: 'IssueResponse';
  id?: Maybe<Scalars['ID']['output']>;
};

export enum IssueStatus {
  closed = 'closed',
  open = 'open',
}

export enum IssueType {
  community_report = 'community_report',
  failed_idv = 'failed_idv',
  problem_report = 'problem_report',
}

export enum LedgerTransactionType {
  deduction_payout = 'deduction_payout',
  funding = 'funding',
}

export type LegalDocument = {
  __typename?: 'LegalDocument';
  createdAt: Scalars['DateTime']['output'];
  documentType: LegalDocumentTypes;
  documentUrl: Scalars['String']['output'];
  effectiveDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  signedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LegalDocumentFilters = {
  documentType?: InputMaybe<LegalDocumentTypes>;
  effectiveDateEnd?: InputMaybe<Scalars['String']['input']>;
  effectiveDateStart?: InputMaybe<Scalars['String']['input']>;
};

export enum LegalDocumentTypes {
  driver_terms_of_use = 'driver_terms_of_use',
  landey_mutual_arbitration_agreement = 'landey_mutual_arbitration_agreement',
  landey_service_agreement = 'landey_service_agreement',
  reservation_contract = 'reservation_contract',
}

export type LegalDocumentsResponse = {
  __typename?: 'LegalDocumentsResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<LegalDocument>>;
};

export type License = {
  __typename?: 'License';
  driver: Driver;
  driverId: Scalars['ID']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseType: LicenseTypes;
  metadata?: Maybe<LicenseMetadata>;
  photoBackUrl?: Maybe<Scalars['String']['output']>;
  photoFrontUrl?: Maybe<Scalars['String']['output']>;
  status: LicenseStatuses;
  updatedAt: Scalars['DateTime']['output'];
};

export type LicenseFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  licenseType__in?: InputMaybe<Array<LicenseTypes>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<LicenseOrdering>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status__in?: InputMaybe<Array<LicenseStatuses>>;
};

export type LicenseMetadata = {
  __typename?: 'LicenseMetadata';
  dmvAddress?: Maybe<Scalars['String']['output']>;
  dmvCity?: Maybe<Scalars['String']['output']>;
  dmvDob?: Maybe<Scalars['Date']['output']>;
  dmvFirstName?: Maybe<Scalars['String']['output']>;
  dmvId?: Maybe<Scalars['String']['output']>;
  dmvLastName?: Maybe<Scalars['String']['output']>;
  dmvState?: Maybe<Scalars['String']['output']>;
  dmvVehicleClass?: Maybe<Scalars['String']['output']>;
  dmvZip?: Maybe<Scalars['String']['output']>;
  tlcFirstName?: Maybe<Scalars['String']['output']>;
  tlcLastName?: Maybe<Scalars['String']['output']>;
  tlcWheelchairAccessibleTrained?: Maybe<Scalars['String']['output']>;
};

export type LicenseMetadataInput = {
  dmvAddress?: InputMaybe<Scalars['String']['input']>;
  dmvCity?: InputMaybe<Scalars['String']['input']>;
  dmvDob?: InputMaybe<Scalars['String']['input']>;
  dmvFirstName?: InputMaybe<Scalars['String']['input']>;
  dmvId?: InputMaybe<Scalars['String']['input']>;
  dmvLastName?: InputMaybe<Scalars['String']['input']>;
  dmvState?: InputMaybe<Scalars['String']['input']>;
  dmvVehicleClass?: InputMaybe<Scalars['String']['input']>;
  dmvZip?: InputMaybe<Scalars['String']['input']>;
  tlcFirstName?: InputMaybe<Scalars['String']['input']>;
  tlcLastName?: InputMaybe<Scalars['String']['input']>;
  tlcWheelchairAccessibleTrained?: InputMaybe<Scalars['String']['input']>;
};

export enum LicenseOrdering {
  createdAtAscending = 'createdAtAscending',
  createdAtDescending = 'createdAtDescending',
}

export type LicenseResponse = {
  __typename?: 'LicenseResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<License>>;
};

export enum LicenseStatuses {
  approved = 'approved',
  pending_review = 'pending_review',
  rejected = 'rejected',
  replaced = 'replaced',
}

export enum LicenseTypes {
  dmv = 'dmv',
  tlc = 'tlc',
}

export type LineString = {
  __typename?: 'LineString';
  coordinates?: Maybe<Scalars['Coordinates']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type LinkAndGeneratePassengerPasswordInput = {
  licensePlate: Scalars['String']['input'];
  passengerAccountId: Scalars['ID']['input'];
};

export type LinkAndGeneratePassengerPasswordResponse = {
  __typename?: 'LinkAndGeneratePassengerPasswordResponse';
  expiresAt: Scalars['DateTime']['output'];
  password: Scalars['String']['output'];
};

export type ListOpenShiftFilters = {
  scheduledStartAt__gte?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAt__lt?: InputMaybe<Scalars['String']['input']>;
};

export type ListOpenShiftTemplateFilters = {
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  endWeekday?: InputMaybe<Scalars['Int']['input']>;
  openShiftTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  pendingScheduleOpenShiftTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startWeekday?: InputMaybe<Scalars['Int']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type ListRecurringShiftsFilters = {
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  endTimeLte?: InputMaybe<Scalars['String']['input']>;
  endWeekday?: InputMaybe<Scalars['Int']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  startTimeGte?: InputMaybe<Scalars['String']['input']>;
  startWeekday?: InputMaybe<Scalars['Int']['input']>;
};

export type ListTrafficViolationTicketResponse = {
  __typename?: 'ListTrafficViolationTicketResponse';
  count: Scalars['Int']['output'];
  results: Array<TrafficViolationTicket>;
};

export enum LocalizedItemTypeEnum {
  airport_fee = 'airport_fee',
  billable_duration_reduction = 'billable_duration_reduction',
  black_car_fee = 'black_car_fee',
  boost_incentive = 'boost_incentive',
  charity_donation = 'charity_donation',
  congestion_fee = 'congestion_fee',
  coupon = 'coupon',
  free_paused_discount = 'free_paused_discount',
  insurance_fee = 'insurance_fee',
  minimum_stripe_charge_reduction = 'minimum_stripe_charge_reduction',
  minutes = 'minutes',
  mta_congestion_fee = 'mta_congestion_fee',
  rental_pass_reduction = 'rental_pass_reduction',
  renter_unable_to_ride = 'renter_unable_to_ride',
  renter_unable_to_ride_reduction = 'renter_unable_to_ride_reduction',
  ride_credit = 'ride_credit',
  ride_discount = 'ride_discount',
  ride_experimental_discount = 'ride_experimental_discount',
  ride_fare = 'ride_fare',
  ride_locations = 'ride_locations',
  ride_paused_time = 'ride_paused_time',
  ride_rental_pass_discount = 'ride_rental_pass_discount',
  ride_subtotal = 'ride_subtotal',
  ride_tax = 'ride_tax',
  ride_time = 'ride_time',
  ride_tip = 'ride_tip',
  ride_total = 'ride_total',
  ride_unlock_fee = 'ride_unlock_fee',
  service_fee = 'service_fee',
  toll_fee = 'toll_fee',
  under_minimum_stripe_charge_threshold = 'under_minimum_stripe_charge_threshold',
}

export type Location = {
  __typename?: 'Location';
  centerPoint: Point;
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<LocationGroup>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resourceCount?: Maybe<Scalars['Int']['output']>;
  system?: Maybe<System>;
  systemId: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
};

export type LocationAction = {
  __typename?: 'LocationAction';
  actionByIdentity: Identity;
  actionByIdentityId: Scalars['String']['output'];
  actionTime: Scalars['DateTime']['output'];
  actionType: LocationActionType;
  locationGroup: LocationGroup;
  resourceId: Scalars['String']['output'];
  serviceContextId: Scalars['String']['output'];
};

export enum LocationActionType {
  added = 'added',
  removed = 'removed',
}

export type LocationFilters = {
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationGroup = {
  __typename?: 'LocationGroup';
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fleetResources?: Maybe<Array<FleetResource>>;
  groupType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location: Location;
  locationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  resourceCount?: Maybe<Scalars['Int']['output']>;
  system?: Maybe<System>;
  systemId: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
};

export type LocationGroupAction = {
  __typename?: 'LocationGroupAction';
  command: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locationGroup: LocationGroup;
  operator: Operator;
};

export type LocationGroupFilters = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  systemId: Scalars['ID']['input'];
};

export type LocationInput = {
  centerPoint: PointInput;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  systemId: Scalars['ID']['input'];
};

export type LoginCXAgentResponse = {
  __typename?: 'LoginCXAgentResponse';
  identity: Identity;
  operator: CXAgent;
  token: Token;
};

export type LoginDriverResponse = {
  __typename?: 'LoginDriverResponse';
  identity?: Maybe<Identity>;
  token?: Maybe<Token>;
};

export type LoginDriveropsResponse = {
  __typename?: 'LoginDriveropsResponse';
  identity?: Maybe<Identity>;
  token?: Maybe<Token>;
};

export type LoginOperatorInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginOperatorResponse = {
  __typename?: 'LoginOperatorResponse';
  identity?: Maybe<Identity>;
  operator?: Maybe<Operator>;
  token?: Maybe<Token>;
};

export type LoginPaxInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  identity: Identity;
  token: Token;
};

export enum LogisticsManagers {
  revel = 'revel',
  revel_rental = 'revel_rental',
  revel_session = 'revel_session',
}

export type ManualSystemClosure = {
  __typename?: 'ManualSystemClosure';
  closureReason?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  start: Scalars['DateTime']['output'];
};

export type MinimumAppVersions = {
  __typename?: 'MinimumAppVersions';
  android: Scalars['String']['output'];
  ios: Scalars['String']['output'];
  web: Scalars['String']['output'];
};

export type ModeratorRentalSelfie = {
  __typename?: 'ModeratorRentalSelfie';
  id: Scalars['ID']['output'];
  rider1ImageUrl: Scalars['String']['output'];
  rider2ImageUrl?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type ModeratorSelfieAssignmentsInput = {
  numRentals: Scalars['Int']['input'];
};

export type ModeratorSelfieQueueStats = {
  __typename?: 'ModeratorSelfieQueueStats';
  length: Scalars['Int']['output'];
};

export type ModeratorSelfieResultsInput = {
  rentalSelfieId: Scalars['ID']['input'];
  rider1valid: Scalars['Boolean']['input'];
  rider2valid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MostRecentNonDriverScheduledHours = {
  __typename?: 'MostRecentNonDriverScheduledHours';
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  shiftId: Scalars['ID']['output'];
};

export type MultiLineStringType = {
  __typename?: 'MultiLineStringType';
  coordinates: Array<Maybe<Scalars['Coordinates']['output']>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MultiPolygon = {
  __typename?: 'MultiPolygon';
  coordinates?: Maybe<Scalars['Coordinates']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptRentalTou?: Maybe<Scalars['Boolean']['output']>;
  acknowledgeDriverTrackLevel: Scalars['Boolean']['output'];
  activateSystemZoneDraft?: Maybe<OpsSystemZone>;
  actviateOpsServiceArea: ServiceArea;
  addDriverPaymentMethod: DriverPaymentMethod;
  addOpsHubResource: HubResource;
  addRentalIdToCommunityReport?: Maybe<CommunityReport>;
  addRenterSuspension?: Maybe<Ineligibility>;
  addRenterViolation?: Maybe<RenterViolation>;
  addRenterViolationImages?: Maybe<RenterViolationImagePresignedUrls>;
  agreeToLegalDocument?: Maybe<Scalars['Boolean']['output']>;
  agreeToReservationContract?: Maybe<Scalars['Boolean']['output']>;
  applyCXCredit?: Maybe<CreditMutationResponse>;
  assignIssue?: Maybe<IssueResponse>;
  assignVehicleToReservation: RentalReservation;
  assignVehicleToShift: DriverShift;
  cancelCXSubscription: CXOrderSubscriptionCancellation;
  cancelDriverOffboarding?: Maybe<Scalars['Boolean']['output']>;
  cancelDriverOpsDriverShift: DriverShift;
  cancelDriverReservation: RentalReservation;
  cancelDriverScheduledShift?: Maybe<DriverScheduledShift>;
  checkInReservation: RentalReservation;
  checkInShift: DriverShift;
  checkInShiftOverride: DriverShift;
  claimCXRentalAccountValidationAssignments?: Maybe<Array<RentalAccountValidation>>;
  claimCXRentalSelfieAssignments?: Maybe<Array<ModeratorRentalSelfie>>;
  claimOpenReservation: RentalReservation;
  claimOpenShiftTemplates: Array<RecurringShift>;
  clearLocationGroupResources?: Maybe<EmptyResponse>;
  clearRideAssignment?: Maybe<Scalars['Boolean']['output']>;
  closeIssue?: Maybe<IssueResponse>;
  closeTask?: Maybe<Task>;
  commandFleetResource?: Maybe<CommandResourceResponse>;
  commandLocationGroupResources?: Maybe<EmptyResponse>;
  commandResource: Scalars['Boolean']['output'];
  commandShiftFleetResource: Scalars['Boolean']['output'];
  completeSecurityDepositPayout?: Maybe<EmptyResponse>;
  completeShiftReview: ShiftReview;
  completeWorkOrder: WorkOrder;
  createAdhocShiftReview: ShiftReview;
  createAdicRegistration?: Maybe<AdicRegistration>;
  createAndStartWorkOrder?: Maybe<WorkOrder>;
  createAssignment?: Maybe<Assignment>;
  createAssignmentOperatorLocation?: Maybe<AssignmentOperatorLocation>;
  createCXInvoice?: Maybe<CXInvoice>;
  createDriverContractConversionForm?: Maybe<DriverContractConversionForm>;
  createDriverIncident: DriverIncident;
  createDriverIncidentDispute: DriverIncidentDispute;
  createDriverIssue: DriverIssue;
  createDriverPhoneMaskingSession?: Maybe<DriverPhoneMaskingSession>;
  createDriverReservation: RentalReservation;
  createDriverScheduledShift: DriverScheduledShift;
  createDriverSecurityDeposit: DriverInvoice;
  createDriverSetupIntent?: Maybe<DriverSetupIntent>;
  createDriverShift: DriverShift;
  createDriverTask: DriverTask;
  createFlag: Flag;
  createFleetResourceServiceLog?: Maybe<ServiceLog>;
  createLicense: License;
  createLocation: Location;
  createLocationGroup: LocationGroup;
  createLocationGroupResource?: Maybe<EmptyResponse>;
  createManualInvoice: DriverInvoice;
  createNote: Note;
  createOperator?: Maybe<Operator>;
  createOperatorLocation?: Maybe<OperatorLocation>;
  createOpsServiceArea: ServiceArea;
  createPhoneVerification: PhoneVerification;
  createRecurringReservations: Array<RecurringReservation>;
  createRecurringShift?: Maybe<RecurringShift>;
  createRepair: Repair;
  createReservation?: Maybe<Reservation>;
  createShiftFromOpenShift: DriverShift;
  createSystemZoneDraft?: Maybe<OpsSystemZone>;
  createTask?: Maybe<Task>;
  createTaskComment?: Maybe<TaskComment>;
  createWorkOrder?: Maybe<WorkOrder>;
  cxApplyCredit?: Maybe<CreditMutationResponse>;
  deleteAssignment?: Maybe<Assignment>;
  deleteDriverContractConversionForm?: Maybe<EmptyResponse>;
  deleteDriverPaymentMethod?: Maybe<EmptyResponse>;
  deleteLocation?: Maybe<EmptyResponse>;
  deleteLocationGroup?: Maybe<EmptyResponse>;
  deleteLocationGroupResource?: Maybe<EmptyResponse>;
  deleteNote?: Maybe<EmptyResponse>;
  deleteRecurringReservation?: Maybe<EmptyResponse>;
  deleteRecurringShift?: Maybe<EmptyResponse>;
  deleteRepair?: Maybe<EmptyResponse>;
  deleteWorkOrder?: Maybe<WorkOrder>;
  dismissDriverTask: DriverTask;
  downgradeWeeklyDriverSecurityDeposit?: Maybe<Scalars['Boolean']['output']>;
  driverCallOutShift: DriverShift;
  driverCreateSharedNumberVerification?: Maybe<EmptyResponse>;
  driverLogin: LoginDriverResponse;
  driverRequestTwoFactorLogin: DriverRequestPhoneLoginResponse;
  driverSignUp: DriverSignUpResponse;
  driverStartShift?: Maybe<DriverShift>;
  driverSubmitSharedNumberVerification?: Maybe<EmptyResponse>;
  driverSubmitTwoFactorLogin: DriverSubmitPhoneLoginResponse;
  endAssignment?: Maybe<Assignment>;
  endDriverScheduledShift: DriverScheduledShift;
  endDriverTask: DriverTask;
  endRenterSuspension?: Maybe<Ineligibility>;
  endReservation: RentalReservation;
  endReservationShift: DriverShift;
  endReservationShiftOverride: DriverShift;
  executeDevelopmentCommandDriverSchedule: Scalars['Boolean']['output'];
  executeDevelopmentCommandDriverScheduledShift: Scalars['Boolean']['output'];
  expireCXCredit?: Maybe<CXCreditBalance>;
  extendDriverUnscheduledBreak: DriverStopPoint;
  extendShift: DriverShift;
  generateDriverAssetPresignedPost: DriverAssetPresignedPost;
  generateIssueImageUploadTarget: IssueImageUploadTarget;
  googleLogin: LoginResponse;
  googleLoginCXAgent?: Maybe<LoginCXAgentResponse>;
  googleLoginOperator?: Maybe<LoginOperatorResponse>;
  grantDriverPunchAuthURL: DriverPunchAuthURL;
  initiateDriverOffboarding?: Maybe<Scalars['Boolean']['output']>;
  issueDriverCredit: DriverCredit;
  linkAndGeneratePassengerPassword?: Maybe<LinkAndGeneratePassengerPasswordResponse>;
  loginOperator?: Maybe<LoginOperatorResponse>;
  loginOtpPax?: Maybe<LoginResponse>;
  logoutPassengerDevice?: Maybe<EmptyResponse>;
  manipulateStopPoint: DriverStopPoint;
  openTask?: Maybe<Task>;
  outOfVehiclePunch: DriverShift;
  passengerCommandResource?: Maybe<CommandResourceResponse>;
  passengerSendSegmentEvent?: Maybe<PassengerSegmentDataResponse>;
  payDriverInvoice?: Maybe<DriverInvoice>;
  progressCurrentDriverStopPoint?: Maybe<DriverStopPoint>;
  publishAssignment?: Maybe<Assignment>;
  ratePassengerRideHail?: Maybe<Scalars['Boolean']['output']>;
  refundInvoice?: Maybe<CXInvoice>;
  removeAssignedVehicleFromReservation: RentalReservation;
  removeAssignedVehicleFromShift: DriverShift;
  removeOpsHubResource: HubResource;
  removeRenterDiscountRate?: Maybe<Scalars['Boolean']['output']>;
  removeRenterPhoneNumber?: Maybe<CXRenter>;
  removeRenterViolation?: Maybe<RenterViolation>;
  reportDriverShiftIssue: DriverShift;
  requestDriverDelete?: Maybe<Scalars['Boolean']['output']>;
  requestDriverRecall?: Maybe<EmptyResponse>;
  requestUnscheduledBreak: DriverShift;
  resendBackgroundCheck?: Maybe<Scalars['Boolean']['output']>;
  resendDocusignDocuments?: Maybe<Scalars['Boolean']['output']>;
  resolveDriverShiftIssue: DriverShift;
  restoreNote: Note;
  retryDriverInvoice?: Maybe<DriverInvoice>;
  retryInvoice?: Maybe<CXInvoice>;
  revealPhoneVerificationCode?: Maybe<VerificationCode>;
  reviewDriverIncidentDispute: DriverIncidentDispute;
  sendRenterPasswordReset?: Maybe<Scalars['Boolean']['output']>;
  setDriverPickupStopPointNoShow?: Maybe<DriverStopPoint>;
  setDriverStopPointAcknowledged?: Maybe<DriverStopPoint>;
  setDriverStopPointArrived?: Maybe<DriverStopPoint>;
  setDriverStopPointComplete?: Maybe<DriverStopPoint>;
  setRenterDiscountRate?: Maybe<DiscountRate>;
  skipDriverScheduledBreak: DriverStopPoint;
  skipWorkOrder: WorkOrder;
  snoozeDriverScheduledBreak: DriverStopPoint;
  softDeleteRenter?: Maybe<Scalars['Boolean']['output']>;
  standbyReservation: RentalReservation;
  startAssignment?: Maybe<Assignment>;
  startDriverScheduledShift: DriverScheduledShift;
  startDriverTask: DriverTask;
  startReservation: RentalReservation;
  startWorkOrder: WorkOrder;
  submitCXRentalAccountValidationResults: Scalars['Boolean']['output'];
  submitCXRentalSelfieResults: Scalars['Boolean']['output'];
  submitDriverScheduledShiftAttestation: DriverScheduledShiftAttestation;
  submitPhoneVerification: PhoneVerification;
  submitUpdateLicenseReview: License;
  transferReservation?: Maybe<Reservation>;
  unlinkPassenger?: Maybe<EmptyResponse>;
  unlockHelmetCaseInRental?: Maybe<Scalars['Boolean']['output']>;
  unlockVehicleForWorkOrder: WorkOrder;
  unlockVehicleInRental?: Maybe<Scalars['Boolean']['output']>;
  updateAssignment?: Maybe<Assignment>;
  updateCXOrder: CXOrder;
  updateCXSubscriptionCancellation: CXOrderSubscriptionCancellation;
  updateDriverInsuranceApprovals: Array<Maybe<DriverInsuranceApproval>>;
  updateDriverPaymentMethod: DriverPaymentMethod;
  updateDriverProfile: Driver;
  updateDriverReservation: RentalReservation;
  updateDriverScheduledShiftTime: DriverShift;
  updateDriverShift: DriverShift;
  updateDriverShiftDevice?: Maybe<DriverShiftDevice>;
  updateDriverShiftState: DriverShift;
  updateDriverTaskSchedule: DriverTask;
  updateDriverTaskTimes: DriverTask;
  updateDriverVehicleExclusions: Array<Maybe<DriverVehicleExclusionMakeAndModel>>;
  updateDriverVehiclePreference: DriverVehiclePreferenceResponse;
  updateFlag: Flag;
  updateInternalDriverProfile: Driver;
  updateLicense: License;
  updateLocation: Location;
  updateLocationGroup: LocationGroup;
  updateMyVehiclePreference: DriverVehiclePreferenceResponse;
  updateOperator?: Maybe<Operator>;
  updatePushNotificationReceived: Scalars['Boolean']['output'];
  updateRecurringReservation: RecurringReservation;
  updateRecurringShift?: Maybe<RecurringShift>;
  updateRecurringShiftSettings?: Maybe<ShiftSettings>;
  updateRenterPhoneNumber?: Maybe<CXRenter>;
  updateRentersEmail?: Maybe<CXRenter>;
  updateRentersName?: Maybe<CXRenter>;
  updateRepair: Repair;
  updateReservationBillingSettings?: Maybe<ReservationBillingSettings>;
  updateShiftClockTimes: DriverShift;
  updateShiftExemptions?: Maybe<ShiftSettings>;
  updateShiftNote: ShiftNote;
  updateShiftSettings?: Maybe<ShiftSettings>;
  updateShiftVehiclesLocation?: Maybe<Scalars['Boolean']['output']>;
  updateWorkOrder?: Maybe<WorkOrder>;
  voidDriverInvoice: DriverInvoice;
  voidInvoice?: Maybe<CXInvoice>;
};

export type MutationacceptRentalTouArgs = {
  id: Scalars['ID']['input'];
};

export type MutationactivateSystemZoneDraftArgs = {
  draftId: Scalars['ID']['input'];
};

export type MutationactviateOpsServiceAreaArgs = {
  input?: InputMaybe<OpsActviateServiceAreaInput>;
};

export type MutationaddDriverPaymentMethodArgs = {
  setupIntentId: Scalars['ID']['input'];
};

export type MutationaddOpsHubResourceArgs = {
  input: OpsHubResourceActionInput;
};

export type MutationaddRentalIdToCommunityReportArgs = {
  input: AddRentalIdToCommunityReportInput;
};

export type MutationaddRenterSuspensionArgs = {
  input: RenterSuspensionAddInput;
};

export type MutationaddRenterViolationArgs = {
  input: AddRenterViolationInput;
};

export type MutationaddRenterViolationImagesArgs = {
  input: AddRenterViolationImagesInput;
};

export type MutationagreeToLegalDocumentArgs = {
  legalDocumentId: Scalars['ID']['input'];
};

export type MutationagreeToReservationContractArgs = {
  input: AgreeToReservationContractInput;
};

export type MutationapplyCXCreditArgs = {
  input?: InputMaybe<CreditInput>;
};

export type MutationassignIssueArgs = {
  id: Scalars['ID']['input'];
};

export type MutationassignVehicleToReservationArgs = {
  fleetResourceId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MutationassignVehicleToShiftArgs = {
  fleetResourceId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MutationcancelCXSubscriptionArgs = {
  id: Scalars['ID']['input'];
  input: CXOrderSubscriptionCancellationInput;
};

export type MutationcancelDriverOffboardingArgs = {
  driverId: Scalars['ID']['input'];
};

export type MutationcancelDriverOpsDriverShiftArgs = {
  input: CancelDriverOpsDriverShiftInput;
};

export type MutationcancelDriverReservationArgs = {
  input: CancelDriverReservationInput;
};

export type MutationcancelDriverScheduledShiftArgs = {
  input: CancelDriverScheduledShiftInput;
};

export type MutationcheckInReservationArgs = {
  input: CheckInReservationInput;
};

export type MutationcheckInShiftArgs = {
  input: CheckInShiftInput;
};

export type MutationcheckInShiftOverrideArgs = {
  input: CheckInShiftOverrideInput;
};

export type MutationclaimCXRentalAccountValidationAssignmentsArgs = {
  input: ModeratorSelfieAssignmentsInput;
};

export type MutationclaimCXRentalSelfieAssignmentsArgs = {
  input: ModeratorSelfieAssignmentsInput;
};

export type MutationclaimOpenReservationArgs = {
  input: ClaimOpenReservationInput;
};

export type MutationclaimOpenShiftTemplatesArgs = {
  input: ClaimOpenShiftTemplatesInput;
};

export type MutationclearLocationGroupResourcesArgs = {
  locationGroupId: Scalars['ID']['input'];
};

export type MutationclearRideAssignmentArgs = {
  rideHailId: Scalars['ID']['input'];
};

export type MutationcloseIssueArgs = {
  input?: InputMaybe<CloseIssueInput>;
};

export type MutationcloseTaskArgs = {
  closeReason: TaskCloseReason;
  id: Scalars['ID']['input'];
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationcommandFleetResourceArgs = {
  id: Scalars['ID']['input'];
  input: commandFleetResourceInput;
};

export type MutationcommandLocationGroupResourcesArgs = {
  command: NiuCommand;
  locationGroupId: Scalars['ID']['input'];
};

export type MutationcommandResourceArgs = {
  id: Scalars['ID']['input'];
  input: CommandResourceInput;
};

export type MutationcommandShiftFleetResourceArgs = {
  input: CommandShiftFleetResourceInput;
  shiftId: Scalars['ID']['input'];
};

export type MutationcompleteSecurityDepositPayoutArgs = {
  payoutRecordId: Scalars['ID']['input'];
};

export type MutationcompleteShiftReviewArgs = {
  id: Scalars['ID']['input'];
  input: CompleteShiftReviewInput;
};

export type MutationcompleteWorkOrderArgs = {
  id: Scalars['ID']['input'];
  ignoreTelematicsErrors?: InputMaybe<Scalars['Boolean']['input']>;
  input?: InputMaybe<UpdateWorkOrderInput>;
};

export type MutationcreateAdhocShiftReviewArgs = {
  input: CreateAdhocShiftReviewInput;
};

export type MutationcreateAdicRegistrationArgs = {
  input: CreateAdicRegistrationInput;
};

export type MutationcreateAndStartWorkOrderArgs = {
  assignmentId: Scalars['ID']['input'];
  createdFor?: InputMaybe<WorkOrderCreationContext>;
  fleetResourceId: Scalars['ID']['input'];
};

export type MutationcreateAssignmentArgs = {
  input: CreateAssignmentInput;
};

export type MutationcreateAssignmentOperatorLocationArgs = {
  assignmentId: Scalars['ID']['input'];
  location: PointInput;
};

export type MutationcreateCXInvoiceArgs = {
  input: CreateInvoiceInput;
};

export type MutationcreateDriverContractConversionFormArgs = {
  driverId: Scalars['ID']['input'];
  input: CreateDriverContractConversionFormInput;
};

export type MutationcreateDriverIncidentArgs = {
  input: CreateDriverIncidentInput;
};

export type MutationcreateDriverIncidentDisputeArgs = {
  input: CreateDriverIncidentDisputeInput;
};

export type MutationcreateDriverIssueArgs = {
  input: CreateDriverIssueInput;
};

export type MutationcreateDriverPhoneMaskingSessionArgs = {
  stopPointId: Scalars['ID']['input'];
};

export type MutationcreateDriverReservationArgs = {
  input: CreateDriverReservationInput;
};

export type MutationcreateDriverScheduledShiftArgs = {
  input: CreateDriverScheduledShiftInput;
};

export type MutationcreateDriverSecurityDepositArgs = {
  input?: InputMaybe<CreateSecurityDepositInput>;
};

export type MutationcreateDriverShiftArgs = {
  input: CreateDriverShiftInput;
};

export type MutationcreateDriverTaskArgs = {
  input: CreateDriverTaskInput;
};

export type MutationcreateFlagArgs = {
  input: CreateFlagInput;
};

export type MutationcreateFleetResourceServiceLogArgs = {
  fleetResourceId: Scalars['String']['input'];
  input: CreateServiceLogInput;
};

export type MutationcreateLicenseArgs = {
  input?: InputMaybe<CreateLicenseInput>;
};

export type MutationcreateLocationArgs = {
  input: LocationInput;
};

export type MutationcreateLocationGroupArgs = {
  input: LocationGroupInput;
};

export type MutationcreateLocationGroupResourceArgs = {
  locationGroupId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};

export type MutationcreateManualInvoiceArgs = {
  input: CreateManualInvoiceInput;
};

export type MutationcreateNoteArgs = {
  input?: InputMaybe<CreateNoteInput>;
};

export type MutationcreateOperatorArgs = {
  input: CreateOperatorInput;
};

export type MutationcreateOperatorLocationArgs = {
  location: PointInput;
};

export type MutationcreateOpsServiceAreaArgs = {
  input?: InputMaybe<OpsCreateServiceAreaInput>;
};

export type MutationcreatePhoneVerificationArgs = {
  input: PhoneVerificationInput;
};

export type MutationcreateRecurringReservationsArgs = {
  input: CreateRecurringReservationsInput;
};

export type MutationcreateRecurringShiftArgs = {
  input: CreateRecurringShiftInput;
};

export type MutationcreateRepairArgs = {
  input: CreateRepairInput;
};

export type MutationcreateReservationArgs = {
  fleetResourceId: Scalars['ID']['input'];
};

export type MutationcreateShiftFromOpenShiftArgs = {
  openShiftId: Scalars['ID']['input'];
};

export type MutationcreateSystemZoneDraftArgs = {
  input?: InputMaybe<OpsCreateSystemZoneDraftInput>;
};

export type MutationcreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationcreateTaskCommentArgs = {
  comment: Scalars['String']['input'];
  taskId: Scalars['ID']['input'];
};

export type MutationcreateWorkOrderArgs = {
  assignmentId: Scalars['ID']['input'];
  createdFor?: InputMaybe<WorkOrderCreationContext>;
  fleetResourceId: Scalars['ID']['input'];
};

export type MutationcxApplyCreditArgs = {
  input?: InputMaybe<CreditInput>;
};

export type MutationdeleteAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteDriverContractConversionFormArgs = {
  driverId: Scalars['ID']['input'];
};

export type MutationdeleteDriverPaymentMethodArgs = {
  paymentMethodId: Scalars['ID']['input'];
};

export type MutationdeleteLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteLocationGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteLocationGroupResourceArgs = {
  locationGroupId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};

export type MutationdeleteNoteArgs = {
  noteId: Scalars['ID']['input'];
};

export type MutationdeleteRecurringReservationArgs = {
  deleteFutureReservations?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationdeleteRecurringShiftArgs = {
  deleteFutureShifts?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationdeleteRepairArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteWorkOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdismissDriverTaskArgs = {
  id: Scalars['ID']['input'];
  input: DismissDriverTaskInput;
};

export type MutationdowngradeWeeklyDriverSecurityDepositArgs = {
  driverId: Scalars['ID']['input'];
};

export type MutationdriverCallOutShiftArgs = {
  input: CancelDriverShiftInput;
};

export type MutationdriverCreateSharedNumberVerificationArgs = {
  phoneNumber: Scalars['String']['input'];
};

export type MutationdriverLoginArgs = {
  googleIdToken: Scalars['ID']['input'];
  platform: Scalars['String']['input'];
};

export type MutationdriverRequestTwoFactorLoginArgs = {
  phoneNumber: Scalars['String']['input'];
};

export type MutationdriverSignUpArgs = {
  input: DriverSignUpInput;
};

export type MutationdriverStartShiftArgs = {
  input: DriverStartShiftInput;
};

export type MutationdriverSubmitSharedNumberVerificationArgs = {
  phoneNumber: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationdriverSubmitTwoFactorLoginArgs = {
  input: DriverSubmitTwoFactorLoginInput;
};

export type MutationendAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationendDriverScheduledShiftArgs = {
  input: EndDriverScheduledShiftInput;
};

export type MutationendDriverTaskArgs = {
  id: Scalars['ID']['input'];
  input: EndDriverTaskInput;
};

export type MutationendRenterSuspensionArgs = {
  input: RenterSuspensionEndInput;
};

export type MutationendReservationArgs = {
  input: EndReservationInput;
};

export type MutationendReservationShiftArgs = {
  input: CheckInShiftInput;
};

export type MutationendReservationShiftOverrideArgs = {
  input: CheckInShiftOverrideInput;
};

export type MutationexecuteDevelopmentCommandDriverScheduleArgs = {
  input: DevelopmentCommandDriverScheduleInput;
};

export type MutationexecuteDevelopmentCommandDriverScheduledShiftArgs = {
  input: DevelopmentCommandDriverScheduledShiftInput;
};

export type MutationexpireCXCreditArgs = {
  id: Scalars['ID']['input'];
};

export type MutationextendDriverUnscheduledBreakArgs = {
  stopPointId: Scalars['ID']['input'];
};

export type MutationextendShiftArgs = {
  shiftId: Scalars['ID']['input'];
};

export type MutationgenerateDriverAssetPresignedPostArgs = {
  contentType?: InputMaybe<Scalars['String']['input']>;
};

export type MutationgoogleLoginArgs = {
  googleIdToken: Scalars['ID']['input'];
};

export type MutationgoogleLoginCXAgentArgs = {
  input?: InputMaybe<GoogleLoginCXAgentInput>;
};

export type MutationgoogleLoginOperatorArgs = {
  input?: InputMaybe<GoogleLoginOperatorInput>;
};

export type MutationinitiateDriverOffboardingArgs = {
  driverId: Scalars['ID']['input'];
};

export type MutationissueDriverCreditArgs = {
  input: IssueDriverCreditInput;
};

export type MutationlinkAndGeneratePassengerPasswordArgs = {
  input: LinkAndGeneratePassengerPasswordInput;
};

export type MutationloginOperatorArgs = {
  input?: InputMaybe<LoginOperatorInput>;
};

export type MutationloginOtpPaxArgs = {
  input?: InputMaybe<LoginPaxInput>;
};

export type MutationmanipulateStopPointArgs = {
  manipulationType: StopPointManipulationTypes;
  stopPointId: Scalars['ID']['input'];
};

export type MutationopenTaskArgs = {
  id: Scalars['ID']['input'];
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationoutOfVehiclePunchArgs = {
  input: OutOfVehiclePunchInput;
};

export type MutationpassengerCommandResourceArgs = {
  input: PassengerCommandResourceInput;
};

export type MutationpassengerSendSegmentEventArgs = {
  input?: InputMaybe<PassengerSegmentInput>;
};

export type MutationpayDriverInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type MutationprogressCurrentDriverStopPointArgs = {
  currentStopPoint: Scalars['ID']['input'];
  updateToState: DriverStopPointStates;
};

export type MutationpublishAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationratePassengerRideHailArgs = {
  input: RatePassengerRideHailInput;
  rideHailId: Scalars['ID']['input'];
};

export type MutationrefundInvoiceArgs = {
  input: RefundInvoiceInput;
};

export type MutationremoveAssignedVehicleFromReservationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationremoveAssignedVehicleFromShiftArgs = {
  id: Scalars['ID']['input'];
};

export type MutationremoveOpsHubResourceArgs = {
  input: OpsHubResourceActionInput;
};

export type MutationremoveRenterDiscountRateArgs = {
  input?: InputMaybe<RemoveRenterDiscountRateInput>;
};

export type MutationremoveRenterPhoneNumberArgs = {
  input: RemoveRenterPhoneNumberInput;
};

export type MutationremoveRenterViolationArgs = {
  input: RemoveRenterViolationInput;
};

export type MutationreportDriverShiftIssueArgs = {
  input: ReportDriverShiftIssueInput;
};

export type MutationrequestDriverDeleteArgs = {
  input?: InputMaybe<RequestDriverDeleteInput>;
};

export type MutationrequestDriverRecallArgs = {
  shiftId: Scalars['ID']['input'];
};

export type MutationrequestUnscheduledBreakArgs = {
  isUrgent?: InputMaybe<Scalars['Boolean']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type MutationresendBackgroundCheckArgs = {
  input: ResendBackgroundCheckInput;
};

export type MutationresendDocusignDocumentsArgs = {
  input: ResendDocusignDocumentsInput;
};

export type MutationresolveDriverShiftIssueArgs = {
  endLocation?: InputMaybe<PointInput>;
  shiftId: Scalars['ID']['input'];
};

export type MutationrestoreNoteArgs = {
  noteId: Scalars['ID']['input'];
};

export type MutationretryDriverInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type MutationretryInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type MutationrevealPhoneVerificationCodeArgs = {
  phoneVerificationId: Scalars['ID']['input'];
};

export type MutationreviewDriverIncidentDisputeArgs = {
  input?: InputMaybe<ReviewDriverIncidentDisputeInput>;
};

export type MutationsendRenterPasswordResetArgs = {
  renterId: Scalars['ID']['input'];
};

export type MutationsetDriverPickupStopPointNoShowArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsetDriverStopPointAcknowledgedArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsetDriverStopPointArrivedArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsetDriverStopPointCompleteArgs = {
  location?: InputMaybe<PointInput>;
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsetRenterDiscountRateArgs = {
  input?: InputMaybe<SetRenterDiscountRateInput>;
};

export type MutationskipDriverScheduledBreakArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationskipWorkOrderArgs = {
  id: Scalars['ID']['input'];
  ignoreTelematicsErrors?: InputMaybe<Scalars['Boolean']['input']>;
  skipReason: WorkOrderSkipReason;
};

export type MutationsnoozeDriverScheduledBreakArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsoftDeleteRenterArgs = {
  input: SoftDeleteRenterInput;
};

export type MutationstandbyReservationArgs = {
  reservationId: Scalars['ID']['input'];
};

export type MutationstartAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationstartDriverScheduledShiftArgs = {
  input: StartDriverScheduledShiftInput;
};

export type MutationstartDriverTaskArgs = {
  id: Scalars['ID']['input'];
  input: StartDriverTaskInput;
};

export type MutationstartReservationArgs = {
  input: StartReservationInput;
};

export type MutationstartWorkOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationsubmitCXRentalAccountValidationResultsArgs = {
  input: Array<RentalAccountValidationResultsInput>;
};

export type MutationsubmitCXRentalSelfieResultsArgs = {
  input: Array<ModeratorSelfieResultsInput>;
};

export type MutationsubmitDriverScheduledShiftAttestationArgs = {
  input: SubmitDriverAttestationInput;
};

export type MutationsubmitPhoneVerificationArgs = {
  input: PhoneVerificationSubmitInput;
};

export type MutationsubmitUpdateLicenseReviewArgs = {
  id: Scalars['ID']['input'];
  review?: InputMaybe<ReviewLicenseInput>;
  updatedLicense?: InputMaybe<UpdateLicenseInput>;
};

export type MutationtransferReservationArgs = {
  id: Scalars['ID']['input'];
  newOperatorId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationunlinkPassengerArgs = {
  input: UnlinkPassengerInput;
};

export type MutationunlockHelmetCaseInRentalArgs = {
  rentalId: Scalars['ID']['input'];
};

export type MutationunlockVehicleForWorkOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationunlockVehicleInRentalArgs = {
  rentalId: Scalars['ID']['input'];
};

export type MutationupdateAssignmentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAssignmentInput;
};

export type MutationupdateCXOrderArgs = {
  id: Scalars['ID']['input'];
  input: CXOrderUpdateInput;
};

export type MutationupdateCXSubscriptionCancellationArgs = {
  id: Scalars['ID']['input'];
  input: CXOrderSubscriptionCancellationInput;
};

export type MutationupdateDriverInsuranceApprovalsArgs = {
  input: UpdateDriverInsuranceApprovalInput;
};

export type MutationupdateDriverPaymentMethodArgs = {
  input: UpdateDriverPaymentMethodInput;
};

export type MutationupdateDriverProfileArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDriverProfileInput;
};

export type MutationupdateDriverReservationArgs = {
  input: UpdateDriverReservationInput;
};

export type MutationupdateDriverScheduledShiftTimeArgs = {
  input: UpdateDriverScheduledShiftTimeInput;
};

export type MutationupdateDriverShiftArgs = {
  input: UpdateDriverShiftInput;
};

export type MutationupdateDriverShiftDeviceArgs = {
  input: UpdateDriverShiftDeviceInput;
  shiftId: Scalars['ID']['input'];
};

export type MutationupdateDriverShiftStateArgs = {
  input: UpdateDriverShiftStateInput;
};

export type MutationupdateDriverTaskScheduleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDriverTaskScheduleInput;
};

export type MutationupdateDriverTaskTimesArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDriverTaskTimesInput;
};

export type MutationupdateDriverVehicleExclusionsArgs = {
  input?: InputMaybe<UpdateDriverVehicleExclusionsInput>;
};

export type MutationupdateDriverVehiclePreferenceArgs = {
  input: UpdateDriverVehiclePreferenceInput;
};

export type MutationupdateFlagArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFlagInput;
};

export type MutationupdateInternalDriverProfileArgs = {
  id: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
};

export type MutationupdateLicenseArgs = {
  id: Scalars['ID']['input'];
  updatedLicense?: InputMaybe<UpdateLicenseInput>;
};

export type MutationupdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: LocationInput;
};

export type MutationupdateLocationGroupArgs = {
  id: Scalars['ID']['input'];
  input: LocationGroupInput;
};

export type MutationupdateMyVehiclePreferenceArgs = {
  input: UpdateDriverVehiclePreferenceInput;
};

export type MutationupdateOperatorArgs = {
  input: UpdateOperatorInput;
};

export type MutationupdatePushNotificationReceivedArgs = {
  input: UpdatePushNotificationReceivedInput;
  shiftId: Scalars['ID']['input'];
};

export type MutationupdateRecurringReservationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRecurringReservationInput;
};

export type MutationupdateRecurringShiftArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRecurringShiftInput;
};

export type MutationupdateRecurringShiftSettingsArgs = {
  id: Scalars['ID']['input'];
  input: ShiftSettingsInput;
};

export type MutationupdateRenterPhoneNumberArgs = {
  input: UpdateRenterPhoneNumberInput;
  renterId: Scalars['ID']['input'];
};

export type MutationupdateRentersEmailArgs = {
  input: updateRentersEmailInput;
};

export type MutationupdateRentersNameArgs = {
  input: updateRentersNameInput;
};

export type MutationupdateRepairArgs = {
  input: UpdateRepairInput;
};

export type MutationupdateReservationBillingSettingsArgs = {
  input: UpdateReservationBillingSettingsInput;
};

export type MutationupdateShiftClockTimesArgs = {
  input: UpdateShiftClockTimesInput;
};

export type MutationupdateShiftExemptionsArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<ShiftExemptionInput>;
};

export type MutationupdateShiftNoteArgs = {
  id: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};

export type MutationupdateShiftSettingsArgs = {
  id: Scalars['ID']['input'];
  input: ShiftSettingsInput;
};

export type MutationupdateShiftVehiclesLocationArgs = {
  input: UpdateShiftVehicleLocationInput;
};

export type MutationupdateWorkOrderArgs = {
  input: UpdateWorkOrderInput;
  workOrderId: Scalars['ID']['input'];
};

export type MutationvoidDriverInvoiceArgs = {
  input: VoidDriverInvoiceInput;
  invoiceId: Scalars['ID']['input'];
};

export type MutationvoidInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export enum NiuCommand {
  ALARM = 'ALARM',
  ALARM_DISABLE = 'ALARM_DISABLE',
  DISABLE_485CMD = 'DISABLE_485CMD',
  ENABLE_485CMD = 'ENABLE_485CMD',
  END = 'END',
  IGNITION_OFF = 'IGNITION_OFF',
  IGNITION_ON = 'IGNITION_ON',
  LOCATING = 'LOCATING',
  REBOOT = 'REBOOT',
  SADDLE_ON = 'SADDLE_ON',
  SECURE_OFF = 'SECURE_OFF',
  SECURE_ON = 'SECURE_ON',
  START = 'START',
  TAILBOX_OFF = 'TAILBOX_OFF',
  TAILBOX_ON = 'TAILBOX_ON',
}

export type Note = {
  __typename?: 'Note';
  createdAt: Scalars['DateTime']['output'];
  createdByIdentity?: Maybe<Identity>;
  createdByIdentityId: Scalars['ID']['output'];
  deletedAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  note: Scalars['String']['output'];
  objectId?: Maybe<Scalars['ID']['output']>;
  objectType?: Maybe<NoteObjectType>;
};

export type NoteFilters = {
  isResolved?: InputMaybe<Scalars['Boolean']['input']>;
  objectId: Scalars['ID']['input'];
  objectType: NoteObjectType;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum NoteObjectType {
  driver = 'driver',
  driver_incident = 'driver_incident',
  reservation = 'reservation',
  scheduled_shift = 'scheduled_shift',
}

export type NoteResponse = {
  __typename?: 'NoteResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<Note>>;
};

export enum OSName {
  android = 'android',
  ios = 'ios',
}

export type OnFleetResourceEventInput = {
  resourceId?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type OnParkingLotEventInput = {
  systemId: Scalars['ID']['input'];
};

export type OpenScheduledShift = {
  __typename?: 'OpenScheduledShift';
  dayKey: Scalars['Int']['output'];
  depotId?: Maybe<Scalars['String']['output']>;
  durationMinutes: Scalars['Int']['output'];
  isAvailable: Scalars['Boolean']['output'];
  isRecurringAvailable: Scalars['Boolean']['output'];
  payCents: Scalars['Int']['output'];
  premiumCents: Scalars['Int']['output'];
  priorityPoints: Scalars['Int']['output'];
  startAt: Scalars['DateTime']['output'];
  startHour: Scalars['Int']['output'];
  startMinute: Scalars['Int']['output'];
  systemId: Scalars['String']['output'];
};

export type OpenScheduledShiftInput = {
  depotId?: InputMaybe<Scalars['String']['input']>;
  driverId?: InputMaybe<Scalars['String']['input']>;
  durationMinutes: Scalars['Int']['input'];
  granularityMinutes?: InputMaybe<Scalars['Int']['input']>;
  startAtGte?: InputMaybe<Scalars['String']['input']>;
  startAtLt?: InputMaybe<Scalars['String']['input']>;
  systemId: Scalars['String']['input'];
  weekOf: Scalars['String']['input'];
};

export type OpenShift = {
  __typename?: 'OpenShift';
  estimatedPay: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  totalProjectedEarnings: Scalars['Int']['output'];
};

export type OpenShiftTemplate = {
  __typename?: 'OpenShiftTemplate';
  depotId?: Maybe<Scalars['ID']['output']>;
  endTime: Scalars['String']['output'];
  endWeekday: Scalars['Int']['output'];
  estimatedPay: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  scheduleCapacity?: Maybe<Scalars['Int']['output']>;
  startTime: Scalars['String']['output'];
  startWeekday: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
};

export type Operator = {
  __typename?: 'Operator';
  active?: Maybe<Scalars['Boolean']['output']>;
  defaultSystem?: Maybe<System>;
  defaultSystemId?: Maybe<Scalars['ID']['output']>;
  dev?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  identity?: Maybe<Identity>;
  roles?: Maybe<Array<Maybe<RoleMember>>>;
};

export type OperatorFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OperatorLocation = {
  __typename?: 'OperatorLocation';
  id: Scalars['ID']['output'];
  location: Point;
  operator?: Maybe<Operator>;
  operatorId: Scalars['ID']['output'];
};

export type OperatorLocationFilters = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  operatorId?: InputMaybe<Scalars['ID']['input']>;
};

export enum OperatorReservationStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export type OperatorReservationsFilters = {
  operatorId: Scalars['ID']['input'];
  status: OperatorReservationStatus;
};

export type OpsActviateServiceAreaInput = {
  product: ServiceAreaProduct;
  serviceAreaId: Scalars['ID']['input'];
};

export type OpsCreateServiceAreaInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  product: ServiceAreaProduct;
  serviceArea: Scalars['Geojson']['input'];
  serviceAreaInverted?: InputMaybe<Scalars['Geojson']['input']>;
  systemId: Scalars['ID']['input'];
};

export type OpsCreateSystemZoneDraftInput = {
  areaGeojson: Scalars['Geojson']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  zoneId: Scalars['ID']['input'];
};

export type OpsHubResourceActionInput = {
  hubId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};

export type OpsHubResourceFilters = {
  excludeTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inSession?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsServiceAreaFilters = {
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  product?: InputMaybe<ServiceAreaProduct>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsSystemServiceAreaHistoryFilters = {
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  product?: InputMaybe<ServiceAreaProduct>;
};

export type OpsSystemZone = {
  __typename?: 'OpsSystemZone';
  areaGeojson?: Maybe<Scalars['Geojson']['output']>;
  areaGeojsonString: Scalars['String']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  draft: OpsSystemZoneDraft;
  drafts: SystemZoneDraftsList;
  id: Scalars['ID']['output'];
  systemId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
  zoneType: OpsSystemZoneType;
};

export type OpsSystemZoneDraft = {
  __typename?: 'OpsSystemZoneDraft';
  areaGeojson: Scalars['Geojson']['output'];
  areaGeojsonString: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type OpsSystemZoneFilters = {
  systemId: Scalars['ID']['input'];
};

export enum OpsSystemZoneType {
  airport = 'airport',
  androidfix = 'androidfix',
  balancing = 'balancing',
  demand_pricing = 'demand_pricing',
  discounted_moped_zone = 'discounted_moped_zone',
  distributed_fleet_start_shift_zone = 'distributed_fleet_start_shift_zone',
  driver_ride_hail_area = 'driver_ride_hail_area',
  park_mobile = 'park_mobile',
  pricing = 'pricing',
  ride_hail_restriction = 'ride_hail_restriction',
  rideable_area = 'rideable_area',
  territory_fee = 'territory_fee',
  ticket_warning_zone = 'ticket_warning_zone',
}

export type OutOfVehiclePunchInput = {
  punchAuth: Scalars['String']['input'];
  punchField: Scalars['String']['input'];
  shiftId: Scalars['ID']['input'];
  userLocation?: InputMaybe<PointInput>;
};

export type PageInfo = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type PagedResult = {
  count: Scalars['Int']['output'];
};

export type Pagination = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginationFilter = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PassengerAppAccount = {
  __typename?: 'PassengerAppAccount';
  createdAt: Scalars['DateTime']['output'];
  deviceType?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  linkedAt?: Maybe<Scalars['DateTime']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PassengerAppAccountFilters = {
  resourceId?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PassengerCommandResourceInput = {
  command: TeslaCommand;
  heater?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
};

export type PassengerRenter = {
  __typename?: 'PassengerRenter';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
};

export type PassengerRideHail = {
  __typename?: 'PassengerRideHail';
  id: Scalars['ID']['output'];
  renter: PassengerRenter;
  rideHailOffer?: Maybe<PassengerRideHailOffer>;
  state?: Maybe<CXRideHailStates>;
  vehicle?: Maybe<PassengerVehicle>;
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type PassengerRideHailEvent = {
  __typename?: 'PassengerRideHailEvent';
  eventType?: Maybe<PassengerRideHailEventType>;
  rideHail?: Maybe<PassengerRideHail>;
};

export enum PassengerRideHailEventType {
  ACTIVE = 'ACTIVE',
  ARRIVED = 'ARRIVED',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  CREATED = 'CREATED',
  DISPATCHED = 'DISPATCHED',
  DRIVER_LOCATION_UPDATE = 'DRIVER_LOCATION_UPDATE',
  DRIVER_REASSIGNED = 'DRIVER_REASSIGNED',
  FAILED = 'FAILED',
  MATCHING = 'MATCHING',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  RIDE_ISSUE = 'RIDE_ISSUE',
}

export type PassengerRideHailOffer = {
  __typename?: 'PassengerRideHailOffer';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  estimatedArrivalTime: Scalars['DateTime']['output'];
  estimatedRemainingTripDurationMinutes?: Maybe<Scalars['Int']['output']>;
  estimatedTripDurationMinutes: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
};

export type PassengerSegmentDataResponse = {
  __typename?: 'PassengerSegmentDataResponse';
  data?: Maybe<Scalars['SegmentData']['output']>;
  segmentEventName?: Maybe<Scalars['String']['output']>;
};

export type PassengerSegmentInput = {
  data?: InputMaybe<Scalars['SegmentData']['input']>;
  segmentEventName?: InputMaybe<Scalars['String']['input']>;
};

export type PassengerVehicle = {
  __typename?: 'PassengerVehicle';
  id: Scalars['ID']['output'];
  licensePlate: Scalars['String']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  passengerCapacity: Scalars['Int']['output'];
  wheelchairAccessible: Scalars['Boolean']['output'];
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  amount: Scalars['Int']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  declineReason?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lastFour: Scalars['String']['output'];
  paymentMethodId: Scalars['ID']['output'];
  refund?: Maybe<Refund>;
  status: Scalars['String']['output'];
  wallet?: Maybe<PaymentMethodWalletType>;
};

export enum PaymentMethodWalletType {
  apple_pay = 'apple_pay',
  google_pay = 'google_pay',
}

export enum PerformanceWarningState {
  danger = 'danger',
  warning = 'warning',
}

export type PhoneValidations = {
  __typename?: 'PhoneValidations';
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type PhoneVerification = {
  __typename?: 'PhoneVerification';
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type PhoneVerificationInput = {
  phoneNumber: Scalars['String']['input'];
};

export type PhoneVerificationSubmitInput = {
  key: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type PhoneVerificationsFilters = {
  context?: InputMaybe<SMSAuthAttemptUserContext>;
  renterId?: InputMaybe<Scalars['ID']['input']>;
};

export type PickupTimer = {
  __typename?: 'PickupTimer';
  noShowErrorDurationSeconds: Scalars['Int']['output'];
  noShowTriggerDurationSeconds: Scalars['Int']['output'];
  noShowWarnDurationSeconds: Scalars['Int']['output'];
  timeToAcknowledgeSeconds: Scalars['Int']['output'];
};

export type Point = {
  __typename?: 'Point';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type PointInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type PriceModification = {
  __typename?: 'PriceModification';
  amount: Scalars['Int']['output'];
  coupon?: Maybe<Coupon>;
  couponId?: Maybe<Scalars['String']['output']>;
  dynamicRateDiscount?: Maybe<DynamicRateDiscount>;
  dynamicRateDiscountId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind?: Maybe<PriceModificationKind>;
  offerId: Scalars['String']['output'];
  renterDiscountRate?: Maybe<RenterDiscountRate>;
  renterDiscountRateId?: Maybe<Scalars['String']['output']>;
};

export enum PriceModificationKind {
  boost_incentive = 'boost_incentive',
  incentive = 'incentive',
  min_fare = 'min_fare',
  non_pax_pickup_time = 'non_pax_pickup_time',
  price_experiment = 'price_experiment',
  price_multiplier = 'price_multiplier',
  ride_type = 'ride_type',
  scheduled_ride_fee = 'scheduled_ride_fee',
  service_fee = 'service_fee',
  zone_pricing = 'zone_pricing',
}

export type ProblemReport = {
  __typename?: 'ProblemReport';
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Resolver fetched fields */
  fleetResource?: Maybe<FleetResource>;
  fleetResourceId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  photos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  rental?: Maybe<CXRental>;
  rentalId: Scalars['ID']['output'];
  renter?: Maybe<CXRenter>;
  renterId: Scalars['ID']['output'];
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Maybe<TagCX>>>;
  tasks: Array<Task>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type ProblemReporttasksArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type ProblemReportsFilters = {
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum PushTokenTypes {
  apn = 'apn',
  expo = 'expo',
  fcm = 'fcm',
}

export type Query = {
  __typename?: 'Query';
  calculateDriverVehicleCreditAmount: DriverCreditAmountResponse;
  currentDriverTrackLevel?: Maybe<DriverTrackLevel>;
  driverDepots: DriverDepotPagedResult;
  driverIncident: DriverIncident;
  driverIncidentDispute: DriverIncidentDispute;
  driverIncidentDisputes: DriverIncidentDisputesResponse;
  driverIncidents: DriverIncidentsResponse;
  driverIssue: DriverIssue;
  driverIssues: DriverIssueResponse;
  driverPayment?: Maybe<DriverPayment>;
  driverPayments?: Maybe<Array<DriverPayment>>;
  driverScheduledShift: DriverScheduledShift;
  driverScheduledShifts: DriverScheduledShiftsPagedResult;
  driverTasks: DriverTaskResponse;
  driverTrackLevels: DriverTrackLevelResponse;
  getAboundToken?: Maybe<AboundToken>;
  getActiveRentals?: Maybe<ActiveCXRentalsList>;
  getAssignment: Assignment;
  getAssignmentOperatorLocation?: Maybe<AssignmentOperatorLocation>;
  getAssignmentOperatorLocations?: Maybe<Array<Maybe<AssignmentOperatorLocation>>>;
  getAssignmentResources: Array<FleetResource>;
  /** @deprecated temp deprecation for deployment */
  getAssignments: Array<Assignment>;
  getAssignmentsV2: AssignmentList;
  getBalancerMap: Scalars['JSON']['output'];
  getCXActiveDynamicRateDiscount?: Maybe<DynamicRateDiscount>;
  getCXAgent?: Maybe<CXAgent>;
  getCXAgents: Array<CXAgent>;
  getCXCreditHistory?: Maybe<Array<CXCreditBalance>>;
  getCXDriverShifts?: Maybe<Array<CXShift>>;
  getCXDynamicRateDiscount?: Maybe<DynamicRateDiscount>;
  getCXDynamicRateDiscounts?: Maybe<Array<Maybe<DynamicRateDiscount>>>;
  getCXFleetResource?: Maybe<FleetResource>;
  getCXFleetResources?: Maybe<Array<Maybe<FleetResource>>>;
  getCXHub: Hub;
  getCXHubs?: Maybe<Array<Hub>>;
  getCXInvoicePDF: Scalars['String']['output'];
  getCXInvoiceReasons: Array<CXInvoiceReason>;
  getCXMobileVersions?: Maybe<CXMobileVersions>;
  getCXPricingBySystem?: Maybe<SystemPricing>;
  getCXProblemReport?: Maybe<ProblemReport>;
  getCXProblemReports?: Maybe<Array<Maybe<ProblemReport>>>;
  getCXRental?: Maybe<CXRental>;
  getCXRentalAccountValidationQueueStats: ModeratorSelfieQueueStats;
  getCXRentalSelfie?: Maybe<ModeratorRentalSelfie>;
  getCXRentalSelfieQueueStats?: Maybe<ModeratorSelfieQueueStats>;
  getCXRentals?: Maybe<RentalsList>;
  getCXRenter?: Maybe<CXRenter>;
  getCXRenters?: Maybe<RentersList>;
  getCXRentersDiscountRateHistory?: Maybe<Array<CXRenterDiscountRate>>;
  getCXRentersEmailHistory?: Maybe<Array<CXRentersEmail>>;
  getCXRentersRentalPassHistory?: Maybe<Array<CXRenterRentalPass>>;
  getCXRideHail: CXRideHail;
  getCXRideHails: CXRideHailList;
  getCXSystem: System;
  getCXSystems: Array<System>;
  getCurrentDriver?: Maybe<Driver>;
  getCurrentDriverStopPoints?: Maybe<DriverStopPointsResponse>;
  getCurrentOperator?: Maybe<Operator>;
  getCurrentStopPoint: DriverStopPoint;
  getDepot?: Maybe<Depot>;
  getDepots: Array<Depot>;
  getDiscountRates?: Maybe<Array<DiscountRate>>;
  getDriver?: Maybe<Driver>;
  getDriverAppConfig: DriverAppConfig;
  getDriverAvailableCredit: Scalars['Int']['output'];
  getDriverAvailableSecurityDepositLedger: Scalars['Int']['output'];
  getDriverContractConversionForm?: Maybe<DriverContractConversionForm>;
  getDriverFleetResource: DriverFleetResource;
  getDriverFleetResourceCount: Scalars['Int']['output'];
  getDriverFleetResourceDetails: DriverFleetResourceDetailsResponse;
  getDriverFleetResources: DriverFleetResourcesResponse;
  getDriverInsuranceApprovals: DriverInsuranceApprovalResponse;
  getDriverInvoice?: Maybe<DriverInvoice>;
  getDriverInvoices: DriverInvoicesResponse;
  getDriverOTPVerificationCode?: Maybe<DriverOTP>;
  /** @deprecated Use getDepot */
  getDriverOpsDepot?: Maybe<Depot>;
  /** @deprecated Use getDepots */
  getDriverOpsDepots?: Maybe<Array<Depot>>;
  getDriverOpsDriver?: Maybe<DriverOpsDriverResponse>;
  getDriverOpsDriverAndDetails: DriverOpsDriverListResponse;
  getDriverOpsDriverTimeAway: DriverOpsDriverTimeAwayResponse;
  /** @deprecated Use getDrivers */
  getDriverOpsDrivers?: Maybe<DriverOpsDriverListResponse>;
  /** @deprecated Use getDriverShifts */
  getDriverOpsShifts: DriverOpsDriverShiftsResponse;
  getDriverPaymentMethod?: Maybe<DriverPaymentMethod>;
  getDriverPaymentMethods: Array<DriverPaymentMethod>;
  getDriverReservationEditableScheduleTimeRange: DriverReservationEditableScheduleTimeRange;
  getDriverRestrictionStatusContent?: Maybe<DriverRestrictionsStatusContent>;
  /** @deprecated Use driverScheduledShift instead */
  getDriverScheduledShift: DriverScheduledShift;
  /** @deprecated Use driverScheduledShifts instead */
  getDriverScheduledShifts: DriverScheduledShiftsPagedResult;
  getDriverShiftDevice?: Maybe<DriverShiftDevice>;
  /** @deprecated Use getMyShifts instead */
  getDriverShifts: DriverShiftsResponse;
  getDriverSystemZones?: Maybe<Array<DriverSystemZone>>;
  getDriverTaxInfo: DriverTaxInfo;
  getDriverTimeAway?: Maybe<Array<Maybe<DriverTimeAway>>>;
  getDriverVehicleExclusions: Array<Maybe<DriverVehicleExclusionMakeAndModel>>;
  getDriverVehiclePreference?: Maybe<DriverVehiclePreferenceResponse>;
  getDriverW9Form: Scalars['String']['output'];
  getDrivers: DriversResponse;
  getFlag: Flag;
  getFlagTypes?: Maybe<Array<FlagType>>;
  getFlags?: Maybe<Array<Flag>>;
  getFleetResource?: Maybe<FleetResource>;
  getFleetResourceMetadata?: Maybe<FleetResourceMetadata>;
  getFleetResourceMetadataFromFleetioId?: Maybe<FleetResourceMetadataResponse>;
  getFleetResourceOpenWorkOrder?: Maybe<WorkOrder>;
  getFleetResourceServiceLog?: Maybe<Array<ServiceLog>>;
  getFleetResourceSessions?: Maybe<Array<FleetResourceSession>>;
  getFleetResources: Array<FleetResource>;
  getFleetResourcesStats: FleetResourcesStats;
  getIdentityRoles: Array<Maybe<RoleMember>>;
  getInsuranceProviders?: Maybe<InsuranceProviderResponse>;
  getInvoiceVoidedReasons: Array<DriverInvoiceVoidedReason>;
  getIssue?: Maybe<Issue>;
  getIssues?: Maybe<IssueList>;
  getLatestFleetResourceServiceLog?: Maybe<ServiceLog>;
  getLatestLegalDocument?: Maybe<LegalDocument>;
  getLatestRentalTou: LegalDocument;
  getLatestReservationContract: LegalDocument;
  getLegalDocument?: Maybe<LegalDocument>;
  getLocation: Location;
  getLocationGroup?: Maybe<LocationGroup>;
  getLocationGroupAction: LocationGroupAction;
  getLocationGroupResources?: Maybe<Array<Maybe<FleetResource>>>;
  getLocationGroups?: Maybe<Array<Maybe<LocationGroup>>>;
  getLocations?: Maybe<Array<Maybe<Location>>>;
  getMostRecentNonDriverScheduledHours: MostRecentNonDriverScheduledHours;
  getMyShift?: Maybe<DriverShift>;
  getMyShifts: DriverShiftsResponse;
  getMyVehiclePreference?: Maybe<DriverVehiclePreferenceResponse>;
  /** @deprecated Use openScheduledShifts instead */
  getOpenScheduledShifts?: Maybe<Array<OpenScheduledShift>>;
  getOperator?: Maybe<Operator>;
  getOperatorLocation?: Maybe<OperatorLocation>;
  getOperatorLocations?: Maybe<Array<Maybe<OperatorLocation>>>;
  getOperatorReservations: Array<Reservation>;
  getOperators: Array<Maybe<Operator>>;
  getOpsHub: Hub;
  getOpsHubResources?: Maybe<Array<HubResource>>;
  getOpsHubs?: Maybe<Array<Hub>>;
  getOpsServiceArea: ServiceArea;
  getOpsServiceAreas: ServiceAreaList;
  getOpsSystem: System;
  getOpsSystemServiceAreaHistory: SystemServiceAreaHistoryList;
  getOpsSystemZones: Array<OpsSystemZone>;
  getOpsSystems: Array<System>;
  getPassengerAppAccounts: Array<PassengerAppAccount>;
  getPhoneVerifications?: Maybe<Array<PhoneVerification>>;
  getRecurringReservation?: Maybe<RecurringReservation>;
  getRecurringShift?: Maybe<RecurringShift>;
  getRemainingUnscheduledBreaks: RemainingUnscheduledBreaks;
  getRentalReservation?: Maybe<RentalReservation>;
  getRenterIdVerifications?: Maybe<Array<CXIdVerification>>;
  getRenterInvoices?: Maybe<CXInvoicesList>;
  getRentersFieldsToValidate?: Maybe<RentersFieldsToValidate>;
  getRentersIneligibilities?: Maybe<Array<Ineligibility>>;
  getRentersViolations?: Maybe<Array<RenterViolation>>;
  getRepairGroups: Array<RepairGroup>;
  getRepairs: Array<Repair>;
  getReservation?: Maybe<Reservation>;
  getReservationBillingSettings?: Maybe<ReservationBillingSettings>;
  getReservationRentalContract?: Maybe<ReservationContract>;
  getReservations?: Maybe<Array<Maybe<Reservation>>>;
  getResourceRidehailVehicle?: Maybe<CXVehicle>;
  getResourceTimeline?: Maybe<ResourceTimelineResponse>;
  getRole?: Maybe<Role>;
  getScheduleOverview?: Maybe<Array<ScheduleOverview>>;
  getSecurityDepositLedger?: Maybe<SecurityDepositLedgerRecord>;
  getShift?: Maybe<DriverShift>;
  getShiftCancellationReasons?: Maybe<Array<ShiftCancellationReason>>;
  getShiftIssue?: Maybe<ShiftIssue>;
  getShiftResourceMetadata?: Maybe<ShiftResourceMetadataResponse>;
  getShiftStopPoints: ShiftStopPointResponse;
  getShifts: DriverShiftsResponse;
  getSignedDocuments: SignedDocuments;
  getSystem?: Maybe<System>;
  getSystemMapResources: SystemMapResourcesList;
  getSystemMapStats: SystemMapStats;
  getSystemServiceAreaGeojson?: Maybe<MultiPolygon>;
  getSystemServiceAreaGeojsonString?: Maybe<Scalars['String']['output']>;
  getSystemServiceAreaInverseGeojson?: Maybe<MultiPolygon>;
  getSystemServiceAreaInverseGeojsonString?: Maybe<Scalars['String']['output']>;
  getSystems: Array<System>;
  getTask?: Maybe<Task>;
  getTaskCategories?: Maybe<Array<TaskCategory>>;
  getTaskComment?: Maybe<TaskComment>;
  getTaskComments?: Maybe<Array<TaskComment>>;
  getTaskFilter: TaskFilter;
  getTaskFilteredFleetResources: Array<FleetResource>;
  getTaskFilters?: Maybe<Array<TaskFilter>>;
  getTaskType?: Maybe<TaskType>;
  getTaskTypes?: Maybe<Array<Maybe<TaskType>>>;
  getTasks?: Maybe<Array<Task>>;
  getTrafficViolationTicket?: Maybe<TrafficViolationTicket>;
  getUsersCXOrderSubscriptions: Array<CXOrderSubscriptions>;
  /** @deprecated Use vehicleFacilities instead */
  getVehicleFacilities: VehicleFacilityResponse;
  /** @deprecated Use vehicleFacility instead */
  getVehicleFacility: VehicleFacility;
  getViolations?: Maybe<Array<Violation>>;
  getWorkOrder?: Maybe<WorkOrder>;
  getWorkOrderTasks: Array<WorkOrderTask>;
  getWorkOrders: Array<WorkOrder>;
  getZone?: Maybe<Zone>;
  getZones?: Maybe<ZoneList>;
  incident: Incident;
  incidents: IncidentsResponse;
  isDeviceInRevelCar: IsInCar;
  listAdicRegistrations: AdicRegistrationResponse;
  listAmenities: AmenityResponse;
  listDriverAddresses: DriverAddressResponse;
  listDriverRestrictionReasons?: Maybe<DriverRestrictionReasonResponse>;
  listLegalDocuments?: Maybe<LegalDocumentsResponse>;
  listLicenses: LicenseResponse;
  listNotes: NoteResponse;
  listOpenShiftTemplates?: Maybe<Array<OpenShiftTemplate>>;
  listOpenShifts?: Maybe<Array<OpenShift>>;
  listRecurringReservations: RecurringReservationsResponse;
  listRecurringShifts?: Maybe<Array<RecurringShift>>;
  listRentalReservations: RentalReservationsResponse;
  listSecurityDepositLedger?: Maybe<SecurityDepositLedgerResponse>;
  listSecurityDepositPayouts: SecurityDepositPayoutsResponse;
  listTrafficViolationTickets: ListTrafficViolationTicketResponse;
  openScheduledShifts?: Maybe<Array<OpenScheduledShift>>;
  passengerGetResource?: Maybe<FleetResource>;
  rideHail?: Maybe<DriverRideHail>;
  rideHails?: Maybe<Array<DriverRideHail>>;
  shiftReview?: Maybe<ShiftReview>;
  shiftReviews: ShiftReviewsResponse;
  track: Track;
  tracks: TrackResponse;
  validateSummonsNumber?: Maybe<ValidatedSummons>;
  vehicleFacilities: VehicleFacilityResponse;
  vehicleFacility: VehicleFacility;
  verifyRentersIdentity?: Maybe<Scalars['Boolean']['output']>;
};

export type QuerycalculateDriverVehicleCreditAmountArgs = {
  minutes: Scalars['Int']['input'];
  vehicleType: DriverVehiclePreferenceType;
};

export type QuerycurrentDriverTrackLevelArgs = {
  driverId: Scalars['ID']['input'];
  trackId: Scalars['ID']['input'];
};

export type QuerydriverDepotsArgs = {
  filters: DriverDepotFilter;
  pageInfo?: InputMaybe<PageInfo>;
};

export type QuerydriverIncidentArgs = {
  driverIncidentId: Scalars['ID']['input'];
};

export type QuerydriverIncidentDisputeArgs = {
  driverIncidentDisputeId: Scalars['ID']['input'];
};

export type QuerydriverIncidentDisputesArgs = {
  filters?: InputMaybe<DriverIncidentDisputesFilters>;
};

export type QuerydriverIncidentsArgs = {
  filters?: InputMaybe<DriverIncidentsFilters>;
};

export type QuerydriverIssueArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydriverIssuesArgs = {
  filters?: InputMaybe<DriverIssueFilter>;
};

export type QuerydriverPaymentArgs = {
  paymentId: Scalars['ID']['input'];
};

export type QuerydriverPaymentsArgs = {
  filters: DriverPaymentFilters;
};

export type QuerydriverScheduledShiftArgs = {
  id: Scalars['ID']['input'];
};

export type QuerydriverScheduledShiftsArgs = {
  filters?: InputMaybe<DriverScheduledShiftFilter>;
  ordering?: InputMaybe<Array<InputMaybe<DriverScheduledShiftOrderingField>>>;
  pageInfo?: InputMaybe<PageInfo>;
};

export type QuerydriverTasksArgs = {
  filters?: InputMaybe<DriverTaskFilter>;
};

export type QuerydriverTrackLevelsArgs = {
  filters?: InputMaybe<DriverTrackLevelFilters>;
};

export type QuerygetAboundTokenArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetActiveRentalsArgs = {
  filters?: InputMaybe<ActiveRentalsFilters>;
};

export type QuerygetAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetAssignmentOperatorLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetAssignmentOperatorLocationsArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type QuerygetAssignmentResourcesArgs = {
  assignmentId: Scalars['ID']['input'];
  operatorLocation?: InputMaybe<PointInput>;
  withBlockedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  withLastServicedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetAssignmentsArgs = {
  filters?: InputMaybe<AssignmentFilters>;
};

export type QuerygetAssignmentsV2Args = {
  filters?: InputMaybe<AssignmentFilters>;
};

export type QuerygetCXActiveDynamicRateDiscountArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetCXAgentArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXAgentsArgs = {
  filters?: InputMaybe<CXAgentFilters>;
};

export type QuerygetCXCreditHistoryArgs = {
  renterId: Scalars['String']['input'];
};

export type QuerygetCXDriverShiftsArgs = {
  filters: CXDriverShiftFilters;
};

export type QuerygetCXDynamicRateDiscountArgs = {
  dynamicRateDiscountId: Scalars['ID']['input'];
};

export type QuerygetCXDynamicRateDiscountsArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetCXFleetResourceArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXFleetResourcesArgs = {
  filters?: InputMaybe<ResourceFilters>;
};

export type QuerygetCXHubArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXHubsArgs = {
  systemId: Scalars['ID']['input'];
};

export type QuerygetCXInvoicePDFArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type QuerygetCXInvoiceReasonsArgs = {
  filters?: InputMaybe<CXInvoiceReasonsFilter>;
};

export type QuerygetCXPricingBySystemArgs = {
  systemId: Scalars['ID']['input'];
};

export type QuerygetCXProblemReportArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXProblemReportsArgs = {
  filters?: InputMaybe<ProblemReportsFilters>;
};

export type QuerygetCXRentalArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXRentalSelfieArgs = {
  rentalSelfieId: Scalars['ID']['input'];
};

export type QuerygetCXRentalsArgs = {
  filters?: InputMaybe<CXRentalsFilters>;
};

export type QuerygetCXRenterArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXRentersArgs = {
  filters?: InputMaybe<CXRentersFilters>;
};

export type QuerygetCXRentersDiscountRateHistoryArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetCXRentersEmailHistoryArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXRentersRentalPassHistoryArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetCXRideHailArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXRideHailsArgs = {
  filters: CXRideHailFilters;
};

export type QuerygetCXSystemArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCurrentStopPointArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetDepotArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetDepotsArgs = {
  filters?: InputMaybe<GetDepotsFilters>;
};

export type QuerygetDiscountRatesArgs = {
  discountRateProduct?: InputMaybe<DiscountRateProductType>;
};

export type QuerygetDriverArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetDriverAvailableCreditArgs = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetDriverAvailableSecurityDepositLedgerArgs = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetDriverContractConversionFormArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetDriverFleetResourceArgs = {
  fleetResourceId: Scalars['ID']['input'];
};

export type QuerygetDriverFleetResourceCountArgs = {
  filters?: InputMaybe<DriverFleetResourceCountFilters>;
};

export type QuerygetDriverFleetResourceDetailsArgs = {
  filters?: InputMaybe<DriverFleetResourceDetailFilters>;
};

export type QuerygetDriverFleetResourcesArgs = {
  filters: DriverFleetResourceFilters;
};

export type QuerygetDriverInsuranceApprovalsArgs = {
  filters?: InputMaybe<DriverInsuranceApprovalFilters>;
};

export type QuerygetDriverInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type QuerygetDriverInvoicesArgs = {
  filters?: InputMaybe<DriverInvoiceFilters>;
};

export type QuerygetDriverOTPVerificationCodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetDriverOpsDepotArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetDriverOpsDriverArgs = {
  driverId: Scalars['String']['input'];
};

export type QuerygetDriverOpsDriverAndDetailsArgs = {
  filters?: InputMaybe<GetDriverOpsDriverAndDetailsFilters>;
};

export type QuerygetDriverOpsDriverTimeAwayArgs = {
  filters: DriverOpsDriverTimeAwayFilters;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetDriverOpsDriversArgs = {
  filters?: InputMaybe<DriverOpsDriversFilters>;
};

export type QuerygetDriverOpsShiftsArgs = {
  filters?: InputMaybe<DriverOpsShiftFilters>;
};

export type QuerygetDriverPaymentMethodArgs = {
  paymentMethodId: Scalars['ID']['input'];
};

export type QuerygetDriverPaymentMethodsArgs = {
  filters?: InputMaybe<DriverPaymentMethodFilters>;
};

export type QuerygetDriverReservationEditableScheduleTimeRangeArgs = {
  reservationId: Scalars['ID']['input'];
};

export type QuerygetDriverRestrictionStatusContentArgs = {
  filters?: InputMaybe<DriverRestrictionStatusContentFilters>;
};

export type QuerygetDriverScheduledShiftArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetDriverScheduledShiftsArgs = {
  filters?: InputMaybe<DriverScheduledShiftFilter>;
  ordering?: InputMaybe<Array<InputMaybe<DriverScheduledShiftOrderingField>>>;
  pageInfo?: InputMaybe<PageInfo>;
};

export type QuerygetDriverShiftDeviceArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetDriverShiftsArgs = {
  filters?: InputMaybe<DriverShiftFilters>;
};

export type QuerygetDriverSystemZonesArgs = {
  filters?: InputMaybe<GetDriverSystemZonesFilters>;
  systemId: Scalars['ID']['input'];
};

export type QuerygetDriverTaxInfoArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetDriverTimeAwayArgs = {
  filters?: InputMaybe<DriverTimeAwayFilters>;
  id: Scalars['ID']['input'];
};

export type QuerygetDriverVehicleExclusionsArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetDriverVehiclePreferenceArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetDriverW9FormArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetDriversArgs = {
  filters?: InputMaybe<DriverFilters>;
};

export type QuerygetFlagArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetFlagsArgs = {
  filters: FlagFilters;
};

export type QuerygetFleetResourceArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetFleetResourceMetadataArgs = {
  resourceId: Scalars['String']['input'];
};

export type QuerygetFleetResourceMetadataFromFleetioIdArgs = {
  fleetioId: Scalars['Int']['input'];
};

export type QuerygetFleetResourceOpenWorkOrderArgs = {
  fleetResourceId: Scalars['ID']['input'];
};

export type QuerygetFleetResourceServiceLogArgs = {
  fleetResourceId: Scalars['String']['input'];
};

export type QuerygetFleetResourceSessionsArgs = {
  filters: FleetResourceSessionFilters;
};

export type QuerygetFleetResourcesArgs = {
  cacheBuster: Scalars['String']['input'];
  filters?: InputMaybe<ResourceFilters>;
};

export type QuerygetFleetResourcesStatsArgs = {
  filters?: InputMaybe<ResourceFilters>;
};

export type QuerygetIdentityRolesArgs = {
  identityId: Scalars['ID']['input'];
};

export type QuerygetIssueArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetIssuesArgs = {
  filters?: InputMaybe<IssueFilters>;
};

export type QuerygetLatestFleetResourceServiceLogArgs = {
  fleetResourceId: Scalars['String']['input'];
};

export type QuerygetLatestLegalDocumentArgs = {
  documentType?: InputMaybe<LegalDocumentTypes>;
};

export type QuerygetLegalDocumentArgs = {
  legalDocumentId: Scalars['ID']['input'];
};

export type QuerygetLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetLocationGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetLocationGroupActionArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetLocationGroupResourcesArgs = {
  locationGroupId: Scalars['ID']['input'];
};

export type QuerygetLocationGroupsArgs = {
  filters?: InputMaybe<LocationGroupFilters>;
};

export type QuerygetLocationsArgs = {
  filters?: InputMaybe<LocationFilters>;
};

export type QuerygetMostRecentNonDriverScheduledHoursArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetMyShiftArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetMyShiftsArgs = {
  filters?: InputMaybe<DriverShiftFilters>;
};

export type QuerygetMyVehiclePreferenceArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetOpenScheduledShiftsArgs = {
  input: OpenScheduledShiftInput;
};

export type QuerygetOperatorArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOperatorLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOperatorLocationsArgs = {
  filters?: InputMaybe<OperatorLocationFilters>;
};

export type QuerygetOperatorReservationsArgs = {
  filters: OperatorReservationsFilters;
};

export type QuerygetOperatorsArgs = {
  filters?: InputMaybe<OperatorFilters>;
};

export type QuerygetOpsHubArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOpsHubResourcesArgs = {
  filters?: InputMaybe<OpsHubResourceFilters>;
  hubId: Scalars['ID']['input'];
};

export type QuerygetOpsHubsArgs = {
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetOpsServiceAreaArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOpsServiceAreasArgs = {
  filters?: InputMaybe<OpsServiceAreaFilters>;
};

export type QuerygetOpsSystemArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOpsSystemServiceAreaHistoryArgs = {
  filters?: InputMaybe<OpsSystemServiceAreaHistoryFilters>;
  id: Scalars['ID']['input'];
};

export type QuerygetOpsSystemZonesArgs = {
  input?: InputMaybe<OpsSystemZoneFilters>;
};

export type QuerygetPassengerAppAccountsArgs = {
  filters?: InputMaybe<PassengerAppAccountFilters>;
};

export type QuerygetPhoneVerificationsArgs = {
  filters?: InputMaybe<PhoneVerificationsFilters>;
};

export type QuerygetRecurringReservationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetRecurringShiftArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetRemainingUnscheduledBreaksArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetRentalReservationArgs = {
  reservationId: Scalars['ID']['input'];
};

export type QuerygetRenterIdVerificationsArgs = {
  renterId: Scalars['String']['input'];
};

export type QuerygetRenterInvoicesArgs = {
  filters?: InputMaybe<InvoiceFilters>;
};

export type QuerygetRentersFieldsToValidateArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetRentersIneligibilitiesArgs = {
  input: RenterIneligibilitesInput;
};

export type QuerygetRentersViolationsArgs = {
  input: RentersViolationsInput;
};

export type QuerygetRepairsArgs = {
  filters?: InputMaybe<RepairFilters>;
};

export type QuerygetReservationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetReservationBillingSettingsArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetReservationRentalContractArgs = {
  reservationId: Scalars['ID']['input'];
};

export type QuerygetReservationsArgs = {
  fleetResourceId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetResourceRidehailVehicleArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetResourceTimelineArgs = {
  filters: ResourceTimelineFilters;
};

export type QuerygetRoleArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetScheduleOverviewArgs = {
  input: ScheduleOverviewInput;
};

export type QuerygetSecurityDepositLedgerArgs = {
  ledgerRecordId: Scalars['ID']['input'];
};

export type QuerygetShiftArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetShiftIssueArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetShiftResourceMetadataArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetShiftStopPointsArgs = {
  filters?: InputMaybe<ShiftStopPointsFilter>;
  shiftId: Scalars['ID']['input'];
};

export type QuerygetShiftsArgs = {
  filters?: InputMaybe<DriverShiftFilters>;
};

export type QuerygetSignedDocumentsArgs = {
  filters: SignedDocumentsFilters;
};

export type QuerygetSystemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  systemIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetSystemMapResourcesArgs = {
  filters?: InputMaybe<SystemMapResourceFilters>;
  id: Scalars['ID']['input'];
};

export type QuerygetSystemMapStatsArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemServiceAreaGeojsonArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemServiceAreaGeojsonStringArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemServiceAreaInverseGeojsonArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemServiceAreaInverseGeojsonStringArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemsArgs = {
  systemIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetTaskArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetTaskCommentArgs = {
  taskCommentId: Scalars['ID']['input'];
};

export type QuerygetTaskCommentsArgs = {
  taskId: Scalars['ID']['input'];
};

export type QuerygetTaskFilterArgs = {
  taskFilterId: Scalars['ID']['input'];
};

export type QuerygetTaskFilteredFleetResourcesArgs = {
  system?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taskTypeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unblock?: InputMaybe<Scalars['Boolean']['input']>;
  withBlockedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  withLastServicedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetTaskTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetTaskTypesArgs = {
  parentTaskCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetTasksArgs = {
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetTrafficViolationTicketArgs = {
  ticketId: Scalars['ID']['input'];
};

export type QuerygetUsersCXOrderSubscriptionsArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetVehicleFacilityArgs = {
  vehicleFacilityId: Scalars['ID']['input'];
};

export type QuerygetWorkOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetWorkOrderTasksArgs = {
  workOrderId: Scalars['ID']['input'];
};

export type QuerygetWorkOrdersArgs = {
  assignmentId: Scalars['ID']['input'];
  filters?: InputMaybe<WorkOrderFilters>;
};

export type QuerygetZoneArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetZonesArgs = {
  filters?: InputMaybe<ZoneFilters>;
};

export type QueryincidentArgs = {
  incidentId: Scalars['ID']['input'];
};

export type QueryisDeviceInRevelCarArgs = {
  deviceName: Scalars['String']['input'];
};

export type QuerylistAdicRegistrationsArgs = {
  filters?: InputMaybe<AdicRegistrationFilters>;
};

export type QuerylistAmenitiesArgs = {
  filters?: InputMaybe<AmenityFilters>;
};

export type QuerylistDriverAddressesArgs = {
  filters?: InputMaybe<DriverAddressFilters>;
};

export type QuerylistDriverRestrictionReasonsArgs = {
  filters?: InputMaybe<DriverRestrictionReasonFilters>;
};

export type QuerylistLegalDocumentsArgs = {
  filters?: InputMaybe<LegalDocumentFilters>;
};

export type QuerylistLicensesArgs = {
  filters?: InputMaybe<LicenseFilters>;
};

export type QuerylistNotesArgs = {
  filters?: InputMaybe<NoteFilters>;
};

export type QuerylistOpenShiftTemplatesArgs = {
  filters?: InputMaybe<ListOpenShiftTemplateFilters>;
};

export type QuerylistOpenShiftsArgs = {
  filters?: InputMaybe<ListOpenShiftFilters>;
};

export type QuerylistRecurringReservationsArgs = {
  filters?: InputMaybe<RecurringReservationFilters>;
};

export type QuerylistRecurringShiftsArgs = {
  filters?: InputMaybe<ListRecurringShiftsFilters>;
};

export type QuerylistRentalReservationsArgs = {
  filters?: InputMaybe<RentalReservationFilters>;
};

export type QuerylistSecurityDepositLedgerArgs = {
  filters?: InputMaybe<SecurityDepositLedgerFilters>;
};

export type QuerylistSecurityDepositPayoutsArgs = {
  filters?: InputMaybe<SecurityDepositPayoutFilters>;
};

export type QuerylistTrafficViolationTicketsArgs = {
  filters?: InputMaybe<TrafficViolationTicketFilters>;
};

export type QueryopenScheduledShiftsArgs = {
  input: OpenScheduledShiftInput;
};

export type QueryrideHailArgs = {
  id: Scalars['ID']['input'];
};

export type QueryrideHailsArgs = {
  filters?: InputMaybe<DriverRideHailFilters>;
};

export type QueryshiftReviewArgs = {
  id: Scalars['ID']['input'];
};

export type QueryshiftReviewsArgs = {
  filters?: InputMaybe<ShiftReviewsFilters>;
};

export type QuerytrackArgs = {
  trackId: Scalars['ID']['input'];
};

export type QuerytracksArgs = {
  filters?: InputMaybe<TrackFilters>;
};

export type QueryvalidateSummonsNumberArgs = {
  input: ValidateSummonsInput;
};

export type QueryvehicleFacilitiesArgs = {
  filters?: InputMaybe<VehicleFacilityFilters>;
};

export type QueryvehicleFacilityArgs = {
  vehicleFacilityId: Scalars['ID']['input'];
};

export type QueryverifyRentersIdentityArgs = {
  input?: InputMaybe<RenterValidationFields>;
};

export type RatePassengerRideHailInput = {
  rating: RatingTypes;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export enum RatingTypes {
  negative = 'negative',
  no_rating = 'no_rating',
  positive = 'positive',
}

export type RawSensors = {
  __typename?: 'RawSensors';
  alarm?: Maybe<Scalars['Boolean']['output']>;
  battery2Id?: Maybe<Scalars['String']['output']>;
  battery2Temp1?: Maybe<Scalars['Int']['output']>;
  battery2Temp2?: Maybe<Scalars['Int']['output']>;
  battery2Temp3?: Maybe<Scalars['Int']['output']>;
  battery2Temp4?: Maybe<Scalars['Int']['output']>;
  batteryId?: Maybe<Scalars['String']['output']>;
  batteryLevel: Scalars['Float']['output'];
  batteryLevel1?: Maybe<Scalars['Int']['output']>;
  batteryLevel2?: Maybe<Scalars['Int']['output']>;
  batteryOn?: Maybe<Scalars['Boolean']['output']>;
  batteryTemp1?: Maybe<Scalars['Int']['output']>;
  batteryTemp2?: Maybe<Scalars['Int']['output']>;
  batteryTemp3?: Maybe<Scalars['Int']['output']>;
  batteryTemp4?: Maybe<Scalars['Int']['output']>;
  batteryVoltage?: Maybe<Scalars['Int']['output']>;
  climateState?: Maybe<ClimateSensors>;
  dashboardBatteryLevel?: Maybe<Scalars['Int']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  ecuBatteryLevel?: Maybe<Scalars['Int']['output']>;
  ecuSoftVer?: Maybe<Scalars['String']['output']>;
  eid?: Maybe<Scalars['String']['output']>;
  enable485cmd?: Maybe<Scalars['Boolean']['output']>;
  estimateMileage?: Maybe<Scalars['Int']['output']>;
  fallState?: Maybe<Scalars['Boolean']['output']>;
  fotaDownloadPercent?: Maybe<Scalars['Int']['output']>;
  gpsSignal?: Maybe<Scalars['String']['output']>;
  gsmSignal?: Maybe<Scalars['Int']['output']>;
  handlebarOn?: Maybe<Scalars['Boolean']['output']>;
  iccid?: Maybe<Scalars['String']['output']>;
  ignitionOn?: Maybe<Scalars['Boolean']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  locating?: Maybe<Scalars['Boolean']['output']>;
  lockcontrolBatteryLevel?: Maybe<Scalars['Int']['output']>;
  lockcontrolSoftVer?: Maybe<Scalars['String']['output']>;
  onlineState?: Maybe<Scalars['Boolean']['output']>;
  positionAccuracy?: Maybe<Scalars['Float']['output']>;
  readyOn?: Maybe<Scalars['Boolean']['output']>;
  saddleOn?: Maybe<Scalars['Boolean']['output']>;
  secureOn?: Maybe<Scalars['Boolean']['output']>;
  serverUpdateTime?: Maybe<Scalars['String']['output']>;
  speed?: Maybe<Scalars['Float']['output']>;
  tailboxLidOn?: Maybe<Scalars['Boolean']['output']>;
  tailboxOn?: Maybe<Scalars['Boolean']['output']>;
  totalMileage?: Maybe<Scalars['Int']['output']>;
  updateTime?: Maybe<Scalars['String']['output']>;
  vehicleState?: Maybe<VehicleStateSensors>;
  vibrationState?: Maybe<Scalars['Boolean']['output']>;
};

export type RecurringReservation = {
  __typename?: 'RecurringReservation';
  beginGeneratingAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  depot?: Maybe<Depot>;
  depotId?: Maybe<Scalars['ID']['output']>;
  driver?: Maybe<Driver>;
  endTime: Scalars['String']['output'];
  endWeekday: Scalars['Int']['output'];
  generatedUntil?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  startTime: Scalars['String']['output'];
  startWeekday: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
};

export type RecurringReservationFilters = {
  depotId__in?: InputMaybe<Array<Scalars['ID']['input']>>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  endTime__lte?: InputMaybe<Scalars['String']['input']>;
  endWeekday?: InputMaybe<Scalars['Int']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  startTime__gte?: InputMaybe<Scalars['String']['input']>;
  startWeekday?: InputMaybe<Scalars['Int']['input']>;
};

export type RecurringReservationScheduleTimesInput = {
  beginGeneratingAt?: InputMaybe<Scalars['DateTime']['input']>;
  endTime: Scalars['String']['input'];
  endWeekday: Scalars['Int']['input'];
  startTime: Scalars['String']['input'];
  startWeekday: Scalars['Int']['input'];
};

export type RecurringReservationsResponse = {
  __typename?: 'RecurringReservationsResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<RecurringReservation>>;
};

export type RecurringShift = {
  __typename?: 'RecurringShift';
  beginGeneratingAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  depot?: Maybe<Depot>;
  depotId?: Maybe<Scalars['ID']['output']>;
  driver: Driver;
  endTime: Scalars['String']['output'];
  endWeekday: Scalars['Int']['output'];
  estimatedPay: Scalars['Int']['output'];
  generatedUntil?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  settings?: Maybe<ShiftSettings>;
  startTime: Scalars['String']['output'];
  startWeekday: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
};

export type Refund = {
  __typename?: 'Refund';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<RefundReasons>;
  status?: Maybe<RefundStatus>;
};

export type RefundInvoiceInput = {
  amount: Scalars['Int']['input'];
  invoiceId: Scalars['ID']['input'];
  reason?: InputMaybe<RefundReasons>;
};

export enum RefundReasons {
  /** @deprecated no longer used by cx */
  driver_issue = 'driver_issue',
  employee_discount_didnt_apply = 'employee_discount_didnt_apply',
  forgot_to_end_ride = 'forgot_to_end_ride',
  fraudulent_charge = 'fraudulent_charge',
  misplaced_ticket_or_tow_charge = 'misplaced_ticket_or_tow_charge',
  moped_gps_issue = 'moped_gps_issue',
  no_longer_needed_ridepass = 'no_longer_needed_ridepass',
  payment_processing_issue = 'payment_processing_issue',
  rideshare_accident = 'rideshare_accident',
  rideshare_driver_complaint = 'rideshare_driver_complaint',
  rideshare_driver_no_show = 'rideshare_driver_no_show',
  /** @deprecated no longer used by cx */
  rideshare_issue = 'rideshare_issue',
  rideshare_lost_item_issue = 'rideshare_lost_item_issue',
  rideshare_navigation_issue = 'rideshare_navigation_issue',
  rideshare_user_did_not_ride = 'rideshare_user_did_not_ride',
  rideshare_vehicle_issue = 'rideshare_vehicle_issue',
  rideshare_wait_time_fee = 'rideshare_wait_time_fee',
  thought_they_had_a_ride_pass = 'thought_they_had_a_ride_pass',
  throttle_not_responsive = 'throttle_not_responsive',
  trouble_starting_ride = 'trouble_starting_ride',
  updated_payment_method = 'updated_payment_method',
}

export enum RefundStatus {
  failed = 'failed',
  pending = 'pending',
  succeeded = 'succeeded',
}

export enum RegistrationStatuses {
  accepted = 'accepted',
  cancelled = 'cancelled',
  denied = 'denied',
  rejected = 'rejected',
  transfered = 'transfered',
  under_review = 'under_review',
}

export type RemainingUnscheduledBreaks = {
  __typename?: 'RemainingUnscheduledBreaks';
  availableBreaks?: Maybe<Scalars['Int']['output']>;
};

export type RemoveRenterDiscountRateInput = {
  renterDiscountRateId: Scalars['ID']['input'];
  renterId: Scalars['ID']['input'];
};

export type RemoveRenterPhoneNumberInput = {
  renterId: Scalars['ID']['input'];
};

export type RemoveRenterViolationInput = {
  cxAgentId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  renterId: Scalars['ID']['input'];
  renterViolationId: Scalars['ID']['input'];
};

export type RentalAccountValidation = {
  __typename?: 'RentalAccountValidation';
  faceImages?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  rider1ImageUrl: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
};

export type RentalAccountValidationResultsInput = {
  rentalAccountValidationId: Scalars['ID']['input'];
  riderAndFaceImageMatch?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RentalReservation = {
  __typename?: 'RentalReservation';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  billingSettings?: Maybe<ReservationBillingSettings>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentIssueId?: Maybe<Scalars['ID']['output']>;
  currentResource?: Maybe<FleetResource>;
  currentResourceId?: Maybe<Scalars['ID']['output']>;
  depot?: Maybe<Depot>;
  depotId?: Maybe<Scalars['ID']['output']>;
  driver?: Maybe<Driver>;
  driverId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<DriverInvoice>>;
  reservationType: RentalReservationTypes;
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  settings?: Maybe<ReservationSettings>;
  state: RentalReservationStates;
  systemId: Scalars['ID']['output'];
  timeline?: Maybe<Array<RentalReservationTimeline>>;
  timelineHead?: Maybe<RentalReservationTimeline>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RentalReservationinvoicesArgs = {
  filters?: InputMaybe<DriverInvoiceFilters>;
};

export type RentalReservationBillingTimeline = {
  __typename?: 'RentalReservationBillingTimeline';
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isBillable?: Maybe<Scalars['Boolean']['output']>;
  issueId?: Maybe<Scalars['ID']['output']>;
  reservationId: Scalars['ID']['output'];
  resource?: Maybe<FleetResource>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  startedAt: Scalars['DateTime']['output'];
  state: RentalReservationStates;
  updatedAt: Scalars['DateTime']['output'];
};

export type RentalReservationFilters = {
  depotId__in?: InputMaybe<Array<Scalars['ID']['input']>>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  hasVehicle?: InputMaybe<Scalars['Boolean']['input']>;
  isInIssue?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<Array<RentalReservationOrdering>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  scheduledStartAt__gte?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAt__lte?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<RentalReservationStates>>;
  state__in?: InputMaybe<Array<RentalReservationStates>>;
  state__nin?: InputMaybe<Array<RentalReservationStates>>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export enum RentalReservationOrdering {
  actualEndAtAscending = 'actualEndAtAscending',
  actualEndAtDescending = 'actualEndAtDescending',
  actualStartAtAscending = 'actualStartAtAscending',
  actualStartAtDescending = 'actualStartAtDescending',
  currentIssueIdAscending = 'currentIssueIdAscending',
  currentIssueIdDescending = 'currentIssueIdDescending',
  scheduledEndAtAscending = 'scheduledEndAtAscending',
  scheduledEndAtDescending = 'scheduledEndAtDescending',
  scheduledStartAtAscending = 'scheduledStartAtAscending',
  scheduledStartAtDescending = 'scheduledStartAtDescending',
}

export enum RentalReservationStates {
  active = 'active',
  canceled = 'canceled',
  complete = 'complete',
  scheduled = 'scheduled',
  stand_by = 'stand_by',
}

export type RentalReservationTimeline = {
  __typename?: 'RentalReservationTimeline';
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isBillable?: Maybe<Scalars['Boolean']['output']>;
  issueId?: Maybe<Scalars['ID']['output']>;
  reservationId: Scalars['ID']['output'];
  resource?: Maybe<FleetResource>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  startedAt: Scalars['DateTime']['output'];
  state: RentalReservationStates;
  updatedAt: Scalars['DateTime']['output'];
};

export enum RentalReservationTypes {
  revel_rideshare = 'revel_rideshare',
  revel_w2 = 'revel_w2',
  uber_fleet = 'uber_fleet',
  uber_rideshare = 'uber_rideshare',
}

export type RentalReservationsResponse = {
  __typename?: 'RentalReservationsResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<RentalReservation>>;
};

export enum RentalSelfieState {
  awaiting_review = 'awaiting_review',
  classified = 'classified',
  pending = 'pending',
  processed = 'processed',
  reviewed = 'reviewed',
  reviewing = 'reviewing',
  skipped_review_due_to_distance = 'skipped_review_due_to_distance',
  uploaded = 'uploaded',
}

export enum RentalStatusEnum {
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
  ERROR = 'ERROR',
  PAUSED = 'PAUSED',
  PRE_RESERVED = 'PRE_RESERVED',
  RESERVED = 'RESERVED',
  RIDER_SAFETY = 'RIDER_SAFETY',
  active = 'active',
  ended = 'ended',
  error = 'error',
  paused = 'paused',
  pre_reserved = 'pre_reserved',
  reserved = 'reserved',
  rider_safety = 'rider_safety',
}

export enum RentalStatusErrorActionEnum {
  END = 'END',
  END_PAUSE = 'END_PAUSE',
  PAUSE = 'PAUSE',
  RIDER_SAFETY = 'RIDER_SAFETY',
  START = 'START',
}

export type RentalTimelineStatus = {
  __typename?: 'RentalTimelineStatus';
  actor?: Maybe<StatusActors>;
  description?: Maybe<Scalars['String']['output']>;
  /** Resolver fetched fields */
  endedByIdentity?: Maybe<Identity>;
  endedByIdentityId?: Maybe<Scalars['ID']['output']>;
  errorAction?: Maybe<RentalStatusErrorActionEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RentalStatusEnum>;
  time?: Maybe<Scalars['DateTime']['output']>;
  transitionStatus?: Maybe<TransitionRentalStatusEnum>;
  userLocation?: Maybe<Point>;
  vehicleAddress?: Maybe<Scalars['String']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type RentalsList = {
  __typename?: 'RentalsList';
  count: Scalars['Int']['output'];
  results: Array<CXRental>;
};

export type Renter = {
  __typename?: 'Renter';
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identity?: Maybe<Identity>;
  updated: Scalars['DateTime']['output'];
};

export enum RenterDeletionTypes {
  full_delete = 'full_delete',
  soft_delete = 'soft_delete',
}

export type RenterDiscountRate = {
  __typename?: 'RenterDiscountRate';
  discountRate: DiscountRate;
  id: Scalars['ID']['output'];
};

export type RenterIneligibilitesInput = {
  filters?: InputMaybe<IneligibilityFilters>;
  renterId: Scalars['ID']['input'];
};

export type RenterSuspensionAddInput = {
  description: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
};

export type RenterSuspensionEndInput = {
  description: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
  renterIneligibilityId: Scalars['ID']['input'];
};

export type RenterValidationFields = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['ID']['input']>;
  paymentmethodLastFour?: InputMaybe<Scalars['String']['input']>;
  renterId: Scalars['ID']['input'];
};

export type RenterViolation = {
  __typename?: 'RenterViolation';
  /** Resolver fetched fields */
  addedBy?: Maybe<Identity>;
  created?: Maybe<Scalars['DateTime']['output']>;
  cxAgentId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<RenterViolationImage>>;
  rentalId?: Maybe<Scalars['ID']['output']>;
  rideHailId?: Maybe<Scalars['ID']['output']>;
  summonsImageUrl?: Maybe<Scalars['String']['output']>;
  summonsNumber?: Maybe<Scalars['String']['output']>;
  suspensionRenterIneligibilityId?: Maybe<Scalars['ID']['output']>;
  violation: Violation;
};

export type RenterViolationImage = {
  __typename?: 'RenterViolationImage';
  imageS3Path?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<Scalars['String']['output']>;
};

export type RenterViolationImagePresignedUrls = {
  __typename?: 'RenterViolationImagePresignedUrls';
  parkingCameraViolationImageUrl?: Maybe<Scalars['String']['output']>;
  parkingTicketImageUrl?: Maybe<Scalars['String']['output']>;
  towTicketImageUrl?: Maybe<Scalars['String']['output']>;
};

export type Renters2FACheckResponse = {
  __typename?: 'Renters2FACheckResponse';
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Renters2FAVerificationCode = {
  __typename?: 'Renters2FAVerificationCode';
  verificationCode?: Maybe<Scalars['String']['output']>;
};

export type RentersFieldsToValidate = {
  __typename?: 'RentersFieldsToValidate';
  dateOfBirth?: Maybe<Scalars['Boolean']['output']>;
  idNumber?: Maybe<Scalars['Boolean']['output']>;
  paymentmethodLastFour?: Maybe<Scalars['Boolean']['output']>;
};

export type RentersList = {
  __typename?: 'RentersList';
  count: Scalars['Int']['output'];
  results: Array<CXRenter>;
};

export type RentersViolationsInput = {
  rentalId?: InputMaybe<Scalars['ID']['input']>;
  renterId: Scalars['ID']['input'];
  renterViolationId?: InputMaybe<Scalars['ID']['input']>;
  rideHailId?: InputMaybe<Scalars['ID']['input']>;
};

export type Repair = {
  __typename?: 'Repair';
  comment?: Maybe<Scalars['String']['output']>;
  completedBy: Operator;
  createdAt: Scalars['DateTime']['output'];
  fleetResource: FleetResource;
  fleetResourceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  repairType: RepairType;
  reservation?: Maybe<Reservation>;
  task?: Maybe<Task>;
  workOrder?: Maybe<WorkOrder>;
};

export type RepairFilters = {
  completedById?: InputMaybe<Scalars['ID']['input']>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  repairTypeId?: InputMaybe<Scalars['ID']['input']>;
  reservationId?: InputMaybe<Scalars['ID']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type RepairGroup = {
  __typename?: 'RepairGroup';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  repairTypes: Array<RepairType>;
};

export type RepairType = {
  __typename?: 'RepairType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  performanceValue: Scalars['Float']['output'];
};

export type ReportDriverShiftIssueInput = {
  imageS3Paths?: InputMaybe<Array<Scalars['String']['input']>>;
  issueType: DriverShiftIssueTypes;
  reportComment?: InputMaybe<Scalars['String']['input']>;
  shiftId: Scalars['ID']['input'];
  startLocation?: InputMaybe<PointInput>;
};

export type RequestDriverDeleteInput = {
  deletedReasons?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResendBackgroundCheckInput = {
  driverId: Scalars['ID']['input'];
};

export type ResendDocusignDocumentsInput = {
  driverId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
};

export type Reservation = {
  __typename?: 'Reservation';
  end?: Maybe<Scalars['DateTime']['output']>;
  fleetResource: FleetResource;
  fleetResourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  operator: Operator;
  reason: Scalars['String']['output'];
  repairs: Array<Repair>;
  start: Scalars['DateTime']['output'];
};

export type ReservationBillingSettings = {
  __typename?: 'ReservationBillingSettings';
  ezPassRental: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  insurancePremiumTier: InsurancePremiumTiers;
  insurancePremiumTierDaily: InsurancePremiumTiersDaily;
};

export type ReservationContract = {
  __typename?: 'ReservationContract';
  acceptedAt: Scalars['DateTime']['output'];
  legalDocument: LegalDocument;
  reservationId: Scalars['ID']['output'];
};

export type ReservationEvent = {
  __typename?: 'ReservationEvent';
  driverId: Scalars['ID']['output'];
  eventType: ReservationEventType;
  reservationId: Scalars['ID']['output'];
};

export type ReservationEventInput = {
  reservationId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ReservationEventType {
  state_changed = 'state_changed',
}

export type ReservationSettings = {
  __typename?: 'ReservationSettings';
  preferredVehicleType?: Maybe<Scalars['String']['output']>;
};

export enum ReservationTypes {
  revel_rideshare = 'revel_rideshare',
  revel_w2 = 'revel_w2',
}

export enum ResourceCommand {
  generic_actuate_frunk = 'generic_actuate_frunk',
  generic_actuate_trunk = 'generic_actuate_trunk',
  generic_charge_port_open = 'generic_charge_port_open',
  generic_flash_lights = 'generic_flash_lights',
  generic_honk = 'generic_honk',
  generic_lock = 'generic_lock',
  generic_start = 'generic_start',
  generic_unlock = 'generic_unlock',
}

export type ResourceFilters = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  deployed?: InputMaybe<Scalars['Boolean']['input']>;
  division?: InputMaybe<Divisions>;
  driveOriginPoint?: InputMaybe<PointInput>;
  excludeResourceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hasBlockingTask?: InputMaybe<Scalars['Boolean']['input']>;
  inMaintenance?: InputMaybe<Scalars['Boolean']['input']>;
  inSession?: InputMaybe<Scalars['Boolean']['input']>;
  inUse?: InputMaybe<Scalars['Boolean']['input']>;
  isInHub?: InputMaybe<Scalars['Boolean']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  nearPoint?: InputMaybe<PointInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unblock?: InputMaybe<Scalars['Boolean']['input']>;
  withBlockedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  withGoogleDirections?: InputMaybe<Scalars['Boolean']['input']>;
  withLastServicedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ResourceMetadataTLCBase = {
  __typename?: 'ResourceMetadataTLCBase';
  name: Scalars['String']['output'];
  tlcBaseId: Scalars['String']['output'];
};

export type ResourceTimelineDetail = {
  __typename?: 'ResourceTimelineDetail';
  fleetResourceSession?: Maybe<FleetResourceSession>;
  locationAction?: Maybe<LocationAction>;
  reservation?: Maybe<Reservation>;
  workOrder?: Maybe<WorkOrder>;
};

export enum ResourceTimelineEventType {
  assignment = 'assignment',
  location = 'location',
  location_group_bulk_command = 'location_group_bulk_command',
  rental = 'rental',
  reservation = 'reservation',
}

export type ResourceTimelineFilters = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<ResourceTimelineEventType>;
  fleetResourceId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type ResourceTimelineItem = {
  __typename?: 'ResourceTimelineItem';
  detail?: Maybe<ResourceTimelineDetail>;
  eventTime: Scalars['DateTime']['output'];
  eventType: Scalars['String']['output'];
};

export type ResourceTimelineResponse = {
  __typename?: 'ResourceTimelineResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<ResourceTimelineItem>>;
};

export type RestrictionReason = {
  __typename?: 'RestrictionReason';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  platform: RestrictionReasonPlatforms;
  reason: RestrictionReasonNameKeys;
  resolutionPriority: Scalars['Int']['output'];
};

export enum RestrictionReasonNameKeys {
  background_check_required = 'background_check_required',
  branch_wallet_activation_required = 'branch_wallet_activation_required',
  dmv_license_approval_required = 'dmv_license_approval_required',
  driver_address_required = 'driver_address_required',
  driver_revoked = 'driver_revoked',
  fleet_employment_review = 'fleet_employment_review',
  fleet_mandatory_drug_test_result_required = 'fleet_mandatory_drug_test_result_required',
  fleet_onboarding_required = 'fleet_onboarding_required',
  fleet_uber_account_reactivation_required = 'fleet_uber_account_reactivation_required',
  insurance_approval_required = 'insurance_approval_required',
  invoice_payment_overdue = 'invoice_payment_overdue',
  mutual_arbitration_agreement_signature_required = 'mutual_arbitration_agreement_signature_required',
  offboarding_initiated = 'offboarding_initiated',
  outstanding_invoice_balance_above_threshold = 'outstanding_invoice_balance_above_threshold',
  payment_method_required = 'payment_method_required',
  rental_restrictions = 'rental_restrictions',
  rental_terms_of_use_required = 'rental_terms_of_use_required',
  ridehail_agreement_document_signatures_required = 'ridehail_agreement_document_signatures_required',
  services_agreement_signature_required = 'services_agreement_signature_required',
  tax_verification_required = 'tax_verification_required',
  tlc_compliance_required = 'tlc_compliance_required',
  vehicle_security_deposit_required = 'vehicle_security_deposit_required',
}

export enum RestrictionReasonPlatforms {
  fleet = 'fleet',
  rental = 'rental',
  rideshare = 'rideshare',
}

export enum RevelViolationCodes {
  moving = 'moving',
  operations = 'operations',
  parking = 'parking',
}

export type ReviewDriverIncidentDisputeInput = {
  incidentDisputeId: Scalars['ID']['input'];
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  status: ReviewDriverIncidentDisputeStatuses;
};

export enum ReviewDriverIncidentDisputeStatuses {
  accepted = 'accepted',
  denied = 'denied',
}

export type ReviewLicenseInput = {
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  status: ReviewLicenseStatuses;
};

export enum ReviewLicenseStatuses {
  approved = 'approved',
  rejected = 'rejected',
}

export type RideHailOfferTollFee = {
  __typename?: 'RideHailOfferTollFee';
  amount: Scalars['Int']['output'];
  tollFee: TollFee;
};

export type RideHailTimeline = {
  __typename?: 'RideHailTimeline';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Point>;
  metadata?: Maybe<RideHailTimelineMetadata>;
  previousAddress?: Maybe<Scalars['String']['output']>;
  state?: Maybe<CXRideHailStates>;
  stopPoint?: Maybe<CXStopPoint>;
};

export type RideHailTimelineMetadata = {
  __typename?: 'RideHailTimelineMetadata';
  rideHailOfferId?: Maybe<Scalars['ID']['output']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RoleMember = {
  __typename?: 'RoleMember';
  id: Scalars['ID']['output'];
  identity: Identity;
  name: Scalars['String']['output'];
  role: Role;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
};

export enum SMSAuthAttemptUserContext {
  login = 'login',
  signup = 'signup',
}

export type ScheduleOverview = {
  __typename?: 'ScheduleOverview';
  completedShifts: Scalars['Int']['output'];
  openShiftsAvailableDurationHours?: Maybe<Array<Scalars['Int']['output']>>;
  overviewOf: Scalars['DateTime']['output'];
  scheduledShifts: Scalars['Int']['output'];
};

export type ScheduleOverviewInput = {
  depotId?: InputMaybe<Scalars['String']['input']>;
  driverId: Scalars['String']['input'];
  startAtGte: Scalars['String']['input'];
  startAtLt: Scalars['String']['input'];
  systemId: Scalars['String']['input'];
};

export type ScheduledBreakTimer = {
  __typename?: 'ScheduledBreakTimer';
  deferredBreakTimeAllowedSeconds: Scalars['Int']['output'];
  totalDurationSeconds: Scalars['Int']['output'];
  warnDurationSeconds: Scalars['Int']['output'];
};

export type ScheduledPickupTimer = {
  __typename?: 'ScheduledPickupTimer';
  noShowErrorDurationSeconds: Scalars['Int']['output'];
  noShowTriggerDurationSeconds: Scalars['Int']['output'];
  noShowWarnDurationSeconds: Scalars['Int']['output'];
};

export type SecurityDepositLedgerFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type SecurityDepositLedgerRecord = {
  __typename?: 'SecurityDepositLedgerRecord';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invoice?: Maybe<DriverInvoice>;
  invoiceId?: Maybe<Scalars['ID']['output']>;
  payout?: Maybe<SecurityDepositPayout>;
  transactionType: LedgerTransactionType;
};

export type SecurityDepositLedgerResponse = {
  __typename?: 'SecurityDepositLedgerResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<SecurityDepositLedgerRecord>>;
};

export type SecurityDepositPayout = {
  __typename?: 'SecurityDepositPayout';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedByIdentityId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  driver?: Maybe<Driver>;
  driverId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  offboardingInitiatedAt?: Maybe<Scalars['DateTime']['output']>;
  payoutItems?: Maybe<Array<SecurityDepositPayoutItem>>;
  status: SecurityDepositPayoutStatuses;
  total: Scalars['Int']['output'];
};

export type SecurityDepositPayoutFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status__in?: InputMaybe<Array<SecurityDepositPayoutStatuses>>;
};

export type SecurityDepositPayoutItem = {
  __typename?: 'SecurityDepositPayoutItem';
  amount: Scalars['Int']['output'];
  displayDetailText?: Maybe<Scalars['String']['output']>;
  displayTitleText?: Maybe<Scalars['String']['output']>;
  invoiceId?: Maybe<Scalars['ID']['output']>;
  itemType: SecurityDepositPayoutItemTypes;
  order: Scalars['Int']['output'];
};

export enum SecurityDepositPayoutItemTypes {
  outstanding_invoice = 'outstanding_invoice',
  security_deposit_release = 'security_deposit_release',
}

export enum SecurityDepositPayoutStatuses {
  issued = 'issued',
  pending = 'pending',
}

export type SecurityDepositPayoutsResponse = {
  __typename?: 'SecurityDepositPayoutsResponse';
  count: Scalars['Int']['output'];
  results: Array<SecurityDepositPayout>;
};

export type Sensors = {
  __typename?: 'Sensors';
  data: RawSensors;
  location: Point;
  updateTime?: Maybe<Scalars['DateTime']['output']>;
  updated: Scalars['DateTime']['output'];
};

export type ServiceArea = {
  __typename?: 'ServiceArea';
  created: Scalars['DateTime']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  product: ServiceAreaProduct;
  serviceAreaGeojson?: Maybe<MultiPolygon>;
  serviceAreaInvertedGeojson?: Maybe<MultiPolygon>;
  system: System;
};

export type ServiceAreaList = {
  __typename?: 'ServiceAreaList';
  count: Scalars['Int']['output'];
  results: Array<ServiceArea>;
};

export enum ServiceAreaProduct {
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export enum ServiceContext {
  assignment = 'assignment',
  location_group_bulk_command = 'location_group_bulk_command',
  rental = 'rental',
  reservation = 'reservation',
}

export type ServiceLog = {
  __typename?: 'ServiceLog';
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  fleetResourceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  odometerAtService: Scalars['Int']['output'];
  servicedAt: Scalars['DateTime']['output'];
  servicedBy: Identity;
  servicedByIdentityId: Scalars['String']['output'];
};

export type SetRenterDiscountRateInput = {
  discountRateId: Scalars['ID']['input'];
  renterId: Scalars['ID']['input'];
};

export type ShiftBreak = {
  __typename?: 'ShiftBreak';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<ShiftBreakTypes>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ShiftBreakTypes {
  scheduled = 'scheduled',
  unscheduled = 'unscheduled',
}

export type ShiftCancellation = {
  __typename?: 'ShiftCancellation';
  canceledByContext?: Maybe<Scalars['String']['output']>;
  canceledById?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dayforceCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  dayforceManuallyEnteredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  shiftCancellationReason: ShiftCancellationReason;
  shortNote?: Maybe<Scalars['String']['output']>;
  xrefCode?: Maybe<Scalars['String']['output']>;
};

export type ShiftCancellationReason = {
  __typename?: 'ShiftCancellationReason';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
  warningText?: Maybe<Scalars['String']['output']>;
};

export type ShiftExemptionInput = {
  attendancePercentage?: InputMaybe<Scalars['Boolean']['input']>;
  avgPickupDelaySeconds?: InputMaybe<Scalars['Boolean']['input']>;
  driverAvailabilityPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  endOfShiftRecallDelaySeconds?: InputMaybe<Scalars['Boolean']['input']>;
  missedRideCount?: InputMaybe<Scalars['Boolean']['input']>;
  negativeRatingCount?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShiftIssue = {
  __typename?: 'ShiftIssue';
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']['output']>;
  endLocation?: Maybe<Point>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  fleetResourceId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  imageUrls?: Maybe<Array<Scalars['String']['output']>>;
  issueType: DriverShiftIssueTypes;
  reportComment?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<Scalars['String']['output']>;
  shift?: Maybe<DriverShift>;
  shiftId?: Maybe<Scalars['ID']['output']>;
  startLocation?: Maybe<Point>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ShiftModificationsInput = {
  finalizedAt?: InputMaybe<Scalars['DateTime']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ShiftNote = {
  __typename?: 'ShiftNote';
  createdAt: Scalars['DateTime']['output'];
  createdByIdentityId: Scalars['ID']['output'];
  note: Scalars['String']['output'];
};

export type ShiftPay = {
  __typename?: 'ShiftPay';
  payCents: Scalars['Int']['output'];
  premiumCents: Scalars['Int']['output'];
  shiftId: Scalars['ID']['output'];
};

export type ShiftResourceMetadata = {
  __typename?: 'ShiftResourceMetadata';
  batteryEnd?: Maybe<Scalars['Float']['output']>;
  batteryStart?: Maybe<Scalars['Float']['output']>;
  driverReachedVehicleAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  licensePlate?: Maybe<Scalars['String']['output']>;
  locationEnd?: Maybe<Point>;
  locationStart?: Maybe<Point>;
  netradyneEndSentAt?: Maybe<Scalars['DateTime']['output']>;
  netradyneStartSentAt?: Maybe<Scalars['DateTime']['output']>;
  odometerMetersEnd?: Maybe<Scalars['Int']['output']>;
  odometerMetersStart?: Maybe<Scalars['Int']['output']>;
  resourceAssignedAt?: Maybe<Scalars['DateTime']['output']>;
  resourceId: Scalars['ID']['output'];
  resourceRemovedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ShiftResourceMetadataResponse = {
  __typename?: 'ShiftResourceMetadataResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<ShiftResourceMetadata>>;
};

export type ShiftReview = {
  __typename?: 'ShiftReview';
  createdAt: Scalars['DateTime']['output'];
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  issues?: Maybe<Array<ShiftReviewIssue>>;
  reviewersNote?: Maybe<Scalars['String']['output']>;
  shiftId: Scalars['ID']['output'];
  state: ShiftReviewState;
};

export type ShiftReviewIssue = {
  __typename?: 'ShiftReviewIssue';
  createdAt: Scalars['DateTime']['output'];
  issueType: ShiftReviewIssueType;
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ShiftReviewIssueType {
  adhoc_shift_edit = 'adhoc_shift_edit',
  attestation = 'attestation',
  driver_clock_adjustment_request = 'driver_clock_adjustment_request',
  rippling_time_entry_overlap = 'rippling_time_entry_overlap',
  shift_evaluation = 'shift_evaluation',
}

export enum ShiftReviewState {
  complete = 'complete',
  in_review = 'in_review',
}

export type ShiftReviewsFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  shiftId?: InputMaybe<Scalars['ID']['input']>;
  stateIn?: InputMaybe<Array<ShiftReviewState>>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type ShiftReviewsResponse = {
  __typename?: 'ShiftReviewsResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<ShiftReview>>;
};

export type ShiftSettings = {
  __typename?: 'ShiftSettings';
  appVersion?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<DeviceTypes>;
  dynamicRecallExtensionEnabled?: Maybe<Scalars['Boolean']['output']>;
  dynamicRecallReductionEnabled?: Maybe<Scalars['Boolean']['output']>;
  exemptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneType?: Maybe<Scalars['String']['output']>;
};

export type ShiftSettingsInput = {
  dynamicRecallExtensionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dynamicRecallReductionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  optionalRecallExtensionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShiftStats = {
  __typename?: 'ShiftStats';
  attendancePercentage?: Maybe<Scalars['Float']['output']>;
  attendancePercentageThreshold?: Maybe<Scalars['Float']['output']>;
  averageDriverRating?: Maybe<Scalars['Float']['output']>;
  averageNumberOfSecondsToAckRides?: Maybe<Scalars['Int']['output']>;
  avgPickupDelaySeconds?: Maybe<Scalars['Int']['output']>;
  avgPickupDelaySecondsThreshold?: Maybe<Scalars['Int']['output']>;
  driverAvailabilityPercentage?: Maybe<Scalars['Float']['output']>;
  driverAvailabilityPercentageThreshold?: Maybe<Scalars['Float']['output']>;
  driverPerformanceStatus?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endOfShiftRecallDelaySeconds?: Maybe<Scalars['Int']['output']>;
  endOfShiftRecallDelaySecondsThreshold?: Maybe<Scalars['Int']['output']>;
  exemptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  missedRideCount?: Maybe<Scalars['Int']['output']>;
  missedRideCountThreshold?: Maybe<Scalars['Int']['output']>;
  negativeRatingCount?: Maybe<Scalars['Int']['output']>;
  negativeRatingCountThreshold?: Maybe<Scalars['Int']['output']>;
  numberOfCompletedRides?: Maybe<Scalars['Int']['output']>;
  numberOfFailedToAckRides?: Maybe<Scalars['Int']['output']>;
  paidDuration?: Maybe<Scalars['Int']['output']>;
  percentageOfAvailability?: Maybe<Scalars['Float']['output']>;
  performancePayMultiplier?: Maybe<Scalars['Float']['output']>;
  performanceStatusThresholds?: Maybe<Scalars['JSON']['output']>;
  secondsInBreak?: Maybe<Scalars['Int']['output']>;
  secondsInIssue?: Maybe<Scalars['Int']['output']>;
  shiftPerformanceStatus?: Maybe<Scalars['Float']['output']>;
};

export type ShiftStopPointResponse = {
  __typename?: 'ShiftStopPointResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverStopPoint>>;
};

export type ShiftStopPointsFilter = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ShiftUpdatedEvent = {
  __typename?: 'ShiftUpdatedEvent';
  eventType: ShiftUpdatedEventType;
  shift: DriverShift;
};

export type ShiftUpdatedEventInput = {
  eventTypes?: InputMaybe<Array<ShiftUpdatedEventType>>;
  shiftId?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ShiftUpdatedEventType {
  ISSUE_CREATED = 'ISSUE_CREATED',
  ISSUE_RESOLVED = 'ISSUE_RESOLVED',
  ISSUE_UPDATED = 'ISSUE_UPDATED',
  ROUTE_UPDATED = 'ROUTE_UPDATED',
  STATE_CHANGED = 'STATE_CHANGED',
  STOP_POINT_ADDED = 'STOP_POINT_ADDED',
  STOP_POINT_UPDATED = 'STOP_POINT_UPDATED',
}

export type SignedDocuments = {
  __typename?: 'SignedDocuments';
  signedUrls?: Maybe<Scalars['SignedUrls']['output']>;
};

export type SignedDocumentsFilters = {
  bucketName: Scalars['String']['input'];
  fileNames: Array<Scalars['String']['input']>;
};

export type SoftDeleteRenterInput = {
  reason: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
};

export type StaleVehicleLocationTimer = {
  __typename?: 'StaleVehicleLocationTimer';
  errorDurationSeconds: Scalars['Int']['output'];
  warnDurationSeconds: Scalars['Int']['output'];
};

export type StartDriverScheduledShiftInput = {
  location?: InputMaybe<PointInput>;
  locationAccuracy?: InputMaybe<Scalars['Float']['input']>;
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type StartDriverTaskInput = {
  location: PointInput;
  locationAccuracy: Scalars['Float']['input'];
};

export type StartReservationInput = {
  location: PointInput;
  locationAccuracy: Scalars['Float']['input'];
  reservationId: Scalars['ID']['input'];
};

export enum StatusActors {
  CRON = 'CRON',
  CX = 'CX',
  EXPIRED_FIRST_RIDE_CRON = 'EXPIRED_FIRST_RIDE_CRON',
  EXPIRED_PRE_RESERVATION_CRON = 'EXPIRED_PRE_RESERVATION_CRON',
  EXPIRED_RIDER_SAFETY_CRON = 'EXPIRED_RIDER_SAFETY_CRON',
  INACTIVE_RENTAL_CRON = 'INACTIVE_RENTAL_CRON',
  RENTER = 'RENTER',
}

export enum StopPointManipulationTypes {
  ARRIVE = 'ARRIVE',
  COMPLETE = 'COMPLETE',
  NO_SHOW = 'NO_SHOW',
  REMATCH = 'REMATCH',
}

export type StoppointMeta = {
  __typename?: 'StoppointMeta';
  canBeCanceled?: Maybe<Scalars['Boolean']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  displayNameShort?: Maybe<Scalars['String']['output']>;
  doneText?: Maybe<Scalars['String']['output']>;
  genericTypeId?: Maybe<Scalars['ID']['output']>;
  issueId?: Maybe<Scalars['ID']['output']>;
  issueType?: Maybe<DriverShiftIssueTypes>;
  remainingSnoozes?: Maybe<Scalars['Int']['output']>;
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  shiftEnd?: Maybe<StoppointMetaShiftEnd>;
};

export type StoppointMetaShiftEnd = {
  __typename?: 'StoppointMetaShiftEnd';
  gracePeriodSecondsAfterStartedAt: Scalars['Int']['output'];
};

export type SubmitDriverAttestationInput = {
  attestation: DriverAttestationInput;
  shiftId: Scalars['ID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  driverScheduledShiftEvent?: Maybe<DriverScheduledShiftEvent>;
  identityLogin?: Maybe<Identity>;
  onFleetResourceEvent?: Maybe<FleetResourceEvent>;
  onParkingLotEvent?: Maybe<HubEvent>;
  onPassengerRidehailEvent: PassengerRideHailEvent;
  reservationEvents?: Maybe<ReservationEvent>;
  shiftUpdated?: Maybe<ShiftUpdatedEvent>;
};

export type SubscriptiondriverScheduledShiftEventArgs = {
  input?: InputMaybe<DriverScheduledShiftEventFilters>;
};

export type SubscriptiononFleetResourceEventArgs = {
  input: OnFleetResourceEventInput;
};

export type SubscriptiononParkingLotEventArgs = {
  input: OnParkingLotEventInput;
};

export type SubscriptionreservationEventsArgs = {
  input?: InputMaybe<ReservationEventInput>;
};

export type SubscriptionshiftUpdatedArgs = {
  input?: InputMaybe<ShiftUpdatedEventInput>;
};

export type System = {
  __typename?: 'System';
  activeServiceAreaDetails?: Maybe<ServiceArea>;
  centerPoint: Point;
  currentServiceAreaId?: Maybe<Scalars['ID']['output']>;
  depots?: Maybe<Array<Depot>>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manualSystemClosures?: Maybe<Array<ManualSystemClosure>>;
  rentalProduct?: Maybe<SystemRentalProduct>;
  rideHailProduct?: Maybe<SystemRideHailProduct>;
  serviceArea?: Maybe<MultiPolygon>;
  serviceAreaDrafts: ServiceAreaList;
  serviceAreaHistory: SystemServiceAreaHistoryList;
  serviceAreaInverted?: Maybe<MultiPolygon>;
  serviceAreaInvertedString?: Maybe<Scalars['String']['output']>;
  serviceAreaLastUpdated?: Maybe<Scalars['DateTime']['output']>;
  serviceAreaString?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<SystemSettings>;
  status?: Maybe<Scalars['String']['output']>;
  systemIdentifier?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  zones: Array<Zone>;
};

export type SystemMapResource = {
  __typename?: 'SystemMapResource';
  batteryLevel: Scalars['Int']['output'];
  filters: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastSessionByOps?: Maybe<Scalars['DateTime']['output']>;
  lastUse?: Maybe<Scalars['DateTime']['output']>;
  lastUseByConsumer?: Maybe<Scalars['DateTime']['output']>;
  licensePlate: Scalars['String']['output'];
  location: Point;
};

export type SystemMapResourceFilters = {
  assignmentSession?: InputMaybe<Scalars['Boolean']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  consumerSession?: InputMaybe<Scalars['Boolean']['input']>;
  discharged?: InputMaybe<Scalars['Boolean']['input']>;
  dischargedSwappable?: InputMaybe<Scalars['Boolean']['input']>;
  inHub?: InputMaybe<Scalars['Boolean']['input']>;
  inLocationGroup?: InputMaybe<Scalars['Boolean']['input']>;
  reservationSession?: InputMaybe<Scalars['Boolean']['input']>;
  specialOps?: InputMaybe<Scalars['Boolean']['input']>;
  towToShop?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SystemMapResourcesList = {
  __typename?: 'SystemMapResourcesList';
  count: Scalars['Int']['output'];
  results: Array<SystemMapResource>;
};

export type SystemMapStats = {
  __typename?: 'SystemMapStats';
  assignmentSession: Scalars['Int']['output'];
  available: Scalars['Int']['output'];
  averageBatteryLevel: Scalars['Float']['output'];
  blocked: Scalars['Int']['output'];
  consumerSession: Scalars['Int']['output'];
  currentDailyRentalsCount: Scalars['Int']['output'];
  discharged: Scalars['Int']['output'];
  dischargedSwappable: Scalars['Int']['output'];
  rentalsTodayCount: Scalars['Int']['output'];
  reservationSession: Scalars['Int']['output'];
  specialOps: Scalars['Int']['output'];
  towToShop: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
};

export type SystemPricing = {
  __typename?: 'SystemPricing';
  currencyCode?: Maybe<Scalars['String']['output']>;
  firstPassengerFee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  rentalActiveRate?: Maybe<Scalars['Int']['output']>;
  rentalPausedRate?: Maybe<Scalars['Int']['output']>;
  secondPassengerFee?: Maybe<Scalars['Int']['output']>;
  signUpFee?: Maybe<Scalars['Int']['output']>;
  systemId: Scalars['String']['output'];
  taxPercentage?: Maybe<Scalars['String']['output']>;
};

export type SystemRentalProduct = {
  __typename?: 'SystemRentalProduct';
  closeTime?: Maybe<Scalars['Time']['output']>;
  manualClosureReason?: Maybe<Scalars['String']['output']>;
  openTime?: Maybe<Scalars['Time']['output']>;
  serviceArea?: Maybe<ServiceArea>;
  serviceAreaDrafts?: Maybe<ServiceAreaList>;
  serviceAreaHistory?: Maybe<SystemServiceAreaHistoryList>;
  systemId: Scalars['ID']['output'];
};

export type SystemRideHailProduct = {
  __typename?: 'SystemRideHailProduct';
  closeTime?: Maybe<Scalars['Time']['output']>;
  manualClosureReason?: Maybe<Scalars['String']['output']>;
  openTime?: Maybe<Scalars['Time']['output']>;
  serviceArea?: Maybe<ServiceArea>;
  serviceAreaDrafts?: Maybe<ServiceAreaList>;
  serviceAreaHistory?: Maybe<SystemServiceAreaHistoryList>;
  systemId: Scalars['ID']['output'];
};

export type SystemServiceAreaHistory = {
  __typename?: 'SystemServiceAreaHistory';
  createdAt: Scalars['DateTime']['output'];
  createdByIdentityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  serviceArea: ServiceArea;
};

export type SystemServiceAreaHistoryList = {
  __typename?: 'SystemServiceAreaHistoryList';
  count: Scalars['Int']['output'];
  results: Array<SystemServiceAreaHistory>;
};

export type SystemSettings = {
  __typename?: 'SystemSettings';
  lowBatteryTagPercentage?: Maybe<Scalars['Int']['output']>;
};

export type SystemZoneDraftsList = {
  __typename?: 'SystemZoneDraftsList';
  count?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<OpsSystemZoneDraft>>;
};

export type TagCX = {
  __typename?: 'TagCX';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Task = {
  __typename?: 'Task';
  assigned: Operator;
  closeReason?: Maybe<Scalars['String']['output']>;
  closedBy?: Maybe<Identity>;
  closedByIdentityId?: Maybe<Scalars['ID']['output']>;
  comments?: Maybe<Array<TaskComment>>;
  created?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Identity>;
  createdByIdentityId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fleetResourceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  taskType: TaskType;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskCategory = {
  __typename?: 'TaskCategory';
  created?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  taskTypes?: Maybe<Array<TaskType>>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export enum TaskCloseReason {
  ISSUE_NOT_PRESENT = 'ISSUE_NOT_PRESENT',
  NEW_TASK_CREATED = 'NEW_TASK_CREATED',
  REPAIRED = 'REPAIRED',
  REPLACED = 'REPLACED',
}

export type TaskComment = {
  __typename?: 'TaskComment';
  comment: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identity: Identity;
  identityId: Scalars['ID']['output'];
  operator?: Maybe<Operator>;
  operatorId?: Maybe<Scalars['ID']['output']>;
  taskId: Scalars['ID']['output'];
};

export type TaskFilter = {
  __typename?: 'TaskFilter';
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  taskTypes?: Maybe<Array<TaskType>>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskModificationsInput = {
  finalizedAt?: InputMaybe<Scalars['DateTime']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  taskId: Scalars['ID']['input'];
};

export type TaskType = {
  __typename?: 'TaskType';
  blocking: Scalars['Boolean']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentTaskCategoryId: Scalars['ID']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type TerritoryFee = {
  __typename?: 'TerritoryFee';
  amount?: Maybe<Scalars['Int']['output']>;
  feeType: TerritoryFeeInvoiceType;
  id: Scalars['ID']['output'];
  nameKey?: Maybe<Scalars['String']['output']>;
  percentageRate?: Maybe<Scalars['Float']['output']>;
  userDescription?: Maybe<Scalars['String']['output']>;
};

export enum TerritoryFeeInvoiceType {
  airport = 'airport',
  black_car = 'black_car',
  congestion = 'congestion',
  mta_congestion = 'mta_congestion',
  nj_surcharge = 'nj_surcharge',
  toll = 'toll',
}

export enum TeslaCommand {
  SET_AUTO_CONDITIONING_START = 'SET_AUTO_CONDITIONING_START',
  SET_AUTO_CONDITIONING_STOP = 'SET_AUTO_CONDITIONING_STOP',
  SET_MEDIA_TOGGLE_PLAYBACK = 'SET_MEDIA_TOGGLE_PLAYBACK',
  SET_MEDIA_VOLUME_DOWN = 'SET_MEDIA_VOLUME_DOWN',
  SET_MEDIA_VOLUME_UP = 'SET_MEDIA_VOLUME_UP',
  SET_SEAT_HEATER = 'SET_SEAT_HEATER',
  SET_TEMPERATURE = 'SET_TEMPERATURE',
}

export enum TeslaSensorOnOff {
  off = 'off',
  on = 'on',
}

export type Timers = {
  __typename?: 'Timers';
  pickup: PickupTimer;
  scheduledBreak: ScheduledBreakTimer;
  scheduledPickup: ScheduledPickupTimer;
  staleVehicleLocation: StaleVehicleLocationTimer;
};

export enum TinStatus {
  CREATED = 'CREATED',
  MATCH = 'MATCH',
  MISMATCH = 'MISMATCH',
  PENDING = 'PENDING',
}

export type Tip = {
  __typename?: 'Tip';
  amount: Scalars['Int']['output'];
  category?: Maybe<Scalars['String']['output']>;
  curencyCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['String']['output']>;
};

export type Token = {
  __typename?: 'Token';
  created: Scalars['DateTime']['output'];
  expires: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type TollFee = {
  __typename?: 'TollFee';
  startTollGuruRoad: Scalars['String']['output'];
  userDescription?: Maybe<Scalars['String']['output']>;
};

export type Track = {
  __typename?: 'Track';
  currentDriverTrackLevel?: Maybe<DriverTrackLevel>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxPoints?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type TrackcurrentDriverTrackLevelArgs = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
};

export type TrackFilters = {
  trackType?: InputMaybe<Scalars['String']['input']>;
};

export type TrackLevel = {
  __typename?: 'TrackLevel';
  acknowledgementRequired?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  level: Scalars['String']['output'];
  pointThreshold: Scalars['Int']['output'];
  trackId: Scalars['ID']['output'];
};

export type TrackResponse = {
  __typename?: 'TrackResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<Track>>;
};

export type TrafficViolationTicket = {
  __typename?: 'TrafficViolationTicket';
  amountDue?: Maybe<Scalars['Int']['output']>;
  cityViolationCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  driver?: Maybe<Driver>;
  fineAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  interestAmount?: Maybe<Scalars['Int']['output']>;
  issuedAt: Scalars['DateTime']['output'];
  licensePlate: Scalars['String']['output'];
  paymentAmount?: Maybe<Scalars['Int']['output']>;
  penaltyAmount?: Maybe<Scalars['Int']['output']>;
  reductionAmount?: Maybe<Scalars['Int']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  revelViolationCode?: Maybe<RevelViolationCodes>;
  shift?: Maybe<DriverShift>;
  stopPoint?: Maybe<DriverStopPoint>;
  summonsNumber: Scalars['String']['output'];
  ticketIssuingAgency?: Maybe<Scalars['String']['output']>;
  ticketUrl?: Maybe<Scalars['String']['output']>;
};

export type TrafficViolationTicketFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  issued_at__gte?: InputMaybe<Scalars['DateTime']['input']>;
  issued_at__lte?: InputMaybe<Scalars['DateTime']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  shiftId?: InputMaybe<Scalars['ID']['input']>;
};

export enum TransitionRentalStatusEnum {
  FIRST_RIDE_EDUCATION = 'FIRST_RIDE_EDUCATION',
  HELMET_CASE_UNLOCKED = 'HELMET_CASE_UNLOCKED',
  HELMET_CASE_UNLOCKING = 'HELMET_CASE_UNLOCKING',
  PASSENGER_SELECTION = 'PASSENGER_SELECTION',
  VEHICLE_STARTED = 'VEHICLE_STARTED',
}

export type UberDriverAction = {
  __typename?: 'UberDriverAction';
  actionDuration?: Maybe<Scalars['Int']['output']>;
  actionReason?: Maybe<Scalars['String']['output']>;
  actionType?: Maybe<Scalars['String']['output']>;
  eventAt: Scalars['DateTime']['output'];
  eventId: Scalars['String']['output'];
};

export type UnformattedDriverReceiptItem = {
  __typename?: 'UnformattedDriverReceiptItem';
  amount: Scalars['Int']['output'];
  itemKey: Scalars['String']['output'];
  itemType: Scalars['String']['output'];
  label: Scalars['String']['output'];
  parentItemKey?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  subLabel: Scalars['String']['output'];
};

export type UnlinkPassengerInput = {
  passengerAccountId: Scalars['ID']['input'];
};

export type UpdateAssignmentInput = {
  assignmentType?: InputMaybe<Scalars['String']['input']>;
  batteryLevelThreshold?: InputMaybe<Scalars['Int']['input']>;
  dispatchNotes?: InputMaybe<Scalars['String']['input']>;
  operatorId?: InputMaybe<Scalars['ID']['input']>;
  startLocation?: InputMaybe<PointInput>;
  taskTypeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unblock?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDriverAddressInput = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  addressType: DriverAddressTypes;
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type UpdateDriverInsuranceApprovalInput = {
  driverId: Scalars['ID']['input'];
  insuranceProviderIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type UpdateDriverPaymentMethodInput = {
  isSelected?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethodId: Scalars['ID']['input'];
};

export type UpdateDriverProfileInfoInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  insuranceProviderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateDriverProfileInput = {
  addresses?: InputMaybe<Array<InputMaybe<UpdateDriverAddressInput>>>;
};

export type UpdateDriverReservationDepotInput = {
  id: Scalars['ID']['input'];
};

export type UpdateDriverReservationInput = {
  depot?: InputMaybe<UpdateDriverReservationDepotInput>;
  reservationId: Scalars['ID']['input'];
  scheduledEndAt?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateDriverScheduledShiftTimeInput = {
  scheduledEndAt?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAt?: InputMaybe<Scalars['String']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type UpdateDriverShiftDeviceInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  deviceId: Scalars['String']['input'];
  deviceType?: InputMaybe<DeviceTypes>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  tokenType: PushTokenTypes;
};

export type UpdateDriverShiftInput = {
  actualEndAt?: InputMaybe<Scalars['String']['input']>;
  actualStartAt?: InputMaybe<Scalars['String']['input']>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  scheduledEndAt?: InputMaybe<Scalars['String']['input']>;
  scheduledRecallAt?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAt?: InputMaybe<Scalars['String']['input']>;
  shiftId: Scalars['String']['input'];
};

export type UpdateDriverShiftStateInput = {
  shiftId: Scalars['ID']['input'];
  state: DriverShiftStates;
};

export type UpdateDriverTaskScheduleInput = {
  windowEndAt?: InputMaybe<Scalars['DateTime']['input']>;
  windowStartAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateDriverTaskTimesInput = {
  finalizedAt?: InputMaybe<Scalars['DateTime']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateDriverVehicleExclusionMakeAndModelInput = {
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
};

export type UpdateDriverVehicleExclusionsInput = {
  driverId: Scalars['ID']['input'];
  makesAndModels: Array<InputMaybe<UpdateDriverVehicleExclusionMakeAndModelInput>>;
};

export type UpdateDriverVehiclePreferenceInput = {
  driverId: Scalars['ID']['input'];
  vehicleType: DriverVehiclePreferenceType;
};

export type UpdateFlagInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  expirationTime?: InputMaybe<Scalars['DateTime']['input']>;
  flagTypeId?: InputMaybe<Scalars['ID']['input']>;
  internalTitle?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<PointInput>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateInternalDriverProfileInput = {
  addresses?: InputMaybe<Array<InputMaybe<UpdateDriverAddressInput>>>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  driverLicenseIssuer?: InputMaybe<Scalars['String']['input']>;
  driverLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employmentStatus?: InputMaybe<DriverUpdateEmploymentStatus>;
  ezPassRental?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fleetResourceId?: InputMaybe<Scalars['String']['input']>;
  insurancePremiumTier?: InputMaybe<InsurancePremiumTiers>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  tlcLicenseExpires?: InputMaybe<Scalars['String']['input']>;
  tlcLicenseNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLicenseInput = {
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<LicenseMetadataInput>;
  photoBackUri?: InputMaybe<Scalars['String']['input']>;
  photoFrontUri?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LicenseStatuses>;
  wheelchairAccessibleTrained?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOperatorInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdatePushNotificationReceivedInput = {
  messageId: Scalars['ID']['input'];
  receivedAt: Scalars['DateTime']['input'];
};

export type UpdateRecurringReservationInput = {
  cancelCurrentSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  endWeekday?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  startWeekday?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRecurringShiftInput = {
  deleteFutureScheduledShifts?: InputMaybe<Scalars['Boolean']['input']>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  endWeekday?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  startWeekday?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRenterPhoneNumberInput = {
  phoneNumber: Scalars['String']['input'];
};

export type UpdateRepairInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  completedById?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  repairTypeId?: InputMaybe<Scalars['ID']['input']>;
  reservationId?: InputMaybe<Scalars['ID']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateReservationBillingSettingsInput = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  insurancePremiumTierDaily?: InputMaybe<InsurancePremiumTiersDaily>;
};

export type UpdateShiftClockTimesInput = {
  actualEndAt?: InputMaybe<Scalars['DateTime']['input']>;
  actualStartAt?: InputMaybe<Scalars['DateTime']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type UpdateShiftVehicleLocationInput = {
  accuracy?: InputMaybe<Scalars['Float']['input']>;
  bearing?: InputMaybe<Scalars['Float']['input']>;
  location: PointInput;
  shiftId: Scalars['ID']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type UpdateWorkOrderInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateSummonsInput = {
  summonsNumber: Scalars['String']['input'];
};

export type ValidatedSummons = {
  __typename?: 'ValidatedSummons';
  summonsImageUrl: Scalars['String']['output'];
  summonsNumber: Scalars['ID']['output'];
};

export type VehicleFacility = {
  __typename?: 'VehicleFacility';
  address: Scalars['String']['output'];
  centerPoint: Point;
  description: Scalars['String']['output'];
  geofenceRadiusMeters: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type VehicleFacilityFilters = {
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type VehicleFacilityResponse = {
  __typename?: 'VehicleFacilityResponse';
  count: Scalars['Int']['output'];
  results: Array<VehicleFacility>;
};

export type VehicleStateSensors = {
  __typename?: 'VehicleStateSensors';
  locked?: Maybe<Scalars['Boolean']['output']>;
  odometer?: Maybe<Scalars['Float']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
};

export type VerificationCode = {
  __typename?: 'VerificationCode';
  id: Scalars['ID']['output'];
  verificationCode: Scalars['String']['output'];
};

export type Violation = {
  __typename?: 'Violation';
  automated: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ViolationActions {
  deletion_requested_permanent_suspension = 'deletion_requested_permanent_suspension',
  permanent_ebike_suspension = 'permanent_ebike_suspension',
  permanent_ridehail_suspension = 'permanent_ridehail_suspension',
  permanent_suspension = 'permanent_suspension',
  temporary_ridehail_suspension = 'temporary_ridehail_suspension',
  temporary_suspension = 'temporary_suspension',
  warning = 'warning',
}

export type VoidDriverInvoiceInput = {
  voidedReason: VoidedInvoiceReasonsEnum;
  voidedReasonContext?: InputMaybe<Scalars['String']['input']>;
};

export enum VoidedInvoiceReasonsEnum {
  driver_ended_reservation_early = 'driver_ended_reservation_early',
  driver_forgot_to_cancel = 'driver_forgot_to_cancel',
  incorrect_amount_charged = 'incorrect_amount_charged',
  other = 'other',
  restriction_issue = 'restriction_issue',
}

export type WaitlistProduct = {
  __typename?: 'WaitlistProduct';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enqueueMethod: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  waitlistClose?: Maybe<Scalars['DateTime']['output']>;
  waitlistOpen?: Maybe<Scalars['DateTime']['output']>;
};

export type WorkOrder = {
  __typename?: 'WorkOrder';
  atVehicleAt?: Maybe<Scalars['DateTime']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  created: Scalars['DateTime']['output'];
  createdFor?: Maybe<WorkOrderCreationContext>;
  driveTime?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endBatteryLevel?: Maybe<Scalars['Int']['output']>;
  fleetResource: FleetResource;
  fleetResourceId?: Maybe<Scalars['ID']['output']>;
  fleetResourceLocationCompleted?: Maybe<Point>;
  fleetResourceLocationCreated: Point;
  fleetResourceLocationUnlocked?: Maybe<Point>;
  id: Scalars['ID']['output'];
  repairs: Array<Repair>;
  resourceTasks?: Maybe<Array<Task>>;
  skipReason?: Maybe<WorkOrderSkipReason>;
  startBatteryLevel?: Maybe<Scalars['Int']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<WorkOrderStatus>;
  systemId?: Maybe<Scalars['ID']['output']>;
  workOrderTasks?: Maybe<Array<WorkOrderTask>>;
};

export type WorkOrderresourceTasksArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum WorkOrderCreationContext {
  BATTERY_SWAP = 'BATTERY_SWAP',
  FLEX = 'FLEX',
  TASK = 'TASK',
}

export type WorkOrderFilters = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum WorkOrderSkipReason {
  CANT_PARK = 'CANT_PARK',
  EMERGENCY = 'EMERGENCY',
  END_ASSIGNMENT = 'END_ASSIGNMENT',
  LOW_FUEL = 'LOW_FUEL',
  MOPED_DAMAGE = 'MOPED_DAMAGE',
  OTHER = 'OTHER',
  SKIPPED_IN_DISPATCH = 'SKIPPED_IN_DISPATCH',
  SYSTEM_UNABLE_TO_RESERVE = 'SYSTEM_UNABLE_TO_RESERVE',
  TELEMATICS = 'TELEMATICS',
  TRAFFIC = 'TRAFFIC',
  UNABLE_TO_LOCATE = 'UNABLE_TO_LOCATE',
  UNVERIFIED_SWAP = 'UNVERIFIED_SWAP',
}

export enum WorkOrderStatus {
  AT_VEHICLE = 'AT_VEHICLE',
  COMPLETE = 'COMPLETE',
  DRAFT = 'DRAFT',
  STARTED = 'STARTED',
  UPCOMING = 'UPCOMING',
}

export type WorkOrderTask = {
  __typename?: 'WorkOrderTask';
  comments?: Maybe<Array<TaskComment>>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  newTaskStatus?: Maybe<Scalars['String']['output']>;
  originalTaskStatus?: Maybe<Scalars['String']['output']>;
  task: Task;
  taskCloseReason?: Maybe<Scalars['String']['output']>;
  taskId: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
  workOrderId: Scalars['ID']['output'];
};

export enum WorkOrderType {
  BATTERY_SWAP = 'BATTERY_SWAP',
  FLEX = 'FLEX',
  TASK = 'TASK',
}

export type Zone = {
  __typename?: 'Zone';
  areaGeojsonString: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nameKey: Scalars['String']['output'];
  product: ServiceAreaProduct;
  system: System;
  systemId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
  zoneType: Scalars['String']['output'];
};

export type ZoneFilters = {
  product?: InputMaybe<ServiceAreaProduct>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
  zoneTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ZoneList = {
  __typename?: 'ZoneList';
  count: Scalars['Int']['output'];
  results: Array<Zone>;
};

export type commandFleetResourceInput = {
  command: NiuCommand;
};

export type updateRentersEmailInput = {
  email: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
};

export type updateRentersNameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
};

export type GetDriverVehicleSelectorVehiclesQueryVariables = Exact<{
  filters: DriverFleetResourceFilters;
}>;

export type GetDriverVehicleSelectorVehiclesQuery = {
  __typename?: 'Query';
  vehicles: {
    __typename?: 'DriverFleetResourcesResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverFleetResource';
      id: string;
      batteryLevel?: number | null;
      licensePlate: string;
      status?: string | null;
      color: string;
      location?: { __typename?: 'Point'; lat: number; lng: number } | null;
      activeShift?: { __typename?: 'DriverShift'; state: DriverShiftStates; id: string } | null;
      activeReservation?: {
        __typename?: 'RentalReservation';
        state: RentalReservationStates;
        id: string;
      } | null;
      assignedDriver?: { __typename?: 'Driver'; firstName: string; lastName: string } | null;
      detail: { __typename?: 'DriverFleetResourceDetail'; make: string; model: string };
      fleetResourceMetadata?: {
        __typename?: 'FleetResourceMetadata';
        insuranceProvider?: {
          __typename?: 'InsuranceProvider';
          displayName: string;
          id: string;
        } | null;
      } | null;
    } | null>;
  };
};

export type AdvancedVehicleSelector_vehicleFacilityFragment = {
  __typename?: 'VehicleFacility';
  name: string;
  id: string;
  geofenceRadiusMeters: number;
  centerPoint: { __typename?: 'Point'; lat: number; lng: number };
};

export type GetDepotSelectorDepotsQueryVariables = Exact<{
  systemIdentifier?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDepotSelectorDepotsQuery = {
  __typename?: 'Query';
  systems: Array<{
    __typename?: 'System';
    depots?: Array<{ __typename?: 'Depot'; id: string; name: string }> | null;
  }>;
};

export type IncidentInfo_incidentFragment = {
  __typename?: 'Incident';
  id: string;
  displayName?: string | null;
  points: number;
  expirationDays?: number | null;
  track?: { __typename?: 'Track'; name: string; displayName: string } | null;
};

export type DriverNotesCard_noteFragment = {
  __typename?: 'Note';
  id: string;
  createdAt: any;
  note: string;
  createdByIdentity?: { __typename?: 'Identity'; firstName: string; lastName: string } | null;
};

export type DriverShiftArrival_shiftFragment = {
  __typename?: 'DriverShift';
  driverArrivedForShiftAt?: any | null;
  shiftCancellation?: {
    __typename?: 'ShiftCancellation';
    shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
  } | null;
};

export type GetInvoiceDetailsSidebarQueryVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type GetInvoiceDetailsSidebarQuery = {
  __typename?: 'Query';
  invoice?: {
    __typename?: 'DriverInvoice';
    id: string;
    status: DriverInvoiceStatuses;
    voidedReason?: string | null;
    voidedAt?: any | null;
    descriptor?: DriverInvoiceDescriptors | null;
    createdAt: any;
    total: number;
    paymentIntents?: Array<{
      __typename?: 'DriverPaymentIntent';
      stripeExternalId?: string | null;
      status?: DriverPaymentIntentStatuses | null;
      createdAt?: any | null;
      paymentMethod?: {
        __typename?: 'DriverPaymentMethod';
        brand?: string | null;
        lastFour?: string | null;
        type?: DriverPaymentMethodTypes | null;
      } | null;
    }> | null;
    invoiceItems?: Array<{
      __typename?: 'DriverInvoiceItem';
      id?: string | null;
      amount?: number | null;
      displayTitleText?: string | null;
      displayDetailText?: string | null;
    }> | null;
    metadata?: { __typename?: 'InvoiceMetaData'; voidedReasonContext?: string | null } | null;
    driver?: {
      __typename?: 'Driver';
      stripeCustomerId?: string | null;
      id: string;
      firstName: string;
      lastName: string;
      email?: string | null;
      avatar?: string | null;
    } | null;
  } | null;
};

export type GetSystemsDepotsQueryVariables = Exact<{
  systemIdentifier: Scalars['String']['input'];
}>;

export type GetSystemsDepotsQuery = {
  __typename?: 'Query';
  systems: Array<{
    __typename?: 'System';
    depots?: Array<{
      __typename?: 'Depot';
      id: string;
      name: string;
      address: string;
      location: { __typename?: 'Point'; lat: number; lng: number };
    }> | null;
  }>;
};

export type GetDriverSystemZonesQueryVariables = Exact<{
  systemId: Scalars['ID']['input'];
}>;

export type GetDriverSystemZonesQuery = {
  __typename?: 'Query';
  zones?: Array<{
    __typename?: 'DriverSystemZone';
    id: string;
    areaInvertedGeojsonString?: string | null;
  }> | null;
};

export type ReservationActionDropdown_reservationFragment = {
  __typename?: 'RentalReservation';
  id: string;
  state: RentalReservationStates;
  scheduledStartAt: any;
  scheduledEndAt: any;
  actualStartAt?: any | null;
  actualEndAt?: any | null;
  depotId?: string | null;
  currentResourceId?: string | null;
  currentResource?: { __typename?: 'FleetResource'; id: string } | null;
};

export type ReservationActionShortcuts_reservationFragment = {
  __typename?: 'RentalReservation';
  id: string;
  currentResource?: { __typename?: 'FleetResource'; id: string } | null;
};

export type ListNotesReservationActionShortcutsQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type ListNotesReservationActionShortcutsQuery = {
  __typename?: 'Query';
  listNotes: { __typename?: 'NoteResponse'; count: number };
};

export type GetReservationDetailsSidebarQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type GetReservationDetailsSidebarQuery = {
  __typename?: 'Query';
  reservation?: {
    __typename?: 'RentalReservation';
    id: string;
    state: RentalReservationStates;
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
    reservationType: RentalReservationTypes;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      phoneNumber: string;
    } | null;
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      color?: string | null;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    } | null;
    settings?: { __typename?: 'ReservationSettings'; preferredVehicleType?: string | null } | null;
    billingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTier: InsurancePremiumTiers;
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  } | null;
};

export type RentalReservationDuration_reservationFragment = {
  __typename?: 'RentalReservation';
  scheduledEndAt: any;
  scheduledStartAt: any;
  actualStartAt?: any | null;
  actualEndAt?: any | null;
  state: RentalReservationStates;
  depot?: { __typename?: 'Depot'; id: string; name: string } | null;
};

export type GetReservationFilterCountQueryVariables = Exact<{
  filters: RentalReservationFilters;
}>;

export type GetReservationFilterCountQuery = {
  __typename?: 'Query';
  reservations: { __typename?: 'RentalReservationsResponse'; count: number };
};

export type ReservationInfoStack_reservationFragment = {
  __typename?: 'RentalReservation';
  scheduledStartAt: any;
  scheduledEndAt: any;
  actualStartAt?: any | null;
  actualEndAt?: any | null;
  reservationType: RentalReservationTypes;
  driver?: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    email?: string | null;
    phoneNumber: string;
  } | null;
  currentResource?: {
    __typename?: 'FleetResource';
    id: string;
    licensePlate: string;
    batteryLevel: number;
    color?: string | null;
    detail?: {
      __typename?: 'FleetResourceDetail';
      make?: string | null;
      model?: string | null;
    } | null;
    location: { __typename?: 'Point'; lat: number; lng: number };
  } | null;
  settings?: { __typename?: 'ReservationSettings'; preferredVehicleType?: string | null } | null;
  billingSettings?: {
    __typename?: 'ReservationBillingSettings';
    insurancePremiumTier: InsurancePremiumTiers;
    insurancePremiumTierDaily: InsurancePremiumTiersDaily;
  } | null;
  depot?: { __typename?: 'Depot'; id: string; name: string } | null;
};

export type ReservationVehicle_reservationFragment = {
  __typename?: 'RentalReservation';
  id: string;
  state: RentalReservationStates;
  depotId?: string | null;
  currentResourceId?: string | null;
  currentResource?: {
    __typename?: 'FleetResource';
    id: string;
    licensePlate: string;
    color?: string | null;
    batteryLevel: number;
    detail?: {
      __typename?: 'FleetResourceDetail';
      make?: string | null;
      model?: string | null;
    } | null;
  } | null;
};

export type ScheduledShiftActionDropdown_shiftFragment = {
  __typename?: 'DriverScheduledShift';
  id: string;
  state: DriverScheduledShiftState;
  scheduledStartAt: any;
  scheduledEndAt: any;
  startedAt?: any | null;
  finalizedAt?: any | null;
  depotId?: string | null;
  reservation?: {
    __typename?: 'RentalReservation';
    id: string;
    state: RentalReservationStates;
    currentResource?: { __typename?: 'FleetResource'; id: string } | null;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
    } | null;
  } | null;
};

export type GetScheduledShiftDetailsSidebarQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetScheduledShiftDetailsSidebarQuery = {
  __typename?: 'Query';
  scheduledShift: {
    __typename?: 'DriverScheduledShift';
    id: string;
    state: DriverScheduledShiftState;
    scheduledStartAt: any;
    scheduledEndAt: any;
    startedAt?: any | null;
    finalizedAt?: any | null;
    depotId?: string | null;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
    };
    reservation?: {
      __typename?: 'RentalReservation';
      id: string;
      state: RentalReservationStates;
      currentResource?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        batteryLevel: number;
        color?: string | null;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
        location: { __typename?: 'Point'; lat: number; lng: number };
      } | null;
      driver?: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      } | null;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
    allTasks: {
      __typename?: 'DriverTaskResponse';
      results?: Array<{
        __typename?: 'DriverTask';
        windowStartAt: any;
        windowEndAt?: any | null;
        state: DriverTaskState;
        startedAt?: any | null;
        finalizedAt?: any | null;
        taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
      }> | null;
    };
    activeTasks: {
      __typename?: 'DriverTaskResponse';
      results?: Array<{
        __typename?: 'DriverTask';
        taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
      }> | null;
    };
  };
};

export type ScheduledShiftDriverInfoField_shiftFragment = {
  __typename?: 'DriverScheduledShift';
  state: DriverScheduledShiftState;
  driver: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
  };
  activeTasks: {
    __typename?: 'DriverTaskResponse';
    results?: Array<{
      __typename?: 'DriverTask';
      taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
    }> | null;
  };
};

export type ScheduledShiftInfoStack_shiftFragment = {
  __typename?: 'DriverScheduledShift';
  id: string;
  scheduledStartAt: any;
  scheduledEndAt: any;
  startedAt?: any | null;
  finalizedAt?: any | null;
  state: DriverScheduledShiftState;
  driver: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    email?: string | null;
  };
  reservation?: {
    __typename?: 'RentalReservation';
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      color?: string | null;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    } | null;
  } | null;
  depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  allTasks: {
    __typename?: 'DriverTaskResponse';
    results?: Array<{
      __typename?: 'DriverTask';
      windowStartAt: any;
      windowEndAt?: any | null;
      state: DriverTaskState;
      startedAt?: any | null;
      finalizedAt?: any | null;
      taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
    }> | null;
  };
  activeTasks: {
    __typename?: 'DriverTaskResponse';
    results?: Array<{
      __typename?: 'DriverTask';
      taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
    }> | null;
  };
};

export type ScheduledShiftStatusChip_shiftFragment = {
  __typename?: 'DriverScheduledShift';
  state: DriverScheduledShiftState;
  activeTasks: {
    __typename?: 'DriverTaskResponse';
    results?: Array<{
      __typename?: 'DriverTask';
      taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
    }> | null;
  };
};

export type ScheduledShiftVehicle_shiftFragment = {
  __typename?: 'DriverScheduledShift';
  id: string;
  reservation?: {
    __typename?: 'RentalReservation';
    id: string;
    state: RentalReservationStates;
    currentResourceId?: string | null;
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      color?: string | null;
      batteryLevel: number;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
    } | null;
    depot?: { __typename?: 'Depot'; name: string } | null;
  } | null;
  activeTasks: {
    __typename?: 'DriverTaskResponse';
    results?: Array<{
      __typename?: 'DriverTask';
      taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
    }> | null;
  };
};

export type ShiftActionDropdown_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  clockIn?: any | null;
  scheduledStartAt: any;
  scheduledEndAt: any;
  reservationType?: ReservationTypes | null;
  note?: { __typename?: 'ShiftNote'; note: string } | null;
  driver: { __typename?: 'Driver'; id: string };
  depot?: { __typename?: 'Depot'; name: string } | null;
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    vehicle?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ShiftActionShortcuts_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  note?: { __typename?: 'ShiftNote'; note: string } | null;
  driver: { __typename?: 'Driver'; id: string };
};

export type ShiftClockOut_shiftFragment = {
  __typename?: 'DriverShift';
  clockIn?: any | null;
  clockOut?: any | null;
  depot?: { __typename?: 'Depot'; id: string } | null;
};

export type GetShiftInfoQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetShiftInfoQuery = {
  __typename?: 'Query';
  shift?: {
    __typename?: 'DriverShift';
    id: string;
    vehicleLocationUpdatedAt?: any | null;
    reservationType?: ReservationTypes | null;
    state: DriverShiftStates;
    issueType?: DriverShiftIssueTypes | null;
    currentIssueId?: string | null;
    clockIn?: any | null;
    scheduledStartAt: any;
    scheduledEndAt: any;
    scheduledRecallAt?: any | null;
    clockOut?: any | null;
    driverArrivedForShiftAt?: any | null;
    note?: { __typename?: 'ShiftNote'; note: string } | null;
    driver: {
      __typename?: 'Driver';
      id: string;
      dayforceXrefCode?: string | null;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      firstShiftCompleted?: boolean | null;
    };
    depot?: { __typename?: 'Depot'; name: string; id: string } | null;
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      vehicle?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        batteryLevel: number;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
      } | null;
    } | null;
    settings?: {
      __typename?: 'ShiftSettings';
      phoneNumber?: string | null;
      phoneType?: string | null;
      deviceType?: DeviceTypes | null;
      appVersion?: string | null;
    } | null;
    reservationBillingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
    breaks?: Array<{
      __typename?: 'DriverShiftBreak';
      id: string;
      type?: DriverShiftBreakTypes | null;
      scheduledStartAt?: any | null;
      scheduledEndAt?: any | null;
      actualStartAt?: any | null;
      actualEndAt?: any | null;
    }> | null;
    stats?: {
      __typename?: 'ShiftStats';
      negativeRatingCount?: number | null;
      negativeRatingCountThreshold?: number | null;
      missedRideCount?: number | null;
      missedRideCountThreshold?: number | null;
      avgPickupDelaySeconds?: number | null;
      avgPickupDelaySecondsThreshold?: number | null;
      driverAvailabilityPercentage?: number | null;
      driverAvailabilityPercentageThreshold?: number | null;
      attendancePercentage?: number | null;
      attendancePercentageThreshold?: number | null;
      endOfShiftRecallDelaySeconds?: number | null;
      endOfShiftRecallDelaySecondsThreshold?: number | null;
      exemptions?: Array<string | null> | null;
      performancePayMultiplier?: number | null;
      driverPerformanceStatus?: number | null;
      performanceStatusThresholds?: any | null;
    } | null;
    shiftCancellation?: {
      __typename?: 'ShiftCancellation';
      shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
    } | null;
    currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
  } | null;
};

export type ShiftDriverInfoField_shiftFragment = {
  __typename?: 'DriverShift';
  state: DriverShiftStates;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  reservationType?: ReservationTypes | null;
  driver: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    firstShiftCompleted?: boolean | null;
  };
  currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
};

export type GetShiftFilterCountQueryVariables = Exact<{
  filters: DriverShiftFilters;
}>;

export type GetShiftFilterCountQuery = {
  __typename?: 'Query';
  shifts: { __typename?: 'DriverShiftsResponse'; count: number };
};

export type ShiftInfoCard_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  scheduledStartAt: any;
  scheduledEndAt: any;
  scheduledRecallAt?: any | null;
  clockIn?: any | null;
  clockOut?: any | null;
  reservationType?: ReservationTypes | null;
  driverArrivedForShiftAt?: any | null;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  driver: {
    __typename?: 'Driver';
    id: string;
    dayforceXrefCode?: string | null;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    firstShiftCompleted?: boolean | null;
  };
  settings?: {
    __typename?: 'ShiftSettings';
    phoneNumber?: string | null;
    phoneType?: string | null;
    deviceType?: DeviceTypes | null;
    appVersion?: string | null;
  } | null;
  depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  reservationBillingSettings?: {
    __typename?: 'ReservationBillingSettings';
    insurancePremiumTierDaily: InsurancePremiumTiersDaily;
  } | null;
  breaks?: Array<{
    __typename?: 'DriverShiftBreak';
    id: string;
    type?: DriverShiftBreakTypes | null;
    scheduledStartAt?: any | null;
    scheduledEndAt?: any | null;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
  }> | null;
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    vehicle?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
    } | null;
  } | null;
  shiftCancellation?: {
    __typename?: 'ShiftCancellation';
    shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
  } | null;
  currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
};

export type ShiftInfoPanel_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  vehicleLocationUpdatedAt?: any | null;
  reservationType?: ReservationTypes | null;
  state: DriverShiftStates;
  scheduledStartAt: any;
  scheduledEndAt: any;
  scheduledRecallAt?: any | null;
  clockIn?: any | null;
  clockOut?: any | null;
  driverArrivedForShiftAt?: any | null;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  note?: { __typename?: 'ShiftNote'; note: string } | null;
  driver: {
    __typename?: 'Driver';
    id: string;
    dayforceXrefCode?: string | null;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    firstShiftCompleted?: boolean | null;
  };
  settings?: {
    __typename?: 'ShiftSettings';
    phoneNumber?: string | null;
    phoneType?: string | null;
    deviceType?: DeviceTypes | null;
    appVersion?: string | null;
  } | null;
  depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  reservationBillingSettings?: {
    __typename?: 'ReservationBillingSettings';
    insurancePremiumTierDaily: InsurancePremiumTiersDaily;
  } | null;
  breaks?: Array<{
    __typename?: 'DriverShiftBreak';
    id: string;
    type?: DriverShiftBreakTypes | null;
    scheduledStartAt?: any | null;
    scheduledEndAt?: any | null;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
  }> | null;
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    vehicle?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
    } | null;
  } | null;
  stats?: {
    __typename?: 'ShiftStats';
    negativeRatingCount?: number | null;
    negativeRatingCountThreshold?: number | null;
    missedRideCount?: number | null;
    missedRideCountThreshold?: number | null;
    avgPickupDelaySeconds?: number | null;
    avgPickupDelaySecondsThreshold?: number | null;
    driverAvailabilityPercentage?: number | null;
    driverAvailabilityPercentageThreshold?: number | null;
    attendancePercentage?: number | null;
    attendancePercentageThreshold?: number | null;
    endOfShiftRecallDelaySeconds?: number | null;
    endOfShiftRecallDelaySecondsThreshold?: number | null;
    exemptions?: Array<string | null> | null;
    performancePayMultiplier?: number | null;
    driverPerformanceStatus?: number | null;
    performanceStatusThresholds?: any | null;
  } | null;
  shiftCancellation?: {
    __typename?: 'ShiftCancellation';
    shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
  } | null;
  currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
};

export type ShiftMapMarker_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  issueType?: DriverShiftIssueTypes | null;
  vehicleLocationUpdatedAt?: any | null;
  driver: { __typename?: 'Driver'; firstName: string; lastName: string };
  vehicleLocation?: { __typename?: 'Point'; lat: number; lng: number } | null;
  currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
};

export type GetShiftMapMarkerInfoQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetShiftMapMarkerInfoQuery = {
  __typename?: 'Query';
  shift?: {
    __typename?: 'DriverShift';
    id: string;
    state: DriverShiftStates;
    issueType?: DriverShiftIssueTypes | null;
    vehicleLocationUpdatedAt?: any | null;
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      vehicle?: { __typename?: 'FleetResource'; licensePlate: string; batteryLevel: number } | null;
    } | null;
    driver: { __typename?: 'Driver'; firstName: string; lastName: string };
    vehicleLocation?: { __typename?: 'Point'; lat: number; lng: number } | null;
    currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
  } | null;
};

export type ShiftScheduledEnd_shiftFragment = {
  __typename?: 'DriverShift';
  scheduledStartAt: any;
  scheduledEndAt: any;
  depot?: { __typename?: 'Depot'; id: string } | null;
};

export type ShiftStatCard_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  stats?: {
    __typename?: 'ShiftStats';
    negativeRatingCount?: number | null;
    negativeRatingCountThreshold?: number | null;
    missedRideCount?: number | null;
    missedRideCountThreshold?: number | null;
    avgPickupDelaySeconds?: number | null;
    avgPickupDelaySecondsThreshold?: number | null;
    driverAvailabilityPercentage?: number | null;
    driverAvailabilityPercentageThreshold?: number | null;
    attendancePercentage?: number | null;
    attendancePercentageThreshold?: number | null;
    endOfShiftRecallDelaySeconds?: number | null;
    endOfShiftRecallDelaySecondsThreshold?: number | null;
    exemptions?: Array<string | null> | null;
    performancePayMultiplier?: number | null;
    driverPerformanceStatus?: number | null;
    performanceStatusThresholds?: any | null;
  } | null;
};

export type ShiftVehicle_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  resourceId?: string | null;
  depot?: { __typename?: 'Depot'; name: string } | null;
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    vehicle?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      color?: string | null;
      batteryLevel: number;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SpotlightDriverSearchQueryVariables = Exact<{
  filters?: InputMaybe<DriverFilters>;
}>;

export type SpotlightDriverSearchQuery = {
  __typename?: 'Query';
  drivers: {
    __typename?: 'DriversResponse';
    results: Array<{
      __typename?: 'Driver';
      id: string;
      avatar?: string | null;
      firstName: string;
      lastName: string;
      phoneNumber: string;
      email?: string | null;
      tlcLicenseNumber?: string | null;
    }>;
  };
};

export type CommandResourceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: CommandResourceInput;
}>;

export type CommandResourceMutation = { __typename?: 'Mutation'; commandResource: boolean };

export type GetVehicleFacilitiesQueryVariables = Exact<{
  filters?: InputMaybe<VehicleFacilityFilters>;
}>;

export type GetVehicleFacilitiesQuery = {
  __typename?: 'Query';
  vehicleFacilities: {
    __typename?: 'VehicleFacilityResponse';
    results: Array<{ __typename?: 'VehicleFacility'; id: string; name: string }>;
  };
};

export type GetVehicleFilterCountQueryVariables = Exact<{
  filters: DriverFleetResourceFilters;
}>;

export type GetVehicleFilterCountQuery = {
  __typename?: 'Query';
  vehicles: { __typename?: 'DriverFleetResourcesResponse'; count: number };
};

export type VehicleInfoButton_fleetResourceFragment = {
  __typename?: 'FleetResource';
  id: string;
  licensePlate: string;
  batteryLevel: number;
  color?: string | null;
  detail?: {
    __typename?: 'FleetResourceDetail';
    make?: string | null;
    model?: string | null;
  } | null;
};

export type VehicleInfoPanel_vehicleFragment = {
  __typename?: 'DriverFleetResource';
  id: string;
  vin: string;
  licensePlate: string;
  batteryLevel?: number | null;
  telematicsProvider: string;
  status?: string | null;
  speedLimitPin?: string | null;
  color: string;
  location?: { __typename?: 'Point'; lat: number; lng: number } | null;
  detail: {
    __typename?: 'DriverFleetResourceDetail';
    make: string;
    model: string;
    year?: number | null;
  };
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    fhvLicenseNumber: string;
    isControllable?: boolean | null;
    tlcBase?: { __typename?: 'ResourceMetadataTLCBase'; name: string } | null;
    dispatchingTlcBase?: { __typename?: 'ResourceMetadataTLCBase'; name: string } | null;
    insuranceProvider?: { __typename?: 'InsuranceProvider'; displayName: string } | null;
    vehicle?: {
      __typename?: 'FleetResource';
      sensors?: { __typename?: 'Sensors'; updateTime?: any | null } | null;
    } | null;
  } | null;
  activeShift?: {
    __typename?: 'DriverShift';
    id: string;
    state: DriverShiftStates;
    scheduledStartAt: any;
    scheduledEndAt: any;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      firstShiftCompleted?: boolean | null;
    };
  } | null;
};

export type VehicleLocationUpdateBadge_fleetResourceFragment = {
  __typename?: 'FleetResource';
  sensors?: { __typename?: 'Sensors'; updateTime?: any | null } | null;
};

export type VehicleMakeModelFragment = {
  __typename?: 'DriverFleetResourceDetail';
  make: string;
  model: string;
};

export type GetVehicleSelectorVehiclesQueryVariables = Exact<{
  filters?: InputMaybe<ResourceFilters>;
}>;

export type GetVehicleSelectorVehiclesQuery = {
  __typename?: 'Query';
  vehicles: Array<{
    __typename?: 'FleetResource';
    id: string;
    batteryLevel: number;
    licensePlate: string;
  }>;
};

export type IssueRentalCreditMutationVariables = Exact<{
  input: IssueDriverCreditInput;
}>;

export type IssueRentalCreditMutation = {
  __typename?: 'Mutation';
  issueDriverCredit: { __typename?: 'DriverCredit'; amountIssued: number };
};

export type CalculateDriverVehicleCreditAmountQueryVariables = Exact<{
  minutes: Scalars['Int']['input'];
  vehicleType: DriverVehiclePreferenceType;
}>;

export type CalculateDriverVehicleCreditAmountQuery = {
  __typename?: 'Query';
  calculateDriverVehicleCreditAmount: {
    __typename?: 'DriverCreditAmountResponse';
    creditAmount: number;
  };
};

export type GetDriverAvailableCreditQueryVariables = Exact<{
  driverId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetDriverAvailableCreditQuery = {
  __typename?: 'Query';
  getDriverAvailableCredit: number;
};

export type GetAssignVehicleToReservationReservationQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type GetAssignVehicleToReservationReservationQuery = {
  __typename?: 'Query';
  reservation?: {
    __typename?: 'RentalReservation';
    id: string;
    currentResourceId?: string | null;
    state: RentalReservationStates;
    depotId?: string | null;
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
    reservationType: RentalReservationTypes;
    depot?: {
      __typename?: 'Depot';
      id: string;
      name: string;
      vehicleFacility?: {
        __typename?: 'VehicleFacility';
        name: string;
        id: string;
        geofenceRadiusMeters: number;
        centerPoint: { __typename?: 'Point'; lat: number; lng: number };
      } | null;
    } | null;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      phoneNumber: string;
    } | null;
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      color?: string | null;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    } | null;
    settings?: { __typename?: 'ReservationSettings'; preferredVehicleType?: string | null } | null;
    billingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTier: InsurancePremiumTiers;
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
  } | null;
};

export type GetAssignVehicleToReservationDriverDetailsQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetAssignVehicleToReservationDriverDetailsQuery = {
  __typename?: 'Query';
  approvals: {
    __typename?: 'DriverInsuranceApprovalResponse';
    results: Array<{
      __typename?: 'DriverInsuranceApproval';
      id: string;
      insuranceProvider: { __typename?: 'InsuranceProvider'; displayName: string; id: string };
    } | null>;
  };
  vehicleExlusions: Array<{
    __typename?: 'DriverVehicleExclusionMakeAndModel';
    make: string;
    model: string;
  } | null>;
  vehiclePreference?: {
    __typename?: 'DriverVehiclePreferenceResponse';
    preferredVehicleType: DriverVehiclePreferenceType;
  } | null;
};

export type AssignVehicleToReservationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  fleetResourceId: Scalars['ID']['input'];
}>;

export type AssignVehicleToReservationMutation = {
  __typename?: 'Mutation';
  assignVehicleToReservation: { __typename?: 'RentalReservation'; id: string };
};

export type RemoveAssignedVehicleFromReservationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveAssignedVehicleFromReservationMutation = {
  __typename?: 'Mutation';
  removeAssignedVehicleFromReservation: { __typename?: 'RentalReservation'; id: string };
};

export type GetAssignVehicleToShiftDriverDetailsQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetAssignVehicleToShiftDriverDetailsQuery = {
  __typename?: 'Query';
  approvals: {
    __typename?: 'DriverInsuranceApprovalResponse';
    results: Array<{
      __typename?: 'DriverInsuranceApproval';
      id: string;
      insuranceProvider: { __typename?: 'InsuranceProvider'; displayName: string; id: string };
    } | null>;
  };
  vehicleExlusions: Array<{
    __typename?: 'DriverVehicleExclusionMakeAndModel';
    make: string;
    model: string;
  } | null>;
  vehiclePreference?: {
    __typename?: 'DriverVehiclePreferenceResponse';
    preferredVehicleType: DriverVehiclePreferenceType;
  } | null;
};

export type GetCancelReservationModalQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type GetCancelReservationModalQuery = {
  __typename?: 'Query';
  reservation?: {
    __typename?: 'RentalReservation';
    id: string;
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
    reservationType: RentalReservationTypes;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      phoneNumber: string;
    } | null;
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      color?: string | null;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    } | null;
    settings?: { __typename?: 'ReservationSettings'; preferredVehicleType?: string | null } | null;
    billingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTier: InsurancePremiumTiers;
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  } | null;
};

export type CancelReservationMutationVariables = Exact<{
  input: CancelDriverReservationInput;
}>;

export type CancelReservationMutation = {
  __typename?: 'Mutation';
  cancelDriverReservation: { __typename?: 'RentalReservation'; id: string };
};

export type GetCancelScheduledShiftModalQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCancelScheduledShiftModalQuery = {
  __typename?: 'Query';
  scheduledShift: {
    __typename?: 'DriverScheduledShift';
    id: string;
    scheduledStartAt: any;
    scheduledEndAt: any;
    startedAt?: any | null;
    finalizedAt?: any | null;
    state: DriverScheduledShiftState;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
    };
    reservation?: {
      __typename?: 'RentalReservation';
      currentResource?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        batteryLevel: number;
        color?: string | null;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
        location: { __typename?: 'Point'; lat: number; lng: number };
      } | null;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
    allTasks: {
      __typename?: 'DriverTaskResponse';
      results?: Array<{
        __typename?: 'DriverTask';
        windowStartAt: any;
        windowEndAt?: any | null;
        state: DriverTaskState;
        startedAt?: any | null;
        finalizedAt?: any | null;
        taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
      }> | null;
    };
    activeTasks: {
      __typename?: 'DriverTaskResponse';
      results?: Array<{
        __typename?: 'DriverTask';
        taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
      }> | null;
    };
  };
};

export type CancelScheduledShiftMutationVariables = Exact<{
  input: CancelDriverScheduledShiftInput;
}>;

export type CancelScheduledShiftMutation = {
  __typename?: 'Mutation';
  cancelDriverScheduledShift?: { __typename?: 'DriverScheduledShift'; id: string } | null;
};

export type GetShiftCancellationReasonsQueryVariables = Exact<{ [key: string]: never }>;

export type GetShiftCancellationReasonsQuery = {
  __typename?: 'Query';
  getShiftCancellationReasons?: Array<{
    __typename?: 'ShiftCancellationReason';
    id: string;
    displayName: string;
    warningText?: string | null;
  }> | null;
};

export type CancelDriverShiftMutationVariables = Exact<{
  input: CancelDriverOpsDriverShiftInput;
}>;

export type CancelDriverShiftMutation = {
  __typename?: 'Mutation';
  cancelDriverOpsDriverShift: { __typename?: 'DriverShift'; id: string };
};

export type GetCheckInReservationModalQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type GetCheckInReservationModalQuery = {
  __typename?: 'Query';
  reservation?: {
    __typename?: 'RentalReservation';
    id: string;
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
    reservationType: RentalReservationTypes;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      phoneNumber: string;
    } | null;
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      color?: string | null;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    } | null;
    settings?: { __typename?: 'ReservationSettings'; preferredVehicleType?: string | null } | null;
    billingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTier: InsurancePremiumTiers;
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  } | null;
};

export type CheckInReservationMutationVariables = Exact<{
  input: CheckInReservationInput;
}>;

export type CheckInReservationMutation = {
  __typename?: 'Mutation';
  checkInReservation: { __typename?: 'RentalReservation'; id: string };
};

export type CheckInShiftOverrideMutationVariables = Exact<{
  input: CheckInShiftOverrideInput;
}>;

export type CheckInShiftOverrideMutation = {
  __typename?: 'Mutation';
  checkInShiftOverride: { __typename?: 'DriverShift'; id: string };
};

export type ReportDriverShiftIssueMutationVariables = Exact<{
  input: ReportDriverShiftIssueInput;
}>;

export type ReportDriverShiftIssueMutation = {
  __typename?: 'Mutation';
  reportDriverShiftIssue: { __typename?: 'DriverShift'; id: string };
};

export type CreateManualInvoiceMutationVariables = Exact<{
  input: CreateManualInvoiceInput;
}>;

export type CreateManualInvoiceMutation = {
  __typename?: 'Mutation';
  invoice: { __typename?: 'DriverInvoice'; id: string };
};

export type CreateRecurringShiftMutationVariables = Exact<{
  input: CreateRecurringShiftInput;
}>;

export type CreateRecurringShiftMutation = {
  __typename?: 'Mutation';
  createRecurringShift?: { __typename?: 'RecurringShift'; id: string } | null;
};

export type GetDriverEmploymentTypeQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverEmploymentTypeQuery = {
  __typename?: 'Query';
  driver?: { __typename?: 'Driver'; id: string; employmentType?: EmploymentTypes | null } | null;
};

export type CreateReservationMutationVariables = Exact<{
  input: CreateDriverReservationInput;
}>;

export type CreateReservationMutation = {
  __typename?: 'Mutation';
  createReservation: { __typename?: 'RentalReservation'; id: string };
};

export type CreateDriverShiftMutationVariables = Exact<{
  input: CreateDriverShiftInput;
}>;

export type CreateDriverShiftMutation = {
  __typename?: 'Mutation';
  shift: { __typename?: 'DriverShift'; scheduledStartAt: any; scheduledEndAt: any };
};

export type DeleteRecurringShiftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  deleteFutureShifts?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteRecurringShiftMutation = {
  __typename?: 'Mutation';
  deleteRecurringShift?: { __typename?: 'EmptyResponse'; id?: string | null } | null;
};

export type UpdateDriverAddressMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverAddressMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: { __typename?: 'Driver'; updatedAt?: any | null };
};

export type UpdateDriverInsuranceApprovalsMutationVariables = Exact<{
  input: UpdateDriverInsuranceApprovalInput;
}>;

export type UpdateDriverInsuranceApprovalsMutation = {
  __typename?: 'Mutation';
  updateDriverInsuranceApprovals: Array<{
    __typename?: 'DriverInsuranceApproval';
    id: string;
  } | null>;
};

export type GetInsuranceProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type GetInsuranceProvidersQuery = {
  __typename?: 'Query';
  insuranceProviders?: {
    __typename?: 'InsuranceProviderResponse';
    results: Array<{ __typename?: 'InsuranceProvider'; displayName: string; id: string }>;
  } | null;
};

export type GetAdicRegistrationsQueryVariables = Exact<{
  filters?: InputMaybe<AdicRegistrationFilters>;
}>;

export type GetAdicRegistrationsQuery = {
  __typename?: 'Query';
  listAdicRegistrations: {
    __typename?: 'AdicRegistrationResponse';
    results?: Array<{
      __typename?: 'AdicRegistration';
      id: string;
      status?: RegistrationStatuses | null;
      response: any;
      updatedAt: any;
    }> | null;
  };
};

export type SubmitAdicRegistrationMutationVariables = Exact<{
  input: CreateAdicRegistrationInput;
}>;

export type SubmitAdicRegistrationMutation = {
  __typename?: 'Mutation';
  createAdicRegistration?: { __typename?: 'AdicRegistration'; id: string } | null;
};

export type UpdateDriverVehicleExclusionsMutationVariables = Exact<{
  input: UpdateDriverVehicleExclusionsInput;
}>;

export type UpdateDriverVehicleExclusionsMutation = {
  __typename?: 'Mutation';
  updateDriverVehicleExclusions: Array<{
    __typename?: 'DriverVehicleExclusionMakeAndModel';
    make: string;
    model: string;
  } | null>;
};

export type GetDriverFleetResourceDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDriverFleetResourceDetailsQuery = {
  __typename?: 'Query';
  fleetResourceDetails: {
    __typename?: 'DriverFleetResourceDetailsResponse';
    results: Array<{
      __typename?: 'DriverFleetResourceDetail';
      make: string;
      model: string;
    } | null>;
  };
};

export type UpdateDriverVehicleMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverVehicleMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: { __typename?: 'Driver'; fleetResourceId?: string | null };
};

export type GetDriverVehiclePreferenceQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverVehiclePreferenceQuery = {
  __typename?: 'Query';
  getDriverVehiclePreference?: {
    __typename?: 'DriverVehiclePreferenceResponse';
    preferredVehicleType: DriverVehiclePreferenceType;
  } | null;
};

export type UpdateDriverVehiclePreferenceMutationVariables = Exact<{
  input: UpdateDriverVehiclePreferenceInput;
}>;

export type UpdateDriverVehiclePreferenceMutation = {
  __typename?: 'Mutation';
  updateDriverVehiclePreference: {
    __typename?: 'DriverVehiclePreferenceResponse';
    preferredVehicleType: DriverVehiclePreferenceType;
  };
};

export type UpdateDriverWeeklyDowngradeMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type UpdateDriverWeeklyDowngradeMutation = {
  __typename?: 'Mutation';
  downgradeWeeklyDriverSecurityDeposit?: boolean | null;
};

export type GetDriverAvailableSecurityDepositLedgerQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverAvailableSecurityDepositLedgerQuery = {
  __typename?: 'Query';
  availableLedger: number;
};

export type UpdateDriverWeeklyRentalMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverWeeklyRentalMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: { __typename?: 'Driver'; fleetResourceId?: string | null };
};

export type GetReservationBillingSettingsQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetReservationBillingSettingsQuery = {
  __typename?: 'Query';
  getReservationBillingSettings?: {
    __typename?: 'ReservationBillingSettings';
    id: string;
    ezPassRental: boolean;
    insurancePremiumTier: InsurancePremiumTiers;
  } | null;
};

export type UpdateRecurringShiftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateRecurringShiftInput;
}>;

export type UpdateRecurringShiftMutation = {
  __typename?: 'Mutation';
  updateRecurringShift?: { __typename?: 'RecurringShift'; id: string } | null;
};

export type UpdateRecurringShiftSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ShiftSettingsInput;
}>;

export type UpdateRecurringShiftSettingsMutation = {
  __typename?: 'Mutation';
  updateRecurringShiftSettings?: {
    __typename?: 'ShiftSettings';
    dynamicRecallExtensionEnabled?: boolean | null;
    dynamicRecallReductionEnabled?: boolean | null;
  } | null;
};

export type GetEditReservationModalQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type GetEditReservationModalQuery = {
  __typename?: 'Query';
  reservation?: {
    __typename?: 'RentalReservation';
    id: string;
    depotId?: string | null;
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
    state: RentalReservationStates;
    reservationType: RentalReservationTypes;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      phoneNumber: string;
    } | null;
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      color?: string | null;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    } | null;
    settings?: { __typename?: 'ReservationSettings'; preferredVehicleType?: string | null } | null;
    billingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTier: InsurancePremiumTiers;
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  } | null;
};

export type UpdateDriverReservationMutationVariables = Exact<{
  input: UpdateDriverReservationInput;
}>;

export type UpdateDriverReservationMutation = {
  __typename?: 'Mutation';
  updateDriverReservation: { __typename?: 'RentalReservation'; id: string };
};

export type GetEndReservationModalQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type GetEndReservationModalQuery = {
  __typename?: 'Query';
  reservation?: {
    __typename?: 'RentalReservation';
    id: string;
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
    reservationType: RentalReservationTypes;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      phoneNumber: string;
    } | null;
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      color?: string | null;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    } | null;
    settings?: { __typename?: 'ReservationSettings'; preferredVehicleType?: string | null } | null;
    billingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTier: InsurancePremiumTiers;
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  } | null;
};

export type EndReservationMutationVariables = Exact<{
  input: EndReservationInput;
}>;

export type EndReservationMutation = {
  __typename?: 'Mutation';
  endReservation: { __typename?: 'RentalReservation'; id: string };
};

export type GetEndScheduledShiftModalQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetEndScheduledShiftModalQuery = {
  __typename?: 'Query';
  scheduledShift: {
    __typename?: 'DriverScheduledShift';
    id: string;
    scheduledStartAt: any;
    scheduledEndAt: any;
    startedAt?: any | null;
    finalizedAt?: any | null;
    state: DriverScheduledShiftState;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
    };
    reservation?: {
      __typename?: 'RentalReservation';
      currentResource?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        batteryLevel: number;
        color?: string | null;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
        location: { __typename?: 'Point'; lat: number; lng: number };
      } | null;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
    allTasks: {
      __typename?: 'DriverTaskResponse';
      results?: Array<{
        __typename?: 'DriverTask';
        windowStartAt: any;
        windowEndAt?: any | null;
        state: DriverTaskState;
        startedAt?: any | null;
        finalizedAt?: any | null;
        taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
      }> | null;
    };
    activeTasks: {
      __typename?: 'DriverTaskResponse';
      results?: Array<{
        __typename?: 'DriverTask';
        taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
      }> | null;
    };
  };
};

export type EndScheduledShiftMutationVariables = Exact<{
  input: EndDriverScheduledShiftInput;
}>;

export type EndScheduledShiftMutation = {
  __typename?: 'Mutation';
  endDriverScheduledShift: { __typename?: 'DriverScheduledShift'; id: string };
};

export type EndShiftShiftOverrideMutationVariables = Exact<{
  input: CheckInShiftOverrideInput;
}>;

export type EndShiftShiftOverrideMutation = {
  __typename?: 'Mutation';
  endReservationShiftOverride: {
    __typename?: 'DriverShift';
    id: string;
    resourceId?: string | null;
  };
};

export type ListIncidentsQueryVariables = Exact<{ [key: string]: never }>;

export type ListIncidentsQuery = {
  __typename?: 'Query';
  incidents: {
    __typename?: 'IncidentsResponse';
    results?: Array<{
      __typename?: 'Incident';
      id: string;
      displayName?: string | null;
      points: number;
      expirationDays?: number | null;
      track?: { __typename?: 'Track'; name: string; displayName: string } | null;
    }> | null;
  };
};

export type CreateDriverIncidentMutationVariables = Exact<{
  input: CreateDriverIncidentInput;
}>;

export type CreateDriverIncidentMutation = {
  __typename?: 'Mutation';
  createDriverIncident: { __typename?: 'DriverIncident'; id: string };
};

export type GetIssueDetailsModalIssueQueryVariables = Exact<{
  issueId: Scalars['ID']['input'];
}>;

export type GetIssueDetailsModalIssueQuery = {
  __typename?: 'Query';
  issue?: {
    __typename?: 'ShiftIssue';
    id: string;
    issueType: DriverShiftIssueTypes;
    reportComment?: string | null;
    imageUrls?: Array<string> | null;
    startedAt?: any | null;
    shiftId?: string | null;
    shift?: {
      __typename?: 'DriverShift';
      id: string;
      state: DriverShiftStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      scheduledRecallAt?: any | null;
      clockIn?: any | null;
      clockOut?: any | null;
      reservationType?: ReservationTypes | null;
      driverArrivedForShiftAt?: any | null;
      issueType?: DriverShiftIssueTypes | null;
      currentIssueId?: string | null;
      driver: {
        __typename?: 'Driver';
        id: string;
        dayforceXrefCode?: string | null;
        phoneNumber: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        firstShiftCompleted?: boolean | null;
      };
      settings?: {
        __typename?: 'ShiftSettings';
        phoneNumber?: string | null;
        phoneType?: string | null;
        deviceType?: DeviceTypes | null;
        appVersion?: string | null;
      } | null;
      depot?: { __typename?: 'Depot'; id: string; name: string } | null;
      reservationBillingSettings?: {
        __typename?: 'ReservationBillingSettings';
        insurancePremiumTierDaily: InsurancePremiumTiersDaily;
      } | null;
      breaks?: Array<{
        __typename?: 'DriverShiftBreak';
        id: string;
        type?: DriverShiftBreakTypes | null;
        scheduledStartAt?: any | null;
        scheduledEndAt?: any | null;
        actualStartAt?: any | null;
        actualEndAt?: any | null;
      }> | null;
      fleetResourceMetadata?: {
        __typename?: 'FleetResourceMetadata';
        vehicle?: {
          __typename?: 'FleetResource';
          id: string;
          licensePlate: string;
          batteryLevel: number;
          detail?: {
            __typename?: 'FleetResourceDetail';
            make?: string | null;
            model?: string | null;
          } | null;
        } | null;
      } | null;
      shiftCancellation?: {
        __typename?: 'ShiftCancellation';
        shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
      } | null;
      currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
    } | null;
  } | null;
};

export type ResolveDriverShiftIssueMutationVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type ResolveDriverShiftIssueMutation = {
  __typename?: 'Mutation';
  resolveDriverShiftIssue: {
    __typename?: 'DriverShift';
    id: string;
    issueType?: DriverShiftIssueTypes | null;
  };
};

export type SubmitUpdateLicenseReviewMutationVariables = Exact<{
  licenseID: Scalars['ID']['input'];
  updateLicenseInput?: InputMaybe<UpdateLicenseInput>;
  reviewInput?: InputMaybe<ReviewLicenseInput>;
}>;

export type SubmitUpdateLicenseReviewMutation = {
  __typename?: 'Mutation';
  submitUpdateLicenseReview: { __typename?: 'License'; id: string };
};

export type UpdateLicenseMutationVariables = Exact<{
  licenseID: Scalars['ID']['input'];
  updateLicenseInput?: InputMaybe<UpdateLicenseInput>;
}>;

export type UpdateLicenseMutation = {
  __typename?: 'Mutation';
  updateLicense: { __typename?: 'License'; id: string };
};

export type CreateLicenseMutationVariables = Exact<{
  input?: InputMaybe<CreateLicenseInput>;
}>;

export type CreateLicenseMutation = {
  __typename?: 'Mutation';
  createLicense: { __typename?: 'License'; id: string };
};

export type GetDriverIncidentNoteInfoQueryVariables = Exact<{
  driverIncidentId: Scalars['ID']['input'];
}>;

export type GetDriverIncidentNoteInfoQuery = {
  __typename?: 'Query';
  driverIncident: {
    __typename?: 'DriverIncident';
    id: string;
    driverId: string;
    createdAt: any;
    occurredAt?: any | null;
    incident?: {
      __typename?: 'Incident';
      id: string;
      displayName?: string | null;
      points: number;
      expirationDays?: number | null;
      track?: { __typename?: 'Track'; name: string; displayName: string } | null;
    } | null;
  };
};

export type GetReservationNoteModalQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type GetReservationNoteModalQuery = {
  __typename?: 'Query';
  reservation?: {
    __typename?: 'RentalReservation';
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
    reservationType: RentalReservationTypes;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      phoneNumber: string;
    } | null;
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      color?: string | null;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    } | null;
    settings?: { __typename?: 'ReservationSettings'; preferredVehicleType?: string | null } | null;
    billingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTier: InsurancePremiumTiers;
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  } | null;
};

export type GetScheduledShiftNoteModalQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetScheduledShiftNoteModalQuery = {
  __typename?: 'Query';
  scheduledShift: {
    __typename?: 'DriverScheduledShift';
    id: string;
    scheduledStartAt: any;
    scheduledEndAt: any;
    startedAt?: any | null;
    finalizedAt?: any | null;
    state: DriverScheduledShiftState;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
    };
    reservation?: {
      __typename?: 'RentalReservation';
      currentResource?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        batteryLevel: number;
        color?: string | null;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
        location: { __typename?: 'Point'; lat: number; lng: number };
      } | null;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
    allTasks: {
      __typename?: 'DriverTaskResponse';
      results?: Array<{
        __typename?: 'DriverTask';
        windowStartAt: any;
        windowEndAt?: any | null;
        state: DriverTaskState;
        startedAt?: any | null;
        finalizedAt?: any | null;
        taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
      }> | null;
    };
    activeTasks: {
      __typename?: 'DriverTaskResponse';
      results?: Array<{
        __typename?: 'DriverTask';
        taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
      }> | null;
    };
  };
};

export type CreateNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;

export type CreateNoteMutation = {
  __typename?: 'Mutation';
  createNote: { __typename?: 'Note'; id: string };
};

export type GetNotesCountQueryVariables = Exact<{
  filters: NoteFilters;
}>;

export type GetNotesCountQuery = {
  __typename?: 'Query';
  notes: { __typename?: 'NoteResponse'; count: number };
};

export type GetNotesQueryVariables = Exact<{
  filters: NoteFilters;
}>;

export type GetNotesQuery = {
  __typename?: 'Query';
  notes: {
    __typename?: 'NoteResponse';
    count: number;
    results?: Array<{
      __typename?: 'Note';
      id: string;
      note: string;
      createdAt: any;
      objectId?: string | null;
      objectType?: NoteObjectType | null;
      createdByIdentity?: {
        __typename?: 'Identity';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
      } | null;
    }> | null;
  };
};

export type ResolveNoteMutationVariables = Exact<{
  noteId: Scalars['ID']['input'];
}>;

export type ResolveNoteMutation = {
  __typename?: 'Mutation';
  deleteNote?: { __typename?: 'EmptyResponse'; id?: string | null } | null;
};

export type UnresolveNoteMutationVariables = Exact<{
  noteId: Scalars['ID']['input'];
}>;

export type UnresolveNoteMutation = {
  __typename?: 'Mutation';
  restoreNote: { __typename?: 'Note'; id: string };
};

export type CreateDriverIssueMutationVariables = Exact<{
  input: CreateDriverIssueInput;
}>;

export type CreateDriverIssueMutation = {
  __typename?: 'Mutation';
  createDriverIssue: { __typename?: 'DriverIssue'; id: string };
};

export type IncidentDisputeDetail_disputeFragment = {
  __typename?: 'DriverIncidentDispute';
  id: string;
  createdAt: any;
  driverDescription?: string | null;
  disputeType: DriverIncidentDisputeTypes;
  status: DriverIncidentDisputeStatuses;
  photoUrls?: Array<string> | null;
  finalizedAt?: any | null;
  rejectionReason?: string | null;
  finalizedByIdentity?: { __typename?: 'Identity'; firstName: string; lastName: string } | null;
};

export type GetDriverIncidentReviewDisputeQueryVariables = Exact<{
  driverIncidentId: Scalars['ID']['input'];
}>;

export type GetDriverIncidentReviewDisputeQuery = {
  __typename?: 'Query';
  driverIncident: {
    __typename?: 'DriverIncident';
    id: string;
    occurredAt?: any | null;
    createdAt: any;
    expiresAt?: any | null;
    driverId: string;
    driver?: {
      __typename?: 'Driver';
      id: string;
      avatar?: string | null;
      email?: string | null;
      firstName: string;
      lastName: string;
    } | null;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdAt: any;
      note: string;
      createdByIdentity?: { __typename?: 'Identity'; firstName: string; lastName: string } | null;
    }> | null;
    incident?: {
      __typename?: 'Incident';
      id: string;
      displayName?: string | null;
      points: number;
      expirationDays?: number | null;
      track?: { __typename?: 'Track'; name: string; displayName: string } | null;
    } | null;
    incidentDisputes?: Array<{
      __typename?: 'DriverIncidentDispute';
      id: string;
      createdAt: any;
      driverDescription?: string | null;
      disputeType: DriverIncidentDisputeTypes;
      status: DriverIncidentDisputeStatuses;
      photoUrls?: Array<string> | null;
      finalizedAt?: any | null;
      rejectionReason?: string | null;
      finalizedByIdentity?: { __typename?: 'Identity'; firstName: string; lastName: string } | null;
    } | null> | null;
  };
};

export type ReviewDriverIncidentDisputeMutationVariables = Exact<{
  input?: InputMaybe<ReviewDriverIncidentDisputeInput>;
}>;

export type ReviewDriverIncidentDisputeMutation = {
  __typename?: 'Mutation';
  reviewDriverIncidentDispute: { __typename?: 'DriverIncidentDispute'; id: string };
};

export type UpdateDriverShiftMutationVariables = Exact<{
  input: UpdateDriverShiftInput;
}>;

export type UpdateDriverShiftMutation = {
  __typename?: 'Mutation';
  updateDriverShift: { __typename?: 'DriverShift'; id: string };
};

export type UpdateShiftClockTimesMutationVariables = Exact<{
  input: UpdateShiftClockTimesInput;
}>;

export type UpdateShiftClockTimesMutation = {
  __typename?: 'Mutation';
  updateShiftClockTimes: { __typename?: 'DriverShift'; id: string };
};

export type UpdateShiftSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ShiftSettingsInput;
}>;

export type UpdateShiftSettingsMutation = {
  __typename?: 'Mutation';
  updateShiftSettings?: {
    __typename?: 'ShiftSettings';
    dynamicRecallExtensionEnabled?: boolean | null;
    dynamicRecallReductionEnabled?: boolean | null;
  } | null;
};

export type AssignVehicleToShiftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  fleetResourceId: Scalars['ID']['input'];
}>;

export type AssignVehicleToShiftMutation = {
  __typename?: 'Mutation';
  assignVehicleToShift: { __typename?: 'DriverShift'; id: string; resourceId?: string | null };
};

export type RemoveAssignedVehicleFromShiftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveAssignedVehicleFromShiftMutation = {
  __typename?: 'Mutation';
  removeAssignedVehicleFromShift: {
    __typename?: 'DriverShift';
    id: string;
    resourceId?: string | null;
  };
};

export type UpdateShiftNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  note: Scalars['String']['input'];
}>;

export type UpdateShiftNoteMutation = {
  __typename?: 'Mutation';
  updateShiftNote: {
    __typename?: 'ShiftNote';
    createdAt: any;
    createdByIdentityId: string;
    note: string;
  };
};

export type UpdateShiftExemptionsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ShiftExemptionInput;
}>;

export type UpdateShiftExemptionsMutation = {
  __typename?: 'Mutation';
  updateShiftExemptions?: {
    __typename?: 'ShiftSettings';
    exemptions?: Array<string | null> | null;
  } | null;
};

export type GetStandbyReservationModalQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type GetStandbyReservationModalQuery = {
  __typename?: 'Query';
  reservation?: {
    __typename?: 'RentalReservation';
    id: string;
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
    reservationType: RentalReservationTypes;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      phoneNumber: string;
    } | null;
    currentResource?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      color?: string | null;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    } | null;
    settings?: { __typename?: 'ReservationSettings'; preferredVehicleType?: string | null } | null;
    billingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTier: InsurancePremiumTiers;
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  } | null;
};

export type StandbyReservationMutationVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type StandbyReservationMutation = {
  __typename?: 'Mutation';
  standbyReservation: { __typename?: 'RentalReservation'; id: string };
};

export type StandbyShiftModalMutationVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type StandbyShiftModalMutation = {
  __typename?: 'Mutation';
  standbyReservation: { __typename?: 'RentalReservation'; id: string };
};

export type GetDriverVoidInvoiceModalQueryVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type GetDriverVoidInvoiceModalQuery = {
  __typename?: 'Query';
  invoice?: {
    __typename?: 'DriverInvoice';
    id: string;
    total: number;
    createdAt: any;
    voidedReason?: string | null;
    metadata?: { __typename?: 'InvoiceMetaData'; voidedReasonContext?: string | null } | null;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      email?: string | null;
      avatar?: string | null;
    } | null;
  } | null;
};

export type GetInvoiceVoidedReasonsQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvoiceVoidedReasonsQuery = {
  __typename?: 'Query';
  getInvoiceVoidedReasons: Array<{
    __typename?: 'DriverInvoiceVoidedReason';
    reason: VoidedInvoiceReasonsEnum;
    displayText: string;
  }>;
};

export type VoidInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
  input: VoidDriverInvoiceInput;
}>;

export type VoidInvoiceMutation = {
  __typename?: 'Mutation';
  invoice: { __typename?: 'DriverInvoice'; id: string };
};

export type GetDepositDetailsSidebarQueryVariables = Exact<{
  ledgerRecordId: Scalars['ID']['input'];
}>;

export type GetDepositDetailsSidebarQuery = {
  __typename?: 'Query';
  ledgerRecord?: {
    __typename?: 'SecurityDepositLedgerRecord';
    id: string;
    createdAt: any;
    amount: number;
    transactionType: LedgerTransactionType;
    invoice?: {
      __typename?: 'DriverInvoice';
      id: string;
      status: DriverInvoiceStatuses;
      voidedReason?: string | null;
      voidedAt?: any | null;
      descriptor?: DriverInvoiceDescriptors | null;
      createdAt: any;
      total: number;
      paymentIntents?: Array<{
        __typename?: 'DriverPaymentIntent';
        stripeExternalId?: string | null;
        status?: DriverPaymentIntentStatuses | null;
        createdAt?: any | null;
        paymentMethod?: {
          __typename?: 'DriverPaymentMethod';
          brand?: string | null;
          lastFour?: string | null;
          type?: DriverPaymentMethodTypes | null;
        } | null;
      }> | null;
      invoiceItems?: Array<{
        __typename?: 'DriverInvoiceItem';
        id?: string | null;
        amount?: number | null;
        displayTitleText?: string | null;
        displayDetailText?: string | null;
      }> | null;
      metadata?: { __typename?: 'InvoiceMetaData'; voidedReasonContext?: string | null } | null;
      driver?: { __typename?: 'Driver'; stripeCustomerId?: string | null } | null;
    } | null;
    payout?: {
      __typename?: 'SecurityDepositPayout';
      id: string;
      createdAt: any;
      completedAt?: any | null;
      total: number;
      status: SecurityDepositPayoutStatuses;
      offboardingInitiatedAt?: any | null;
      payoutItems?: Array<{
        __typename?: 'SecurityDepositPayoutItem';
        amount: number;
        itemType: SecurityDepositPayoutItemTypes;
        displayTitleText?: string | null;
        displayDetailText?: string | null;
        order: number;
      }> | null;
      driver?: {
        __typename?: 'Driver';
        id: string;
        avatar?: string | null;
        firstName: string;
        lastName: string;
        email?: string | null;
        addresses?: Array<{
          __typename?: 'DriverAddress';
          address1: string;
          address2?: string | null;
          city: string;
          state: string;
          postalCode: string;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export type GetDriverSecurityDepositLedgerRecordsQueryVariables = Exact<{
  filters?: InputMaybe<SecurityDepositLedgerFilters>;
}>;

export type GetDriverSecurityDepositLedgerRecordsQuery = {
  __typename?: 'Query';
  ledgerRecords?: {
    __typename?: 'SecurityDepositLedgerResponse';
    count: number;
    results?: Array<{
      __typename?: 'SecurityDepositLedgerRecord';
      id: string;
      createdAt: any;
      amount: number;
      transactionType: LedgerTransactionType;
      invoice?: {
        __typename?: 'DriverInvoice';
        paymentIntents?: Array<{
          __typename?: 'DriverPaymentIntent';
          stripeExternalId?: string | null;
        }> | null;
      } | null;
    }> | null;
  } | null;
};

export type ResendDocusignDocumentsMutationVariables = Exact<{
  input: ResendDocusignDocumentsInput;
}>;

export type ResendDocusignDocumentsMutation = {
  __typename?: 'Mutation';
  resendDocusignDocuments?: boolean | null;
};

export type ResendBackgroundCheckMutationVariables = Exact<{
  input: ResendBackgroundCheckInput;
}>;

export type ResendBackgroundCheckMutation = {
  __typename?: 'Mutation';
  resendBackgroundCheck?: boolean | null;
};

export type GetDriverRestrictionsContentQueryVariables = Exact<{
  filters?: InputMaybe<DriverRestrictionStatusContentFilters>;
}>;

export type GetDriverRestrictionsContentQuery = {
  __typename?: 'Query';
  restrictionsContent?: {
    __typename?: 'DriverRestrictionsStatusContent';
    groups: Array<{
      __typename?: 'DriverRestrictionStatusGroup';
      id: string;
      title?: string | null;
      description?: string | null;
      items?: Array<{
        __typename?: 'DriverRestrictionItem';
        key: RestrictionReasonNameKeys;
        id: string;
        label: string;
        value?: boolean | null;
        description?: string | null;
        link?: { __typename?: 'DriverRestrictionItemLink'; text: string; href: string } | null;
      }> | null;
    }>;
  } | null;
};

export type GetTracksQueryVariables = Exact<{ [key: string]: never }>;

export type GetTracksQuery = {
  __typename?: 'Query';
  tracks: {
    __typename?: 'TrackResponse';
    results?: Array<{ __typename?: 'Track'; name: string; displayName: string }> | null;
  };
};

export type GetDriverIncidentsQueryVariables = Exact<{
  filters: DriverIncidentsFilters;
}>;

export type GetDriverIncidentsQuery = {
  __typename?: 'Query';
  driverIncidents: {
    __typename?: 'DriverIncidentsResponse';
    results?: Array<{
      __typename?: 'DriverIncident';
      id: string;
      createdAt: any;
      expiresAt?: any | null;
      appealApprovedAt?: any | null;
      incidentDisputes?: Array<{
        __typename?: 'DriverIncidentDispute';
        id: string;
        status: DriverIncidentDisputeStatuses;
        disputeType: DriverIncidentDisputeTypes;
      } | null> | null;
      incident?: {
        __typename?: 'Incident';
        id: string;
        displayName?: string | null;
        points: number;
        expirationDays?: number | null;
        track?: { __typename?: 'Track'; name: string; displayName: string } | null;
      } | null;
    }> | null;
  };
};

export type GetDriverIncidentQueryVariables = Exact<{
  driverIncidentId: Scalars['ID']['input'];
}>;

export type GetDriverIncidentQuery = {
  __typename?: 'Query';
  driverIncident: {
    __typename?: 'DriverIncident';
    id: string;
    createdAt: any;
    occurredAt?: any | null;
    forgivenAt?: any | null;
    expiresAt?: any | null;
    appealApprovedAt?: any | null;
    driverId: string;
    incident?: {
      __typename?: 'Incident';
      id: string;
      displayName?: string | null;
      points: number;
      expirationDays?: number | null;
      track?: { __typename?: 'Track'; name: string; displayName: string } | null;
    } | null;
    notes?: Array<{
      __typename?: 'Note';
      id: string;
      createdAt: any;
      note: string;
      createdByIdentity?: { __typename?: 'Identity'; firstName: string; lastName: string } | null;
    }> | null;
    incidentDisputes?: Array<{
      __typename?: 'DriverIncidentDispute';
      id: string;
      createdAt: any;
      driverDescription?: string | null;
      disputeType: DriverIncidentDisputeTypes;
      status: DriverIncidentDisputeStatuses;
      photoUrls?: Array<string> | null;
      finalizedAt?: any | null;
      rejectionReason?: string | null;
      finalizedByIdentity?: { __typename?: 'Identity'; firstName: string; lastName: string } | null;
    } | null> | null;
  };
};

export type GetInvoiceTabInvoicesQueryVariables = Exact<{
  filters?: InputMaybe<DriverInvoiceFilters>;
}>;

export type GetInvoiceTabInvoicesQuery = {
  __typename?: 'Query';
  invoices: {
    __typename?: 'DriverInvoicesResponse';
    count: number;
    results?: Array<{
      __typename?: 'DriverInvoice';
      id: string;
      createdAt: any;
      subtotal: number;
      tax: number;
      taxPercentage: number;
      total: number;
      status: DriverInvoiceStatuses;
      descriptor?: DriverInvoiceDescriptors | null;
      appliedCredit?: number | null;
      driver?: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        email?: string | null;
        stripeCustomerId?: string | null;
      } | null;
      invoiceItems?: Array<{
        __typename?: 'DriverInvoiceItem';
        amount?: number | null;
        displayTitleText?: string | null;
        displayDetailText?: string | null;
        id?: string | null;
      }> | null;
      paymentIntents?: Array<{
        __typename?: 'DriverPaymentIntent';
        createdAt?: any | null;
        status?: DriverPaymentIntentStatuses | null;
        amount?: number | null;
        stripeExternalId?: string | null;
        paymentMethod?: {
          __typename?: 'DriverPaymentMethod';
          lastFour?: string | null;
          brand?: string | null;
          type?: DriverPaymentMethodTypes | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type GetInvoiceTabInvoices_invoiceFragment = {
  __typename?: 'DriverInvoice';
  id: string;
  createdAt: any;
  subtotal: number;
  tax: number;
  taxPercentage: number;
  total: number;
  status: DriverInvoiceStatuses;
  descriptor?: DriverInvoiceDescriptors | null;
  appliedCredit?: number | null;
  driver?: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    email?: string | null;
    stripeCustomerId?: string | null;
  } | null;
  invoiceItems?: Array<{
    __typename?: 'DriverInvoiceItem';
    amount?: number | null;
    displayTitleText?: string | null;
    displayDetailText?: string | null;
    id?: string | null;
  }> | null;
  paymentIntents?: Array<{
    __typename?: 'DriverPaymentIntent';
    createdAt?: any | null;
    status?: DriverPaymentIntentStatuses | null;
    amount?: number | null;
    stripeExternalId?: string | null;
    paymentMethod?: {
      __typename?: 'DriverPaymentMethod';
      lastFour?: string | null;
      brand?: string | null;
      type?: DriverPaymentMethodTypes | null;
    } | null;
  }> | null;
};

export type GetReservationsTabQueryVariables = Exact<{
  filters: RentalReservationFilters;
}>;

export type GetReservationsTabQuery = {
  __typename?: 'Query';
  reservations: {
    __typename?: 'RentalReservationsResponse';
    count: number;
    results?: Array<{
      __typename?: 'RentalReservation';
      id: string;
      state: RentalReservationStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      actualStartAt?: any | null;
      actualEndAt?: any | null;
      depotId?: string | null;
      currentResourceId?: string | null;
      driver?: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      } | null;
      depot?: { __typename?: 'Depot'; id: string; name: string } | null;
      currentResource?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        color?: string | null;
        batteryLevel: number;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type GetRidesTabRidesQueryVariables = Exact<{
  filters?: InputMaybe<DriverRideHailFilters>;
}>;

export type GetRidesTabRidesQuery = {
  __typename?: 'Query';
  rides?: Array<{
    __typename?: 'DriverRideHail';
    id: string;
    state: DriverRideHailStates;
    finalizedAt?: any | null;
    tripDurationSeconds?: number | null;
    renterId?: string | null;
    shiftId?: string | null;
    consumerRideHailId?: string | null;
    driverRenter?: {
      __typename?: 'DriverRenter';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      profilePhoto?: string | null;
    } | null;
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      vehicle?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        batteryLevel: number;
        color?: string | null;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
      } | null;
    } | null;
    stopPoints?: Array<{
      __typename?: 'DriverStopPoint';
      id: string;
      completedAt?: any | null;
      stopType: DriverStopPointTypes;
      startedAt?: any | null;
    }> | null;
    receipt?: { __typename?: 'DriverRideReceipt'; amount: number } | null;
  }> | null;
};

export type ListRecurringShiftsQueryVariables = Exact<{
  filters?: InputMaybe<ListRecurringShiftsFilters>;
}>;

export type ListRecurringShiftsQuery = {
  __typename?: 'Query';
  listRecurringShifts?: Array<{
    __typename?: 'RecurringShift';
    id: string;
    startWeekday: number;
    endWeekday: number;
    startTime: string;
    endTime: string;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  }> | null;
};

export type GetRecurringShiftQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRecurringShiftQuery = {
  __typename?: 'Query';
  getRecurringShift?: {
    __typename?: 'RecurringShift';
    id: string;
    startWeekday: number;
    endWeekday: number;
    startTime: string;
    endTime: string;
    depotId?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    generatedUntil?: any | null;
    depot?: { __typename?: 'Depot'; id: string; name: string; systemId?: string | null } | null;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      dayforceXrefCode?: string | null;
      employmentType?: EmploymentTypes | null;
    };
    settings?: {
      __typename?: 'ShiftSettings';
      dynamicRecallExtensionEnabled?: boolean | null;
      dynamicRecallReductionEnabled?: boolean | null;
    } | null;
  } | null;
};

export type GetShiftsTabShiftsQueryVariables = Exact<{
  filters: DriverShiftFilters;
}>;

export type GetShiftsTabShiftsQuery = {
  __typename?: 'Query';
  shifts: {
    __typename?: 'DriverShiftsResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverShift';
      issueType?: DriverShiftIssueTypes | null;
      currentIssueId?: string | null;
      id: string;
      state: DriverShiftStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      actualStartAt?: any | null;
      scheduledRecallAt?: any | null;
      clockIn?: any | null;
      clockOut?: any | null;
      reservationType?: ReservationTypes | null;
      driverArrivedForShiftAt?: any | null;
      driver: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      };
      currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
      depot?: { __typename?: 'Depot'; id: string; name: string } | null;
      shiftCancellation?: {
        __typename?: 'ShiftCancellation';
        shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
      } | null;
      note?: { __typename?: 'ShiftNote'; note: string } | null;
      fleetResourceMetadata?: {
        __typename?: 'FleetResourceMetadata';
        vehicle?: {
          __typename?: 'FleetResource';
          id: string;
          licensePlate: string;
          batteryLevel: number;
          detail?: {
            __typename?: 'FleetResourceDetail';
            make?: string | null;
            model?: string | null;
          } | null;
        } | null;
      } | null;
    }>;
  };
};

export type ListTrafficViolationTicketsQueryVariables = Exact<{
  filters?: InputMaybe<TrafficViolationTicketFilters>;
}>;

export type ListTrafficViolationTicketsQuery = {
  __typename?: 'Query';
  listTrafficViolationTickets: {
    __typename?: 'ListTrafficViolationTicketResponse';
    count: number;
    results: Array<{
      __typename?: 'TrafficViolationTicket';
      id: string;
      fineAmount?: number | null;
      issuedAt: any;
      createdAt: any;
      resourceId?: string | null;
      licensePlate: string;
      revelViolationCode?: RevelViolationCodes | null;
      summonsNumber: string;
      ticketIssuingAgency?: string | null;
      ticketUrl?: string | null;
      shift?: { __typename?: 'DriverShift'; id: string } | null;
    }>;
  };
};

export type GetDriversScreenQueryVariables = Exact<{
  filters: DriverFilters;
}>;

export type GetDriversScreenQuery = {
  __typename?: 'Query';
  drivers: {
    __typename?: 'DriversResponse';
    count: number;
    results: Array<{
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      employmentStatus?: DriverEmploymentStatus | null;
      dayforceXrefCode?: string | null;
      tlcLicenseNumber?: string | null;
      phoneNumber: string;
      firstShiftCompleted?: boolean | null;
      terminationDate?: any | null;
      employmentType?: EmploymentTypes | null;
      fleetResource?: { __typename?: 'FleetResource'; id: string; licensePlate: string } | null;
    }>;
  };
};

export type GetDriverQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverQuery = {
  __typename?: 'Query';
  getDriver?: {
    __typename?: 'Driver';
    id: string;
    avatar?: string | null;
    firstName: string;
    lastName: string;
    email?: string | null;
    phoneNumber: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    dayforceXrefCode?: string | null;
    tlcLicenseNumber?: string | null;
    tlcLicenseExpires?: Date | null;
    driverLicenseIssuer?: string | null;
    driverLicenseNumber?: string | null;
    terminationDate?: any | null;
    employmentStatus?: DriverEmploymentStatus | null;
    deletedAt?: any | null;
    employmentType?: EmploymentTypes | null;
    fleetResource?: { __typename?: 'FleetResource'; id: string; licensePlate: string } | null;
    otp?: { __typename?: 'DriverOTP'; expiresAt: any } | null;
    stats?: {
      __typename?: 'DriverStats';
      performanceStatus?: number | null;
      performanceStatusThresholds?: any | null;
    } | null;
  } | null;
  getDriverInsuranceApprovals: {
    __typename?: 'DriverInsuranceApprovalResponse';
    results: Array<{
      __typename?: 'DriverInsuranceApproval';
      id: string;
      insuranceProvider: { __typename?: 'InsuranceProvider'; id: string; displayName: string };
    } | null>;
  };
  getDriverVehicleExclusions: Array<{
    __typename?: 'DriverVehicleExclusionMakeAndModel';
    make: string;
    model: string;
  } | null>;
  getReservationBillingSettings?: {
    __typename?: 'ReservationBillingSettings';
    insurancePremiumTierDaily: InsurancePremiumTiersDaily;
  } | null;
};

export type GetDriverAddressQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
  addressType?: InputMaybe<DriverAddressTypes>;
}>;

export type GetDriverAddressQuery = {
  __typename?: 'Query';
  listDriverAddresses: {
    __typename?: 'DriverAddressResponse';
    results?: Array<{
      __typename?: 'DriverAddress';
      address1: string;
      address2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      addressType: DriverAddressTypes;
    }> | null;
  };
};

export type GetDriverLicenses_licenseFragment = {
  __typename?: 'License';
  id: string;
  updatedAt: any;
  licenseType: LicenseTypes;
  licenseNumber?: string | null;
  expiresAt?: Date | null;
  status: LicenseStatuses;
  photoFrontUrl?: string | null;
  driverId: string;
  driver: {
    __typename?: 'Driver';
    id: string;
    avatar?: string | null;
    firstName: string;
    lastName: string;
  };
  metadata?: {
    __typename?: 'LicenseMetadata';
    dmvId?: string | null;
    dmvFirstName?: string | null;
    dmvLastName?: string | null;
    dmvAddress?: string | null;
    dmvCity?: string | null;
    dmvState?: string | null;
    dmvZip?: string | null;
    dmvDob?: Date | null;
    dmvVehicleClass?: string | null;
    tlcFirstName?: string | null;
    tlcLastName?: string | null;
  } | null;
};

export type GetDriverLicensesQueryVariables = Exact<{
  filters?: InputMaybe<LicenseFilters>;
}>;

export type GetDriverLicensesQuery = {
  __typename?: 'Query';
  listLicenses: {
    __typename?: 'LicenseResponse';
    count: number;
    results?: Array<{
      __typename?: 'License';
      id: string;
      updatedAt: any;
      licenseType: LicenseTypes;
      licenseNumber?: string | null;
      expiresAt?: Date | null;
      status: LicenseStatuses;
      photoFrontUrl?: string | null;
      driverId: string;
      driver: {
        __typename?: 'Driver';
        id: string;
        avatar?: string | null;
        firstName: string;
        lastName: string;
      };
      metadata?: {
        __typename?: 'LicenseMetadata';
        dmvId?: string | null;
        dmvFirstName?: string | null;
        dmvLastName?: string | null;
        dmvAddress?: string | null;
        dmvCity?: string | null;
        dmvState?: string | null;
        dmvZip?: string | null;
        dmvDob?: Date | null;
        dmvVehicleClass?: string | null;
        tlcFirstName?: string | null;
        tlcLastName?: string | null;
      } | null;
    }> | null;
  };
};

export type GetDriverTracksQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
  filters?: InputMaybe<TrackFilters>;
}>;

export type GetDriverTracksQuery = {
  __typename?: 'Query';
  tracks: {
    __typename?: 'TrackResponse';
    results?: Array<{
      __typename?: 'Track';
      id: string;
      maxPoints?: number | null;
      name: string;
      displayName: string;
      currentDriverTrackLevel?: {
        __typename?: 'DriverTrackLevel';
        warningState?: PerformanceWarningState | null;
        level?: { __typename?: 'TrackLevel'; pointThreshold: number } | null;
      } | null;
    }> | null;
  };
};

export type GetDriverShiftQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetDriverShiftQuery = {
  __typename?: 'Query';
  getShift?: {
    __typename?: 'DriverShift';
    id: string;
    state: DriverShiftStates;
    issueType?: DriverShiftIssueTypes | null;
    resourceId?: string | null;
    currentIssueId?: string | null;
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    scheduledRecallAt?: any | null;
    clockIn?: any | null;
    clockOut?: any | null;
    reservationId?: string | null;
    depotId?: string | null;
    reservationType?: ReservationTypes | null;
    driverArrivedForShiftAt?: any | null;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      dayforceXrefCode?: string | null;
      phoneNumber: string;
      firstShiftCompleted?: boolean | null;
    };
    depot?: {
      __typename?: 'Depot';
      id: string;
      name: string;
      vehicleFacility?: {
        __typename?: 'VehicleFacility';
        name: string;
        id: string;
        geofenceRadiusMeters: number;
        centerPoint: { __typename?: 'Point'; lat: number; lng: number };
      } | null;
    } | null;
    breaks?: Array<{
      __typename?: 'DriverShiftBreak';
      id: string;
      type?: DriverShiftBreakTypes | null;
      scheduledStartAt?: any | null;
      scheduledEndAt?: any | null;
      actualStartAt?: any | null;
      actualEndAt?: any | null;
    }> | null;
    note?: { __typename?: 'ShiftNote'; note: string } | null;
    settings?: {
      __typename?: 'ShiftSettings';
      dynamicRecallExtensionEnabled?: boolean | null;
      dynamicRecallReductionEnabled?: boolean | null;
      phoneNumber?: string | null;
      phoneType?: string | null;
      deviceType?: DeviceTypes | null;
      appVersion?: string | null;
    } | null;
    currentStopPoint?: {
      __typename?: 'DriverStopPoint';
      id: string;
      stopType: DriverStopPointTypes;
    } | null;
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      vehicle?: {
        __typename?: 'FleetResource';
        licensePlate: string;
        batteryLevel: number;
        color?: string | null;
        id: string;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
      } | null;
    } | null;
    stats?: {
      __typename?: 'ShiftStats';
      negativeRatingCount?: number | null;
      negativeRatingCountThreshold?: number | null;
      missedRideCount?: number | null;
      missedRideCountThreshold?: number | null;
      avgPickupDelaySeconds?: number | null;
      avgPickupDelaySecondsThreshold?: number | null;
      driverAvailabilityPercentage?: number | null;
      driverAvailabilityPercentageThreshold?: number | null;
      attendancePercentage?: number | null;
      attendancePercentageThreshold?: number | null;
      endOfShiftRecallDelaySeconds?: number | null;
      endOfShiftRecallDelaySecondsThreshold?: number | null;
      exemptions?: Array<string | null> | null;
      performancePayMultiplier?: number | null;
      driverPerformanceStatus?: number | null;
      performanceStatusThresholds?: any | null;
    } | null;
    reservationBillingSettings?: {
      __typename?: 'ReservationBillingSettings';
      insurancePremiumTierDaily: InsurancePremiumTiersDaily;
    } | null;
    shiftCancellation?: {
      __typename?: 'ShiftCancellation';
      shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
    } | null;
  } | null;
};

export type GetDriverOtpValidationCodeQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverOtpValidationCodeQuery = {
  __typename?: 'Query';
  getDriverOTPVerificationCode?: {
    __typename?: 'DriverOTP';
    expiresAt: any;
    verificationCode?: string | null;
  } | null;
};

export type GenerateDriverAssetPresignedPostMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateDriverAssetPresignedPostMutation = {
  __typename?: 'Mutation';
  generateDriverAssetPresignedPost: {
    __typename?: 'DriverAssetPresignedPost';
    url: string;
    key: string;
    fields: any;
  };
};

export type UpdateDriverAvatarMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverAvatarMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: { __typename?: 'Driver'; avatar?: string | null };
};

export type UpdateDriverInfoMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverInfoMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: {
    __typename?: 'Driver';
    firstName: string;
    lastName: string;
    email?: string | null;
    phoneNumber: string;
    employmentStatus?: DriverEmploymentStatus | null;
  };
};

export type InvoiceDriverInfoField_invoiceFragment = {
  __typename?: 'DriverInvoice';
  driver?: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    avatar?: string | null;
  } | null;
};

export type GetInvoicesScreenQueryVariables = Exact<{
  filters?: InputMaybe<DriverInvoiceFilters>;
}>;

export type GetInvoicesScreenQuery = {
  __typename?: 'Query';
  invoices: {
    __typename?: 'DriverInvoicesResponse';
    count: number;
    results?: Array<{
      __typename?: 'DriverInvoice';
      id: string;
      createdAt: any;
      subtotal: number;
      tax: number;
      taxPercentage: number;
      total: number;
      status: DriverInvoiceStatuses;
      descriptor?: DriverInvoiceDescriptors | null;
      appliedCredit?: number | null;
      driver?: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        email?: string | null;
        stripeCustomerId?: string | null;
      } | null;
      invoiceItems?: Array<{
        __typename?: 'DriverInvoiceItem';
        amount?: number | null;
        displayTitleText?: string | null;
        displayDetailText?: string | null;
        id?: string | null;
      }> | null;
      paymentIntents?: Array<{
        __typename?: 'DriverPaymentIntent';
        createdAt?: any | null;
        status?: DriverPaymentIntentStatuses | null;
        amount?: number | null;
        stripeExternalId?: string | null;
        paymentMethod?: {
          __typename?: 'DriverPaymentMethod';
          lastFour?: string | null;
          brand?: string | null;
          type?: DriverPaymentMethodTypes | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type GetInvoicesScreen_invoiceFragment = {
  __typename?: 'DriverInvoice';
  id: string;
  createdAt: any;
  subtotal: number;
  tax: number;
  taxPercentage: number;
  total: number;
  status: DriverInvoiceStatuses;
  descriptor?: DriverInvoiceDescriptors | null;
  appliedCredit?: number | null;
  driver?: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    email?: string | null;
    stripeCustomerId?: string | null;
  } | null;
  invoiceItems?: Array<{
    __typename?: 'DriverInvoiceItem';
    amount?: number | null;
    displayTitleText?: string | null;
    displayDetailText?: string | null;
    id?: string | null;
  }> | null;
  paymentIntents?: Array<{
    __typename?: 'DriverPaymentIntent';
    createdAt?: any | null;
    status?: DriverPaymentIntentStatuses | null;
    amount?: number | null;
    stripeExternalId?: string | null;
    paymentMethod?: {
      __typename?: 'DriverPaymentMethod';
      lastFour?: string | null;
      brand?: string | null;
      type?: DriverPaymentMethodTypes | null;
    } | null;
  }> | null;
};

export type RetryInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type RetryInvoiceMutation = {
  __typename?: 'Mutation';
  retryDriverInvoice?: {
    __typename?: 'DriverInvoice';
    id: string;
    createdAt: any;
    subtotal: number;
    tax: number;
    taxPercentage: number;
    total: number;
    status: DriverInvoiceStatuses;
    descriptor?: DriverInvoiceDescriptors | null;
    appliedCredit?: number | null;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      stripeCustomerId?: string | null;
    } | null;
    invoiceItems?: Array<{
      __typename?: 'DriverInvoiceItem';
      amount?: number | null;
      displayTitleText?: string | null;
      displayDetailText?: string | null;
      id?: string | null;
    }> | null;
    paymentIntents?: Array<{
      __typename?: 'DriverPaymentIntent';
      createdAt?: any | null;
      status?: DriverPaymentIntentStatuses | null;
      amount?: number | null;
      stripeExternalId?: string | null;
      paymentMethod?: {
        __typename?: 'DriverPaymentMethod';
        lastFour?: string | null;
        brand?: string | null;
        type?: DriverPaymentMethodTypes | null;
      } | null;
    }> | null;
  } | null;
};

export type GetSelectedShiftMapLocationQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetSelectedShiftMapLocationQuery = {
  __typename?: 'Query';
  shift?: {
    __typename?: 'DriverShift';
    vehicleLocation?: { __typename?: 'Point'; lat: number; lng: number } | null;
  } | null;
};

export type GetBalancerMapConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetBalancerMapConfigQuery = {
  __typename?: 'Query';
  config: {
    __typename?: 'DriverAppConfig';
    balancerMap: {
      __typename?: 'BalancerMapConfig';
      calculateRelativeBalance: boolean;
      showDeficit: boolean;
      minDeficit: number;
      maxDeficit: number;
      deficitColorLight: string;
      deficitColorDark: string;
      showSurplus: boolean;
      minSurplus: number;
      maxSurplus: number;
      surplusColorLight: string;
      surplusColorDark: string;
      minOpacity: number;
      maxOpacity: number;
    };
  };
};

export type GetBalancerMapQueryVariables = Exact<{ [key: string]: never }>;

export type GetBalancerMapQuery = { __typename?: 'Query'; getBalancerMap: any };

export type GetSelectedShiftMapRouteQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetSelectedShiftMapRouteQuery = {
  __typename?: 'Query';
  shift?: {
    __typename?: 'DriverShift';
    id: string;
    state: DriverShiftStates;
    issueType?: DriverShiftIssueTypes | null;
    liveRouteUrl?: string | null;
  } | null;
  stopPoints: {
    __typename?: 'ShiftStopPointResponse';
    count: number;
    results?: Array<{
      __typename?: 'DriverStopPoint';
      id: string;
      stopType: DriverStopPointTypes;
      assignedAt?: any | null;
      finalizedAt?: any | null;
      acknowledgedAt?: any | null;
      state: DriverStopPointStates;
      address: string;
      location: { __typename?: 'Point'; lat: number; lng: number };
    }> | null;
  };
};

export type GetDriverMapsScreenQueryVariables = Exact<{
  filters: DriverShiftFilters;
}>;

export type GetDriverMapsScreenQuery = {
  __typename?: 'Query';
  shifts: {
    __typename?: 'DriverShiftsResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverShift';
      id: string;
      state: DriverShiftStates;
      issueType?: DriverShiftIssueTypes | null;
      vehicleLocationUpdatedAt?: any | null;
      driver: { __typename?: 'Driver'; firstName: string; lastName: string };
      vehicleLocation?: { __typename?: 'Point'; lat: number; lng: number } | null;
      currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
    }>;
  };
};

export type GetReservationsScreenQueryVariables = Exact<{
  filters: RentalReservationFilters;
}>;

export type GetReservationsScreenQuery = {
  __typename?: 'Query';
  reservations: {
    __typename?: 'RentalReservationsResponse';
    count: number;
    results?: Array<{
      __typename?: 'RentalReservation';
      id: string;
      state: RentalReservationStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      actualStartAt?: any | null;
      actualEndAt?: any | null;
      depotId?: string | null;
      currentResourceId?: string | null;
      driver?: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      } | null;
      depot?: { __typename?: 'Depot'; id: string; name: string } | null;
      currentResource?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        color?: string | null;
        batteryLevel: number;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type GetDriverIncidentDisputesQueryVariables = Exact<{
  filters?: InputMaybe<DriverIncidentDisputesFilters>;
}>;

export type GetDriverIncidentDisputesQuery = {
  __typename?: 'Query';
  disputes: {
    __typename?: 'DriverIncidentDisputesResponse';
    count: number;
    results?: Array<{
      __typename?: 'DriverIncidentDispute';
      id: string;
      createdAt: any;
      disputeType: DriverIncidentDisputeTypes;
      driverIncidentId: string;
      driverIncident?: {
        __typename?: 'DriverIncident';
        id: string;
        driver?: {
          __typename?: 'Driver';
          id: string;
          firstName: string;
          lastName: string;
          avatar?: string | null;
        } | null;
        incident?: {
          __typename?: 'Incident';
          displayName?: string | null;
          points: number;
          track?: { __typename?: 'Track'; displayName: string } | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type GetOffboardingScreenListQueryVariables = Exact<{
  filters?: InputMaybe<SecurityDepositPayoutFilters>;
}>;

export type GetOffboardingScreenListQuery = {
  __typename?: 'Query';
  securityDepositPayouts: {
    __typename?: 'SecurityDepositPayoutsResponse';
    count: number;
    results: Array<{
      __typename?: 'SecurityDepositPayout';
      id: string;
      createdAt: any;
      total: number;
      status: SecurityDepositPayoutStatuses;
      driver?: {
        __typename?: 'Driver';
        id: string;
        avatar?: string | null;
        firstName: string;
        lastName: string;
        email?: string | null;
        addresses?: Array<{
          __typename?: 'DriverAddress';
          address1: string;
          address2?: string | null;
          city: string;
          state: string;
          postalCode: string;
        }> | null;
      } | null;
    }>;
  };
};

export type ConfirmCheckMutationVariables = Exact<{
  payoutRecordId: Scalars['ID']['input'];
}>;

export type ConfirmCheckMutation = {
  __typename?: 'Mutation';
  payoutRecord?: { __typename?: 'EmptyResponse'; id?: string | null } | null;
};

export type RideHailQueryVariables = Exact<{
  rideHailId: Scalars['ID']['input'];
}>;

export type RideHailQuery = {
  __typename?: 'Query';
  rideHail?: {
    __typename?: 'DriverRideHail';
    id: string;
    canceledAt?: any | null;
    createdAt: any;
    assignedAt?: any | null;
    driverId?: string | null;
    renterId?: string | null;
    estimatedTripMeters?: number | null;
    invoiceTotal?: number | null;
    completedAt?: any | null;
    failedAt?: any | null;
    finalizedAt?: any | null;
    rejectedAt?: any | null;
    state: DriverRideHailStates;
    resourceId?: string | null;
    vehicleBearing?: number | null;
    tripDurationSeconds?: number | null;
    systemId?: string | null;
    consumerMetadata?: any | null;
    consumerRideHailId?: string | null;
    shiftId?: string | null;
    driverRenter?: {
      __typename?: 'DriverRenter';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      profilePhoto?: string | null;
    } | null;
    receipt?: {
      __typename?: 'DriverRideReceipt';
      amount: number;
      notes?: Array<string> | null;
      items: Array<{
        __typename?: 'DriverReceiptItemGroup';
        itemKey: string;
        label: string;
        amount: number;
        items: Array<{
          __typename?: 'DriverReceiptItem';
          itemKey: string;
          label: string;
          subLabel?: string | null;
          amount: number;
        }>;
      }>;
    } | null;
    route?: { __typename?: 'LineString'; coordinates?: any | null; type?: string | null } | null;
    shift?: {
      __typename?: 'DriverShift';
      id: string;
      driver: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      };
      vehicleLocation?: { __typename?: 'Point'; lat: number; lng: number } | null;
    } | null;
    stopPoints?: Array<{
      __typename?: 'DriverStopPoint';
      id: string;
      address: string;
      createdAt: any;
      estimatedArrivalTime?: any | null;
      state: DriverStopPointStates;
      stopType: DriverStopPointTypes;
      currentRouteGeojsonString?: string | null;
      renterId?: string | null;
      systemId?: string | null;
      requestedArrivalAt?: any | null;
      actualArrivalTime?: any | null;
      arrivedAt?: any | null;
      startedAt?: any | null;
      canceledAt?: any | null;
      completedAt?: any | null;
      failedAt?: any | null;
      estimatedTripMeters?: number | null;
      estimatedArrivalTimeAtOffer?: any | null;
      parentId?: string | null;
      order?: number | null;
      isCurrent?: boolean | null;
      acknowledgedAt?: any | null;
      finalizedAt?: any | null;
      assignedAt?: any | null;
      priority: number;
      consumerRideHailId?: string | null;
      location: { __typename?: 'Point'; lat: number; lng: number };
    }> | null;
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      vehicle?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        batteryLevel: number;
        color?: string | null;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ScheduledShiftClockOut_shiftFragment = {
  __typename?: 'DriverScheduledShift';
  startedAt?: any | null;
  finalizedAt?: any | null;
  depot?: { __typename?: 'Depot'; id: string } | null;
};

export type GetScheduledShiftFilterCountQueryVariables = Exact<{
  filters: DriverScheduledShiftFilter;
  pageInfo?: InputMaybe<PageInfo>;
}>;

export type GetScheduledShiftFilterCountQuery = {
  __typename?: 'Query';
  shifts: { __typename?: 'DriverScheduledShiftsPagedResult'; count: number };
};

export type ScheduledShiftScheduledEnd_shiftFragment = {
  __typename?: 'DriverScheduledShift';
  scheduledStartAt: any;
  scheduledEndAt: any;
  depot?: { __typename?: 'Depot'; id: string } | null;
};

export type GetDriverScheduledShiftsScreenQueryVariables = Exact<{
  filters: DriverScheduledShiftFilter;
  pageInfo?: InputMaybe<PageInfo>;
  ordering?: InputMaybe<
    | Array<InputMaybe<DriverScheduledShiftOrderingField>>
    | InputMaybe<DriverScheduledShiftOrderingField>
  >;
}>;

export type GetDriverScheduledShiftsScreenQuery = {
  __typename?: 'Query';
  scheduledShifts: {
    __typename?: 'DriverScheduledShiftsPagedResult';
    count: number;
    results?: Array<{
      __typename?: 'DriverScheduledShift';
      id: string;
      state: DriverScheduledShiftState;
      scheduledStartAt: any;
      scheduledEndAt: any;
      startedAt?: any | null;
      finalizedAt?: any | null;
      depot?: { __typename?: 'Depot'; id: string } | null;
      driver: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      };
      activeTasks: {
        __typename?: 'DriverTaskResponse';
        results?: Array<{
          __typename?: 'DriverTask';
          taskDefinition: { __typename?: 'DriverTaskDefinition'; name: DriverTaskTypes };
        }> | null;
      };
      reservation?: {
        __typename?: 'RentalReservation';
        id: string;
        state: RentalReservationStates;
        currentResourceId?: string | null;
        currentResource?: {
          __typename?: 'FleetResource';
          id: string;
          licensePlate: string;
          color?: string | null;
          batteryLevel: number;
          detail?: {
            __typename?: 'FleetResourceDetail';
            make?: string | null;
            model?: string | null;
          } | null;
        } | null;
        depot?: { __typename?: 'Depot'; name: string } | null;
      } | null;
    }> | null;
  };
};

export type StopPointActionMenu_stopPointFragment = {
  __typename?: 'DriverStopPoint';
  id: string;
  stopType: DriverStopPointTypes;
  state: DriverStopPointStates;
};

export type ManipulateStopPointMutationVariables = Exact<{
  stopPointId: Scalars['ID']['input'];
  manipulationType: StopPointManipulationTypes;
}>;

export type ManipulateStopPointMutation = {
  __typename?: 'Mutation';
  stopPoint: { __typename?: 'DriverStopPoint'; id: string };
};

export type GetShiftStopPointsQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
  filters?: InputMaybe<ShiftStopPointsFilter>;
}>;

export type GetShiftStopPointsQuery = {
  __typename?: 'Query';
  stopPoints: {
    __typename?: 'ShiftStopPointResponse';
    count: number;
    results?: Array<{
      __typename?: 'DriverStopPoint';
      id: string;
      stopType: DriverStopPointTypes;
      assignedAt?: any | null;
      finalizedAt?: any | null;
      acknowledgedAt?: any | null;
      state: DriverStopPointStates;
      consumerRideHailId?: string | null;
      address: string;
      metadata?: { __typename?: 'StoppointMeta'; issueType?: DriverShiftIssueTypes | null } | null;
    }> | null;
  };
};

export type GetDriverShiftsScreenQueryVariables = Exact<{
  filters: DriverShiftFilters;
}>;

export type GetDriverShiftsScreenQuery = {
  __typename?: 'Query';
  shifts: {
    __typename?: 'DriverShiftsResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverShift';
      id: string;
      state: DriverShiftStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      scheduledRecallAt?: any | null;
      reservationType?: ReservationTypes | null;
      clockIn?: any | null;
      clockOut?: any | null;
      resourceId?: string | null;
      issueType?: DriverShiftIssueTypes | null;
      currentIssueId?: string | null;
      driverArrivedForShiftAt?: any | null;
      driver: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        firstShiftCompleted?: boolean | null;
      };
      fleetResourceMetadata?: {
        __typename?: 'FleetResourceMetadata';
        vehicle?: {
          __typename?: 'FleetResource';
          id: string;
          licensePlate: string;
          batteryLevel: number;
          color?: string | null;
          detail?: {
            __typename?: 'FleetResourceDetail';
            make?: string | null;
            model?: string | null;
          } | null;
        } | null;
      } | null;
      currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
      depot?: { __typename?: 'Depot'; id: string; name: string } | null;
      shiftCancellation?: {
        __typename?: 'ShiftCancellation';
        shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
      } | null;
      note?: { __typename?: 'ShiftNote'; note: string } | null;
    }>;
  };
};

export type GetShiftResourceMetadataQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetShiftResourceMetadataQuery = {
  __typename?: 'Query';
  getShiftResourceMetadata?: {
    __typename?: 'ShiftResourceMetadataResponse';
    results?: Array<{
      __typename?: 'ShiftResourceMetadata';
      id: string;
      resourceId: string;
      licensePlate?: string | null;
      driverReachedVehicleAt?: any | null;
      resourceAssignedAt?: any | null;
      resourceRemovedAt?: any | null;
    }> | null;
  } | null;
};

export type GetDriverFleetResourceQueryVariables = Exact<{
  fleetResourceId: Scalars['ID']['input'];
}>;

export type GetDriverFleetResourceQuery = {
  __typename?: 'Query';
  vehicle: {
    __typename?: 'DriverFleetResource';
    id: string;
    vin: string;
    licensePlate: string;
    batteryLevel?: number | null;
    telematicsProvider: string;
    status?: string | null;
    speedLimitPin?: string | null;
    color: string;
    location?: { __typename?: 'Point'; lat: number; lng: number } | null;
    detail: {
      __typename?: 'DriverFleetResourceDetail';
      make: string;
      model: string;
      year?: number | null;
    };
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      fhvLicenseNumber: string;
      isControllable?: boolean | null;
      tlcBase?: { __typename?: 'ResourceMetadataTLCBase'; name: string } | null;
      dispatchingTlcBase?: { __typename?: 'ResourceMetadataTLCBase'; name: string } | null;
      insuranceProvider?: { __typename?: 'InsuranceProvider'; displayName: string } | null;
      vehicle?: {
        __typename?: 'FleetResource';
        sensors?: { __typename?: 'Sensors'; updateTime?: any | null } | null;
      } | null;
    } | null;
    activeShift?: {
      __typename?: 'DriverShift';
      id: string;
      state: DriverShiftStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      driver: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        firstShiftCompleted?: boolean | null;
      };
    } | null;
  };
};

export type GetVehiclesScreenQueryVariables = Exact<{
  filters: DriverFleetResourceFilters;
}>;

export type GetVehiclesScreenQuery = {
  __typename?: 'Query';
  vehicles: {
    __typename?: 'DriverFleetResourcesResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverFleetResource';
      id: string;
      licensePlate: string;
      color: string;
      vin: string;
      batteryLevel?: number | null;
      status?: string | null;
      updated: any;
      detail: { __typename?: 'DriverFleetResourceDetail'; make: string; model: string };
      location?: { __typename?: 'Point'; lat: number; lng: number } | null;
    } | null>;
  };
};

export type ZoneGroup_zoneFragment = {
  __typename?: 'Zone';
  id: string;
  nameKey: string;
  zoneType: string;
  areaGeojsonString: string;
};

export type ZonePageZonesQueryVariables = Exact<{
  filters: ZoneFilters;
}>;

export type ZonePageZonesQuery = {
  __typename?: 'Query';
  zones?: {
    __typename?: 'ZoneList';
    results: Array<{
      __typename?: 'Zone';
      zoneType: string;
      id: string;
      nameKey: string;
      areaGeojsonString: string;
    }>;
  } | null;
};

export type SystemSettings_systemFragment = {
  __typename?: 'System';
  id: string;
  timeZone?: string | null;
  centerPoint: { __typename?: 'Point'; lat: number; lng: number };
  settings?: { __typename?: 'SystemSettings'; lowBatteryTagPercentage?: number | null } | null;
};

export type GetSystemQueryQueryVariables = Exact<{
  systemIdentifier: Scalars['String']['input'];
}>;

export type GetSystemQueryQuery = {
  __typename?: 'Query';
  system?: {
    __typename?: 'System';
    id: string;
    timeZone?: string | null;
    centerPoint: { __typename?: 'Point'; lat: number; lng: number };
    settings?: { __typename?: 'SystemSettings'; lowBatteryTagPercentage?: number | null } | null;
  } | null;
};

/**
 * __useGetDriverVehicleSelectorVehiclesQuery__
 *
 * To run a query within a React component, call `useGetDriverVehicleSelectorVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverVehicleSelectorVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverVehicleSelectorVehiclesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriverVehicleSelectorVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverVehicleSelectorVehiclesQuery,
    Operations.GetDriverVehicleSelectorVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverVehicleSelectorVehiclesQuery,
    Operations.GetDriverVehicleSelectorVehiclesQueryVariables
  >(Operations.GetDriverVehicleSelectorVehicles, options);
}
export function useGetDriverVehicleSelectorVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverVehicleSelectorVehiclesQuery,
    Operations.GetDriverVehicleSelectorVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverVehicleSelectorVehiclesQuery,
    Operations.GetDriverVehicleSelectorVehiclesQueryVariables
  >(Operations.GetDriverVehicleSelectorVehicles, options);
}
export type GetDriverVehicleSelectorVehiclesQueryHookResult = ReturnType<
  typeof useGetDriverVehicleSelectorVehiclesQuery
>;
export type GetDriverVehicleSelectorVehiclesLazyQueryHookResult = ReturnType<
  typeof useGetDriverVehicleSelectorVehiclesLazyQuery
>;
export type GetDriverVehicleSelectorVehiclesQueryResult = Apollo.QueryResult<
  Operations.GetDriverVehicleSelectorVehiclesQuery,
  Operations.GetDriverVehicleSelectorVehiclesQueryVariables
>;

/**
 * __useGetDepotSelectorDepotsQuery__
 *
 * To run a query within a React component, call `useGetDepotSelectorDepotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepotSelectorDepotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepotSelectorDepotsQuery({
 *   variables: {
 *      systemIdentifier: // value for 'systemIdentifier'
 *   },
 * });
 */
export function useGetDepotSelectorDepotsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetDepotSelectorDepotsQuery,
    Operations.GetDepotSelectorDepotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDepotSelectorDepotsQuery,
    Operations.GetDepotSelectorDepotsQueryVariables
  >(Operations.GetDepotSelectorDepots, options);
}
export function useGetDepotSelectorDepotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDepotSelectorDepotsQuery,
    Operations.GetDepotSelectorDepotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDepotSelectorDepotsQuery,
    Operations.GetDepotSelectorDepotsQueryVariables
  >(Operations.GetDepotSelectorDepots, options);
}
export type GetDepotSelectorDepotsQueryHookResult = ReturnType<
  typeof useGetDepotSelectorDepotsQuery
>;
export type GetDepotSelectorDepotsLazyQueryHookResult = ReturnType<
  typeof useGetDepotSelectorDepotsLazyQuery
>;
export type GetDepotSelectorDepotsQueryResult = Apollo.QueryResult<
  Operations.GetDepotSelectorDepotsQuery,
  Operations.GetDepotSelectorDepotsQueryVariables
>;

/**
 * __useGetInvoiceDetailsSidebarQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDetailsSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDetailsSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDetailsSidebarQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceDetailsSidebarQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetInvoiceDetailsSidebarQuery,
    Operations.GetInvoiceDetailsSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetInvoiceDetailsSidebarQuery,
    Operations.GetInvoiceDetailsSidebarQueryVariables
  >(Operations.GetInvoiceDetailsSidebar, options);
}
export function useGetInvoiceDetailsSidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetInvoiceDetailsSidebarQuery,
    Operations.GetInvoiceDetailsSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetInvoiceDetailsSidebarQuery,
    Operations.GetInvoiceDetailsSidebarQueryVariables
  >(Operations.GetInvoiceDetailsSidebar, options);
}
export type GetInvoiceDetailsSidebarQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsSidebarQuery
>;
export type GetInvoiceDetailsSidebarLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsSidebarLazyQuery
>;
export type GetInvoiceDetailsSidebarQueryResult = Apollo.QueryResult<
  Operations.GetInvoiceDetailsSidebarQuery,
  Operations.GetInvoiceDetailsSidebarQueryVariables
>;

/**
 * __useGetSystemsDepotsQuery__
 *
 * To run a query within a React component, call `useGetSystemsDepotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemsDepotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemsDepotsQuery({
 *   variables: {
 *      systemIdentifier: // value for 'systemIdentifier'
 *   },
 * });
 */
export function useGetSystemsDepotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetSystemsDepotsQuery,
    Operations.GetSystemsDepotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetSystemsDepotsQuery,
    Operations.GetSystemsDepotsQueryVariables
  >(Operations.GetSystemsDepots, options);
}
export function useGetSystemsDepotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetSystemsDepotsQuery,
    Operations.GetSystemsDepotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetSystemsDepotsQuery,
    Operations.GetSystemsDepotsQueryVariables
  >(Operations.GetSystemsDepots, options);
}
export type GetSystemsDepotsQueryHookResult = ReturnType<typeof useGetSystemsDepotsQuery>;
export type GetSystemsDepotsLazyQueryHookResult = ReturnType<typeof useGetSystemsDepotsLazyQuery>;
export type GetSystemsDepotsQueryResult = Apollo.QueryResult<
  Operations.GetSystemsDepotsQuery,
  Operations.GetSystemsDepotsQueryVariables
>;

/**
 * __useGetDriverSystemZonesQuery__
 *
 * To run a query within a React component, call `useGetDriverSystemZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverSystemZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverSystemZonesQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useGetDriverSystemZonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverSystemZonesQuery,
    Operations.GetDriverSystemZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverSystemZonesQuery,
    Operations.GetDriverSystemZonesQueryVariables
  >(Operations.GetDriverSystemZones, options);
}
export function useGetDriverSystemZonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverSystemZonesQuery,
    Operations.GetDriverSystemZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverSystemZonesQuery,
    Operations.GetDriverSystemZonesQueryVariables
  >(Operations.GetDriverSystemZones, options);
}
export type GetDriverSystemZonesQueryHookResult = ReturnType<typeof useGetDriverSystemZonesQuery>;
export type GetDriverSystemZonesLazyQueryHookResult = ReturnType<
  typeof useGetDriverSystemZonesLazyQuery
>;
export type GetDriverSystemZonesQueryResult = Apollo.QueryResult<
  Operations.GetDriverSystemZonesQuery,
  Operations.GetDriverSystemZonesQueryVariables
>;

/**
 * __useListNotesReservationActionShortcutsQuery__
 *
 * To run a query within a React component, call `useListNotesReservationActionShortcutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNotesReservationActionShortcutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNotesReservationActionShortcutsQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useListNotesReservationActionShortcutsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ListNotesReservationActionShortcutsQuery,
    Operations.ListNotesReservationActionShortcutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.ListNotesReservationActionShortcutsQuery,
    Operations.ListNotesReservationActionShortcutsQueryVariables
  >(Operations.ListNotesReservationActionShortcuts, options);
}
export function useListNotesReservationActionShortcutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ListNotesReservationActionShortcutsQuery,
    Operations.ListNotesReservationActionShortcutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.ListNotesReservationActionShortcutsQuery,
    Operations.ListNotesReservationActionShortcutsQueryVariables
  >(Operations.ListNotesReservationActionShortcuts, options);
}
export type ListNotesReservationActionShortcutsQueryHookResult = ReturnType<
  typeof useListNotesReservationActionShortcutsQuery
>;
export type ListNotesReservationActionShortcutsLazyQueryHookResult = ReturnType<
  typeof useListNotesReservationActionShortcutsLazyQuery
>;
export type ListNotesReservationActionShortcutsQueryResult = Apollo.QueryResult<
  Operations.ListNotesReservationActionShortcutsQuery,
  Operations.ListNotesReservationActionShortcutsQueryVariables
>;

/**
 * __useGetReservationDetailsSidebarQuery__
 *
 * To run a query within a React component, call `useGetReservationDetailsSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationDetailsSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationDetailsSidebarQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationDetailsSidebarQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetReservationDetailsSidebarQuery,
    Operations.GetReservationDetailsSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetReservationDetailsSidebarQuery,
    Operations.GetReservationDetailsSidebarQueryVariables
  >(Operations.GetReservationDetailsSidebar, options);
}
export function useGetReservationDetailsSidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetReservationDetailsSidebarQuery,
    Operations.GetReservationDetailsSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetReservationDetailsSidebarQuery,
    Operations.GetReservationDetailsSidebarQueryVariables
  >(Operations.GetReservationDetailsSidebar, options);
}
export type GetReservationDetailsSidebarQueryHookResult = ReturnType<
  typeof useGetReservationDetailsSidebarQuery
>;
export type GetReservationDetailsSidebarLazyQueryHookResult = ReturnType<
  typeof useGetReservationDetailsSidebarLazyQuery
>;
export type GetReservationDetailsSidebarQueryResult = Apollo.QueryResult<
  Operations.GetReservationDetailsSidebarQuery,
  Operations.GetReservationDetailsSidebarQueryVariables
>;

/**
 * __useGetReservationFilterCountQuery__
 *
 * To run a query within a React component, call `useGetReservationFilterCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationFilterCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationFilterCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetReservationFilterCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetReservationFilterCountQuery,
    Operations.GetReservationFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetReservationFilterCountQuery,
    Operations.GetReservationFilterCountQueryVariables
  >(Operations.GetReservationFilterCount, options);
}
export function useGetReservationFilterCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetReservationFilterCountQuery,
    Operations.GetReservationFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetReservationFilterCountQuery,
    Operations.GetReservationFilterCountQueryVariables
  >(Operations.GetReservationFilterCount, options);
}
export type GetReservationFilterCountQueryHookResult = ReturnType<
  typeof useGetReservationFilterCountQuery
>;
export type GetReservationFilterCountLazyQueryHookResult = ReturnType<
  typeof useGetReservationFilterCountLazyQuery
>;
export type GetReservationFilterCountQueryResult = Apollo.QueryResult<
  Operations.GetReservationFilterCountQuery,
  Operations.GetReservationFilterCountQueryVariables
>;

/**
 * __useGetScheduledShiftDetailsSidebarQuery__
 *
 * To run a query within a React component, call `useGetScheduledShiftDetailsSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledShiftDetailsSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledShiftDetailsSidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScheduledShiftDetailsSidebarQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetScheduledShiftDetailsSidebarQuery,
    Operations.GetScheduledShiftDetailsSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetScheduledShiftDetailsSidebarQuery,
    Operations.GetScheduledShiftDetailsSidebarQueryVariables
  >(Operations.GetScheduledShiftDetailsSidebar, options);
}
export function useGetScheduledShiftDetailsSidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetScheduledShiftDetailsSidebarQuery,
    Operations.GetScheduledShiftDetailsSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetScheduledShiftDetailsSidebarQuery,
    Operations.GetScheduledShiftDetailsSidebarQueryVariables
  >(Operations.GetScheduledShiftDetailsSidebar, options);
}
export type GetScheduledShiftDetailsSidebarQueryHookResult = ReturnType<
  typeof useGetScheduledShiftDetailsSidebarQuery
>;
export type GetScheduledShiftDetailsSidebarLazyQueryHookResult = ReturnType<
  typeof useGetScheduledShiftDetailsSidebarLazyQuery
>;
export type GetScheduledShiftDetailsSidebarQueryResult = Apollo.QueryResult<
  Operations.GetScheduledShiftDetailsSidebarQuery,
  Operations.GetScheduledShiftDetailsSidebarQueryVariables
>;

/**
 * __useGetShiftInfoQuery__
 *
 * To run a query within a React component, call `useGetShiftInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftInfoQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useGetShiftInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetShiftInfoQuery,
    Operations.GetShiftInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.GetShiftInfoQuery, Operations.GetShiftInfoQueryVariables>(
    Operations.GetShiftInfo,
    options,
  );
}
export function useGetShiftInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetShiftInfoQuery,
    Operations.GetShiftInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.GetShiftInfoQuery, Operations.GetShiftInfoQueryVariables>(
    Operations.GetShiftInfo,
    options,
  );
}
export type GetShiftInfoQueryHookResult = ReturnType<typeof useGetShiftInfoQuery>;
export type GetShiftInfoLazyQueryHookResult = ReturnType<typeof useGetShiftInfoLazyQuery>;
export type GetShiftInfoQueryResult = Apollo.QueryResult<
  Operations.GetShiftInfoQuery,
  Operations.GetShiftInfoQueryVariables
>;

/**
 * __useGetShiftFilterCountQuery__
 *
 * To run a query within a React component, call `useGetShiftFilterCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftFilterCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftFilterCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetShiftFilterCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetShiftFilterCountQuery,
    Operations.GetShiftFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetShiftFilterCountQuery,
    Operations.GetShiftFilterCountQueryVariables
  >(Operations.GetShiftFilterCount, options);
}
export function useGetShiftFilterCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetShiftFilterCountQuery,
    Operations.GetShiftFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetShiftFilterCountQuery,
    Operations.GetShiftFilterCountQueryVariables
  >(Operations.GetShiftFilterCount, options);
}
export type GetShiftFilterCountQueryHookResult = ReturnType<typeof useGetShiftFilterCountQuery>;
export type GetShiftFilterCountLazyQueryHookResult = ReturnType<
  typeof useGetShiftFilterCountLazyQuery
>;
export type GetShiftFilterCountQueryResult = Apollo.QueryResult<
  Operations.GetShiftFilterCountQuery,
  Operations.GetShiftFilterCountQueryVariables
>;

/**
 * __useGetShiftMapMarkerInfoQuery__
 *
 * To run a query within a React component, call `useGetShiftMapMarkerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftMapMarkerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftMapMarkerInfoQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useGetShiftMapMarkerInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetShiftMapMarkerInfoQuery,
    Operations.GetShiftMapMarkerInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetShiftMapMarkerInfoQuery,
    Operations.GetShiftMapMarkerInfoQueryVariables
  >(Operations.GetShiftMapMarkerInfo, options);
}
export function useGetShiftMapMarkerInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetShiftMapMarkerInfoQuery,
    Operations.GetShiftMapMarkerInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetShiftMapMarkerInfoQuery,
    Operations.GetShiftMapMarkerInfoQueryVariables
  >(Operations.GetShiftMapMarkerInfo, options);
}
export type GetShiftMapMarkerInfoQueryHookResult = ReturnType<typeof useGetShiftMapMarkerInfoQuery>;
export type GetShiftMapMarkerInfoLazyQueryHookResult = ReturnType<
  typeof useGetShiftMapMarkerInfoLazyQuery
>;
export type GetShiftMapMarkerInfoQueryResult = Apollo.QueryResult<
  Operations.GetShiftMapMarkerInfoQuery,
  Operations.GetShiftMapMarkerInfoQueryVariables
>;

/**
 * __useSpotlightDriverSearchQuery__
 *
 * To run a query within a React component, call `useSpotlightDriverSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpotlightDriverSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpotlightDriverSearchQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSpotlightDriverSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.SpotlightDriverSearchQuery,
    Operations.SpotlightDriverSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.SpotlightDriverSearchQuery,
    Operations.SpotlightDriverSearchQueryVariables
  >(Operations.SpotlightDriverSearch, options);
}
export function useSpotlightDriverSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.SpotlightDriverSearchQuery,
    Operations.SpotlightDriverSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.SpotlightDriverSearchQuery,
    Operations.SpotlightDriverSearchQueryVariables
  >(Operations.SpotlightDriverSearch, options);
}
export type SpotlightDriverSearchQueryHookResult = ReturnType<typeof useSpotlightDriverSearchQuery>;
export type SpotlightDriverSearchLazyQueryHookResult = ReturnType<
  typeof useSpotlightDriverSearchLazyQuery
>;
export type SpotlightDriverSearchQueryResult = Apollo.QueryResult<
  Operations.SpotlightDriverSearchQuery,
  Operations.SpotlightDriverSearchQueryVariables
>;
export type CommandResourceMutationFn = Apollo.MutationFunction<
  Operations.CommandResourceMutation,
  Operations.CommandResourceMutationVariables
>;

/**
 * __useCommandResourceMutation__
 *
 * To run a mutation, you first call `useCommandResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommandResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commandResourceMutation, { data, loading, error }] = useCommandResourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommandResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CommandResourceMutation,
    Operations.CommandResourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CommandResourceMutation,
    Operations.CommandResourceMutationVariables
  >(Operations.CommandResource, options);
}
export type CommandResourceMutationHookResult = ReturnType<typeof useCommandResourceMutation>;
export type CommandResourceMutationResult = Apollo.MutationResult<Operations.CommandResourceMutation>;
export type CommandResourceMutationOptions = Apollo.BaseMutationOptions<
  Operations.CommandResourceMutation,
  Operations.CommandResourceMutationVariables
>;

/**
 * __useGetVehicleFacilitiesQuery__
 *
 * To run a query within a React component, call `useGetVehicleFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleFacilitiesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetVehicleFacilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetVehicleFacilitiesQuery,
    Operations.GetVehicleFacilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetVehicleFacilitiesQuery,
    Operations.GetVehicleFacilitiesQueryVariables
  >(Operations.GetVehicleFacilities, options);
}
export function useGetVehicleFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetVehicleFacilitiesQuery,
    Operations.GetVehicleFacilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetVehicleFacilitiesQuery,
    Operations.GetVehicleFacilitiesQueryVariables
  >(Operations.GetVehicleFacilities, options);
}
export type GetVehicleFacilitiesQueryHookResult = ReturnType<typeof useGetVehicleFacilitiesQuery>;
export type GetVehicleFacilitiesLazyQueryHookResult = ReturnType<
  typeof useGetVehicleFacilitiesLazyQuery
>;
export type GetVehicleFacilitiesQueryResult = Apollo.QueryResult<
  Operations.GetVehicleFacilitiesQuery,
  Operations.GetVehicleFacilitiesQueryVariables
>;

/**
 * __useGetVehicleFilterCountQuery__
 *
 * To run a query within a React component, call `useGetVehicleFilterCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleFilterCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleFilterCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetVehicleFilterCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetVehicleFilterCountQuery,
    Operations.GetVehicleFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetVehicleFilterCountQuery,
    Operations.GetVehicleFilterCountQueryVariables
  >(Operations.GetVehicleFilterCount, options);
}
export function useGetVehicleFilterCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetVehicleFilterCountQuery,
    Operations.GetVehicleFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetVehicleFilterCountQuery,
    Operations.GetVehicleFilterCountQueryVariables
  >(Operations.GetVehicleFilterCount, options);
}
export type GetVehicleFilterCountQueryHookResult = ReturnType<typeof useGetVehicleFilterCountQuery>;
export type GetVehicleFilterCountLazyQueryHookResult = ReturnType<
  typeof useGetVehicleFilterCountLazyQuery
>;
export type GetVehicleFilterCountQueryResult = Apollo.QueryResult<
  Operations.GetVehicleFilterCountQuery,
  Operations.GetVehicleFilterCountQueryVariables
>;

/**
 * __useGetVehicleSelectorVehiclesQuery__
 *
 * To run a query within a React component, call `useGetVehicleSelectorVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleSelectorVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleSelectorVehiclesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetVehicleSelectorVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetVehicleSelectorVehiclesQuery,
    Operations.GetVehicleSelectorVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetVehicleSelectorVehiclesQuery,
    Operations.GetVehicleSelectorVehiclesQueryVariables
  >(Operations.GetVehicleSelectorVehicles, options);
}
export function useGetVehicleSelectorVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetVehicleSelectorVehiclesQuery,
    Operations.GetVehicleSelectorVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetVehicleSelectorVehiclesQuery,
    Operations.GetVehicleSelectorVehiclesQueryVariables
  >(Operations.GetVehicleSelectorVehicles, options);
}
export type GetVehicleSelectorVehiclesQueryHookResult = ReturnType<
  typeof useGetVehicleSelectorVehiclesQuery
>;
export type GetVehicleSelectorVehiclesLazyQueryHookResult = ReturnType<
  typeof useGetVehicleSelectorVehiclesLazyQuery
>;
export type GetVehicleSelectorVehiclesQueryResult = Apollo.QueryResult<
  Operations.GetVehicleSelectorVehiclesQuery,
  Operations.GetVehicleSelectorVehiclesQueryVariables
>;
export type IssueRentalCreditMutationFn = Apollo.MutationFunction<
  Operations.IssueRentalCreditMutation,
  Operations.IssueRentalCreditMutationVariables
>;

/**
 * __useIssueRentalCreditMutation__
 *
 * To run a mutation, you first call `useIssueRentalCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueRentalCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueRentalCreditMutation, { data, loading, error }] = useIssueRentalCreditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueRentalCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.IssueRentalCreditMutation,
    Operations.IssueRentalCreditMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.IssueRentalCreditMutation,
    Operations.IssueRentalCreditMutationVariables
  >(Operations.IssueRentalCredit, options);
}
export type IssueRentalCreditMutationHookResult = ReturnType<typeof useIssueRentalCreditMutation>;
export type IssueRentalCreditMutationResult = Apollo.MutationResult<Operations.IssueRentalCreditMutation>;
export type IssueRentalCreditMutationOptions = Apollo.BaseMutationOptions<
  Operations.IssueRentalCreditMutation,
  Operations.IssueRentalCreditMutationVariables
>;

/**
 * __useCalculateDriverVehicleCreditAmountQuery__
 *
 * To run a query within a React component, call `useCalculateDriverVehicleCreditAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateDriverVehicleCreditAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateDriverVehicleCreditAmountQuery({
 *   variables: {
 *      minutes: // value for 'minutes'
 *      vehicleType: // value for 'vehicleType'
 *   },
 * });
 */
export function useCalculateDriverVehicleCreditAmountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.CalculateDriverVehicleCreditAmountQuery,
    Operations.CalculateDriverVehicleCreditAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.CalculateDriverVehicleCreditAmountQuery,
    Operations.CalculateDriverVehicleCreditAmountQueryVariables
  >(Operations.CalculateDriverVehicleCreditAmount, options);
}
export function useCalculateDriverVehicleCreditAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.CalculateDriverVehicleCreditAmountQuery,
    Operations.CalculateDriverVehicleCreditAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.CalculateDriverVehicleCreditAmountQuery,
    Operations.CalculateDriverVehicleCreditAmountQueryVariables
  >(Operations.CalculateDriverVehicleCreditAmount, options);
}
export type CalculateDriverVehicleCreditAmountQueryHookResult = ReturnType<
  typeof useCalculateDriverVehicleCreditAmountQuery
>;
export type CalculateDriverVehicleCreditAmountLazyQueryHookResult = ReturnType<
  typeof useCalculateDriverVehicleCreditAmountLazyQuery
>;
export type CalculateDriverVehicleCreditAmountQueryResult = Apollo.QueryResult<
  Operations.CalculateDriverVehicleCreditAmountQuery,
  Operations.CalculateDriverVehicleCreditAmountQueryVariables
>;

/**
 * __useGetDriverAvailableCreditQuery__
 *
 * To run a query within a React component, call `useGetDriverAvailableCreditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverAvailableCreditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverAvailableCreditQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverAvailableCreditQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetDriverAvailableCreditQuery,
    Operations.GetDriverAvailableCreditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverAvailableCreditQuery,
    Operations.GetDriverAvailableCreditQueryVariables
  >(Operations.GetDriverAvailableCredit, options);
}
export function useGetDriverAvailableCreditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverAvailableCreditQuery,
    Operations.GetDriverAvailableCreditQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverAvailableCreditQuery,
    Operations.GetDriverAvailableCreditQueryVariables
  >(Operations.GetDriverAvailableCredit, options);
}
export type GetDriverAvailableCreditQueryHookResult = ReturnType<
  typeof useGetDriverAvailableCreditQuery
>;
export type GetDriverAvailableCreditLazyQueryHookResult = ReturnType<
  typeof useGetDriverAvailableCreditLazyQuery
>;
export type GetDriverAvailableCreditQueryResult = Apollo.QueryResult<
  Operations.GetDriverAvailableCreditQuery,
  Operations.GetDriverAvailableCreditQueryVariables
>;

/**
 * __useGetAssignVehicleToReservationReservationQuery__
 *
 * To run a query within a React component, call `useGetAssignVehicleToReservationReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignVehicleToReservationReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignVehicleToReservationReservationQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetAssignVehicleToReservationReservationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetAssignVehicleToReservationReservationQuery,
    Operations.GetAssignVehicleToReservationReservationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetAssignVehicleToReservationReservationQuery,
    Operations.GetAssignVehicleToReservationReservationQueryVariables
  >(Operations.GetAssignVehicleToReservationReservation, options);
}
export function useGetAssignVehicleToReservationReservationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetAssignVehicleToReservationReservationQuery,
    Operations.GetAssignVehicleToReservationReservationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetAssignVehicleToReservationReservationQuery,
    Operations.GetAssignVehicleToReservationReservationQueryVariables
  >(Operations.GetAssignVehicleToReservationReservation, options);
}
export type GetAssignVehicleToReservationReservationQueryHookResult = ReturnType<
  typeof useGetAssignVehicleToReservationReservationQuery
>;
export type GetAssignVehicleToReservationReservationLazyQueryHookResult = ReturnType<
  typeof useGetAssignVehicleToReservationReservationLazyQuery
>;
export type GetAssignVehicleToReservationReservationQueryResult = Apollo.QueryResult<
  Operations.GetAssignVehicleToReservationReservationQuery,
  Operations.GetAssignVehicleToReservationReservationQueryVariables
>;

/**
 * __useGetAssignVehicleToReservationDriverDetailsQuery__
 *
 * To run a query within a React component, call `useGetAssignVehicleToReservationDriverDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignVehicleToReservationDriverDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignVehicleToReservationDriverDetailsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetAssignVehicleToReservationDriverDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetAssignVehicleToReservationDriverDetailsQuery,
    Operations.GetAssignVehicleToReservationDriverDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetAssignVehicleToReservationDriverDetailsQuery,
    Operations.GetAssignVehicleToReservationDriverDetailsQueryVariables
  >(Operations.GetAssignVehicleToReservationDriverDetails, options);
}
export function useGetAssignVehicleToReservationDriverDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetAssignVehicleToReservationDriverDetailsQuery,
    Operations.GetAssignVehicleToReservationDriverDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetAssignVehicleToReservationDriverDetailsQuery,
    Operations.GetAssignVehicleToReservationDriverDetailsQueryVariables
  >(Operations.GetAssignVehicleToReservationDriverDetails, options);
}
export type GetAssignVehicleToReservationDriverDetailsQueryHookResult = ReturnType<
  typeof useGetAssignVehicleToReservationDriverDetailsQuery
>;
export type GetAssignVehicleToReservationDriverDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAssignVehicleToReservationDriverDetailsLazyQuery
>;
export type GetAssignVehicleToReservationDriverDetailsQueryResult = Apollo.QueryResult<
  Operations.GetAssignVehicleToReservationDriverDetailsQuery,
  Operations.GetAssignVehicleToReservationDriverDetailsQueryVariables
>;
export type AssignVehicleToReservationMutationFn = Apollo.MutationFunction<
  Operations.AssignVehicleToReservationMutation,
  Operations.AssignVehicleToReservationMutationVariables
>;

/**
 * __useAssignVehicleToReservationMutation__
 *
 * To run a mutation, you first call `useAssignVehicleToReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVehicleToReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVehicleToReservationMutation, { data, loading, error }] = useAssignVehicleToReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fleetResourceId: // value for 'fleetResourceId'
 *   },
 * });
 */
export function useAssignVehicleToReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AssignVehicleToReservationMutation,
    Operations.AssignVehicleToReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.AssignVehicleToReservationMutation,
    Operations.AssignVehicleToReservationMutationVariables
  >(Operations.AssignVehicleToReservation, options);
}
export type AssignVehicleToReservationMutationHookResult = ReturnType<
  typeof useAssignVehicleToReservationMutation
>;
export type AssignVehicleToReservationMutationResult = Apollo.MutationResult<Operations.AssignVehicleToReservationMutation>;
export type AssignVehicleToReservationMutationOptions = Apollo.BaseMutationOptions<
  Operations.AssignVehicleToReservationMutation,
  Operations.AssignVehicleToReservationMutationVariables
>;
export type RemoveAssignedVehicleFromReservationMutationFn = Apollo.MutationFunction<
  Operations.RemoveAssignedVehicleFromReservationMutation,
  Operations.RemoveAssignedVehicleFromReservationMutationVariables
>;

/**
 * __useRemoveAssignedVehicleFromReservationMutation__
 *
 * To run a mutation, you first call `useRemoveAssignedVehicleFromReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssignedVehicleFromReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssignedVehicleFromReservationMutation, { data, loading, error }] = useRemoveAssignedVehicleFromReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAssignedVehicleFromReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.RemoveAssignedVehicleFromReservationMutation,
    Operations.RemoveAssignedVehicleFromReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.RemoveAssignedVehicleFromReservationMutation,
    Operations.RemoveAssignedVehicleFromReservationMutationVariables
  >(Operations.RemoveAssignedVehicleFromReservation, options);
}
export type RemoveAssignedVehicleFromReservationMutationHookResult = ReturnType<
  typeof useRemoveAssignedVehicleFromReservationMutation
>;
export type RemoveAssignedVehicleFromReservationMutationResult = Apollo.MutationResult<Operations.RemoveAssignedVehicleFromReservationMutation>;
export type RemoveAssignedVehicleFromReservationMutationOptions = Apollo.BaseMutationOptions<
  Operations.RemoveAssignedVehicleFromReservationMutation,
  Operations.RemoveAssignedVehicleFromReservationMutationVariables
>;

/**
 * __useGetAssignVehicleToShiftDriverDetailsQuery__
 *
 * To run a query within a React component, call `useGetAssignVehicleToShiftDriverDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignVehicleToShiftDriverDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignVehicleToShiftDriverDetailsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetAssignVehicleToShiftDriverDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetAssignVehicleToShiftDriverDetailsQuery,
    Operations.GetAssignVehicleToShiftDriverDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetAssignVehicleToShiftDriverDetailsQuery,
    Operations.GetAssignVehicleToShiftDriverDetailsQueryVariables
  >(Operations.GetAssignVehicleToShiftDriverDetails, options);
}
export function useGetAssignVehicleToShiftDriverDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetAssignVehicleToShiftDriverDetailsQuery,
    Operations.GetAssignVehicleToShiftDriverDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetAssignVehicleToShiftDriverDetailsQuery,
    Operations.GetAssignVehicleToShiftDriverDetailsQueryVariables
  >(Operations.GetAssignVehicleToShiftDriverDetails, options);
}
export type GetAssignVehicleToShiftDriverDetailsQueryHookResult = ReturnType<
  typeof useGetAssignVehicleToShiftDriverDetailsQuery
>;
export type GetAssignVehicleToShiftDriverDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAssignVehicleToShiftDriverDetailsLazyQuery
>;
export type GetAssignVehicleToShiftDriverDetailsQueryResult = Apollo.QueryResult<
  Operations.GetAssignVehicleToShiftDriverDetailsQuery,
  Operations.GetAssignVehicleToShiftDriverDetailsQueryVariables
>;

/**
 * __useGetCancelReservationModalQuery__
 *
 * To run a query within a React component, call `useGetCancelReservationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCancelReservationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCancelReservationModalQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetCancelReservationModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetCancelReservationModalQuery,
    Operations.GetCancelReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetCancelReservationModalQuery,
    Operations.GetCancelReservationModalQueryVariables
  >(Operations.GetCancelReservationModal, options);
}
export function useGetCancelReservationModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetCancelReservationModalQuery,
    Operations.GetCancelReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetCancelReservationModalQuery,
    Operations.GetCancelReservationModalQueryVariables
  >(Operations.GetCancelReservationModal, options);
}
export type GetCancelReservationModalQueryHookResult = ReturnType<
  typeof useGetCancelReservationModalQuery
>;
export type GetCancelReservationModalLazyQueryHookResult = ReturnType<
  typeof useGetCancelReservationModalLazyQuery
>;
export type GetCancelReservationModalQueryResult = Apollo.QueryResult<
  Operations.GetCancelReservationModalQuery,
  Operations.GetCancelReservationModalQueryVariables
>;
export type CancelReservationMutationFn = Apollo.MutationFunction<
  Operations.CancelReservationMutation,
  Operations.CancelReservationMutationVariables
>;

/**
 * __useCancelReservationMutation__
 *
 * To run a mutation, you first call `useCancelReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReservationMutation, { data, loading, error }] = useCancelReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CancelReservationMutation,
    Operations.CancelReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CancelReservationMutation,
    Operations.CancelReservationMutationVariables
  >(Operations.CancelReservation, options);
}
export type CancelReservationMutationHookResult = ReturnType<typeof useCancelReservationMutation>;
export type CancelReservationMutationResult = Apollo.MutationResult<Operations.CancelReservationMutation>;
export type CancelReservationMutationOptions = Apollo.BaseMutationOptions<
  Operations.CancelReservationMutation,
  Operations.CancelReservationMutationVariables
>;

/**
 * __useGetCancelScheduledShiftModalQuery__
 *
 * To run a query within a React component, call `useGetCancelScheduledShiftModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCancelScheduledShiftModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCancelScheduledShiftModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCancelScheduledShiftModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetCancelScheduledShiftModalQuery,
    Operations.GetCancelScheduledShiftModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetCancelScheduledShiftModalQuery,
    Operations.GetCancelScheduledShiftModalQueryVariables
  >(Operations.GetCancelScheduledShiftModal, options);
}
export function useGetCancelScheduledShiftModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetCancelScheduledShiftModalQuery,
    Operations.GetCancelScheduledShiftModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetCancelScheduledShiftModalQuery,
    Operations.GetCancelScheduledShiftModalQueryVariables
  >(Operations.GetCancelScheduledShiftModal, options);
}
export type GetCancelScheduledShiftModalQueryHookResult = ReturnType<
  typeof useGetCancelScheduledShiftModalQuery
>;
export type GetCancelScheduledShiftModalLazyQueryHookResult = ReturnType<
  typeof useGetCancelScheduledShiftModalLazyQuery
>;
export type GetCancelScheduledShiftModalQueryResult = Apollo.QueryResult<
  Operations.GetCancelScheduledShiftModalQuery,
  Operations.GetCancelScheduledShiftModalQueryVariables
>;
export type CancelScheduledShiftMutationFn = Apollo.MutationFunction<
  Operations.CancelScheduledShiftMutation,
  Operations.CancelScheduledShiftMutationVariables
>;

/**
 * __useCancelScheduledShiftMutation__
 *
 * To run a mutation, you first call `useCancelScheduledShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledShiftMutation, { data, loading, error }] = useCancelScheduledShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelScheduledShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CancelScheduledShiftMutation,
    Operations.CancelScheduledShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CancelScheduledShiftMutation,
    Operations.CancelScheduledShiftMutationVariables
  >(Operations.CancelScheduledShift, options);
}
export type CancelScheduledShiftMutationHookResult = ReturnType<
  typeof useCancelScheduledShiftMutation
>;
export type CancelScheduledShiftMutationResult = Apollo.MutationResult<Operations.CancelScheduledShiftMutation>;
export type CancelScheduledShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.CancelScheduledShiftMutation,
  Operations.CancelScheduledShiftMutationVariables
>;

/**
 * __useGetShiftCancellationReasonsQuery__
 *
 * To run a query within a React component, call `useGetShiftCancellationReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftCancellationReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftCancellationReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShiftCancellationReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetShiftCancellationReasonsQuery,
    Operations.GetShiftCancellationReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetShiftCancellationReasonsQuery,
    Operations.GetShiftCancellationReasonsQueryVariables
  >(Operations.GetShiftCancellationReasons, options);
}
export function useGetShiftCancellationReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetShiftCancellationReasonsQuery,
    Operations.GetShiftCancellationReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetShiftCancellationReasonsQuery,
    Operations.GetShiftCancellationReasonsQueryVariables
  >(Operations.GetShiftCancellationReasons, options);
}
export type GetShiftCancellationReasonsQueryHookResult = ReturnType<
  typeof useGetShiftCancellationReasonsQuery
>;
export type GetShiftCancellationReasonsLazyQueryHookResult = ReturnType<
  typeof useGetShiftCancellationReasonsLazyQuery
>;
export type GetShiftCancellationReasonsQueryResult = Apollo.QueryResult<
  Operations.GetShiftCancellationReasonsQuery,
  Operations.GetShiftCancellationReasonsQueryVariables
>;
export type CancelDriverShiftMutationFn = Apollo.MutationFunction<
  Operations.CancelDriverShiftMutation,
  Operations.CancelDriverShiftMutationVariables
>;

/**
 * __useCancelDriverShiftMutation__
 *
 * To run a mutation, you first call `useCancelDriverShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDriverShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDriverShiftMutation, { data, loading, error }] = useCancelDriverShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelDriverShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CancelDriverShiftMutation,
    Operations.CancelDriverShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CancelDriverShiftMutation,
    Operations.CancelDriverShiftMutationVariables
  >(Operations.CancelDriverShift, options);
}
export type CancelDriverShiftMutationHookResult = ReturnType<typeof useCancelDriverShiftMutation>;
export type CancelDriverShiftMutationResult = Apollo.MutationResult<Operations.CancelDriverShiftMutation>;
export type CancelDriverShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.CancelDriverShiftMutation,
  Operations.CancelDriverShiftMutationVariables
>;

/**
 * __useGetCheckInReservationModalQuery__
 *
 * To run a query within a React component, call `useGetCheckInReservationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckInReservationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckInReservationModalQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetCheckInReservationModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetCheckInReservationModalQuery,
    Operations.GetCheckInReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetCheckInReservationModalQuery,
    Operations.GetCheckInReservationModalQueryVariables
  >(Operations.GetCheckInReservationModal, options);
}
export function useGetCheckInReservationModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetCheckInReservationModalQuery,
    Operations.GetCheckInReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetCheckInReservationModalQuery,
    Operations.GetCheckInReservationModalQueryVariables
  >(Operations.GetCheckInReservationModal, options);
}
export type GetCheckInReservationModalQueryHookResult = ReturnType<
  typeof useGetCheckInReservationModalQuery
>;
export type GetCheckInReservationModalLazyQueryHookResult = ReturnType<
  typeof useGetCheckInReservationModalLazyQuery
>;
export type GetCheckInReservationModalQueryResult = Apollo.QueryResult<
  Operations.GetCheckInReservationModalQuery,
  Operations.GetCheckInReservationModalQueryVariables
>;
export type CheckInReservationMutationFn = Apollo.MutationFunction<
  Operations.CheckInReservationMutation,
  Operations.CheckInReservationMutationVariables
>;

/**
 * __useCheckInReservationMutation__
 *
 * To run a mutation, you first call `useCheckInReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInReservationMutation, { data, loading, error }] = useCheckInReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckInReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CheckInReservationMutation,
    Operations.CheckInReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CheckInReservationMutation,
    Operations.CheckInReservationMutationVariables
  >(Operations.CheckInReservation, options);
}
export type CheckInReservationMutationHookResult = ReturnType<typeof useCheckInReservationMutation>;
export type CheckInReservationMutationResult = Apollo.MutationResult<Operations.CheckInReservationMutation>;
export type CheckInReservationMutationOptions = Apollo.BaseMutationOptions<
  Operations.CheckInReservationMutation,
  Operations.CheckInReservationMutationVariables
>;
export type CheckInShiftOverrideMutationFn = Apollo.MutationFunction<
  Operations.CheckInShiftOverrideMutation,
  Operations.CheckInShiftOverrideMutationVariables
>;

/**
 * __useCheckInShiftOverrideMutation__
 *
 * To run a mutation, you first call `useCheckInShiftOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInShiftOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInShiftOverrideMutation, { data, loading, error }] = useCheckInShiftOverrideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckInShiftOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CheckInShiftOverrideMutation,
    Operations.CheckInShiftOverrideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CheckInShiftOverrideMutation,
    Operations.CheckInShiftOverrideMutationVariables
  >(Operations.CheckInShiftOverride, options);
}
export type CheckInShiftOverrideMutationHookResult = ReturnType<
  typeof useCheckInShiftOverrideMutation
>;
export type CheckInShiftOverrideMutationResult = Apollo.MutationResult<Operations.CheckInShiftOverrideMutation>;
export type CheckInShiftOverrideMutationOptions = Apollo.BaseMutationOptions<
  Operations.CheckInShiftOverrideMutation,
  Operations.CheckInShiftOverrideMutationVariables
>;
export type ReportDriverShiftIssueMutationFn = Apollo.MutationFunction<
  Operations.ReportDriverShiftIssueMutation,
  Operations.ReportDriverShiftIssueMutationVariables
>;

/**
 * __useReportDriverShiftIssueMutation__
 *
 * To run a mutation, you first call `useReportDriverShiftIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportDriverShiftIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportDriverShiftIssueMutation, { data, loading, error }] = useReportDriverShiftIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportDriverShiftIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ReportDriverShiftIssueMutation,
    Operations.ReportDriverShiftIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ReportDriverShiftIssueMutation,
    Operations.ReportDriverShiftIssueMutationVariables
  >(Operations.ReportDriverShiftIssue, options);
}
export type ReportDriverShiftIssueMutationHookResult = ReturnType<
  typeof useReportDriverShiftIssueMutation
>;
export type ReportDriverShiftIssueMutationResult = Apollo.MutationResult<Operations.ReportDriverShiftIssueMutation>;
export type ReportDriverShiftIssueMutationOptions = Apollo.BaseMutationOptions<
  Operations.ReportDriverShiftIssueMutation,
  Operations.ReportDriverShiftIssueMutationVariables
>;
export type CreateManualInvoiceMutationFn = Apollo.MutationFunction<
  Operations.CreateManualInvoiceMutation,
  Operations.CreateManualInvoiceMutationVariables
>;

/**
 * __useCreateManualInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateManualInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualInvoiceMutation, { data, loading, error }] = useCreateManualInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManualInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateManualInvoiceMutation,
    Operations.CreateManualInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateManualInvoiceMutation,
    Operations.CreateManualInvoiceMutationVariables
  >(Operations.CreateManualInvoice, options);
}
export type CreateManualInvoiceMutationHookResult = ReturnType<
  typeof useCreateManualInvoiceMutation
>;
export type CreateManualInvoiceMutationResult = Apollo.MutationResult<Operations.CreateManualInvoiceMutation>;
export type CreateManualInvoiceMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateManualInvoiceMutation,
  Operations.CreateManualInvoiceMutationVariables
>;
export type CreateRecurringShiftMutationFn = Apollo.MutationFunction<
  Operations.CreateRecurringShiftMutation,
  Operations.CreateRecurringShiftMutationVariables
>;

/**
 * __useCreateRecurringShiftMutation__
 *
 * To run a mutation, you first call `useCreateRecurringShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringShiftMutation, { data, loading, error }] = useCreateRecurringShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecurringShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateRecurringShiftMutation,
    Operations.CreateRecurringShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateRecurringShiftMutation,
    Operations.CreateRecurringShiftMutationVariables
  >(Operations.CreateRecurringShift, options);
}
export type CreateRecurringShiftMutationHookResult = ReturnType<
  typeof useCreateRecurringShiftMutation
>;
export type CreateRecurringShiftMutationResult = Apollo.MutationResult<Operations.CreateRecurringShiftMutation>;
export type CreateRecurringShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateRecurringShiftMutation,
  Operations.CreateRecurringShiftMutationVariables
>;

/**
 * __useGetDriverEmploymentTypeQuery__
 *
 * To run a query within a React component, call `useGetDriverEmploymentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverEmploymentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverEmploymentTypeQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverEmploymentTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverEmploymentTypeQuery,
    Operations.GetDriverEmploymentTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverEmploymentTypeQuery,
    Operations.GetDriverEmploymentTypeQueryVariables
  >(Operations.GetDriverEmploymentType, options);
}
export function useGetDriverEmploymentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverEmploymentTypeQuery,
    Operations.GetDriverEmploymentTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverEmploymentTypeQuery,
    Operations.GetDriverEmploymentTypeQueryVariables
  >(Operations.GetDriverEmploymentType, options);
}
export type GetDriverEmploymentTypeQueryHookResult = ReturnType<
  typeof useGetDriverEmploymentTypeQuery
>;
export type GetDriverEmploymentTypeLazyQueryHookResult = ReturnType<
  typeof useGetDriverEmploymentTypeLazyQuery
>;
export type GetDriverEmploymentTypeQueryResult = Apollo.QueryResult<
  Operations.GetDriverEmploymentTypeQuery,
  Operations.GetDriverEmploymentTypeQueryVariables
>;
export type CreateReservationMutationFn = Apollo.MutationFunction<
  Operations.CreateReservationMutation,
  Operations.CreateReservationMutationVariables
>;

/**
 * __useCreateReservationMutation__
 *
 * To run a mutation, you first call `useCreateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationMutation, { data, loading, error }] = useCreateReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateReservationMutation,
    Operations.CreateReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateReservationMutation,
    Operations.CreateReservationMutationVariables
  >(Operations.CreateReservation, options);
}
export type CreateReservationMutationHookResult = ReturnType<typeof useCreateReservationMutation>;
export type CreateReservationMutationResult = Apollo.MutationResult<Operations.CreateReservationMutation>;
export type CreateReservationMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateReservationMutation,
  Operations.CreateReservationMutationVariables
>;
export type CreateDriverShiftMutationFn = Apollo.MutationFunction<
  Operations.CreateDriverShiftMutation,
  Operations.CreateDriverShiftMutationVariables
>;

/**
 * __useCreateDriverShiftMutation__
 *
 * To run a mutation, you first call `useCreateDriverShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverShiftMutation, { data, loading, error }] = useCreateDriverShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateDriverShiftMutation,
    Operations.CreateDriverShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateDriverShiftMutation,
    Operations.CreateDriverShiftMutationVariables
  >(Operations.CreateDriverShift, options);
}
export type CreateDriverShiftMutationHookResult = ReturnType<typeof useCreateDriverShiftMutation>;
export type CreateDriverShiftMutationResult = Apollo.MutationResult<Operations.CreateDriverShiftMutation>;
export type CreateDriverShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateDriverShiftMutation,
  Operations.CreateDriverShiftMutationVariables
>;
export type DeleteRecurringShiftMutationFn = Apollo.MutationFunction<
  Operations.DeleteRecurringShiftMutation,
  Operations.DeleteRecurringShiftMutationVariables
>;

/**
 * __useDeleteRecurringShiftMutation__
 *
 * To run a mutation, you first call `useDeleteRecurringShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecurringShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecurringShiftMutation, { data, loading, error }] = useDeleteRecurringShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleteFutureShifts: // value for 'deleteFutureShifts'
 *   },
 * });
 */
export function useDeleteRecurringShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.DeleteRecurringShiftMutation,
    Operations.DeleteRecurringShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.DeleteRecurringShiftMutation,
    Operations.DeleteRecurringShiftMutationVariables
  >(Operations.DeleteRecurringShift, options);
}
export type DeleteRecurringShiftMutationHookResult = ReturnType<
  typeof useDeleteRecurringShiftMutation
>;
export type DeleteRecurringShiftMutationResult = Apollo.MutationResult<Operations.DeleteRecurringShiftMutation>;
export type DeleteRecurringShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeleteRecurringShiftMutation,
  Operations.DeleteRecurringShiftMutationVariables
>;
export type UpdateDriverAddressMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverAddressMutation,
  Operations.UpdateDriverAddressMutationVariables
>;

/**
 * __useUpdateDriverAddressMutation__
 *
 * To run a mutation, you first call `useUpdateDriverAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverAddressMutation, { data, loading, error }] = useUpdateDriverAddressMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverAddressMutation,
    Operations.UpdateDriverAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverAddressMutation,
    Operations.UpdateDriverAddressMutationVariables
  >(Operations.UpdateDriverAddress, options);
}
export type UpdateDriverAddressMutationHookResult = ReturnType<
  typeof useUpdateDriverAddressMutation
>;
export type UpdateDriverAddressMutationResult = Apollo.MutationResult<Operations.UpdateDriverAddressMutation>;
export type UpdateDriverAddressMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverAddressMutation,
  Operations.UpdateDriverAddressMutationVariables
>;
export type UpdateDriverInsuranceApprovalsMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverInsuranceApprovalsMutation,
  Operations.UpdateDriverInsuranceApprovalsMutationVariables
>;

/**
 * __useUpdateDriverInsuranceApprovalsMutation__
 *
 * To run a mutation, you first call `useUpdateDriverInsuranceApprovalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverInsuranceApprovalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverInsuranceApprovalsMutation, { data, loading, error }] = useUpdateDriverInsuranceApprovalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverInsuranceApprovalsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverInsuranceApprovalsMutation,
    Operations.UpdateDriverInsuranceApprovalsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverInsuranceApprovalsMutation,
    Operations.UpdateDriverInsuranceApprovalsMutationVariables
  >(Operations.UpdateDriverInsuranceApprovals, options);
}
export type UpdateDriverInsuranceApprovalsMutationHookResult = ReturnType<
  typeof useUpdateDriverInsuranceApprovalsMutation
>;
export type UpdateDriverInsuranceApprovalsMutationResult = Apollo.MutationResult<Operations.UpdateDriverInsuranceApprovalsMutation>;
export type UpdateDriverInsuranceApprovalsMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverInsuranceApprovalsMutation,
  Operations.UpdateDriverInsuranceApprovalsMutationVariables
>;

/**
 * __useGetInsuranceProvidersQuery__
 *
 * To run a query within a React component, call `useGetInsuranceProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuranceProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuranceProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInsuranceProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetInsuranceProvidersQuery,
    Operations.GetInsuranceProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetInsuranceProvidersQuery,
    Operations.GetInsuranceProvidersQueryVariables
  >(Operations.GetInsuranceProviders, options);
}
export function useGetInsuranceProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetInsuranceProvidersQuery,
    Operations.GetInsuranceProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetInsuranceProvidersQuery,
    Operations.GetInsuranceProvidersQueryVariables
  >(Operations.GetInsuranceProviders, options);
}
export type GetInsuranceProvidersQueryHookResult = ReturnType<typeof useGetInsuranceProvidersQuery>;
export type GetInsuranceProvidersLazyQueryHookResult = ReturnType<
  typeof useGetInsuranceProvidersLazyQuery
>;
export type GetInsuranceProvidersQueryResult = Apollo.QueryResult<
  Operations.GetInsuranceProvidersQuery,
  Operations.GetInsuranceProvidersQueryVariables
>;

/**
 * __useGetAdicRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetAdicRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdicRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdicRegistrationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAdicRegistrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetAdicRegistrationsQuery,
    Operations.GetAdicRegistrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetAdicRegistrationsQuery,
    Operations.GetAdicRegistrationsQueryVariables
  >(Operations.GetAdicRegistrations, options);
}
export function useGetAdicRegistrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetAdicRegistrationsQuery,
    Operations.GetAdicRegistrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetAdicRegistrationsQuery,
    Operations.GetAdicRegistrationsQueryVariables
  >(Operations.GetAdicRegistrations, options);
}
export type GetAdicRegistrationsQueryHookResult = ReturnType<typeof useGetAdicRegistrationsQuery>;
export type GetAdicRegistrationsLazyQueryHookResult = ReturnType<
  typeof useGetAdicRegistrationsLazyQuery
>;
export type GetAdicRegistrationsQueryResult = Apollo.QueryResult<
  Operations.GetAdicRegistrationsQuery,
  Operations.GetAdicRegistrationsQueryVariables
>;
export type SubmitAdicRegistrationMutationFn = Apollo.MutationFunction<
  Operations.SubmitAdicRegistrationMutation,
  Operations.SubmitAdicRegistrationMutationVariables
>;

/**
 * __useSubmitAdicRegistrationMutation__
 *
 * To run a mutation, you first call `useSubmitAdicRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAdicRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAdicRegistrationMutation, { data, loading, error }] = useSubmitAdicRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAdicRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.SubmitAdicRegistrationMutation,
    Operations.SubmitAdicRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.SubmitAdicRegistrationMutation,
    Operations.SubmitAdicRegistrationMutationVariables
  >(Operations.SubmitAdicRegistration, options);
}
export type SubmitAdicRegistrationMutationHookResult = ReturnType<
  typeof useSubmitAdicRegistrationMutation
>;
export type SubmitAdicRegistrationMutationResult = Apollo.MutationResult<Operations.SubmitAdicRegistrationMutation>;
export type SubmitAdicRegistrationMutationOptions = Apollo.BaseMutationOptions<
  Operations.SubmitAdicRegistrationMutation,
  Operations.SubmitAdicRegistrationMutationVariables
>;
export type UpdateDriverVehicleExclusionsMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverVehicleExclusionsMutation,
  Operations.UpdateDriverVehicleExclusionsMutationVariables
>;

/**
 * __useUpdateDriverVehicleExclusionsMutation__
 *
 * To run a mutation, you first call `useUpdateDriverVehicleExclusionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverVehicleExclusionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverVehicleExclusionsMutation, { data, loading, error }] = useUpdateDriverVehicleExclusionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverVehicleExclusionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverVehicleExclusionsMutation,
    Operations.UpdateDriverVehicleExclusionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverVehicleExclusionsMutation,
    Operations.UpdateDriverVehicleExclusionsMutationVariables
  >(Operations.UpdateDriverVehicleExclusions, options);
}
export type UpdateDriverVehicleExclusionsMutationHookResult = ReturnType<
  typeof useUpdateDriverVehicleExclusionsMutation
>;
export type UpdateDriverVehicleExclusionsMutationResult = Apollo.MutationResult<Operations.UpdateDriverVehicleExclusionsMutation>;
export type UpdateDriverVehicleExclusionsMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverVehicleExclusionsMutation,
  Operations.UpdateDriverVehicleExclusionsMutationVariables
>;

/**
 * __useGetDriverFleetResourceDetailsQuery__
 *
 * To run a query within a React component, call `useGetDriverFleetResourceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverFleetResourceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverFleetResourceDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDriverFleetResourceDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetDriverFleetResourceDetailsQuery,
    Operations.GetDriverFleetResourceDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverFleetResourceDetailsQuery,
    Operations.GetDriverFleetResourceDetailsQueryVariables
  >(Operations.GetDriverFleetResourceDetails, options);
}
export function useGetDriverFleetResourceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverFleetResourceDetailsQuery,
    Operations.GetDriverFleetResourceDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverFleetResourceDetailsQuery,
    Operations.GetDriverFleetResourceDetailsQueryVariables
  >(Operations.GetDriverFleetResourceDetails, options);
}
export type GetDriverFleetResourceDetailsQueryHookResult = ReturnType<
  typeof useGetDriverFleetResourceDetailsQuery
>;
export type GetDriverFleetResourceDetailsLazyQueryHookResult = ReturnType<
  typeof useGetDriverFleetResourceDetailsLazyQuery
>;
export type GetDriverFleetResourceDetailsQueryResult = Apollo.QueryResult<
  Operations.GetDriverFleetResourceDetailsQuery,
  Operations.GetDriverFleetResourceDetailsQueryVariables
>;
export type UpdateDriverVehicleMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverVehicleMutation,
  Operations.UpdateDriverVehicleMutationVariables
>;

/**
 * __useUpdateDriverVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateDriverVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverVehicleMutation, { data, loading, error }] = useUpdateDriverVehicleMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverVehicleMutation,
    Operations.UpdateDriverVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverVehicleMutation,
    Operations.UpdateDriverVehicleMutationVariables
  >(Operations.UpdateDriverVehicle, options);
}
export type UpdateDriverVehicleMutationHookResult = ReturnType<
  typeof useUpdateDriverVehicleMutation
>;
export type UpdateDriverVehicleMutationResult = Apollo.MutationResult<Operations.UpdateDriverVehicleMutation>;
export type UpdateDriverVehicleMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverVehicleMutation,
  Operations.UpdateDriverVehicleMutationVariables
>;

/**
 * __useGetDriverVehiclePreferenceQuery__
 *
 * To run a query within a React component, call `useGetDriverVehiclePreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverVehiclePreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverVehiclePreferenceQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverVehiclePreferenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverVehiclePreferenceQuery,
    Operations.GetDriverVehiclePreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverVehiclePreferenceQuery,
    Operations.GetDriverVehiclePreferenceQueryVariables
  >(Operations.GetDriverVehiclePreference, options);
}
export function useGetDriverVehiclePreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverVehiclePreferenceQuery,
    Operations.GetDriverVehiclePreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverVehiclePreferenceQuery,
    Operations.GetDriverVehiclePreferenceQueryVariables
  >(Operations.GetDriverVehiclePreference, options);
}
export type GetDriverVehiclePreferenceQueryHookResult = ReturnType<
  typeof useGetDriverVehiclePreferenceQuery
>;
export type GetDriverVehiclePreferenceLazyQueryHookResult = ReturnType<
  typeof useGetDriverVehiclePreferenceLazyQuery
>;
export type GetDriverVehiclePreferenceQueryResult = Apollo.QueryResult<
  Operations.GetDriverVehiclePreferenceQuery,
  Operations.GetDriverVehiclePreferenceQueryVariables
>;
export type UpdateDriverVehiclePreferenceMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverVehiclePreferenceMutation,
  Operations.UpdateDriverVehiclePreferenceMutationVariables
>;

/**
 * __useUpdateDriverVehiclePreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateDriverVehiclePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverVehiclePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverVehiclePreferenceMutation, { data, loading, error }] = useUpdateDriverVehiclePreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverVehiclePreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverVehiclePreferenceMutation,
    Operations.UpdateDriverVehiclePreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverVehiclePreferenceMutation,
    Operations.UpdateDriverVehiclePreferenceMutationVariables
  >(Operations.UpdateDriverVehiclePreference, options);
}
export type UpdateDriverVehiclePreferenceMutationHookResult = ReturnType<
  typeof useUpdateDriverVehiclePreferenceMutation
>;
export type UpdateDriverVehiclePreferenceMutationResult = Apollo.MutationResult<Operations.UpdateDriverVehiclePreferenceMutation>;
export type UpdateDriverVehiclePreferenceMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverVehiclePreferenceMutation,
  Operations.UpdateDriverVehiclePreferenceMutationVariables
>;
export type UpdateDriverWeeklyDowngradeMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverWeeklyDowngradeMutation,
  Operations.UpdateDriverWeeklyDowngradeMutationVariables
>;

/**
 * __useUpdateDriverWeeklyDowngradeMutation__
 *
 * To run a mutation, you first call `useUpdateDriverWeeklyDowngradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverWeeklyDowngradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverWeeklyDowngradeMutation, { data, loading, error }] = useUpdateDriverWeeklyDowngradeMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useUpdateDriverWeeklyDowngradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverWeeklyDowngradeMutation,
    Operations.UpdateDriverWeeklyDowngradeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverWeeklyDowngradeMutation,
    Operations.UpdateDriverWeeklyDowngradeMutationVariables
  >(Operations.UpdateDriverWeeklyDowngrade, options);
}
export type UpdateDriverWeeklyDowngradeMutationHookResult = ReturnType<
  typeof useUpdateDriverWeeklyDowngradeMutation
>;
export type UpdateDriverWeeklyDowngradeMutationResult = Apollo.MutationResult<Operations.UpdateDriverWeeklyDowngradeMutation>;
export type UpdateDriverWeeklyDowngradeMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverWeeklyDowngradeMutation,
  Operations.UpdateDriverWeeklyDowngradeMutationVariables
>;

/**
 * __useGetDriverAvailableSecurityDepositLedgerQuery__
 *
 * To run a query within a React component, call `useGetDriverAvailableSecurityDepositLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverAvailableSecurityDepositLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverAvailableSecurityDepositLedgerQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverAvailableSecurityDepositLedgerQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverAvailableSecurityDepositLedgerQuery,
    Operations.GetDriverAvailableSecurityDepositLedgerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverAvailableSecurityDepositLedgerQuery,
    Operations.GetDriverAvailableSecurityDepositLedgerQueryVariables
  >(Operations.GetDriverAvailableSecurityDepositLedger, options);
}
export function useGetDriverAvailableSecurityDepositLedgerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverAvailableSecurityDepositLedgerQuery,
    Operations.GetDriverAvailableSecurityDepositLedgerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverAvailableSecurityDepositLedgerQuery,
    Operations.GetDriverAvailableSecurityDepositLedgerQueryVariables
  >(Operations.GetDriverAvailableSecurityDepositLedger, options);
}
export type GetDriverAvailableSecurityDepositLedgerQueryHookResult = ReturnType<
  typeof useGetDriverAvailableSecurityDepositLedgerQuery
>;
export type GetDriverAvailableSecurityDepositLedgerLazyQueryHookResult = ReturnType<
  typeof useGetDriverAvailableSecurityDepositLedgerLazyQuery
>;
export type GetDriverAvailableSecurityDepositLedgerQueryResult = Apollo.QueryResult<
  Operations.GetDriverAvailableSecurityDepositLedgerQuery,
  Operations.GetDriverAvailableSecurityDepositLedgerQueryVariables
>;
export type UpdateDriverWeeklyRentalMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverWeeklyRentalMutation,
  Operations.UpdateDriverWeeklyRentalMutationVariables
>;

/**
 * __useUpdateDriverWeeklyRentalMutation__
 *
 * To run a mutation, you first call `useUpdateDriverWeeklyRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverWeeklyRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverWeeklyRentalMutation, { data, loading, error }] = useUpdateDriverWeeklyRentalMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverWeeklyRentalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverWeeklyRentalMutation,
    Operations.UpdateDriverWeeklyRentalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverWeeklyRentalMutation,
    Operations.UpdateDriverWeeklyRentalMutationVariables
  >(Operations.UpdateDriverWeeklyRental, options);
}
export type UpdateDriverWeeklyRentalMutationHookResult = ReturnType<
  typeof useUpdateDriverWeeklyRentalMutation
>;
export type UpdateDriverWeeklyRentalMutationResult = Apollo.MutationResult<Operations.UpdateDriverWeeklyRentalMutation>;
export type UpdateDriverWeeklyRentalMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverWeeklyRentalMutation,
  Operations.UpdateDriverWeeklyRentalMutationVariables
>;

/**
 * __useGetReservationBillingSettingsQuery__
 *
 * To run a query within a React component, call `useGetReservationBillingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationBillingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationBillingSettingsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetReservationBillingSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetReservationBillingSettingsQuery,
    Operations.GetReservationBillingSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetReservationBillingSettingsQuery,
    Operations.GetReservationBillingSettingsQueryVariables
  >(Operations.GetReservationBillingSettings, options);
}
export function useGetReservationBillingSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetReservationBillingSettingsQuery,
    Operations.GetReservationBillingSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetReservationBillingSettingsQuery,
    Operations.GetReservationBillingSettingsQueryVariables
  >(Operations.GetReservationBillingSettings, options);
}
export type GetReservationBillingSettingsQueryHookResult = ReturnType<
  typeof useGetReservationBillingSettingsQuery
>;
export type GetReservationBillingSettingsLazyQueryHookResult = ReturnType<
  typeof useGetReservationBillingSettingsLazyQuery
>;
export type GetReservationBillingSettingsQueryResult = Apollo.QueryResult<
  Operations.GetReservationBillingSettingsQuery,
  Operations.GetReservationBillingSettingsQueryVariables
>;
export type UpdateRecurringShiftMutationFn = Apollo.MutationFunction<
  Operations.UpdateRecurringShiftMutation,
  Operations.UpdateRecurringShiftMutationVariables
>;

/**
 * __useUpdateRecurringShiftMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringShiftMutation, { data, loading, error }] = useUpdateRecurringShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecurringShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateRecurringShiftMutation,
    Operations.UpdateRecurringShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateRecurringShiftMutation,
    Operations.UpdateRecurringShiftMutationVariables
  >(Operations.UpdateRecurringShift, options);
}
export type UpdateRecurringShiftMutationHookResult = ReturnType<
  typeof useUpdateRecurringShiftMutation
>;
export type UpdateRecurringShiftMutationResult = Apollo.MutationResult<Operations.UpdateRecurringShiftMutation>;
export type UpdateRecurringShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateRecurringShiftMutation,
  Operations.UpdateRecurringShiftMutationVariables
>;
export type UpdateRecurringShiftSettingsMutationFn = Apollo.MutationFunction<
  Operations.UpdateRecurringShiftSettingsMutation,
  Operations.UpdateRecurringShiftSettingsMutationVariables
>;

/**
 * __useUpdateRecurringShiftSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringShiftSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringShiftSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringShiftSettingsMutation, { data, loading, error }] = useUpdateRecurringShiftSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecurringShiftSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateRecurringShiftSettingsMutation,
    Operations.UpdateRecurringShiftSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateRecurringShiftSettingsMutation,
    Operations.UpdateRecurringShiftSettingsMutationVariables
  >(Operations.UpdateRecurringShiftSettings, options);
}
export type UpdateRecurringShiftSettingsMutationHookResult = ReturnType<
  typeof useUpdateRecurringShiftSettingsMutation
>;
export type UpdateRecurringShiftSettingsMutationResult = Apollo.MutationResult<Operations.UpdateRecurringShiftSettingsMutation>;
export type UpdateRecurringShiftSettingsMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateRecurringShiftSettingsMutation,
  Operations.UpdateRecurringShiftSettingsMutationVariables
>;

/**
 * __useGetEditReservationModalQuery__
 *
 * To run a query within a React component, call `useGetEditReservationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditReservationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditReservationModalQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetEditReservationModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetEditReservationModalQuery,
    Operations.GetEditReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetEditReservationModalQuery,
    Operations.GetEditReservationModalQueryVariables
  >(Operations.GetEditReservationModal, options);
}
export function useGetEditReservationModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetEditReservationModalQuery,
    Operations.GetEditReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetEditReservationModalQuery,
    Operations.GetEditReservationModalQueryVariables
  >(Operations.GetEditReservationModal, options);
}
export type GetEditReservationModalQueryHookResult = ReturnType<
  typeof useGetEditReservationModalQuery
>;
export type GetEditReservationModalLazyQueryHookResult = ReturnType<
  typeof useGetEditReservationModalLazyQuery
>;
export type GetEditReservationModalQueryResult = Apollo.QueryResult<
  Operations.GetEditReservationModalQuery,
  Operations.GetEditReservationModalQueryVariables
>;
export type UpdateDriverReservationMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverReservationMutation,
  Operations.UpdateDriverReservationMutationVariables
>;

/**
 * __useUpdateDriverReservationMutation__
 *
 * To run a mutation, you first call `useUpdateDriverReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverReservationMutation, { data, loading, error }] = useUpdateDriverReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverReservationMutation,
    Operations.UpdateDriverReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverReservationMutation,
    Operations.UpdateDriverReservationMutationVariables
  >(Operations.UpdateDriverReservation, options);
}
export type UpdateDriverReservationMutationHookResult = ReturnType<
  typeof useUpdateDriverReservationMutation
>;
export type UpdateDriverReservationMutationResult = Apollo.MutationResult<Operations.UpdateDriverReservationMutation>;
export type UpdateDriverReservationMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverReservationMutation,
  Operations.UpdateDriverReservationMutationVariables
>;

/**
 * __useGetEndReservationModalQuery__
 *
 * To run a query within a React component, call `useGetEndReservationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndReservationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndReservationModalQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetEndReservationModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetEndReservationModalQuery,
    Operations.GetEndReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetEndReservationModalQuery,
    Operations.GetEndReservationModalQueryVariables
  >(Operations.GetEndReservationModal, options);
}
export function useGetEndReservationModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetEndReservationModalQuery,
    Operations.GetEndReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetEndReservationModalQuery,
    Operations.GetEndReservationModalQueryVariables
  >(Operations.GetEndReservationModal, options);
}
export type GetEndReservationModalQueryHookResult = ReturnType<
  typeof useGetEndReservationModalQuery
>;
export type GetEndReservationModalLazyQueryHookResult = ReturnType<
  typeof useGetEndReservationModalLazyQuery
>;
export type GetEndReservationModalQueryResult = Apollo.QueryResult<
  Operations.GetEndReservationModalQuery,
  Operations.GetEndReservationModalQueryVariables
>;
export type EndReservationMutationFn = Apollo.MutationFunction<
  Operations.EndReservationMutation,
  Operations.EndReservationMutationVariables
>;

/**
 * __useEndReservationMutation__
 *
 * To run a mutation, you first call `useEndReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endReservationMutation, { data, loading, error }] = useEndReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.EndReservationMutation,
    Operations.EndReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.EndReservationMutation,
    Operations.EndReservationMutationVariables
  >(Operations.EndReservation, options);
}
export type EndReservationMutationHookResult = ReturnType<typeof useEndReservationMutation>;
export type EndReservationMutationResult = Apollo.MutationResult<Operations.EndReservationMutation>;
export type EndReservationMutationOptions = Apollo.BaseMutationOptions<
  Operations.EndReservationMutation,
  Operations.EndReservationMutationVariables
>;

/**
 * __useGetEndScheduledShiftModalQuery__
 *
 * To run a query within a React component, call `useGetEndScheduledShiftModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScheduledShiftModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScheduledShiftModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEndScheduledShiftModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetEndScheduledShiftModalQuery,
    Operations.GetEndScheduledShiftModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetEndScheduledShiftModalQuery,
    Operations.GetEndScheduledShiftModalQueryVariables
  >(Operations.GetEndScheduledShiftModal, options);
}
export function useGetEndScheduledShiftModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetEndScheduledShiftModalQuery,
    Operations.GetEndScheduledShiftModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetEndScheduledShiftModalQuery,
    Operations.GetEndScheduledShiftModalQueryVariables
  >(Operations.GetEndScheduledShiftModal, options);
}
export type GetEndScheduledShiftModalQueryHookResult = ReturnType<
  typeof useGetEndScheduledShiftModalQuery
>;
export type GetEndScheduledShiftModalLazyQueryHookResult = ReturnType<
  typeof useGetEndScheduledShiftModalLazyQuery
>;
export type GetEndScheduledShiftModalQueryResult = Apollo.QueryResult<
  Operations.GetEndScheduledShiftModalQuery,
  Operations.GetEndScheduledShiftModalQueryVariables
>;
export type EndScheduledShiftMutationFn = Apollo.MutationFunction<
  Operations.EndScheduledShiftMutation,
  Operations.EndScheduledShiftMutationVariables
>;

/**
 * __useEndScheduledShiftMutation__
 *
 * To run a mutation, you first call `useEndScheduledShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndScheduledShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endScheduledShiftMutation, { data, loading, error }] = useEndScheduledShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndScheduledShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.EndScheduledShiftMutation,
    Operations.EndScheduledShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.EndScheduledShiftMutation,
    Operations.EndScheduledShiftMutationVariables
  >(Operations.EndScheduledShift, options);
}
export type EndScheduledShiftMutationHookResult = ReturnType<typeof useEndScheduledShiftMutation>;
export type EndScheduledShiftMutationResult = Apollo.MutationResult<Operations.EndScheduledShiftMutation>;
export type EndScheduledShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.EndScheduledShiftMutation,
  Operations.EndScheduledShiftMutationVariables
>;
export type EndShiftShiftOverrideMutationFn = Apollo.MutationFunction<
  Operations.EndShiftShiftOverrideMutation,
  Operations.EndShiftShiftOverrideMutationVariables
>;

/**
 * __useEndShiftShiftOverrideMutation__
 *
 * To run a mutation, you first call `useEndShiftShiftOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndShiftShiftOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endShiftShiftOverrideMutation, { data, loading, error }] = useEndShiftShiftOverrideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndShiftShiftOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.EndShiftShiftOverrideMutation,
    Operations.EndShiftShiftOverrideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.EndShiftShiftOverrideMutation,
    Operations.EndShiftShiftOverrideMutationVariables
  >(Operations.EndShiftShiftOverride, options);
}
export type EndShiftShiftOverrideMutationHookResult = ReturnType<
  typeof useEndShiftShiftOverrideMutation
>;
export type EndShiftShiftOverrideMutationResult = Apollo.MutationResult<Operations.EndShiftShiftOverrideMutation>;
export type EndShiftShiftOverrideMutationOptions = Apollo.BaseMutationOptions<
  Operations.EndShiftShiftOverrideMutation,
  Operations.EndShiftShiftOverrideMutationVariables
>;

/**
 * __useListIncidentsQuery__
 *
 * To run a query within a React component, call `useListIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIncidentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListIncidentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.ListIncidentsQuery,
    Operations.ListIncidentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ListIncidentsQuery, Operations.ListIncidentsQueryVariables>(
    Operations.ListIncidents,
    options,
  );
}
export function useListIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ListIncidentsQuery,
    Operations.ListIncidentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ListIncidentsQuery, Operations.ListIncidentsQueryVariables>(
    Operations.ListIncidents,
    options,
  );
}
export type ListIncidentsQueryHookResult = ReturnType<typeof useListIncidentsQuery>;
export type ListIncidentsLazyQueryHookResult = ReturnType<typeof useListIncidentsLazyQuery>;
export type ListIncidentsQueryResult = Apollo.QueryResult<
  Operations.ListIncidentsQuery,
  Operations.ListIncidentsQueryVariables
>;
export type CreateDriverIncidentMutationFn = Apollo.MutationFunction<
  Operations.CreateDriverIncidentMutation,
  Operations.CreateDriverIncidentMutationVariables
>;

/**
 * __useCreateDriverIncidentMutation__
 *
 * To run a mutation, you first call `useCreateDriverIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverIncidentMutation, { data, loading, error }] = useCreateDriverIncidentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverIncidentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateDriverIncidentMutation,
    Operations.CreateDriverIncidentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateDriverIncidentMutation,
    Operations.CreateDriverIncidentMutationVariables
  >(Operations.CreateDriverIncident, options);
}
export type CreateDriverIncidentMutationHookResult = ReturnType<
  typeof useCreateDriverIncidentMutation
>;
export type CreateDriverIncidentMutationResult = Apollo.MutationResult<Operations.CreateDriverIncidentMutation>;
export type CreateDriverIncidentMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateDriverIncidentMutation,
  Operations.CreateDriverIncidentMutationVariables
>;

/**
 * __useGetIssueDetailsModalIssueQuery__
 *
 * To run a query within a React component, call `useGetIssueDetailsModalIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueDetailsModalIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueDetailsModalIssueQuery({
 *   variables: {
 *      issueId: // value for 'issueId'
 *   },
 * });
 */
export function useGetIssueDetailsModalIssueQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetIssueDetailsModalIssueQuery,
    Operations.GetIssueDetailsModalIssueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetIssueDetailsModalIssueQuery,
    Operations.GetIssueDetailsModalIssueQueryVariables
  >(Operations.GetIssueDetailsModalIssue, options);
}
export function useGetIssueDetailsModalIssueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetIssueDetailsModalIssueQuery,
    Operations.GetIssueDetailsModalIssueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetIssueDetailsModalIssueQuery,
    Operations.GetIssueDetailsModalIssueQueryVariables
  >(Operations.GetIssueDetailsModalIssue, options);
}
export type GetIssueDetailsModalIssueQueryHookResult = ReturnType<
  typeof useGetIssueDetailsModalIssueQuery
>;
export type GetIssueDetailsModalIssueLazyQueryHookResult = ReturnType<
  typeof useGetIssueDetailsModalIssueLazyQuery
>;
export type GetIssueDetailsModalIssueQueryResult = Apollo.QueryResult<
  Operations.GetIssueDetailsModalIssueQuery,
  Operations.GetIssueDetailsModalIssueQueryVariables
>;
export type ResolveDriverShiftIssueMutationFn = Apollo.MutationFunction<
  Operations.ResolveDriverShiftIssueMutation,
  Operations.ResolveDriverShiftIssueMutationVariables
>;

/**
 * __useResolveDriverShiftIssueMutation__
 *
 * To run a mutation, you first call `useResolveDriverShiftIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveDriverShiftIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveDriverShiftIssueMutation, { data, loading, error }] = useResolveDriverShiftIssueMutation({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useResolveDriverShiftIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ResolveDriverShiftIssueMutation,
    Operations.ResolveDriverShiftIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ResolveDriverShiftIssueMutation,
    Operations.ResolveDriverShiftIssueMutationVariables
  >(Operations.ResolveDriverShiftIssue, options);
}
export type ResolveDriverShiftIssueMutationHookResult = ReturnType<
  typeof useResolveDriverShiftIssueMutation
>;
export type ResolveDriverShiftIssueMutationResult = Apollo.MutationResult<Operations.ResolveDriverShiftIssueMutation>;
export type ResolveDriverShiftIssueMutationOptions = Apollo.BaseMutationOptions<
  Operations.ResolveDriverShiftIssueMutation,
  Operations.ResolveDriverShiftIssueMutationVariables
>;
export type SubmitUpdateLicenseReviewMutationFn = Apollo.MutationFunction<
  Operations.SubmitUpdateLicenseReviewMutation,
  Operations.SubmitUpdateLicenseReviewMutationVariables
>;

/**
 * __useSubmitUpdateLicenseReviewMutation__
 *
 * To run a mutation, you first call `useSubmitUpdateLicenseReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitUpdateLicenseReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitUpdateLicenseReviewMutation, { data, loading, error }] = useSubmitUpdateLicenseReviewMutation({
 *   variables: {
 *      licenseID: // value for 'licenseID'
 *      updateLicenseInput: // value for 'updateLicenseInput'
 *      reviewInput: // value for 'reviewInput'
 *   },
 * });
 */
export function useSubmitUpdateLicenseReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.SubmitUpdateLicenseReviewMutation,
    Operations.SubmitUpdateLicenseReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.SubmitUpdateLicenseReviewMutation,
    Operations.SubmitUpdateLicenseReviewMutationVariables
  >(Operations.SubmitUpdateLicenseReview, options);
}
export type SubmitUpdateLicenseReviewMutationHookResult = ReturnType<
  typeof useSubmitUpdateLicenseReviewMutation
>;
export type SubmitUpdateLicenseReviewMutationResult = Apollo.MutationResult<Operations.SubmitUpdateLicenseReviewMutation>;
export type SubmitUpdateLicenseReviewMutationOptions = Apollo.BaseMutationOptions<
  Operations.SubmitUpdateLicenseReviewMutation,
  Operations.SubmitUpdateLicenseReviewMutationVariables
>;
export type UpdateLicenseMutationFn = Apollo.MutationFunction<
  Operations.UpdateLicenseMutation,
  Operations.UpdateLicenseMutationVariables
>;

/**
 * __useUpdateLicenseMutation__
 *
 * To run a mutation, you first call `useUpdateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicenseMutation, { data, loading, error }] = useUpdateLicenseMutation({
 *   variables: {
 *      licenseID: // value for 'licenseID'
 *      updateLicenseInput: // value for 'updateLicenseInput'
 *   },
 * });
 */
export function useUpdateLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateLicenseMutation,
    Operations.UpdateLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateLicenseMutation,
    Operations.UpdateLicenseMutationVariables
  >(Operations.UpdateLicense, options);
}
export type UpdateLicenseMutationHookResult = ReturnType<typeof useUpdateLicenseMutation>;
export type UpdateLicenseMutationResult = Apollo.MutationResult<Operations.UpdateLicenseMutation>;
export type UpdateLicenseMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateLicenseMutation,
  Operations.UpdateLicenseMutationVariables
>;
export type CreateLicenseMutationFn = Apollo.MutationFunction<
  Operations.CreateLicenseMutation,
  Operations.CreateLicenseMutationVariables
>;

/**
 * __useCreateLicenseMutation__
 *
 * To run a mutation, you first call `useCreateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseMutation, { data, loading, error }] = useCreateLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateLicenseMutation,
    Operations.CreateLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateLicenseMutation,
    Operations.CreateLicenseMutationVariables
  >(Operations.CreateLicense, options);
}
export type CreateLicenseMutationHookResult = ReturnType<typeof useCreateLicenseMutation>;
export type CreateLicenseMutationResult = Apollo.MutationResult<Operations.CreateLicenseMutation>;
export type CreateLicenseMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateLicenseMutation,
  Operations.CreateLicenseMutationVariables
>;

/**
 * __useGetDriverIncidentNoteInfoQuery__
 *
 * To run a query within a React component, call `useGetDriverIncidentNoteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverIncidentNoteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverIncidentNoteInfoQuery({
 *   variables: {
 *      driverIncidentId: // value for 'driverIncidentId'
 *   },
 * });
 */
export function useGetDriverIncidentNoteInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverIncidentNoteInfoQuery,
    Operations.GetDriverIncidentNoteInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverIncidentNoteInfoQuery,
    Operations.GetDriverIncidentNoteInfoQueryVariables
  >(Operations.GetDriverIncidentNoteInfo, options);
}
export function useGetDriverIncidentNoteInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverIncidentNoteInfoQuery,
    Operations.GetDriverIncidentNoteInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverIncidentNoteInfoQuery,
    Operations.GetDriverIncidentNoteInfoQueryVariables
  >(Operations.GetDriverIncidentNoteInfo, options);
}
export type GetDriverIncidentNoteInfoQueryHookResult = ReturnType<
  typeof useGetDriverIncidentNoteInfoQuery
>;
export type GetDriverIncidentNoteInfoLazyQueryHookResult = ReturnType<
  typeof useGetDriverIncidentNoteInfoLazyQuery
>;
export type GetDriverIncidentNoteInfoQueryResult = Apollo.QueryResult<
  Operations.GetDriverIncidentNoteInfoQuery,
  Operations.GetDriverIncidentNoteInfoQueryVariables
>;

/**
 * __useGetReservationNoteModalQuery__
 *
 * To run a query within a React component, call `useGetReservationNoteModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationNoteModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationNoteModalQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationNoteModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetReservationNoteModalQuery,
    Operations.GetReservationNoteModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetReservationNoteModalQuery,
    Operations.GetReservationNoteModalQueryVariables
  >(Operations.GetReservationNoteModal, options);
}
export function useGetReservationNoteModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetReservationNoteModalQuery,
    Operations.GetReservationNoteModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetReservationNoteModalQuery,
    Operations.GetReservationNoteModalQueryVariables
  >(Operations.GetReservationNoteModal, options);
}
export type GetReservationNoteModalQueryHookResult = ReturnType<
  typeof useGetReservationNoteModalQuery
>;
export type GetReservationNoteModalLazyQueryHookResult = ReturnType<
  typeof useGetReservationNoteModalLazyQuery
>;
export type GetReservationNoteModalQueryResult = Apollo.QueryResult<
  Operations.GetReservationNoteModalQuery,
  Operations.GetReservationNoteModalQueryVariables
>;

/**
 * __useGetScheduledShiftNoteModalQuery__
 *
 * To run a query within a React component, call `useGetScheduledShiftNoteModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledShiftNoteModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledShiftNoteModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScheduledShiftNoteModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetScheduledShiftNoteModalQuery,
    Operations.GetScheduledShiftNoteModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetScheduledShiftNoteModalQuery,
    Operations.GetScheduledShiftNoteModalQueryVariables
  >(Operations.GetScheduledShiftNoteModal, options);
}
export function useGetScheduledShiftNoteModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetScheduledShiftNoteModalQuery,
    Operations.GetScheduledShiftNoteModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetScheduledShiftNoteModalQuery,
    Operations.GetScheduledShiftNoteModalQueryVariables
  >(Operations.GetScheduledShiftNoteModal, options);
}
export type GetScheduledShiftNoteModalQueryHookResult = ReturnType<
  typeof useGetScheduledShiftNoteModalQuery
>;
export type GetScheduledShiftNoteModalLazyQueryHookResult = ReturnType<
  typeof useGetScheduledShiftNoteModalLazyQuery
>;
export type GetScheduledShiftNoteModalQueryResult = Apollo.QueryResult<
  Operations.GetScheduledShiftNoteModalQuery,
  Operations.GetScheduledShiftNoteModalQueryVariables
>;
export type CreateNoteMutationFn = Apollo.MutationFunction<
  Operations.CreateNoteMutation,
  Operations.CreateNoteMutationVariables
>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateNoteMutation,
    Operations.CreateNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateNoteMutation, Operations.CreateNoteMutationVariables>(
    Operations.CreateNote,
    options,
  );
}
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<Operations.CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateNoteMutation,
  Operations.CreateNoteMutationVariables
>;

/**
 * __useGetNotesCountQuery__
 *
 * To run a query within a React component, call `useGetNotesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetNotesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetNotesCountQuery,
    Operations.GetNotesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.GetNotesCountQuery, Operations.GetNotesCountQueryVariables>(
    Operations.GetNotesCount,
    options,
  );
}
export function useGetNotesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetNotesCountQuery,
    Operations.GetNotesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.GetNotesCountQuery, Operations.GetNotesCountQueryVariables>(
    Operations.GetNotesCount,
    options,
  );
}
export type GetNotesCountQueryHookResult = ReturnType<typeof useGetNotesCountQuery>;
export type GetNotesCountLazyQueryHookResult = ReturnType<typeof useGetNotesCountLazyQuery>;
export type GetNotesCountQueryResult = Apollo.QueryResult<
  Operations.GetNotesCountQuery,
  Operations.GetNotesCountQueryVariables
>;

/**
 * __useGetNotesQuery__
 *
 * To run a query within a React component, call `useGetNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetNotesQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.GetNotesQuery, Operations.GetNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.GetNotesQuery, Operations.GetNotesQueryVariables>(
    Operations.GetNotes,
    options,
  );
}
export function useGetNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetNotesQuery,
    Operations.GetNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.GetNotesQuery, Operations.GetNotesQueryVariables>(
    Operations.GetNotes,
    options,
  );
}
export type GetNotesQueryHookResult = ReturnType<typeof useGetNotesQuery>;
export type GetNotesLazyQueryHookResult = ReturnType<typeof useGetNotesLazyQuery>;
export type GetNotesQueryResult = Apollo.QueryResult<
  Operations.GetNotesQuery,
  Operations.GetNotesQueryVariables
>;
export type ResolveNoteMutationFn = Apollo.MutationFunction<
  Operations.ResolveNoteMutation,
  Operations.ResolveNoteMutationVariables
>;

/**
 * __useResolveNoteMutation__
 *
 * To run a mutation, you first call `useResolveNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveNoteMutation, { data, loading, error }] = useResolveNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useResolveNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ResolveNoteMutation,
    Operations.ResolveNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ResolveNoteMutation,
    Operations.ResolveNoteMutationVariables
  >(Operations.ResolveNote, options);
}
export type ResolveNoteMutationHookResult = ReturnType<typeof useResolveNoteMutation>;
export type ResolveNoteMutationResult = Apollo.MutationResult<Operations.ResolveNoteMutation>;
export type ResolveNoteMutationOptions = Apollo.BaseMutationOptions<
  Operations.ResolveNoteMutation,
  Operations.ResolveNoteMutationVariables
>;
export type UnresolveNoteMutationFn = Apollo.MutationFunction<
  Operations.UnresolveNoteMutation,
  Operations.UnresolveNoteMutationVariables
>;

/**
 * __useUnresolveNoteMutation__
 *
 * To run a mutation, you first call `useUnresolveNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnresolveNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unresolveNoteMutation, { data, loading, error }] = useUnresolveNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useUnresolveNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UnresolveNoteMutation,
    Operations.UnresolveNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UnresolveNoteMutation,
    Operations.UnresolveNoteMutationVariables
  >(Operations.UnresolveNote, options);
}
export type UnresolveNoteMutationHookResult = ReturnType<typeof useUnresolveNoteMutation>;
export type UnresolveNoteMutationResult = Apollo.MutationResult<Operations.UnresolveNoteMutation>;
export type UnresolveNoteMutationOptions = Apollo.BaseMutationOptions<
  Operations.UnresolveNoteMutation,
  Operations.UnresolveNoteMutationVariables
>;
export type CreateDriverIssueMutationFn = Apollo.MutationFunction<
  Operations.CreateDriverIssueMutation,
  Operations.CreateDriverIssueMutationVariables
>;

/**
 * __useCreateDriverIssueMutation__
 *
 * To run a mutation, you first call `useCreateDriverIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverIssueMutation, { data, loading, error }] = useCreateDriverIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateDriverIssueMutation,
    Operations.CreateDriverIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateDriverIssueMutation,
    Operations.CreateDriverIssueMutationVariables
  >(Operations.CreateDriverIssue, options);
}
export type CreateDriverIssueMutationHookResult = ReturnType<typeof useCreateDriverIssueMutation>;
export type CreateDriverIssueMutationResult = Apollo.MutationResult<Operations.CreateDriverIssueMutation>;
export type CreateDriverIssueMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateDriverIssueMutation,
  Operations.CreateDriverIssueMutationVariables
>;

/**
 * __useGetDriverIncidentReviewDisputeQuery__
 *
 * To run a query within a React component, call `useGetDriverIncidentReviewDisputeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverIncidentReviewDisputeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverIncidentReviewDisputeQuery({
 *   variables: {
 *      driverIncidentId: // value for 'driverIncidentId'
 *   },
 * });
 */
export function useGetDriverIncidentReviewDisputeQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverIncidentReviewDisputeQuery,
    Operations.GetDriverIncidentReviewDisputeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverIncidentReviewDisputeQuery,
    Operations.GetDriverIncidentReviewDisputeQueryVariables
  >(Operations.GetDriverIncidentReviewDispute, options);
}
export function useGetDriverIncidentReviewDisputeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverIncidentReviewDisputeQuery,
    Operations.GetDriverIncidentReviewDisputeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverIncidentReviewDisputeQuery,
    Operations.GetDriverIncidentReviewDisputeQueryVariables
  >(Operations.GetDriverIncidentReviewDispute, options);
}
export type GetDriverIncidentReviewDisputeQueryHookResult = ReturnType<
  typeof useGetDriverIncidentReviewDisputeQuery
>;
export type GetDriverIncidentReviewDisputeLazyQueryHookResult = ReturnType<
  typeof useGetDriverIncidentReviewDisputeLazyQuery
>;
export type GetDriverIncidentReviewDisputeQueryResult = Apollo.QueryResult<
  Operations.GetDriverIncidentReviewDisputeQuery,
  Operations.GetDriverIncidentReviewDisputeQueryVariables
>;
export type ReviewDriverIncidentDisputeMutationFn = Apollo.MutationFunction<
  Operations.ReviewDriverIncidentDisputeMutation,
  Operations.ReviewDriverIncidentDisputeMutationVariables
>;

/**
 * __useReviewDriverIncidentDisputeMutation__
 *
 * To run a mutation, you first call `useReviewDriverIncidentDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewDriverIncidentDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewDriverIncidentDisputeMutation, { data, loading, error }] = useReviewDriverIncidentDisputeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewDriverIncidentDisputeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ReviewDriverIncidentDisputeMutation,
    Operations.ReviewDriverIncidentDisputeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ReviewDriverIncidentDisputeMutation,
    Operations.ReviewDriverIncidentDisputeMutationVariables
  >(Operations.ReviewDriverIncidentDispute, options);
}
export type ReviewDriverIncidentDisputeMutationHookResult = ReturnType<
  typeof useReviewDriverIncidentDisputeMutation
>;
export type ReviewDriverIncidentDisputeMutationResult = Apollo.MutationResult<Operations.ReviewDriverIncidentDisputeMutation>;
export type ReviewDriverIncidentDisputeMutationOptions = Apollo.BaseMutationOptions<
  Operations.ReviewDriverIncidentDisputeMutation,
  Operations.ReviewDriverIncidentDisputeMutationVariables
>;
export type UpdateDriverShiftMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverShiftMutation,
  Operations.UpdateDriverShiftMutationVariables
>;

/**
 * __useUpdateDriverShiftMutation__
 *
 * To run a mutation, you first call `useUpdateDriverShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverShiftMutation, { data, loading, error }] = useUpdateDriverShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverShiftMutation,
    Operations.UpdateDriverShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverShiftMutation,
    Operations.UpdateDriverShiftMutationVariables
  >(Operations.UpdateDriverShift, options);
}
export type UpdateDriverShiftMutationHookResult = ReturnType<typeof useUpdateDriverShiftMutation>;
export type UpdateDriverShiftMutationResult = Apollo.MutationResult<Operations.UpdateDriverShiftMutation>;
export type UpdateDriverShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverShiftMutation,
  Operations.UpdateDriverShiftMutationVariables
>;
export type UpdateShiftClockTimesMutationFn = Apollo.MutationFunction<
  Operations.UpdateShiftClockTimesMutation,
  Operations.UpdateShiftClockTimesMutationVariables
>;

/**
 * __useUpdateShiftClockTimesMutation__
 *
 * To run a mutation, you first call `useUpdateShiftClockTimesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftClockTimesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftClockTimesMutation, { data, loading, error }] = useUpdateShiftClockTimesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShiftClockTimesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateShiftClockTimesMutation,
    Operations.UpdateShiftClockTimesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateShiftClockTimesMutation,
    Operations.UpdateShiftClockTimesMutationVariables
  >(Operations.UpdateShiftClockTimes, options);
}
export type UpdateShiftClockTimesMutationHookResult = ReturnType<
  typeof useUpdateShiftClockTimesMutation
>;
export type UpdateShiftClockTimesMutationResult = Apollo.MutationResult<Operations.UpdateShiftClockTimesMutation>;
export type UpdateShiftClockTimesMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateShiftClockTimesMutation,
  Operations.UpdateShiftClockTimesMutationVariables
>;
export type UpdateShiftSettingsMutationFn = Apollo.MutationFunction<
  Operations.UpdateShiftSettingsMutation,
  Operations.UpdateShiftSettingsMutationVariables
>;

/**
 * __useUpdateShiftSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateShiftSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftSettingsMutation, { data, loading, error }] = useUpdateShiftSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShiftSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateShiftSettingsMutation,
    Operations.UpdateShiftSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateShiftSettingsMutation,
    Operations.UpdateShiftSettingsMutationVariables
  >(Operations.UpdateShiftSettings, options);
}
export type UpdateShiftSettingsMutationHookResult = ReturnType<
  typeof useUpdateShiftSettingsMutation
>;
export type UpdateShiftSettingsMutationResult = Apollo.MutationResult<Operations.UpdateShiftSettingsMutation>;
export type UpdateShiftSettingsMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateShiftSettingsMutation,
  Operations.UpdateShiftSettingsMutationVariables
>;
export type AssignVehicleToShiftMutationFn = Apollo.MutationFunction<
  Operations.AssignVehicleToShiftMutation,
  Operations.AssignVehicleToShiftMutationVariables
>;

/**
 * __useAssignVehicleToShiftMutation__
 *
 * To run a mutation, you first call `useAssignVehicleToShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVehicleToShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVehicleToShiftMutation, { data, loading, error }] = useAssignVehicleToShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fleetResourceId: // value for 'fleetResourceId'
 *   },
 * });
 */
export function useAssignVehicleToShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AssignVehicleToShiftMutation,
    Operations.AssignVehicleToShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.AssignVehicleToShiftMutation,
    Operations.AssignVehicleToShiftMutationVariables
  >(Operations.AssignVehicleToShift, options);
}
export type AssignVehicleToShiftMutationHookResult = ReturnType<
  typeof useAssignVehicleToShiftMutation
>;
export type AssignVehicleToShiftMutationResult = Apollo.MutationResult<Operations.AssignVehicleToShiftMutation>;
export type AssignVehicleToShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.AssignVehicleToShiftMutation,
  Operations.AssignVehicleToShiftMutationVariables
>;
export type RemoveAssignedVehicleFromShiftMutationFn = Apollo.MutationFunction<
  Operations.RemoveAssignedVehicleFromShiftMutation,
  Operations.RemoveAssignedVehicleFromShiftMutationVariables
>;

/**
 * __useRemoveAssignedVehicleFromShiftMutation__
 *
 * To run a mutation, you first call `useRemoveAssignedVehicleFromShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssignedVehicleFromShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssignedVehicleFromShiftMutation, { data, loading, error }] = useRemoveAssignedVehicleFromShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAssignedVehicleFromShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.RemoveAssignedVehicleFromShiftMutation,
    Operations.RemoveAssignedVehicleFromShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.RemoveAssignedVehicleFromShiftMutation,
    Operations.RemoveAssignedVehicleFromShiftMutationVariables
  >(Operations.RemoveAssignedVehicleFromShift, options);
}
export type RemoveAssignedVehicleFromShiftMutationHookResult = ReturnType<
  typeof useRemoveAssignedVehicleFromShiftMutation
>;
export type RemoveAssignedVehicleFromShiftMutationResult = Apollo.MutationResult<Operations.RemoveAssignedVehicleFromShiftMutation>;
export type RemoveAssignedVehicleFromShiftMutationOptions = Apollo.BaseMutationOptions<
  Operations.RemoveAssignedVehicleFromShiftMutation,
  Operations.RemoveAssignedVehicleFromShiftMutationVariables
>;
export type UpdateShiftNoteMutationFn = Apollo.MutationFunction<
  Operations.UpdateShiftNoteMutation,
  Operations.UpdateShiftNoteMutationVariables
>;

/**
 * __useUpdateShiftNoteMutation__
 *
 * To run a mutation, you first call `useUpdateShiftNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftNoteMutation, { data, loading, error }] = useUpdateShiftNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateShiftNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateShiftNoteMutation,
    Operations.UpdateShiftNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateShiftNoteMutation,
    Operations.UpdateShiftNoteMutationVariables
  >(Operations.UpdateShiftNote, options);
}
export type UpdateShiftNoteMutationHookResult = ReturnType<typeof useUpdateShiftNoteMutation>;
export type UpdateShiftNoteMutationResult = Apollo.MutationResult<Operations.UpdateShiftNoteMutation>;
export type UpdateShiftNoteMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateShiftNoteMutation,
  Operations.UpdateShiftNoteMutationVariables
>;
export type UpdateShiftExemptionsMutationFn = Apollo.MutationFunction<
  Operations.UpdateShiftExemptionsMutation,
  Operations.UpdateShiftExemptionsMutationVariables
>;

/**
 * __useUpdateShiftExemptionsMutation__
 *
 * To run a mutation, you first call `useUpdateShiftExemptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftExemptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftExemptionsMutation, { data, loading, error }] = useUpdateShiftExemptionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShiftExemptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateShiftExemptionsMutation,
    Operations.UpdateShiftExemptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateShiftExemptionsMutation,
    Operations.UpdateShiftExemptionsMutationVariables
  >(Operations.UpdateShiftExemptions, options);
}
export type UpdateShiftExemptionsMutationHookResult = ReturnType<
  typeof useUpdateShiftExemptionsMutation
>;
export type UpdateShiftExemptionsMutationResult = Apollo.MutationResult<Operations.UpdateShiftExemptionsMutation>;
export type UpdateShiftExemptionsMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateShiftExemptionsMutation,
  Operations.UpdateShiftExemptionsMutationVariables
>;

/**
 * __useGetStandbyReservationModalQuery__
 *
 * To run a query within a React component, call `useGetStandbyReservationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandbyReservationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandbyReservationModalQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetStandbyReservationModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetStandbyReservationModalQuery,
    Operations.GetStandbyReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetStandbyReservationModalQuery,
    Operations.GetStandbyReservationModalQueryVariables
  >(Operations.GetStandbyReservationModal, options);
}
export function useGetStandbyReservationModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetStandbyReservationModalQuery,
    Operations.GetStandbyReservationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetStandbyReservationModalQuery,
    Operations.GetStandbyReservationModalQueryVariables
  >(Operations.GetStandbyReservationModal, options);
}
export type GetStandbyReservationModalQueryHookResult = ReturnType<
  typeof useGetStandbyReservationModalQuery
>;
export type GetStandbyReservationModalLazyQueryHookResult = ReturnType<
  typeof useGetStandbyReservationModalLazyQuery
>;
export type GetStandbyReservationModalQueryResult = Apollo.QueryResult<
  Operations.GetStandbyReservationModalQuery,
  Operations.GetStandbyReservationModalQueryVariables
>;
export type StandbyReservationMutationFn = Apollo.MutationFunction<
  Operations.StandbyReservationMutation,
  Operations.StandbyReservationMutationVariables
>;

/**
 * __useStandbyReservationMutation__
 *
 * To run a mutation, you first call `useStandbyReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStandbyReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [standbyReservationMutation, { data, loading, error }] = useStandbyReservationMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useStandbyReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.StandbyReservationMutation,
    Operations.StandbyReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.StandbyReservationMutation,
    Operations.StandbyReservationMutationVariables
  >(Operations.StandbyReservation, options);
}
export type StandbyReservationMutationHookResult = ReturnType<typeof useStandbyReservationMutation>;
export type StandbyReservationMutationResult = Apollo.MutationResult<Operations.StandbyReservationMutation>;
export type StandbyReservationMutationOptions = Apollo.BaseMutationOptions<
  Operations.StandbyReservationMutation,
  Operations.StandbyReservationMutationVariables
>;
export type StandbyShiftModalMutationFn = Apollo.MutationFunction<
  Operations.StandbyShiftModalMutation,
  Operations.StandbyShiftModalMutationVariables
>;

/**
 * __useStandbyShiftModalMutation__
 *
 * To run a mutation, you first call `useStandbyShiftModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStandbyShiftModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [standbyShiftModalMutation, { data, loading, error }] = useStandbyShiftModalMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useStandbyShiftModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.StandbyShiftModalMutation,
    Operations.StandbyShiftModalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.StandbyShiftModalMutation,
    Operations.StandbyShiftModalMutationVariables
  >(Operations.StandbyShiftModal, options);
}
export type StandbyShiftModalMutationHookResult = ReturnType<typeof useStandbyShiftModalMutation>;
export type StandbyShiftModalMutationResult = Apollo.MutationResult<Operations.StandbyShiftModalMutation>;
export type StandbyShiftModalMutationOptions = Apollo.BaseMutationOptions<
  Operations.StandbyShiftModalMutation,
  Operations.StandbyShiftModalMutationVariables
>;

/**
 * __useGetDriverVoidInvoiceModalQuery__
 *
 * To run a query within a React component, call `useGetDriverVoidInvoiceModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverVoidInvoiceModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverVoidInvoiceModalQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetDriverVoidInvoiceModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverVoidInvoiceModalQuery,
    Operations.GetDriverVoidInvoiceModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverVoidInvoiceModalQuery,
    Operations.GetDriverVoidInvoiceModalQueryVariables
  >(Operations.GetDriverVoidInvoiceModal, options);
}
export function useGetDriverVoidInvoiceModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverVoidInvoiceModalQuery,
    Operations.GetDriverVoidInvoiceModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverVoidInvoiceModalQuery,
    Operations.GetDriverVoidInvoiceModalQueryVariables
  >(Operations.GetDriverVoidInvoiceModal, options);
}
export type GetDriverVoidInvoiceModalQueryHookResult = ReturnType<
  typeof useGetDriverVoidInvoiceModalQuery
>;
export type GetDriverVoidInvoiceModalLazyQueryHookResult = ReturnType<
  typeof useGetDriverVoidInvoiceModalLazyQuery
>;
export type GetDriverVoidInvoiceModalQueryResult = Apollo.QueryResult<
  Operations.GetDriverVoidInvoiceModalQuery,
  Operations.GetDriverVoidInvoiceModalQueryVariables
>;

/**
 * __useGetInvoiceVoidedReasonsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceVoidedReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceVoidedReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceVoidedReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceVoidedReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetInvoiceVoidedReasonsQuery,
    Operations.GetInvoiceVoidedReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetInvoiceVoidedReasonsQuery,
    Operations.GetInvoiceVoidedReasonsQueryVariables
  >(Operations.GetInvoiceVoidedReasons, options);
}
export function useGetInvoiceVoidedReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetInvoiceVoidedReasonsQuery,
    Operations.GetInvoiceVoidedReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetInvoiceVoidedReasonsQuery,
    Operations.GetInvoiceVoidedReasonsQueryVariables
  >(Operations.GetInvoiceVoidedReasons, options);
}
export type GetInvoiceVoidedReasonsQueryHookResult = ReturnType<
  typeof useGetInvoiceVoidedReasonsQuery
>;
export type GetInvoiceVoidedReasonsLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceVoidedReasonsLazyQuery
>;
export type GetInvoiceVoidedReasonsQueryResult = Apollo.QueryResult<
  Operations.GetInvoiceVoidedReasonsQuery,
  Operations.GetInvoiceVoidedReasonsQueryVariables
>;
export type VoidInvoiceMutationFn = Apollo.MutationFunction<
  Operations.VoidInvoiceMutation,
  Operations.VoidInvoiceMutationVariables
>;

/**
 * __useVoidInvoiceMutation__
 *
 * To run a mutation, you first call `useVoidInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidInvoiceMutation, { data, loading, error }] = useVoidInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoidInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.VoidInvoiceMutation,
    Operations.VoidInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.VoidInvoiceMutation,
    Operations.VoidInvoiceMutationVariables
  >(Operations.VoidInvoice, options);
}
export type VoidInvoiceMutationHookResult = ReturnType<typeof useVoidInvoiceMutation>;
export type VoidInvoiceMutationResult = Apollo.MutationResult<Operations.VoidInvoiceMutation>;
export type VoidInvoiceMutationOptions = Apollo.BaseMutationOptions<
  Operations.VoidInvoiceMutation,
  Operations.VoidInvoiceMutationVariables
>;

/**
 * __useGetDepositDetailsSidebarQuery__
 *
 * To run a query within a React component, call `useGetDepositDetailsSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositDetailsSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositDetailsSidebarQuery({
 *   variables: {
 *      ledgerRecordId: // value for 'ledgerRecordId'
 *   },
 * });
 */
export function useGetDepositDetailsSidebarQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDepositDetailsSidebarQuery,
    Operations.GetDepositDetailsSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDepositDetailsSidebarQuery,
    Operations.GetDepositDetailsSidebarQueryVariables
  >(Operations.GetDepositDetailsSidebar, options);
}
export function useGetDepositDetailsSidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDepositDetailsSidebarQuery,
    Operations.GetDepositDetailsSidebarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDepositDetailsSidebarQuery,
    Operations.GetDepositDetailsSidebarQueryVariables
  >(Operations.GetDepositDetailsSidebar, options);
}
export type GetDepositDetailsSidebarQueryHookResult = ReturnType<
  typeof useGetDepositDetailsSidebarQuery
>;
export type GetDepositDetailsSidebarLazyQueryHookResult = ReturnType<
  typeof useGetDepositDetailsSidebarLazyQuery
>;
export type GetDepositDetailsSidebarQueryResult = Apollo.QueryResult<
  Operations.GetDepositDetailsSidebarQuery,
  Operations.GetDepositDetailsSidebarQueryVariables
>;

/**
 * __useGetDriverSecurityDepositLedgerRecordsQuery__
 *
 * To run a query within a React component, call `useGetDriverSecurityDepositLedgerRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverSecurityDepositLedgerRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverSecurityDepositLedgerRecordsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriverSecurityDepositLedgerRecordsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetDriverSecurityDepositLedgerRecordsQuery,
    Operations.GetDriverSecurityDepositLedgerRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverSecurityDepositLedgerRecordsQuery,
    Operations.GetDriverSecurityDepositLedgerRecordsQueryVariables
  >(Operations.GetDriverSecurityDepositLedgerRecords, options);
}
export function useGetDriverSecurityDepositLedgerRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverSecurityDepositLedgerRecordsQuery,
    Operations.GetDriverSecurityDepositLedgerRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverSecurityDepositLedgerRecordsQuery,
    Operations.GetDriverSecurityDepositLedgerRecordsQueryVariables
  >(Operations.GetDriverSecurityDepositLedgerRecords, options);
}
export type GetDriverSecurityDepositLedgerRecordsQueryHookResult = ReturnType<
  typeof useGetDriverSecurityDepositLedgerRecordsQuery
>;
export type GetDriverSecurityDepositLedgerRecordsLazyQueryHookResult = ReturnType<
  typeof useGetDriverSecurityDepositLedgerRecordsLazyQuery
>;
export type GetDriverSecurityDepositLedgerRecordsQueryResult = Apollo.QueryResult<
  Operations.GetDriverSecurityDepositLedgerRecordsQuery,
  Operations.GetDriverSecurityDepositLedgerRecordsQueryVariables
>;
export type ResendDocusignDocumentsMutationFn = Apollo.MutationFunction<
  Operations.ResendDocusignDocumentsMutation,
  Operations.ResendDocusignDocumentsMutationVariables
>;

/**
 * __useResendDocusignDocumentsMutation__
 *
 * To run a mutation, you first call `useResendDocusignDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendDocusignDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendDocusignDocumentsMutation, { data, loading, error }] = useResendDocusignDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendDocusignDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ResendDocusignDocumentsMutation,
    Operations.ResendDocusignDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ResendDocusignDocumentsMutation,
    Operations.ResendDocusignDocumentsMutationVariables
  >(Operations.ResendDocusignDocuments, options);
}
export type ResendDocusignDocumentsMutationHookResult = ReturnType<
  typeof useResendDocusignDocumentsMutation
>;
export type ResendDocusignDocumentsMutationResult = Apollo.MutationResult<Operations.ResendDocusignDocumentsMutation>;
export type ResendDocusignDocumentsMutationOptions = Apollo.BaseMutationOptions<
  Operations.ResendDocusignDocumentsMutation,
  Operations.ResendDocusignDocumentsMutationVariables
>;
export type ResendBackgroundCheckMutationFn = Apollo.MutationFunction<
  Operations.ResendBackgroundCheckMutation,
  Operations.ResendBackgroundCheckMutationVariables
>;

/**
 * __useResendBackgroundCheckMutation__
 *
 * To run a mutation, you first call `useResendBackgroundCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendBackgroundCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendBackgroundCheckMutation, { data, loading, error }] = useResendBackgroundCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendBackgroundCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ResendBackgroundCheckMutation,
    Operations.ResendBackgroundCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ResendBackgroundCheckMutation,
    Operations.ResendBackgroundCheckMutationVariables
  >(Operations.ResendBackgroundCheck, options);
}
export type ResendBackgroundCheckMutationHookResult = ReturnType<
  typeof useResendBackgroundCheckMutation
>;
export type ResendBackgroundCheckMutationResult = Apollo.MutationResult<Operations.ResendBackgroundCheckMutation>;
export type ResendBackgroundCheckMutationOptions = Apollo.BaseMutationOptions<
  Operations.ResendBackgroundCheckMutation,
  Operations.ResendBackgroundCheckMutationVariables
>;

/**
 * __useGetDriverRestrictionsContentQuery__
 *
 * To run a query within a React component, call `useGetDriverRestrictionsContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverRestrictionsContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverRestrictionsContentQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriverRestrictionsContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetDriverRestrictionsContentQuery,
    Operations.GetDriverRestrictionsContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverRestrictionsContentQuery,
    Operations.GetDriverRestrictionsContentQueryVariables
  >(Operations.GetDriverRestrictionsContent, options);
}
export function useGetDriverRestrictionsContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverRestrictionsContentQuery,
    Operations.GetDriverRestrictionsContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverRestrictionsContentQuery,
    Operations.GetDriverRestrictionsContentQueryVariables
  >(Operations.GetDriverRestrictionsContent, options);
}
export type GetDriverRestrictionsContentQueryHookResult = ReturnType<
  typeof useGetDriverRestrictionsContentQuery
>;
export type GetDriverRestrictionsContentLazyQueryHookResult = ReturnType<
  typeof useGetDriverRestrictionsContentLazyQuery
>;
export type GetDriverRestrictionsContentQueryResult = Apollo.QueryResult<
  Operations.GetDriverRestrictionsContentQuery,
  Operations.GetDriverRestrictionsContentQueryVariables
>;

/**
 * __useGetTracksQuery__
 *
 * To run a query within a React component, call `useGetTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTracksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTracksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetTracksQuery,
    Operations.GetTracksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.GetTracksQuery, Operations.GetTracksQueryVariables>(
    Operations.GetTracks,
    options,
  );
}
export function useGetTracksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetTracksQuery,
    Operations.GetTracksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.GetTracksQuery, Operations.GetTracksQueryVariables>(
    Operations.GetTracks,
    options,
  );
}
export type GetTracksQueryHookResult = ReturnType<typeof useGetTracksQuery>;
export type GetTracksLazyQueryHookResult = ReturnType<typeof useGetTracksLazyQuery>;
export type GetTracksQueryResult = Apollo.QueryResult<
  Operations.GetTracksQuery,
  Operations.GetTracksQueryVariables
>;

/**
 * __useGetDriverIncidentsQuery__
 *
 * To run a query within a React component, call `useGetDriverIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverIncidentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriverIncidentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverIncidentsQuery,
    Operations.GetDriverIncidentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverIncidentsQuery,
    Operations.GetDriverIncidentsQueryVariables
  >(Operations.GetDriverIncidents, options);
}
export function useGetDriverIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverIncidentsQuery,
    Operations.GetDriverIncidentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverIncidentsQuery,
    Operations.GetDriverIncidentsQueryVariables
  >(Operations.GetDriverIncidents, options);
}
export type GetDriverIncidentsQueryHookResult = ReturnType<typeof useGetDriverIncidentsQuery>;
export type GetDriverIncidentsLazyQueryHookResult = ReturnType<
  typeof useGetDriverIncidentsLazyQuery
>;
export type GetDriverIncidentsQueryResult = Apollo.QueryResult<
  Operations.GetDriverIncidentsQuery,
  Operations.GetDriverIncidentsQueryVariables
>;

/**
 * __useGetDriverIncidentQuery__
 *
 * To run a query within a React component, call `useGetDriverIncidentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverIncidentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverIncidentQuery({
 *   variables: {
 *      driverIncidentId: // value for 'driverIncidentId'
 *   },
 * });
 */
export function useGetDriverIncidentQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverIncidentQuery,
    Operations.GetDriverIncidentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverIncidentQuery,
    Operations.GetDriverIncidentQueryVariables
  >(Operations.GetDriverIncident, options);
}
export function useGetDriverIncidentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverIncidentQuery,
    Operations.GetDriverIncidentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverIncidentQuery,
    Operations.GetDriverIncidentQueryVariables
  >(Operations.GetDriverIncident, options);
}
export type GetDriverIncidentQueryHookResult = ReturnType<typeof useGetDriverIncidentQuery>;
export type GetDriverIncidentLazyQueryHookResult = ReturnType<typeof useGetDriverIncidentLazyQuery>;
export type GetDriverIncidentQueryResult = Apollo.QueryResult<
  Operations.GetDriverIncidentQuery,
  Operations.GetDriverIncidentQueryVariables
>;

/**
 * __useGetInvoiceTabInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoiceTabInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceTabInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceTabInvoicesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetInvoiceTabInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetInvoiceTabInvoicesQuery,
    Operations.GetInvoiceTabInvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetInvoiceTabInvoicesQuery,
    Operations.GetInvoiceTabInvoicesQueryVariables
  >(Operations.GetInvoiceTabInvoices, options);
}
export function useGetInvoiceTabInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetInvoiceTabInvoicesQuery,
    Operations.GetInvoiceTabInvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetInvoiceTabInvoicesQuery,
    Operations.GetInvoiceTabInvoicesQueryVariables
  >(Operations.GetInvoiceTabInvoices, options);
}
export type GetInvoiceTabInvoicesQueryHookResult = ReturnType<typeof useGetInvoiceTabInvoicesQuery>;
export type GetInvoiceTabInvoicesLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceTabInvoicesLazyQuery
>;
export type GetInvoiceTabInvoicesQueryResult = Apollo.QueryResult<
  Operations.GetInvoiceTabInvoicesQuery,
  Operations.GetInvoiceTabInvoicesQueryVariables
>;

/**
 * __useGetReservationsTabQuery__
 *
 * To run a query within a React component, call `useGetReservationsTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsTabQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetReservationsTabQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetReservationsTabQuery,
    Operations.GetReservationsTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetReservationsTabQuery,
    Operations.GetReservationsTabQueryVariables
  >(Operations.GetReservationsTab, options);
}
export function useGetReservationsTabLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetReservationsTabQuery,
    Operations.GetReservationsTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetReservationsTabQuery,
    Operations.GetReservationsTabQueryVariables
  >(Operations.GetReservationsTab, options);
}
export type GetReservationsTabQueryHookResult = ReturnType<typeof useGetReservationsTabQuery>;
export type GetReservationsTabLazyQueryHookResult = ReturnType<
  typeof useGetReservationsTabLazyQuery
>;
export type GetReservationsTabQueryResult = Apollo.QueryResult<
  Operations.GetReservationsTabQuery,
  Operations.GetReservationsTabQueryVariables
>;

/**
 * __useGetRidesTabRidesQuery__
 *
 * To run a query within a React component, call `useGetRidesTabRidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRidesTabRidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRidesTabRidesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRidesTabRidesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetRidesTabRidesQuery,
    Operations.GetRidesTabRidesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetRidesTabRidesQuery,
    Operations.GetRidesTabRidesQueryVariables
  >(Operations.GetRidesTabRides, options);
}
export function useGetRidesTabRidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetRidesTabRidesQuery,
    Operations.GetRidesTabRidesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetRidesTabRidesQuery,
    Operations.GetRidesTabRidesQueryVariables
  >(Operations.GetRidesTabRides, options);
}
export type GetRidesTabRidesQueryHookResult = ReturnType<typeof useGetRidesTabRidesQuery>;
export type GetRidesTabRidesLazyQueryHookResult = ReturnType<typeof useGetRidesTabRidesLazyQuery>;
export type GetRidesTabRidesQueryResult = Apollo.QueryResult<
  Operations.GetRidesTabRidesQuery,
  Operations.GetRidesTabRidesQueryVariables
>;

/**
 * __useListRecurringShiftsQuery__
 *
 * To run a query within a React component, call `useListRecurringShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRecurringShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRecurringShiftsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListRecurringShiftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.ListRecurringShiftsQuery,
    Operations.ListRecurringShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.ListRecurringShiftsQuery,
    Operations.ListRecurringShiftsQueryVariables
  >(Operations.ListRecurringShifts, options);
}
export function useListRecurringShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ListRecurringShiftsQuery,
    Operations.ListRecurringShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.ListRecurringShiftsQuery,
    Operations.ListRecurringShiftsQueryVariables
  >(Operations.ListRecurringShifts, options);
}
export type ListRecurringShiftsQueryHookResult = ReturnType<typeof useListRecurringShiftsQuery>;
export type ListRecurringShiftsLazyQueryHookResult = ReturnType<
  typeof useListRecurringShiftsLazyQuery
>;
export type ListRecurringShiftsQueryResult = Apollo.QueryResult<
  Operations.ListRecurringShiftsQuery,
  Operations.ListRecurringShiftsQueryVariables
>;

/**
 * __useGetRecurringShiftQuery__
 *
 * To run a query within a React component, call `useGetRecurringShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringShiftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecurringShiftQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetRecurringShiftQuery,
    Operations.GetRecurringShiftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetRecurringShiftQuery,
    Operations.GetRecurringShiftQueryVariables
  >(Operations.GetRecurringShift, options);
}
export function useGetRecurringShiftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetRecurringShiftQuery,
    Operations.GetRecurringShiftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetRecurringShiftQuery,
    Operations.GetRecurringShiftQueryVariables
  >(Operations.GetRecurringShift, options);
}
export type GetRecurringShiftQueryHookResult = ReturnType<typeof useGetRecurringShiftQuery>;
export type GetRecurringShiftLazyQueryHookResult = ReturnType<typeof useGetRecurringShiftLazyQuery>;
export type GetRecurringShiftQueryResult = Apollo.QueryResult<
  Operations.GetRecurringShiftQuery,
  Operations.GetRecurringShiftQueryVariables
>;

/**
 * __useGetShiftsTabShiftsQuery__
 *
 * To run a query within a React component, call `useGetShiftsTabShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftsTabShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftsTabShiftsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetShiftsTabShiftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetShiftsTabShiftsQuery,
    Operations.GetShiftsTabShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetShiftsTabShiftsQuery,
    Operations.GetShiftsTabShiftsQueryVariables
  >(Operations.GetShiftsTabShifts, options);
}
export function useGetShiftsTabShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetShiftsTabShiftsQuery,
    Operations.GetShiftsTabShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetShiftsTabShiftsQuery,
    Operations.GetShiftsTabShiftsQueryVariables
  >(Operations.GetShiftsTabShifts, options);
}
export type GetShiftsTabShiftsQueryHookResult = ReturnType<typeof useGetShiftsTabShiftsQuery>;
export type GetShiftsTabShiftsLazyQueryHookResult = ReturnType<
  typeof useGetShiftsTabShiftsLazyQuery
>;
export type GetShiftsTabShiftsQueryResult = Apollo.QueryResult<
  Operations.GetShiftsTabShiftsQuery,
  Operations.GetShiftsTabShiftsQueryVariables
>;

/**
 * __useListTrafficViolationTicketsQuery__
 *
 * To run a query within a React component, call `useListTrafficViolationTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTrafficViolationTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTrafficViolationTicketsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListTrafficViolationTicketsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.ListTrafficViolationTicketsQuery,
    Operations.ListTrafficViolationTicketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.ListTrafficViolationTicketsQuery,
    Operations.ListTrafficViolationTicketsQueryVariables
  >(Operations.ListTrafficViolationTickets, options);
}
export function useListTrafficViolationTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ListTrafficViolationTicketsQuery,
    Operations.ListTrafficViolationTicketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.ListTrafficViolationTicketsQuery,
    Operations.ListTrafficViolationTicketsQueryVariables
  >(Operations.ListTrafficViolationTickets, options);
}
export type ListTrafficViolationTicketsQueryHookResult = ReturnType<
  typeof useListTrafficViolationTicketsQuery
>;
export type ListTrafficViolationTicketsLazyQueryHookResult = ReturnType<
  typeof useListTrafficViolationTicketsLazyQuery
>;
export type ListTrafficViolationTicketsQueryResult = Apollo.QueryResult<
  Operations.ListTrafficViolationTicketsQuery,
  Operations.ListTrafficViolationTicketsQueryVariables
>;

/**
 * __useGetDriversScreenQuery__
 *
 * To run a query within a React component, call `useGetDriversScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversScreenQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriversScreenQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriversScreenQuery,
    Operations.GetDriversScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriversScreenQuery,
    Operations.GetDriversScreenQueryVariables
  >(Operations.GetDriversScreen, options);
}
export function useGetDriversScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriversScreenQuery,
    Operations.GetDriversScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriversScreenQuery,
    Operations.GetDriversScreenQueryVariables
  >(Operations.GetDriversScreen, options);
}
export type GetDriversScreenQueryHookResult = ReturnType<typeof useGetDriversScreenQuery>;
export type GetDriversScreenLazyQueryHookResult = ReturnType<typeof useGetDriversScreenLazyQuery>;
export type GetDriversScreenQueryResult = Apollo.QueryResult<
  Operations.GetDriversScreenQuery,
  Operations.GetDriversScreenQueryVariables
>;

/**
 * __useGetDriverQuery__
 *
 * To run a query within a React component, call `useGetDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverQuery,
    Operations.GetDriverQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.GetDriverQuery, Operations.GetDriverQueryVariables>(
    Operations.GetDriver,
    options,
  );
}
export function useGetDriverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverQuery,
    Operations.GetDriverQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.GetDriverQuery, Operations.GetDriverQueryVariables>(
    Operations.GetDriver,
    options,
  );
}
export type GetDriverQueryHookResult = ReturnType<typeof useGetDriverQuery>;
export type GetDriverLazyQueryHookResult = ReturnType<typeof useGetDriverLazyQuery>;
export type GetDriverQueryResult = Apollo.QueryResult<
  Operations.GetDriverQuery,
  Operations.GetDriverQueryVariables
>;

/**
 * __useGetDriverAddressQuery__
 *
 * To run a query within a React component, call `useGetDriverAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverAddressQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      addressType: // value for 'addressType'
 *   },
 * });
 */
export function useGetDriverAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverAddressQuery,
    Operations.GetDriverAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverAddressQuery,
    Operations.GetDriverAddressQueryVariables
  >(Operations.GetDriverAddress, options);
}
export function useGetDriverAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverAddressQuery,
    Operations.GetDriverAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverAddressQuery,
    Operations.GetDriverAddressQueryVariables
  >(Operations.GetDriverAddress, options);
}
export type GetDriverAddressQueryHookResult = ReturnType<typeof useGetDriverAddressQuery>;
export type GetDriverAddressLazyQueryHookResult = ReturnType<typeof useGetDriverAddressLazyQuery>;
export type GetDriverAddressQueryResult = Apollo.QueryResult<
  Operations.GetDriverAddressQuery,
  Operations.GetDriverAddressQueryVariables
>;

/**
 * __useGetDriverLicensesQuery__
 *
 * To run a query within a React component, call `useGetDriverLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverLicensesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriverLicensesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetDriverLicensesQuery,
    Operations.GetDriverLicensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverLicensesQuery,
    Operations.GetDriverLicensesQueryVariables
  >(Operations.GetDriverLicenses, options);
}
export function useGetDriverLicensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverLicensesQuery,
    Operations.GetDriverLicensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverLicensesQuery,
    Operations.GetDriverLicensesQueryVariables
  >(Operations.GetDriverLicenses, options);
}
export type GetDriverLicensesQueryHookResult = ReturnType<typeof useGetDriverLicensesQuery>;
export type GetDriverLicensesLazyQueryHookResult = ReturnType<typeof useGetDriverLicensesLazyQuery>;
export type GetDriverLicensesQueryResult = Apollo.QueryResult<
  Operations.GetDriverLicensesQuery,
  Operations.GetDriverLicensesQueryVariables
>;

/**
 * __useGetDriverTracksQuery__
 *
 * To run a query within a React component, call `useGetDriverTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverTracksQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriverTracksQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverTracksQuery,
    Operations.GetDriverTracksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.GetDriverTracksQuery, Operations.GetDriverTracksQueryVariables>(
    Operations.GetDriverTracks,
    options,
  );
}
export function useGetDriverTracksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverTracksQuery,
    Operations.GetDriverTracksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverTracksQuery,
    Operations.GetDriverTracksQueryVariables
  >(Operations.GetDriverTracks, options);
}
export type GetDriverTracksQueryHookResult = ReturnType<typeof useGetDriverTracksQuery>;
export type GetDriverTracksLazyQueryHookResult = ReturnType<typeof useGetDriverTracksLazyQuery>;
export type GetDriverTracksQueryResult = Apollo.QueryResult<
  Operations.GetDriverTracksQuery,
  Operations.GetDriverTracksQueryVariables
>;

/**
 * __useGetDriverShiftQuery__
 *
 * To run a query within a React component, call `useGetDriverShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverShiftQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useGetDriverShiftQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverShiftQuery,
    Operations.GetDriverShiftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.GetDriverShiftQuery, Operations.GetDriverShiftQueryVariables>(
    Operations.GetDriverShift,
    options,
  );
}
export function useGetDriverShiftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverShiftQuery,
    Operations.GetDriverShiftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverShiftQuery,
    Operations.GetDriverShiftQueryVariables
  >(Operations.GetDriverShift, options);
}
export type GetDriverShiftQueryHookResult = ReturnType<typeof useGetDriverShiftQuery>;
export type GetDriverShiftLazyQueryHookResult = ReturnType<typeof useGetDriverShiftLazyQuery>;
export type GetDriverShiftQueryResult = Apollo.QueryResult<
  Operations.GetDriverShiftQuery,
  Operations.GetDriverShiftQueryVariables
>;

/**
 * __useGetDriverOtpValidationCodeQuery__
 *
 * To run a query within a React component, call `useGetDriverOtpValidationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverOtpValidationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverOtpValidationCodeQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverOtpValidationCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverOtpValidationCodeQuery,
    Operations.GetDriverOtpValidationCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverOtpValidationCodeQuery,
    Operations.GetDriverOtpValidationCodeQueryVariables
  >(Operations.GetDriverOtpValidationCode, options);
}
export function useGetDriverOtpValidationCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverOtpValidationCodeQuery,
    Operations.GetDriverOtpValidationCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverOtpValidationCodeQuery,
    Operations.GetDriverOtpValidationCodeQueryVariables
  >(Operations.GetDriverOtpValidationCode, options);
}
export type GetDriverOtpValidationCodeQueryHookResult = ReturnType<
  typeof useGetDriverOtpValidationCodeQuery
>;
export type GetDriverOtpValidationCodeLazyQueryHookResult = ReturnType<
  typeof useGetDriverOtpValidationCodeLazyQuery
>;
export type GetDriverOtpValidationCodeQueryResult = Apollo.QueryResult<
  Operations.GetDriverOtpValidationCodeQuery,
  Operations.GetDriverOtpValidationCodeQueryVariables
>;
export type GenerateDriverAssetPresignedPostMutationFn = Apollo.MutationFunction<
  Operations.GenerateDriverAssetPresignedPostMutation,
  Operations.GenerateDriverAssetPresignedPostMutationVariables
>;

/**
 * __useGenerateDriverAssetPresignedPostMutation__
 *
 * To run a mutation, you first call `useGenerateDriverAssetPresignedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDriverAssetPresignedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDriverAssetPresignedPostMutation, { data, loading, error }] = useGenerateDriverAssetPresignedPostMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateDriverAssetPresignedPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.GenerateDriverAssetPresignedPostMutation,
    Operations.GenerateDriverAssetPresignedPostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.GenerateDriverAssetPresignedPostMutation,
    Operations.GenerateDriverAssetPresignedPostMutationVariables
  >(Operations.GenerateDriverAssetPresignedPost, options);
}
export type GenerateDriverAssetPresignedPostMutationHookResult = ReturnType<
  typeof useGenerateDriverAssetPresignedPostMutation
>;
export type GenerateDriverAssetPresignedPostMutationResult = Apollo.MutationResult<Operations.GenerateDriverAssetPresignedPostMutation>;
export type GenerateDriverAssetPresignedPostMutationOptions = Apollo.BaseMutationOptions<
  Operations.GenerateDriverAssetPresignedPostMutation,
  Operations.GenerateDriverAssetPresignedPostMutationVariables
>;
export type UpdateDriverAvatarMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverAvatarMutation,
  Operations.UpdateDriverAvatarMutationVariables
>;

/**
 * __useUpdateDriverAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateDriverAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverAvatarMutation, { data, loading, error }] = useUpdateDriverAvatarMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverAvatarMutation,
    Operations.UpdateDriverAvatarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverAvatarMutation,
    Operations.UpdateDriverAvatarMutationVariables
  >(Operations.UpdateDriverAvatar, options);
}
export type UpdateDriverAvatarMutationHookResult = ReturnType<typeof useUpdateDriverAvatarMutation>;
export type UpdateDriverAvatarMutationResult = Apollo.MutationResult<Operations.UpdateDriverAvatarMutation>;
export type UpdateDriverAvatarMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverAvatarMutation,
  Operations.UpdateDriverAvatarMutationVariables
>;
export type UpdateDriverInfoMutationFn = Apollo.MutationFunction<
  Operations.UpdateDriverInfoMutation,
  Operations.UpdateDriverInfoMutationVariables
>;

/**
 * __useUpdateDriverInfoMutation__
 *
 * To run a mutation, you first call `useUpdateDriverInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverInfoMutation, { data, loading, error }] = useUpdateDriverInfoMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateDriverInfoMutation,
    Operations.UpdateDriverInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateDriverInfoMutation,
    Operations.UpdateDriverInfoMutationVariables
  >(Operations.UpdateDriverInfo, options);
}
export type UpdateDriverInfoMutationHookResult = ReturnType<typeof useUpdateDriverInfoMutation>;
export type UpdateDriverInfoMutationResult = Apollo.MutationResult<Operations.UpdateDriverInfoMutation>;
export type UpdateDriverInfoMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateDriverInfoMutation,
  Operations.UpdateDriverInfoMutationVariables
>;

/**
 * __useGetInvoicesScreenQuery__
 *
 * To run a query within a React component, call `useGetInvoicesScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesScreenQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetInvoicesScreenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetInvoicesScreenQuery,
    Operations.GetInvoicesScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetInvoicesScreenQuery,
    Operations.GetInvoicesScreenQueryVariables
  >(Operations.GetInvoicesScreen, options);
}
export function useGetInvoicesScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetInvoicesScreenQuery,
    Operations.GetInvoicesScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetInvoicesScreenQuery,
    Operations.GetInvoicesScreenQueryVariables
  >(Operations.GetInvoicesScreen, options);
}
export type GetInvoicesScreenQueryHookResult = ReturnType<typeof useGetInvoicesScreenQuery>;
export type GetInvoicesScreenLazyQueryHookResult = ReturnType<typeof useGetInvoicesScreenLazyQuery>;
export type GetInvoicesScreenQueryResult = Apollo.QueryResult<
  Operations.GetInvoicesScreenQuery,
  Operations.GetInvoicesScreenQueryVariables
>;
export type RetryInvoiceMutationFn = Apollo.MutationFunction<
  Operations.RetryInvoiceMutation,
  Operations.RetryInvoiceMutationVariables
>;

/**
 * __useRetryInvoiceMutation__
 *
 * To run a mutation, you first call `useRetryInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryInvoiceMutation, { data, loading, error }] = useRetryInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useRetryInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.RetryInvoiceMutation,
    Operations.RetryInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.RetryInvoiceMutation,
    Operations.RetryInvoiceMutationVariables
  >(Operations.RetryInvoice, options);
}
export type RetryInvoiceMutationHookResult = ReturnType<typeof useRetryInvoiceMutation>;
export type RetryInvoiceMutationResult = Apollo.MutationResult<Operations.RetryInvoiceMutation>;
export type RetryInvoiceMutationOptions = Apollo.BaseMutationOptions<
  Operations.RetryInvoiceMutation,
  Operations.RetryInvoiceMutationVariables
>;

/**
 * __useGetSelectedShiftMapLocationQuery__
 *
 * To run a query within a React component, call `useGetSelectedShiftMapLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedShiftMapLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedShiftMapLocationQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useGetSelectedShiftMapLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetSelectedShiftMapLocationQuery,
    Operations.GetSelectedShiftMapLocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetSelectedShiftMapLocationQuery,
    Operations.GetSelectedShiftMapLocationQueryVariables
  >(Operations.GetSelectedShiftMapLocation, options);
}
export function useGetSelectedShiftMapLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetSelectedShiftMapLocationQuery,
    Operations.GetSelectedShiftMapLocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetSelectedShiftMapLocationQuery,
    Operations.GetSelectedShiftMapLocationQueryVariables
  >(Operations.GetSelectedShiftMapLocation, options);
}
export type GetSelectedShiftMapLocationQueryHookResult = ReturnType<
  typeof useGetSelectedShiftMapLocationQuery
>;
export type GetSelectedShiftMapLocationLazyQueryHookResult = ReturnType<
  typeof useGetSelectedShiftMapLocationLazyQuery
>;
export type GetSelectedShiftMapLocationQueryResult = Apollo.QueryResult<
  Operations.GetSelectedShiftMapLocationQuery,
  Operations.GetSelectedShiftMapLocationQueryVariables
>;

/**
 * __useGetBalancerMapConfigQuery__
 *
 * To run a query within a React component, call `useGetBalancerMapConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalancerMapConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalancerMapConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBalancerMapConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetBalancerMapConfigQuery,
    Operations.GetBalancerMapConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetBalancerMapConfigQuery,
    Operations.GetBalancerMapConfigQueryVariables
  >(Operations.GetBalancerMapConfig, options);
}
export function useGetBalancerMapConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetBalancerMapConfigQuery,
    Operations.GetBalancerMapConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetBalancerMapConfigQuery,
    Operations.GetBalancerMapConfigQueryVariables
  >(Operations.GetBalancerMapConfig, options);
}
export type GetBalancerMapConfigQueryHookResult = ReturnType<typeof useGetBalancerMapConfigQuery>;
export type GetBalancerMapConfigLazyQueryHookResult = ReturnType<
  typeof useGetBalancerMapConfigLazyQuery
>;
export type GetBalancerMapConfigQueryResult = Apollo.QueryResult<
  Operations.GetBalancerMapConfigQuery,
  Operations.GetBalancerMapConfigQueryVariables
>;

/**
 * __useGetBalancerMapQuery__
 *
 * To run a query within a React component, call `useGetBalancerMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalancerMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalancerMapQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBalancerMapQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetBalancerMapQuery,
    Operations.GetBalancerMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.GetBalancerMapQuery, Operations.GetBalancerMapQueryVariables>(
    Operations.GetBalancerMap,
    options,
  );
}
export function useGetBalancerMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetBalancerMapQuery,
    Operations.GetBalancerMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetBalancerMapQuery,
    Operations.GetBalancerMapQueryVariables
  >(Operations.GetBalancerMap, options);
}
export type GetBalancerMapQueryHookResult = ReturnType<typeof useGetBalancerMapQuery>;
export type GetBalancerMapLazyQueryHookResult = ReturnType<typeof useGetBalancerMapLazyQuery>;
export type GetBalancerMapQueryResult = Apollo.QueryResult<
  Operations.GetBalancerMapQuery,
  Operations.GetBalancerMapQueryVariables
>;

/**
 * __useGetSelectedShiftMapRouteQuery__
 *
 * To run a query within a React component, call `useGetSelectedShiftMapRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedShiftMapRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedShiftMapRouteQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useGetSelectedShiftMapRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetSelectedShiftMapRouteQuery,
    Operations.GetSelectedShiftMapRouteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetSelectedShiftMapRouteQuery,
    Operations.GetSelectedShiftMapRouteQueryVariables
  >(Operations.GetSelectedShiftMapRoute, options);
}
export function useGetSelectedShiftMapRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetSelectedShiftMapRouteQuery,
    Operations.GetSelectedShiftMapRouteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetSelectedShiftMapRouteQuery,
    Operations.GetSelectedShiftMapRouteQueryVariables
  >(Operations.GetSelectedShiftMapRoute, options);
}
export type GetSelectedShiftMapRouteQueryHookResult = ReturnType<
  typeof useGetSelectedShiftMapRouteQuery
>;
export type GetSelectedShiftMapRouteLazyQueryHookResult = ReturnType<
  typeof useGetSelectedShiftMapRouteLazyQuery
>;
export type GetSelectedShiftMapRouteQueryResult = Apollo.QueryResult<
  Operations.GetSelectedShiftMapRouteQuery,
  Operations.GetSelectedShiftMapRouteQueryVariables
>;

/**
 * __useGetDriverMapsScreenQuery__
 *
 * To run a query within a React component, call `useGetDriverMapsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverMapsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverMapsScreenQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriverMapsScreenQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverMapsScreenQuery,
    Operations.GetDriverMapsScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverMapsScreenQuery,
    Operations.GetDriverMapsScreenQueryVariables
  >(Operations.GetDriverMapsScreen, options);
}
export function useGetDriverMapsScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverMapsScreenQuery,
    Operations.GetDriverMapsScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverMapsScreenQuery,
    Operations.GetDriverMapsScreenQueryVariables
  >(Operations.GetDriverMapsScreen, options);
}
export type GetDriverMapsScreenQueryHookResult = ReturnType<typeof useGetDriverMapsScreenQuery>;
export type GetDriverMapsScreenLazyQueryHookResult = ReturnType<
  typeof useGetDriverMapsScreenLazyQuery
>;
export type GetDriverMapsScreenQueryResult = Apollo.QueryResult<
  Operations.GetDriverMapsScreenQuery,
  Operations.GetDriverMapsScreenQueryVariables
>;

/**
 * __useGetReservationsScreenQuery__
 *
 * To run a query within a React component, call `useGetReservationsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsScreenQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetReservationsScreenQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetReservationsScreenQuery,
    Operations.GetReservationsScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetReservationsScreenQuery,
    Operations.GetReservationsScreenQueryVariables
  >(Operations.GetReservationsScreen, options);
}
export function useGetReservationsScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetReservationsScreenQuery,
    Operations.GetReservationsScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetReservationsScreenQuery,
    Operations.GetReservationsScreenQueryVariables
  >(Operations.GetReservationsScreen, options);
}
export type GetReservationsScreenQueryHookResult = ReturnType<typeof useGetReservationsScreenQuery>;
export type GetReservationsScreenLazyQueryHookResult = ReturnType<
  typeof useGetReservationsScreenLazyQuery
>;
export type GetReservationsScreenQueryResult = Apollo.QueryResult<
  Operations.GetReservationsScreenQuery,
  Operations.GetReservationsScreenQueryVariables
>;

/**
 * __useGetDriverIncidentDisputesQuery__
 *
 * To run a query within a React component, call `useGetDriverIncidentDisputesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverIncidentDisputesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverIncidentDisputesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriverIncidentDisputesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetDriverIncidentDisputesQuery,
    Operations.GetDriverIncidentDisputesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverIncidentDisputesQuery,
    Operations.GetDriverIncidentDisputesQueryVariables
  >(Operations.GetDriverIncidentDisputes, options);
}
export function useGetDriverIncidentDisputesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverIncidentDisputesQuery,
    Operations.GetDriverIncidentDisputesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverIncidentDisputesQuery,
    Operations.GetDriverIncidentDisputesQueryVariables
  >(Operations.GetDriverIncidentDisputes, options);
}
export type GetDriverIncidentDisputesQueryHookResult = ReturnType<
  typeof useGetDriverIncidentDisputesQuery
>;
export type GetDriverIncidentDisputesLazyQueryHookResult = ReturnType<
  typeof useGetDriverIncidentDisputesLazyQuery
>;
export type GetDriverIncidentDisputesQueryResult = Apollo.QueryResult<
  Operations.GetDriverIncidentDisputesQuery,
  Operations.GetDriverIncidentDisputesQueryVariables
>;

/**
 * __useGetOffboardingScreenListQuery__
 *
 * To run a query within a React component, call `useGetOffboardingScreenListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOffboardingScreenListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOffboardingScreenListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetOffboardingScreenListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.GetOffboardingScreenListQuery,
    Operations.GetOffboardingScreenListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetOffboardingScreenListQuery,
    Operations.GetOffboardingScreenListQueryVariables
  >(Operations.GetOffboardingScreenList, options);
}
export function useGetOffboardingScreenListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetOffboardingScreenListQuery,
    Operations.GetOffboardingScreenListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetOffboardingScreenListQuery,
    Operations.GetOffboardingScreenListQueryVariables
  >(Operations.GetOffboardingScreenList, options);
}
export type GetOffboardingScreenListQueryHookResult = ReturnType<
  typeof useGetOffboardingScreenListQuery
>;
export type GetOffboardingScreenListLazyQueryHookResult = ReturnType<
  typeof useGetOffboardingScreenListLazyQuery
>;
export type GetOffboardingScreenListQueryResult = Apollo.QueryResult<
  Operations.GetOffboardingScreenListQuery,
  Operations.GetOffboardingScreenListQueryVariables
>;
export type ConfirmCheckMutationFn = Apollo.MutationFunction<
  Operations.ConfirmCheckMutation,
  Operations.ConfirmCheckMutationVariables
>;

/**
 * __useConfirmCheckMutation__
 *
 * To run a mutation, you first call `useConfirmCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCheckMutation, { data, loading, error }] = useConfirmCheckMutation({
 *   variables: {
 *      payoutRecordId: // value for 'payoutRecordId'
 *   },
 * });
 */
export function useConfirmCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ConfirmCheckMutation,
    Operations.ConfirmCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ConfirmCheckMutation,
    Operations.ConfirmCheckMutationVariables
  >(Operations.ConfirmCheck, options);
}
export type ConfirmCheckMutationHookResult = ReturnType<typeof useConfirmCheckMutation>;
export type ConfirmCheckMutationResult = Apollo.MutationResult<Operations.ConfirmCheckMutation>;
export type ConfirmCheckMutationOptions = Apollo.BaseMutationOptions<
  Operations.ConfirmCheckMutation,
  Operations.ConfirmCheckMutationVariables
>;

/**
 * __useRideHailQuery__
 *
 * To run a query within a React component, call `useRideHailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideHailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideHailQuery({
 *   variables: {
 *      rideHailId: // value for 'rideHailId'
 *   },
 * });
 */
export function useRideHailQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.RideHailQuery, Operations.RideHailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.RideHailQuery, Operations.RideHailQueryVariables>(
    Operations.RideHail,
    options,
  );
}
export function useRideHailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.RideHailQuery,
    Operations.RideHailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.RideHailQuery, Operations.RideHailQueryVariables>(
    Operations.RideHail,
    options,
  );
}
export type RideHailQueryHookResult = ReturnType<typeof useRideHailQuery>;
export type RideHailLazyQueryHookResult = ReturnType<typeof useRideHailLazyQuery>;
export type RideHailQueryResult = Apollo.QueryResult<
  Operations.RideHailQuery,
  Operations.RideHailQueryVariables
>;

/**
 * __useGetScheduledShiftFilterCountQuery__
 *
 * To run a query within a React component, call `useGetScheduledShiftFilterCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledShiftFilterCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledShiftFilterCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function useGetScheduledShiftFilterCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetScheduledShiftFilterCountQuery,
    Operations.GetScheduledShiftFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetScheduledShiftFilterCountQuery,
    Operations.GetScheduledShiftFilterCountQueryVariables
  >(Operations.GetScheduledShiftFilterCount, options);
}
export function useGetScheduledShiftFilterCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetScheduledShiftFilterCountQuery,
    Operations.GetScheduledShiftFilterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetScheduledShiftFilterCountQuery,
    Operations.GetScheduledShiftFilterCountQueryVariables
  >(Operations.GetScheduledShiftFilterCount, options);
}
export type GetScheduledShiftFilterCountQueryHookResult = ReturnType<
  typeof useGetScheduledShiftFilterCountQuery
>;
export type GetScheduledShiftFilterCountLazyQueryHookResult = ReturnType<
  typeof useGetScheduledShiftFilterCountLazyQuery
>;
export type GetScheduledShiftFilterCountQueryResult = Apollo.QueryResult<
  Operations.GetScheduledShiftFilterCountQuery,
  Operations.GetScheduledShiftFilterCountQueryVariables
>;

/**
 * __useGetDriverScheduledShiftsScreenQuery__
 *
 * To run a query within a React component, call `useGetDriverScheduledShiftsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverScheduledShiftsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverScheduledShiftsScreenQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pageInfo: // value for 'pageInfo'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useGetDriverScheduledShiftsScreenQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverScheduledShiftsScreenQuery,
    Operations.GetDriverScheduledShiftsScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverScheduledShiftsScreenQuery,
    Operations.GetDriverScheduledShiftsScreenQueryVariables
  >(Operations.GetDriverScheduledShiftsScreen, options);
}
export function useGetDriverScheduledShiftsScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverScheduledShiftsScreenQuery,
    Operations.GetDriverScheduledShiftsScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverScheduledShiftsScreenQuery,
    Operations.GetDriverScheduledShiftsScreenQueryVariables
  >(Operations.GetDriverScheduledShiftsScreen, options);
}
export type GetDriverScheduledShiftsScreenQueryHookResult = ReturnType<
  typeof useGetDriverScheduledShiftsScreenQuery
>;
export type GetDriverScheduledShiftsScreenLazyQueryHookResult = ReturnType<
  typeof useGetDriverScheduledShiftsScreenLazyQuery
>;
export type GetDriverScheduledShiftsScreenQueryResult = Apollo.QueryResult<
  Operations.GetDriverScheduledShiftsScreenQuery,
  Operations.GetDriverScheduledShiftsScreenQueryVariables
>;
export type ManipulateStopPointMutationFn = Apollo.MutationFunction<
  Operations.ManipulateStopPointMutation,
  Operations.ManipulateStopPointMutationVariables
>;

/**
 * __useManipulateStopPointMutation__
 *
 * To run a mutation, you first call `useManipulateStopPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManipulateStopPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manipulateStopPointMutation, { data, loading, error }] = useManipulateStopPointMutation({
 *   variables: {
 *      stopPointId: // value for 'stopPointId'
 *      manipulationType: // value for 'manipulationType'
 *   },
 * });
 */
export function useManipulateStopPointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ManipulateStopPointMutation,
    Operations.ManipulateStopPointMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ManipulateStopPointMutation,
    Operations.ManipulateStopPointMutationVariables
  >(Operations.ManipulateStopPoint, options);
}
export type ManipulateStopPointMutationHookResult = ReturnType<
  typeof useManipulateStopPointMutation
>;
export type ManipulateStopPointMutationResult = Apollo.MutationResult<Operations.ManipulateStopPointMutation>;
export type ManipulateStopPointMutationOptions = Apollo.BaseMutationOptions<
  Operations.ManipulateStopPointMutation,
  Operations.ManipulateStopPointMutationVariables
>;

/**
 * __useGetShiftStopPointsQuery__
 *
 * To run a query within a React component, call `useGetShiftStopPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftStopPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftStopPointsQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetShiftStopPointsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetShiftStopPointsQuery,
    Operations.GetShiftStopPointsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetShiftStopPointsQuery,
    Operations.GetShiftStopPointsQueryVariables
  >(Operations.GetShiftStopPoints, options);
}
export function useGetShiftStopPointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetShiftStopPointsQuery,
    Operations.GetShiftStopPointsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetShiftStopPointsQuery,
    Operations.GetShiftStopPointsQueryVariables
  >(Operations.GetShiftStopPoints, options);
}
export type GetShiftStopPointsQueryHookResult = ReturnType<typeof useGetShiftStopPointsQuery>;
export type GetShiftStopPointsLazyQueryHookResult = ReturnType<
  typeof useGetShiftStopPointsLazyQuery
>;
export type GetShiftStopPointsQueryResult = Apollo.QueryResult<
  Operations.GetShiftStopPointsQuery,
  Operations.GetShiftStopPointsQueryVariables
>;

/**
 * __useGetDriverShiftsScreenQuery__
 *
 * To run a query within a React component, call `useGetDriverShiftsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverShiftsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverShiftsScreenQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDriverShiftsScreenQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverShiftsScreenQuery,
    Operations.GetDriverShiftsScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverShiftsScreenQuery,
    Operations.GetDriverShiftsScreenQueryVariables
  >(Operations.GetDriverShiftsScreen, options);
}
export function useGetDriverShiftsScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverShiftsScreenQuery,
    Operations.GetDriverShiftsScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverShiftsScreenQuery,
    Operations.GetDriverShiftsScreenQueryVariables
  >(Operations.GetDriverShiftsScreen, options);
}
export type GetDriverShiftsScreenQueryHookResult = ReturnType<typeof useGetDriverShiftsScreenQuery>;
export type GetDriverShiftsScreenLazyQueryHookResult = ReturnType<
  typeof useGetDriverShiftsScreenLazyQuery
>;
export type GetDriverShiftsScreenQueryResult = Apollo.QueryResult<
  Operations.GetDriverShiftsScreenQuery,
  Operations.GetDriverShiftsScreenQueryVariables
>;

/**
 * __useGetShiftResourceMetadataQuery__
 *
 * To run a query within a React component, call `useGetShiftResourceMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftResourceMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftResourceMetadataQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useGetShiftResourceMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetShiftResourceMetadataQuery,
    Operations.GetShiftResourceMetadataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetShiftResourceMetadataQuery,
    Operations.GetShiftResourceMetadataQueryVariables
  >(Operations.GetShiftResourceMetadata, options);
}
export function useGetShiftResourceMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetShiftResourceMetadataQuery,
    Operations.GetShiftResourceMetadataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetShiftResourceMetadataQuery,
    Operations.GetShiftResourceMetadataQueryVariables
  >(Operations.GetShiftResourceMetadata, options);
}
export type GetShiftResourceMetadataQueryHookResult = ReturnType<
  typeof useGetShiftResourceMetadataQuery
>;
export type GetShiftResourceMetadataLazyQueryHookResult = ReturnType<
  typeof useGetShiftResourceMetadataLazyQuery
>;
export type GetShiftResourceMetadataQueryResult = Apollo.QueryResult<
  Operations.GetShiftResourceMetadataQuery,
  Operations.GetShiftResourceMetadataQueryVariables
>;

/**
 * __useGetDriverFleetResourceQuery__
 *
 * To run a query within a React component, call `useGetDriverFleetResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverFleetResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverFleetResourceQuery({
 *   variables: {
 *      fleetResourceId: // value for 'fleetResourceId'
 *   },
 * });
 */
export function useGetDriverFleetResourceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetDriverFleetResourceQuery,
    Operations.GetDriverFleetResourceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetDriverFleetResourceQuery,
    Operations.GetDriverFleetResourceQueryVariables
  >(Operations.GetDriverFleetResource, options);
}
export function useGetDriverFleetResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetDriverFleetResourceQuery,
    Operations.GetDriverFleetResourceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetDriverFleetResourceQuery,
    Operations.GetDriverFleetResourceQueryVariables
  >(Operations.GetDriverFleetResource, options);
}
export type GetDriverFleetResourceQueryHookResult = ReturnType<
  typeof useGetDriverFleetResourceQuery
>;
export type GetDriverFleetResourceLazyQueryHookResult = ReturnType<
  typeof useGetDriverFleetResourceLazyQuery
>;
export type GetDriverFleetResourceQueryResult = Apollo.QueryResult<
  Operations.GetDriverFleetResourceQuery,
  Operations.GetDriverFleetResourceQueryVariables
>;

/**
 * __useGetVehiclesScreenQuery__
 *
 * To run a query within a React component, call `useGetVehiclesScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclesScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclesScreenQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetVehiclesScreenQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetVehiclesScreenQuery,
    Operations.GetVehiclesScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.GetVehiclesScreenQuery,
    Operations.GetVehiclesScreenQueryVariables
  >(Operations.GetVehiclesScreen, options);
}
export function useGetVehiclesScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetVehiclesScreenQuery,
    Operations.GetVehiclesScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetVehiclesScreenQuery,
    Operations.GetVehiclesScreenQueryVariables
  >(Operations.GetVehiclesScreen, options);
}
export type GetVehiclesScreenQueryHookResult = ReturnType<typeof useGetVehiclesScreenQuery>;
export type GetVehiclesScreenLazyQueryHookResult = ReturnType<typeof useGetVehiclesScreenLazyQuery>;
export type GetVehiclesScreenQueryResult = Apollo.QueryResult<
  Operations.GetVehiclesScreenQuery,
  Operations.GetVehiclesScreenQueryVariables
>;

/**
 * __useZonePageZonesQuery__
 *
 * To run a query within a React component, call `useZonePageZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useZonePageZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZonePageZonesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useZonePageZonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ZonePageZonesQuery,
    Operations.ZonePageZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ZonePageZonesQuery, Operations.ZonePageZonesQueryVariables>(
    Operations.ZonePageZones,
    options,
  );
}
export function useZonePageZonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ZonePageZonesQuery,
    Operations.ZonePageZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ZonePageZonesQuery, Operations.ZonePageZonesQueryVariables>(
    Operations.ZonePageZones,
    options,
  );
}
export type ZonePageZonesQueryHookResult = ReturnType<typeof useZonePageZonesQuery>;
export type ZonePageZonesLazyQueryHookResult = ReturnType<typeof useZonePageZonesLazyQuery>;
export type ZonePageZonesQueryResult = Apollo.QueryResult<
  Operations.ZonePageZonesQuery,
  Operations.ZonePageZonesQueryVariables
>;

/**
 * __useGetSystemQueryQuery__
 *
 * To run a query within a React component, call `useGetSystemQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemQueryQuery({
 *   variables: {
 *      systemIdentifier: // value for 'systemIdentifier'
 *   },
 * });
 */
export function useGetSystemQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.GetSystemQueryQuery,
    Operations.GetSystemQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.GetSystemQueryQuery, Operations.GetSystemQueryQueryVariables>(
    Operations.GetSystemQuery,
    options,
  );
}
export function useGetSystemQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.GetSystemQueryQuery,
    Operations.GetSystemQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.GetSystemQueryQuery,
    Operations.GetSystemQueryQueryVariables
  >(Operations.GetSystemQuery, options);
}
export type GetSystemQueryQueryHookResult = ReturnType<typeof useGetSystemQueryQuery>;
export type GetSystemQueryLazyQueryHookResult = ReturnType<typeof useGetSystemQueryLazyQuery>;
export type GetSystemQueryQueryResult = Apollo.QueryResult<
  Operations.GetSystemQueryQuery,
  Operations.GetSystemQueryQueryVariables
>;
export const namedOperations = {
  Query: {
    GetDriverVehicleSelectorVehicles: 'GetDriverVehicleSelectorVehicles',
    GetDepotSelectorDepots: 'GetDepotSelectorDepots',
    GetInvoiceDetailsSidebar: 'GetInvoiceDetailsSidebar',
    GetSystemsDepots: 'GetSystemsDepots',
    GetDriverSystemZones: 'GetDriverSystemZones',
    ListNotesReservationActionShortcuts: 'ListNotesReservationActionShortcuts',
    GetReservationDetailsSidebar: 'GetReservationDetailsSidebar',
    GetReservationFilterCount: 'GetReservationFilterCount',
    GetScheduledShiftDetailsSidebar: 'GetScheduledShiftDetailsSidebar',
    GetShiftInfo: 'GetShiftInfo',
    GetShiftFilterCount: 'GetShiftFilterCount',
    GetShiftMapMarkerInfo: 'GetShiftMapMarkerInfo',
    SpotlightDriverSearch: 'SpotlightDriverSearch',
    GetVehicleFacilities: 'GetVehicleFacilities',
    GetVehicleFilterCount: 'GetVehicleFilterCount',
    GetVehicleSelectorVehicles: 'GetVehicleSelectorVehicles',
    CalculateDriverVehicleCreditAmount: 'CalculateDriverVehicleCreditAmount',
    GetDriverAvailableCredit: 'GetDriverAvailableCredit',
    GetAssignVehicleToReservationReservation: 'GetAssignVehicleToReservationReservation',
    GetAssignVehicleToReservationDriverDetails: 'GetAssignVehicleToReservationDriverDetails',
    GetAssignVehicleToShiftDriverDetails: 'GetAssignVehicleToShiftDriverDetails',
    GetCancelReservationModal: 'GetCancelReservationModal',
    GetCancelScheduledShiftModal: 'GetCancelScheduledShiftModal',
    GetShiftCancellationReasons: 'GetShiftCancellationReasons',
    GetCheckInReservationModal: 'GetCheckInReservationModal',
    GetDriverEmploymentType: 'GetDriverEmploymentType',
    GetInsuranceProviders: 'GetInsuranceProviders',
    GetAdicRegistrations: 'GetAdicRegistrations',
    GetDriverFleetResourceDetails: 'GetDriverFleetResourceDetails',
    GetDriverVehiclePreference: 'GetDriverVehiclePreference',
    GetDriverAvailableSecurityDepositLedger: 'GetDriverAvailableSecurityDepositLedger',
    GetReservationBillingSettings: 'GetReservationBillingSettings',
    GetEditReservationModal: 'GetEditReservationModal',
    GetEndReservationModal: 'GetEndReservationModal',
    GetEndScheduledShiftModal: 'GetEndScheduledShiftModal',
    ListIncidents: 'ListIncidents',
    GetIssueDetailsModalIssue: 'GetIssueDetailsModalIssue',
    GetDriverIncidentNoteInfo: 'GetDriverIncidentNoteInfo',
    GetReservationNoteModal: 'GetReservationNoteModal',
    GetScheduledShiftNoteModal: 'GetScheduledShiftNoteModal',
    GetNotesCount: 'GetNotesCount',
    GetNotes: 'GetNotes',
    GetDriverIncidentReviewDispute: 'GetDriverIncidentReviewDispute',
    GetStandbyReservationModal: 'GetStandbyReservationModal',
    GetDriverVoidInvoiceModal: 'GetDriverVoidInvoiceModal',
    GetInvoiceVoidedReasons: 'GetInvoiceVoidedReasons',
    GetDepositDetailsSidebar: 'GetDepositDetailsSidebar',
    GetDriverSecurityDepositLedgerRecords: 'GetDriverSecurityDepositLedgerRecords',
    GetDriverRestrictionsContent: 'GetDriverRestrictionsContent',
    GetTracks: 'GetTracks',
    GetDriverIncidents: 'GetDriverIncidents',
    GetDriverIncident: 'GetDriverIncident',
    GetInvoiceTabInvoices: 'GetInvoiceTabInvoices',
    GetReservationsTab: 'GetReservationsTab',
    GetRidesTabRides: 'GetRidesTabRides',
    ListRecurringShifts: 'ListRecurringShifts',
    GetRecurringShift: 'GetRecurringShift',
    GetShiftsTabShifts: 'GetShiftsTabShifts',
    ListTrafficViolationTickets: 'ListTrafficViolationTickets',
    GetDriversScreen: 'GetDriversScreen',
    GetDriver: 'GetDriver',
    GetDriverAddress: 'GetDriverAddress',
    GetDriverLicenses: 'GetDriverLicenses',
    GetDriverTracks: 'GetDriverTracks',
    GetDriverShift: 'GetDriverShift',
    GetDriverOtpValidationCode: 'GetDriverOtpValidationCode',
    GetInvoicesScreen: 'GetInvoicesScreen',
    GetSelectedShiftMapLocation: 'GetSelectedShiftMapLocation',
    GetBalancerMapConfig: 'GetBalancerMapConfig',
    GetBalancerMap: 'GetBalancerMap',
    GetSelectedShiftMapRoute: 'GetSelectedShiftMapRoute',
    GetDriverMapsScreen: 'GetDriverMapsScreen',
    GetReservationsScreen: 'GetReservationsScreen',
    GetDriverIncidentDisputes: 'GetDriverIncidentDisputes',
    GetOffboardingScreenList: 'GetOffboardingScreenList',
    RideHail: 'RideHail',
    GetScheduledShiftFilterCount: 'GetScheduledShiftFilterCount',
    GetDriverScheduledShiftsScreen: 'GetDriverScheduledShiftsScreen',
    GetShiftStopPoints: 'GetShiftStopPoints',
    GetDriverShiftsScreen: 'GetDriverShiftsScreen',
    GetShiftResourceMetadata: 'GetShiftResourceMetadata',
    GetDriverFleetResource: 'GetDriverFleetResource',
    GetVehiclesScreen: 'GetVehiclesScreen',
    ZonePageZones: 'ZonePageZones',
    GetSystemQuery: 'GetSystemQuery',
  },
  Mutation: {
    CommandResource: 'CommandResource',
    IssueRentalCredit: 'IssueRentalCredit',
    AssignVehicleToReservation: 'AssignVehicleToReservation',
    RemoveAssignedVehicleFromReservation: 'RemoveAssignedVehicleFromReservation',
    CancelReservation: 'CancelReservation',
    CancelScheduledShift: 'CancelScheduledShift',
    CancelDriverShift: 'CancelDriverShift',
    CheckInReservation: 'CheckInReservation',
    CheckInShiftOverride: 'CheckInShiftOverride',
    ReportDriverShiftIssue: 'ReportDriverShiftIssue',
    CreateManualInvoice: 'CreateManualInvoice',
    CreateRecurringShift: 'CreateRecurringShift',
    CreateReservation: 'CreateReservation',
    CreateDriverShift: 'CreateDriverShift',
    DeleteRecurringShift: 'DeleteRecurringShift',
    UpdateDriverAddress: 'UpdateDriverAddress',
    UpdateDriverInsuranceApprovals: 'UpdateDriverInsuranceApprovals',
    SubmitAdicRegistration: 'SubmitAdicRegistration',
    UpdateDriverVehicleExclusions: 'UpdateDriverVehicleExclusions',
    UpdateDriverVehicle: 'UpdateDriverVehicle',
    UpdateDriverVehiclePreference: 'UpdateDriverVehiclePreference',
    UpdateDriverWeeklyDowngrade: 'UpdateDriverWeeklyDowngrade',
    UpdateDriverWeeklyRental: 'UpdateDriverWeeklyRental',
    UpdateRecurringShift: 'UpdateRecurringShift',
    UpdateRecurringShiftSettings: 'UpdateRecurringShiftSettings',
    UpdateDriverReservation: 'UpdateDriverReservation',
    EndReservation: 'EndReservation',
    EndScheduledShift: 'EndScheduledShift',
    EndShiftShiftOverride: 'EndShiftShiftOverride',
    CreateDriverIncident: 'CreateDriverIncident',
    ResolveDriverShiftIssue: 'ResolveDriverShiftIssue',
    SubmitUpdateLicenseReview: 'SubmitUpdateLicenseReview',
    UpdateLicense: 'UpdateLicense',
    CreateLicense: 'CreateLicense',
    CreateNote: 'CreateNote',
    ResolveNote: 'ResolveNote',
    UnresolveNote: 'UnresolveNote',
    CreateDriverIssue: 'CreateDriverIssue',
    ReviewDriverIncidentDispute: 'ReviewDriverIncidentDispute',
    UpdateDriverShift: 'UpdateDriverShift',
    UpdateShiftClockTimes: 'UpdateShiftClockTimes',
    UpdateShiftSettings: 'UpdateShiftSettings',
    AssignVehicleToShift: 'AssignVehicleToShift',
    RemoveAssignedVehicleFromShift: 'RemoveAssignedVehicleFromShift',
    UpdateShiftNote: 'UpdateShiftNote',
    UpdateShiftExemptions: 'UpdateShiftExemptions',
    StandbyReservation: 'StandbyReservation',
    StandbyShiftModal: 'StandbyShiftModal',
    VoidInvoice: 'VoidInvoice',
    ResendDocusignDocuments: 'ResendDocusignDocuments',
    ResendBackgroundCheck: 'ResendBackgroundCheck',
    GenerateDriverAssetPresignedPost: 'GenerateDriverAssetPresignedPost',
    UpdateDriverAvatar: 'UpdateDriverAvatar',
    UpdateDriverInfo: 'UpdateDriverInfo',
    RetryInvoice: 'RetryInvoice',
    ConfirmCheck: 'ConfirmCheck',
    ManipulateStopPoint: 'ManipulateStopPoint',
  },
  Fragment: {
    AdvancedVehicleSelector_vehicleFacility: 'AdvancedVehicleSelector_vehicleFacility',
    IncidentInfo_incident: 'IncidentInfo_incident',
    DriverNotesCard_note: 'DriverNotesCard_note',
    DriverShiftArrival_shift: 'DriverShiftArrival_shift',
    ReservationActionDropdown_reservation: 'ReservationActionDropdown_reservation',
    ReservationActionShortcuts_reservation: 'ReservationActionShortcuts_reservation',
    RentalReservationDuration_reservation: 'RentalReservationDuration_reservation',
    ReservationInfoStack_reservation: 'ReservationInfoStack_reservation',
    ReservationVehicle_reservation: 'ReservationVehicle_reservation',
    ScheduledShiftActionDropdown_shift: 'ScheduledShiftActionDropdown_shift',
    ScheduledShiftDriverInfoField_shift: 'ScheduledShiftDriverInfoField_shift',
    ScheduledShiftInfoStack_shift: 'ScheduledShiftInfoStack_shift',
    ScheduledShiftStatusChip_shift: 'ScheduledShiftStatusChip_shift',
    ScheduledShiftVehicle_shift: 'ScheduledShiftVehicle_shift',
    ShiftActionDropdown_shift: 'ShiftActionDropdown_shift',
    ShiftActionShortcuts_shift: 'ShiftActionShortcuts_shift',
    ShiftClockOut_shift: 'ShiftClockOut_shift',
    ShiftDriverInfoField_shift: 'ShiftDriverInfoField_shift',
    ShiftInfoCard_shift: 'ShiftInfoCard_shift',
    ShiftInfoPanel_shift: 'ShiftInfoPanel_shift',
    ShiftMapMarker_shift: 'ShiftMapMarker_shift',
    ShiftScheduledEnd_shift: 'ShiftScheduledEnd_shift',
    ShiftStatCard_shift: 'ShiftStatCard_shift',
    ShiftVehicle_shift: 'ShiftVehicle_shift',
    VehicleInfoButton_fleetResource: 'VehicleInfoButton_fleetResource',
    VehicleInfoPanel_vehicle: 'VehicleInfoPanel_vehicle',
    VehicleLocationUpdateBadge_fleetResource: 'VehicleLocationUpdateBadge_fleetResource',
    VehicleMakeModel: 'VehicleMakeModel',
    IncidentDisputeDetail_dispute: 'IncidentDisputeDetail_dispute',
    GetInvoiceTabInvoices_invoice: 'GetInvoiceTabInvoices_invoice',
    GetDriverLicenses_license: 'GetDriverLicenses_license',
    InvoiceDriverInfoField_invoice: 'InvoiceDriverInfoField_invoice',
    GetInvoicesScreen_invoice: 'GetInvoicesScreen_invoice',
    ScheduledShiftClockOut_shift: 'ScheduledShiftClockOut_shift',
    ScheduledShiftScheduledEnd_shift: 'ScheduledShiftScheduledEnd_shift',
    StopPointActionMenu_stopPoint: 'StopPointActionMenu_stopPoint',
    ZoneGroup_zone: 'ZoneGroup_zone',
    SystemSettings_system: 'SystemSettings_system',
  },
};
