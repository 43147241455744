import { createContext, FC, useContext, useEffect } from 'react';
import { useSession } from 'next-auth/client';
import { useGetSystemQueryQuery } from 'generated/page';
import { triggerApolloErrorNotification } from 'services/utils';
import { useRouter } from 'next/router';
import { gql } from '@apollo/client';
import { SystemSettings_systemFragment } from 'generated/types';
import { dayjsRoot } from 'services/dayjs';
import { LoadingOverlay } from '@revel-transit/revel-components-internal';
import { DEFAULT_OPERATION, OPERATIONS, OperationId, Operation } from './constants';

gql`
  fragment SystemSettings_system on System {
    id
    timeZone
    centerPoint {
      lat
      lng
    }
    settings {
      lowBatteryTagPercentage
    }
  }

  query GetSystemQuery($systemIdentifier: String!) {
    system: getSystem(systemIdentifier: $systemIdentifier) {
      ...SystemSettings_system
    }
  }
`;

export interface OperationContext {
  selectedOperation: Operation;
  system?: SystemSettings_systemFragment;
}

const OperationContext = createContext<OperationContext>({
  selectedOperation: DEFAULT_OPERATION,
});

const useSelectedOperation = () => {
  return useContext(OperationContext);
};

const OperationContextProvider: FC = ({ children }) => {
  const [session] = useSession();
  const router = useRouter();
  const selectedOperationId = router.query.operationId as OperationId;
  const selectedOperation = OPERATIONS[selectedOperationId] || DEFAULT_OPERATION;
  const skip = !session;

  // Only fetch the system if the user is authenticated
  const { data } = useGetSystemQueryQuery({
    skip: !session,
    variables: { systemIdentifier: selectedOperation.systemIdentifier },
    onError: (err) => triggerApolloErrorNotification(err, 'Error loading system'),
  });

  useEffect(() => {
    if (data?.system?.timeZone) {
      dayjsRoot.tz.setDefault(data.system.timeZone);
    } else {
      dayjsRoot.tz.setDefault();
    }
  }, [data?.system?.timeZone]);

  if (!skip && !data) {
    return <LoadingOverlay visible />;
  }

  return (
    <OperationContext.Provider
      value={{
        selectedOperation,
        system: data?.system,
      }}
    >
      {children}
    </OperationContext.Provider>
  );
};

export { useSelectedOperation, OperationContextProvider };
