import dayjsRoot from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';

// Dayjs Plugins
dayjsRoot.extend(utc);
dayjsRoot.extend(timezone);
dayjsRoot.extend(duration);
dayjsRoot.extend(relativeTime);
dayjsRoot.extend(weekday);
dayjsRoot.extend(isBetween);
dayjsRoot.extend(advancedFormat);
dayjsRoot.extend(isSameOrBefore);
dayjsRoot.extend(isSameOrAfter);
dayjsRoot.extend(isToday);
dayjsRoot.extend(customParseFormat);
dayjsRoot.extend(isoWeek);

// Defaults
dayjsRoot.tz.setDefault('America/New_York');

dayjsRoot.Ls.en.weekStart = 1;

export { dayjsRoot };

export function getDefaultTz() {
  // @ts-ignore
  return dayjsRoot().tz().$x.$timezone; // hack to get the default timezone from the dayjs object
}

export function getLocalTz() {
  return dayjsRoot.tz.guess();
}

export function dayjs(
  date?: dayjsRoot.ConfigType,
  format?: dayjsRoot.OptionType,
  keepLocalTime?: boolean,
) {
  const defaultTz = getDefaultTz();
  if (format) {
    return dayjsRoot(date, format).tz(defaultTz, keepLocalTime);
  }
  return dayjsRoot(date).tz(defaultTz, keepLocalTime);
}

export const dateToTzDate = (date: dayjsRoot.ConfigType) => {
  const defaultTz = getDefaultTz();
  return dayjsRoot(date).tz(defaultTz, true);
};

export function dateFromTzDate(date: dayjsRoot.ConfigType) {
  const localTz = getLocalTz();
  return dayjsRoot(date).tz().tz(localTz, true);
}

export type Dayjs = dayjsRoot.Dayjs;
