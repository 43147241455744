import * as Icons from '@revel-transit/revel-icons';

export enum OperationId {
  NY = 'ny',
  LA = 'la',
}

export enum OperationTypes {
  FLEET = 'fleet',
  RIDESHARE = 'rideshare',
}

export interface Operation {
  id: OperationId;
  name: string;
  type: OperationTypes;
  systemIdentifier: string;
  homePage: string;
  timezone: string;
}

export const OPERATIONS: Record<OperationId, Operation> = {
  [OperationId.NY]: {
    id: OperationId.NY,
    name: 'New York',
    type: OperationTypes.RIDESHARE,
    systemIdentifier: 'newyork',
    homePage: `/${OperationId.NY}/shifts`,
    timezone: 'America/New_York',
  },
  [OperationId.LA]: {
    id: OperationId.LA,
    name: 'Los Angeles',
    type: OperationTypes.FLEET,
    systemIdentifier: 'losangeles',
    homePage: `/${OperationId.LA}/schedules`,
    timezone: 'America/Los_Angeles',
  },
};

export const DEFAULT_OPERATION = OPERATIONS[OperationId.NY];

const RIDESHARE_PAGES = [
  ...(process.env.NEXT_PUBLIC_HAS_RESERVATIONS_TAB === 'true'
    ? [
        {
          label: 'Reservations',
          href: '/reservations',
          icon: <Icons.Car />,
        },
      ]
    : [
        {
          label: 'Shifts',
          href: '/shifts',
          icon: <Icons.Car />,
        },
      ]),
  {
    label: 'Map',
    href: '/map',
    icon: <Icons.Map />,
  },
  {
    label: 'Drivers',
    href: '/drivers',
    icon: <Icons.Person />,
  },
  {
    label: 'Vehicles',
    href: '/vehicles',
    icon: <Icons.Car />,
  },
  {
    label: 'Invoices',
    href: '/invoices',
    icon: <Icons.Money />,
  },
  {
    label: 'Reviews',
    href: '/reviews',
    icon: <Icons.IDCard />,
  },
];

const FLEET_PAGES = [
  {
    label: 'Shifts',
    href: '/schedules',
    icon: <Icons.Clock />,
  },
  {
    label: 'Vehicles',
    href: '/vehicles',
    icon: <Icons.Car />,
  },
  {
    label: 'Drivers',
    href: '/drivers',
    icon: <Icons.Person />,
  },
  {
    label: 'Reviews',
    href: '/reviews',
    icon: <Icons.IDCard />,
  },
];

export const OPERATION_PAGES: Record<
  OperationTypes,
  {
    label: string;
    href: string;
    icon: JSX.Element;
  }[]
> = {
  [OperationTypes.RIDESHARE]: RIDESHARE_PAGES,
  [OperationTypes.FLEET]: FLEET_PAGES,
};
